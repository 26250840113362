package com.jet.classroomhero.data.remote.sources

import com.jet.classroomhero.Logger
import com.jet.classroomhero.UtilsCommon.Companion.getLocalDateTimeWithTimeZoneOffset
import com.jet.classroomhero.data.LocalUserSource
import com.jet.classroomhero.data.ProtectedRemoteUserSource
import com.jet.classroomhero.entities.ImageUploadResponse
import com.jet.classroomhero.entities.User
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import kotlinx.datetime.Clock
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json

class ProtectedNetworkUserSource(localUserSource: LocalUserSource) : ProtectedNetworkSource(localUserSource), ProtectedRemoteUserSource {
    val logger = Logger()

    init {
        logger.logMessage("$TAG init")
    }

    companion object {
        private val TAG = ProtectedNetworkUserSource::class.simpleName
    }

  override suspend fun changePassword(password: String): Boolean = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/accounts/change_password/"
        val requestBody = Json.parseToJsonElement("""
            {
                "password": $password
            }
        """)
        val response: HttpResponse = client.put(url) {
            contentType(ContentType.Application.Json)
            setBody(requestBody)
        }
        return@withContext response.status.isSuccess()
    }

    override suspend fun changeUsername(username: String): User = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/accounts/change_username/"
        val requestBody = Json.parseToJsonElement("""
            {
                "username": $username
            }
        """)
        return@withContext client.put(url) {
            contentType(ContentType.Application.Json)
            setBody(requestBody)
        }.body<User>()
    }

    override suspend fun changeEmail(email: String): Boolean = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/accounts/change_email/"
        val requestBody = Json.parseToJsonElement("""
            {
                "email": $email
            }
        """)
        val response: HttpResponse = client.put(url) {
            contentType(ContentType.Application.Json)
            setBody(requestBody)
        }
        return@withContext response.status.isSuccess()
    }

    override suspend fun deleteAccount(): Boolean = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/accounts/delete/"
        val response: HttpResponse = client.delete(url)
        return@withContext response.status.isSuccess()
    }


    override suspend fun editAccount(firstName: String, lastName: String, email: String, username: String, role: String): User = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/accounts/edit/"
        val requestBody = Json.parseToJsonElement("""
            {
                "first_name": "$firstName",
                "last_name": "$lastName",
                "email": "$email",
                "username": "$username",
                "role": $role
            }
        """)
        logger.logMessage(requestBody.toString())
        return@withContext client.patch(url) {
            contentType(ContentType.Application.Json)
            setBody(requestBody)
        }.body<User>()
    }


    override suspend fun finishAccount(firstName: String, password: String): User = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/accounts/register/finish_account/"
        val requestBody = Json.parseToJsonElement(
            """
            {
                "first_name": "$firstName",
                "password": "$password"
            }
        """
        )
        logger.logMessage(requestBody.toString())
        return@withContext client.put(url) {
            contentType(ContentType.Application.Json)
            setBody(requestBody)
        }.body() as User
    }

    override suspend fun clearJwtToken() {
      client.config {
        Auth {
          providers.filterIsInstance<BearerAuthProvider>().forEach {
            logger.logMessage("COMMON: Clearing Auth Token")
            it.clearToken()
          }
        }
      }
    }

    override suspend fun userDetail(): User = withContext(Dispatchers.Default) {
      val requestBody = Json.parseToJsonElement("""
            {
                "timestamp": "${getLocalDateTimeWithTimeZoneOffset()}"
            }
        """)
      return@withContext client.post("${baseUrl}api/accounts/me/") {
        contentType(ContentType.Application.Json)
        setBody(requestBody)
      }.body<User>()
    }

    override suspend fun updateProfilePhoto(bytes: ByteArray): ImageUploadResponse {
        println("updating profile photo")
        val url = "${baseUrl}api/accounts/update_photo/"
        val response: HttpResponse = client.submitFormWithBinaryData(
          url = url,
          formData = formData {
            append("description", "Profile Photo Upload")
            append("image", bytes, Headers.build {
              append(HttpHeaders.ContentType, "image/png")
              append(HttpHeaders.ContentDisposition, "filename=profile_photo_${Clock.System.now().toEpochMilliseconds()}.png")
            })
          }
        ) {
          onUpload { bytesSentTotal, contentLength ->
            println("Sent $bytesSentTotal bytes from $contentLength")
          }
        }
      try {
        val rawJson: String = response.body()
        return Json.decodeFromString(rawJson)
      } catch (e: Exception) {
        throw Exception("Error parsing response: ${e.message}")
      }
    }

    override suspend fun updateProfileCover(bytes: ByteArray): ImageUploadResponse {
      println("updating cover photo")
      val url = "${baseUrl}api/accounts/update_cover/"
      val response: HttpResponse = client.submitFormWithBinaryData(
        url = url,
        formData = formData {
          append("description", "Cover Photo Upload")
          append("image", bytes, Headers.build {
            append(HttpHeaders.ContentType, "image/png")
            append(HttpHeaders.ContentDisposition, "filename=cover_photo_${Clock.System.now().toEpochMilliseconds()}.png")
          })
        }
      ) {
        onUpload { bytesSentTotal, contentLength ->
          println("Sent $bytesSentTotal bytes from $contentLength")
        }
      }
      try {
        val rawJson: String = response.body()
        return Json.decodeFromString(rawJson)
      } catch (e: Exception) {
        throw Exception("Error parsing response: ${e.message}")
      }
    }

    override suspend fun createFirebaseMessagingDevice(token: String, platform: String): Boolean = withContext(Dispatchers.Default) {
      val url = "${baseUrl}api/accounts/fcm/create_device/"
      val requestBody = Json.parseToJsonElement(
        """
            {
                "registration_id": "$token",
                "type": "$platform"
            }
        """
      )
      logger.logMessage(requestBody.toString())
      return@withContext client.post(url) {
        contentType(ContentType.Application.Json)
        setBody(requestBody)
      }.status.isSuccess()
    }

}