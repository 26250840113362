package com.jet.classroomhero.data.remote.sources

import com.jet.classroomhero.Logger
import com.jet.classroomhero.UtilsCommon
import com.jet.classroomhero.data.LocalUserSource
import com.jet.classroomhero.data.RemoteClassSource
import com.jet.classroomhero.entities.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.client.utils.EmptyContent.contentType
import io.ktor.http.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json

class NetworkClassSource(localUserSource: LocalUserSource) : ProtectedNetworkSource(localUserSource), RemoteClassSource {
    val logger = Logger()

    override suspend fun createClass(newClass: Class, templateId: Int?): Class = withContext(Dispatchers.Default) {
        val url = if (templateId != null && templateId != 0){
            "${baseUrl}api/classes/create/${templateId}/"
        } else {
            "${baseUrl}api/classes/create/"
        }

        return@withContext client.post(url) {
            contentType(ContentType.Application.Json)
            setBody(newClass)
        }.body()
    }

    override suspend fun editClass(updatedClass: Class): Class = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${updatedClass.id}/edit/"
        logger.logMessage(updatedClass.toString())
        return@withContext client.put(url) {
            contentType(ContentType.Application.Json)
            setBody(updatedClass)
        }.body()
    }

    override suspend fun deleteClass(classId: Int): Boolean = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/delete/"
        val response: HttpResponse = client.delete(url) {
            contentType(ContentType.Application.Json)
        }
        return@withContext response.status.isSuccess()
    }

    override suspend fun fetchGroup(groupId: Int): Class = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/$groupId/"
        return@withContext client.get(url) {
            contentType(ContentType.Application.Json)
        }.body()
    }

    override suspend fun fetchClasses(): List<Class> = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/"
        val json = client.get(url) {
            contentType(ContentType.Application.Json)
        }.bodyAsText()
        return@withContext UtilsCommon.jsonConfig.decodeFromString(json)
    }

    override suspend fun fetchMemberships(): List<Class> = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/memberships/"
        val json = client.get(url) {
            contentType(ContentType.Application.Json)
        }.bodyAsText()
        return@withContext UtilsCommon.jsonConfig.decodeFromString(json)
    }

    override suspend fun fetchGroupStats(groupId: Int): GroupStats = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${groupId}/stats/"
        return@withContext client.get(url) {
            contentType(ContentType.Application.Json)
        }.body()
    }

    @OptIn(ExperimentalSerializationApi::class)
    override suspend fun updateCurrencyIcon(groupId: Int, bytes: ByteArray): ImageUploadResponse = withContext(Dispatchers.Default) {
        println("$TAG updating profile photo")
        val url = "${baseUrl}api/classes/${groupId}/update_currency_icon/"
        val response: HttpResponse = client.submitFormWithBinaryData(
            url = url,
            formData = formData {
                append("description", "Currency Icon Upload")
                append("image", bytes, Headers.build {
                    append(HttpHeaders.ContentType, "image/png")
                    append(HttpHeaders.ContentDisposition, "filename=currency_icon_${groupId}.png")
                })
            }
        ) {
            onUpload { bytesSentTotal, contentLength ->
                // TODO: Hook in a callback so that we can show a progress bar in the UI
                println("Sent $bytesSentTotal bytes from $contentLength")
//                if (bytesSentTotal == contentLength) {
//
//                }
            }
        }
        try {
            val rawJson: String = response.body()
            println("$TAG received raw json $rawJson")
            val uploadResponse = Json.decodeFromString<ImageUploadResponse>(rawJson)
            println("$TAG response $uploadResponse")
            return@withContext uploadResponse
        } catch (e: Exception) {
            println("$TAG exception ${e.message}")
            throw Exception("Error parsing response: ${e.message}")
        }
    }

    override suspend fun updateGroupLogo(groupId: Int, bytes: ByteArray): ImageUploadResponse = withContext(Dispatchers.Default) {
        println("$TAG updating group logo")
        val url = "${baseUrl}api/classes/${groupId}/update_group_logo/"
        val response: HttpResponse = client.submitFormWithBinaryData(
            url = url,
            formData = formData {
                append("description", "Group Logo Upload")
                append("image", bytes, Headers.build {
                    append(HttpHeaders.ContentType, "image/png")
                    append(HttpHeaders.ContentDisposition, "filename=group_logo_${groupId}.png")
                })
            }
        ) {
            onUpload { bytesSentTotal, contentLength ->
                println("Sent $bytesSentTotal bytes from $contentLength")
            }
        }
        try {
            val rawJson: String = response.body()
            println("$TAG received raw json $rawJson")
            val uploadResponse = Json.decodeFromString<ImageUploadResponse>(rawJson)
            println("$TAG response $uploadResponse")
            return@withContext uploadResponse
        } catch (e: Exception) {
            println("$TAG exception ${e.message}")
            throw Exception("Error parsing response: ${e.message}")
        }
    }

    override suspend fun updateGroupCover(groupId: Int, bytes: ByteArray): ImageUploadResponse = withContext(Dispatchers.Default) {
        println("$TAG updating group cover")
        val url = "${baseUrl}api/classes/${groupId}/update_group_cover/"
        val response: HttpResponse = client.submitFormWithBinaryData(
            url = url,
            formData = formData {
                append("description", "Group Cover Upload")
                append("image", bytes, Headers.build {
                    append(HttpHeaders.ContentType, "image/png")
                    append(HttpHeaders.ContentDisposition, "filename=group_cover_${groupId}.png")
                })
            }
        ) {
            onUpload { bytesSentTotal, contentLength ->
                println("Sent $bytesSentTotal bytes from $contentLength")
            }
        }
        try {
            val rawJson: String = response.body()
            println("$TAG received raw json $rawJson")
            val uploadResponse = Json.decodeFromString<ImageUploadResponse>(rawJson)
            println("$TAG response $uploadResponse")
            return@withContext uploadResponse
        } catch (e: Exception) {
            println("$TAG exception ${e.message}")
            throw Exception("Error parsing response: ${e.message}")
        }
    }

    override suspend fun resetGroup(groupId: Int): Boolean = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${groupId}/reset/"
        val response: HttpResponse = client.put(url)
        return@withContext response.status.isSuccess()
    }

    override suspend fun fetchStockGroupMedia(): StockGroupMediaResponse = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/stock_media/"
        return@withContext client.get(url).body()
    }

    override suspend fun completeInvite(classHash: String): Class = withContext(Dispatchers.Default) {
       return@withContext client.get("${baseUrl}api/classes/complete_invite/$classHash/"){
           contentType(ContentType.Application.Json)
       }.body()
    }

    override suspend fun leaveGroup(groupId: Int): Boolean = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${groupId}/unlink_group_member/"
        val response: HttpResponse = client.put(url)
        return@withContext response.status.isSuccess()
    }

    override suspend fun completeStudentInvite(studentHash: String): Class = withContext(Dispatchers.Default) {
        return@withContext client.get("${baseUrl}api/classes/student/link/$studentHash/"){
            contentType(ContentType.Application.Json)
        }.body()
    }

    companion object {
        private val TAG = NetworkClassSource::class.simpleName
    }
}