package com.jet.classroomhero.entities

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.json.JsonNames

@Serializable
data class ReinforcerUpload (
  var id: Int = 0,
  @JsonNames("achievement")
  var achievementId: Int = 0,
  @JsonNames("member")
  var memberId: Int = 0,
  @JsonNames("group")
  var groupId: Int = 0,
  @SerialName("file")
  var fileUrl: String = "",
  @SerialName("type")
  var fileType: String = "",
  @SerialName("status")
  var status: String = UploadStatus.PENDING.status,  // can only be pending, accepted, rejected
  @SerialName("feedback")
  var feedback: List<ReinforcerUploadFeedback> = emptyList(),
  @SerialName("created_at")
  var createdAt: String = "",
  @SerialName("updated_at")
  var updatedAt: String = "",
  @Transient
  var fileName: String = "",
  @Transient
  var filePath: String = "", // used on iOS only
  @Transient
  var bytes: ByteArray? = null
) {
  override fun equals(other: Any?): Boolean {
    if (this === other) return true
    if (other == null || this::class != other::class) return false

    other as ReinforcerUpload

    if (id != other.id) return false
    if (achievementId != other.achievementId) return false
    if (memberId != other.memberId) return false
    if (groupId != other.groupId) return false
    if (fileUrl != other.fileUrl) return false
    if (fileType != other.fileType) return false
    if (fileName != other.fileName) return false
    if (filePath != other.filePath) return false
    if (bytes != null) {
      if (other.bytes == null) return false
      if (!bytes.contentEquals(other.bytes)) return false
    } else if (other.bytes != null) return false

    return true
  }

  override fun hashCode(): Int {
    var result = id
    result = 31 * result + achievementId
    result = 31 * result + memberId
    result = 31 * result + groupId
    result = 31 * result + fileUrl.hashCode()
    result = 31 * result + fileType.hashCode()
    result = 31 * result + fileName.hashCode()
    result = 31 * result + filePath.hashCode()
    result = 31 * result + (bytes?.contentHashCode() ?: 0)
    return result
  }

  fun isRejected(): Boolean {
    return this.status == UploadStatus.REJECTED.status
  }

  fun isPending(): Boolean {
    return this.status == UploadStatus.PENDING.status
  }

}


enum class UploadStatus(val status: String) {
  PENDING("pending"),
  ACCEPTED("accepted"),
  REJECTED("rejected")
}
