package com.jet.classroomhero.usecases

import com.jet.classroomhero.data.JarRepository
import com.jet.classroomhero.entities.Jar

class JarUseCases(private val jarRepository: JarRepository) {

  suspend fun createJar(jar: Jar, classId: Int): Jar? = jarRepository.createJar(jar, classId)
  suspend fun editJar(updatedJar: Jar, classId: Int): Jar? = jarRepository.editJar(updatedJar, classId)
  suspend fun deleteJar(itemId: Int, classId: Int): Boolean = jarRepository.deleteJar(itemId, classId)
  suspend fun getJars(forceFetch: Boolean = false, classId: Int): List<Jar> = jarRepository.getJars(classId, forceFetch)
  //suspend fun getJar(itemId: Int): Item = jarRepository.getItem(itemId)

}