package components.modals.groups

import com.jet.classroomhero.entities.StockCurrencyIcon
import components.forms.GroupFormProps
import components.profile.GroupCss
import components.widgets.Modal
import kotlinx.css.*
import kotlinx.html.js.onClickFunction
import react.RBuilder
import react.dom.key
import styled.*

fun RBuilder.stockIconsModal(props: GroupFormProps, onClose: () -> Unit, onPressed: (StockCurrencyIcon) -> Unit) {
  Modal(onClose = {
    onClose()
  }) {
    styledDiv {
      css {
        display = Display.flex
        alignItems = Align.center
        flexDirection = FlexDirection.column
      }
      styledH1 {
        + "Choose an Icon"
      }
      styledDiv {
        css(GroupCss.grid)
        props.stockMedia?.currencyIcons?.forEachIndexed { index, currencyIcon ->
          styledDiv {
            css(GroupCss.item)
            attrs.key = index.toString()
            attrs.onClickFunction = {
              onPressed(currencyIcon)
              onClose()
            }
            styledImg {
              css {
                height = 100.px
              }
              attrs.src = currencyIcon.icon
            }
            styledP {
              + currencyIcon.name
            }
          }
        }
      }
    }
  }
}