package components.dashboard.items

import com.jet.classroomhero.entities.Class
import com.jet.classroomhero.entities.Item
import components.dashboard.DashboardCss
import components.dashboard.getSelectedRouteColor
import components.modals.ModalType
import components.widgets.*
import csstype.HtmlAttributes
import kotlinx.css.*
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import react.Props
import react.RBuilder
import react.fc
import styled.css
import styled.styledDiv
import styled.styledP
import styled.styledSpan
import styles.GlobalCss
import styles.GlobalCss.flexColumn
import styles.GlobalCss.flexRow

external interface ItemListComponentProps : Props {
  // The reinforcers do display
  var items: List<Item>
  // The currently selected item
  var selectedItem: Item?
  // The callback after pressing on a reinforcer
  var onPressItem: (Item) -> Unit
  // The reference to the currently selected group
  var currentGroup: Class
  // For displaying modals
  var onShowModalType: (ModalType) -> Unit
  // managers and members have slightly different views
  var isManager: Boolean
}

val ItemListComponent = fc<ItemListComponentProps> { props ->
  styledDiv {
    css(flexColumn)
    css{
      width = 100.pct
      height = 100.pct
      overflowY = Overflow.auto
    }
    if (props.isManager) {
      styledDiv {
        css(flexRow)
        css {
          maxWidth = 260.px
          padding = "12px"
        }
        Button(
          text = "Create an Item",
          bgColor = "#00796b",
          faIcon = "fa-plus",
          customCss = {
            width = 100.pct
            borderRadius = 0.px
            borderWidth = 0.px
          }
        ) {
          props.onShowModalType(ModalType.CREATE_ITEM)
        }
      }
    }

    styledDiv{
      css(DashboardCss.grid)
      for (item in props.items) {
        styledDiv {
          attrs.id = "item-" + item.id.toString() + "-grid-item"
          css(DashboardCss.gridItem)
          attrs.onClickFunction = {
            props.onPressItem(item)
          }
          css {
            display = Display.flex
            alignItems = Align.center
            backgroundColor = getSelectedRouteColor(props.selectedItem?.id == item.id)
          }

          styledDiv {
            css(DashboardCss.memberPhoto)
            css {
              display = Display.flex
              justifyContent = JustifyContent.spaceBetween
              alignItems = Align.center
            }
            circularFaIcon(
              faIcon = "fa-shopping-bag",
              bgColor = Color("#00796b"),
              size = 30.px
            )
          }
          styledDiv{
            css {
              textAlign = TextAlign.center
              gap = 5.px
            }
            styledP {
              css {
                maxWidth = 150.px
                textAlign = TextAlign.center
              }
              +"${item.name}"
            }
          }
          styledDiv {
            css {
              display = Display.flex
              justifyContent = JustifyContent.spaceBetween
              alignItems = Align.center
              gap = 5.px
            }
            valueAndCurrencyIcon(
              amount = item.cost,
              isLoss = true,
              currencyImgUrl = props.currentGroup.currencyIconUrl!!,
              iconSize = 22.px,
              amountSide = Side.LEFT
            )
          }
        }
      }
    }
  }
}

fun RBuilder.createItemButton(props: ItemListComponentProps) {
  if (props.items.isEmpty()) {
    styledDiv {
//      css {
//        paddingTop = 28.px
//        paddingLeft = 14.px
//        paddingRight = 14.px
//        textAlign = kotlinx.css.TextAlign.center
//      }
      Button(
        text = "Create an Item",
        bgColor = "#00796b",
        faIcon = "fa-plus",
        customCss = {
          width = 100.pct
          borderRadius = 0.px
          borderWidth = 0.px
        }
      ) {
        props.onShowModalType(ModalType.CREATE_ITEM)
      }
    }
  } else {
    Button(
      text = "Create an Item",
      bgColor = "#00796b",
      faIcon = "fa-plus",
      customCss = {
        width = 100.pct
        borderRadius = 0.px
        borderWidth = 0.px
      }
    ) {
      props.onShowModalType(ModalType.CREATE_ITEM)
    }
  }
}