package com.jet.classroomhero.data.remote.sources

import com.jet.classroomhero.data.LocalUserSource
import com.jet.classroomhero.data.RemoteTemplateSource
import com.jet.classroomhero.entities.Template
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.serialization.json.Json


class NetworkTemplateSource(localUserSource: LocalUserSource) : ProtectedNetworkSource(localUserSource), RemoteTemplateSource {

    override suspend fun fetchTemplates(): List<Template> {
        val url = "${baseUrl}api/group_templates/"
        return client.get(url) {
            contentType(ContentType.Application.Json)
        }.body()
    }

    override suspend fun applyTemplate(groupId: Int, templateId: Int): Boolean {
        val url = "${baseUrl}api/group_templates/apply/"
        val requestBody = Json.parseToJsonElement("""
            {
                "group_id": "$groupId",
                "group_template_id": "$templateId"
            }
        """)
        val response: HttpResponse = client.post(url) {
            contentType(ContentType.Application.Json)
            setBody(requestBody)
        }
        return response.status.isSuccess()
    }

}