package components.widgets

import com.jet.classroomhero.UtilsCommon
import com.jet.classroomhero.entities.Achievement
import com.jet.classroomhero.entities.Class
import com.jet.classroomhero.entities.getCurrencyIconUrl
import components.widgets.NavBarCss.navList
import components.widgets.NavBarCss.navListItem
import components.widgets.NavBarCss.navListLink
import components.widgets.AuthenticatedNavCss.dropdown
import components.widgets.AuthenticatedNavCss.innerDropdown
import components.widgets.AuthenticatedNavCss.userDropDown
import kotlinx.browser.document
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.css.properties.BoxShadow
import kotlinx.css.properties.TextDecoration
import kotlinx.html.js.onClickFunction
import react.Props
import react.RBuilder
import react.fc
import styled.*
import styles.GlobalCss.flexRow


external interface NavBarProps : Props {

}

val NavBar = fc<NavBarProps> { props ->
    styledDiv {
        css(NavBarCss.container)
        styledA {
            attrs.href = UtilsCommon.getBaseUrlForBuildConfig()
            styledImg {
                css {
                    height = 50.px
                }
                attrs.src = "/images/ch_logo_alt.png"
            }
        }

        styledUl {
            css(navList)
            styledLi {
                css(navListItem)
                styledA {
                    attrs.href = "/login"

                    css(navListLink)
                    + "Login"
                }
            }
            styledLi {
                css(navListItem)
                styledA {
                    attrs.href = "/register"
                    css(navListLink)
                    + "Sign Up"
                }
            }
        }
    }
}

private object NavBarCss : StyleSheet("NavBarCss", isStatic = true) {
    val container by css {
        display = Display.flex
        alignItems = Align.center
        width = 100.pct
        paddingLeft = 24.px
        paddingRight = 24.px
        gap = 22.px
        backgroundColor = Color.white
        height = 80.px
    }

    val navList by css {
        display = Display.flex
        flexDirection = FlexDirection.row
        alignItems = Align.center
        width = 220.px
        listStyleType = ListStyleType.none
        margin = "0"
        padding = "0"
        overflow = Overflow.hidden
        height = 100.pct
    }

    val navListItem by css {
        display = Display.flex
        height = 100.pct
        alignItems = Align.center
        hover {
            backgroundColor = Color("#eee")
            cursor = Cursor.pointer;
            color = Color("#1cb3e4")
        }
    }

    val navListLink by css {
        display = Display.block
        color = Color("#2f2f2f")
        textAlign = TextAlign.center
        padding = "14px 16px"
        textDecoration = TextDecoration.none
    }
}

fun RBuilder.userDropDown(name: String?) {
    styledDiv {
        css(userDropDown)
        styledI {
            css {
                classes.add("fa")
                classes.add("fa-user")
                fontSize = 24.px
            }
        }
        styledP {
            css {
                marginLeft = 10.px
            }
            name?.let {
                + it
            }
        }

        styledDiv {
            css(dropdown)
            styledDiv {
                css(innerDropdown)
                styledP {
                    attrs.onClickFunction = {
                        document.location?.href = "/settings"
                    }
                    + "Settings"
                }

                styledP {
                    attrs.onClickFunction = {
                        CoroutineScope(Dispatchers.Unconfined).launch {
                            InjectorUtils.provideUserUseCases().logout()
                            document.location?.href = UtilsCommon.getBaseUrlForBuildConfig() + "accounts/logout/"
                        }
                    }
                    + "Logout"
                }
            }
        }

    }
}

fun RBuilder.notificationDropDown(
    completedAchievements: List<Achievement>,
    groups: List<Class>
) {
    styledDiv {
        css(userDropDown)
        styledI {
            css {
                classes.add("fa")
                classes.add("fa-bell")
            }
        }
        styledDiv {
            css(dropdown)
            styledDiv {
                css(innerDropdown)
                completedAchievements.forEach { achievement ->
                    val currencyIconUrl = groups.find { achievement.id == it.id }?.getCurrencyIconUrl() ?: ""
                    styledDiv {
                        css(flexRow)
                        css {
                            justifyContent = JustifyContent.spaceBetween
                            alignItems = Align.center
                            gap = 4.px
                        }
                        valueAndCurrencyIcon(achievement.achievementValue, currencyImgUrl = currencyIconUrl)
                        styledSpan {
                            + "Completed ${achievement.achievementName}!"
                        }
                    }
                }
            }
        }
    }
}




object AuthenticatedNavCss : StyleSheet("AuthenticatedNavCss", isStatic = true) {
    val userDropDown by css {
        display = Display.flex
        flexDirection = FlexDirection.row
        alignItems = Align.center
        cursor = Cursor.pointer
    }
    val dropdown by css {
        display = Display.none
        position = Position.absolute
        maxWidth = 120.px
        width = 100.pct
        boxShadow += BoxShadow(false, 0.px, 8.px, 16.px, 0.px, Color.lightSlateGray)
        paddingTop = 12.px
        paddingBottom = 12.px
        paddingLeft = 16.px
        paddingRight = 16.px
        zIndex = 1
        height = 60.px
        top = 54.px
        backgroundColor = Color.white
        ancestorHover(".$name-${AuthenticatedNavCss::userDropDown.name}") {
            display = Display.block
        }
    }
    val innerDropdown by css {
        ancestorHover(".$name-${AuthenticatedNavCss::dropdown.name}") {
            display = Display.block
        }
    }
}