package components.modals.groups

import components.dashboard.DashboardLocalState
import components.dashboard.DashboardProps
import components.forms.CreateOrEditGroupForm
import components.forms.DeleteClassForm
import components.modals.ModalType
import components.profile.ProfileLocalState
import components.profile.ProfileProps
import components.widgets.Modal
import kotlinx.browser.window
import kotlinx.css.*
import react.RBuilder
import styled.css
import styled.styledDiv
import styled.styledH2

fun RBuilder.deleteGroupModal(
    profileProps: ProfileProps?,
    dashboardProps: DashboardProps?,
    profileState: ProfileLocalState?,
    dashboardState: DashboardLocalState?
) {
    Modal(onClose = {
        if (profileProps != null) {
            profileProps.showModal(false, null)
            profileProps.setEditing(false)

        } else if (dashboardProps != null) {
            dashboardProps.showModal(false, null)
        }
    }) {
        styledDiv {
            css {
                width = 40.pct
            }
            styledH2 {
                css {
                    margin = "12px 0"
                }
                + "Confirm Delete Class"
            }
            if (profileProps != null)
                DeleteClassForm {
                    attrs.group = profileState?.selectedGroup
                    attrs.onDeleteClass = { group ->
                        console.log(group)
                        profileProps.deleteGroup(group.id)
                        profileProps.setEditing(false)
                        profileProps.showModal(false, null)
                    }
                }
            else if (dashboardProps != null)
                DeleteClassForm {
                    attrs.group = dashboardProps?.currentGroup
                    attrs.onDeleteClass = { group ->
                        console.log(group)
                        dashboardProps.deleteGroup(group.id)
                        dashboardProps.showModal(false, null)
                        window.location.href = "/profile"

                    }
                }
        }
    }
}
