package components.accounts

import com.jet.classroomhero.UtilsCommon
import com.jet.classroomhero.entities.User
import components.widgets.Button
import components.accounts.LoginCardCss.inputStyle
import components.accounts.LoginCardCss.line
import components.accounts.LoginCardCss.loginHeader
import components.accounts.LoginCardCss.loginSubHeader
import csstype.BorderStyle
import csstype.HtmlAttributes
import kotlinx.browser.document
import kotlinx.css.*
import kotlinx.html.ButtonType
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onSubmitFunction
import org.w3c.dom.HTMLInputElement
import react.*
import react.dom.html.ReactHTML.div
import styled.*
import styles.GlobalCss

external interface LoginCardProps : Props {
    var user: User
    var isLoading: Boolean
    var errorMessage: String
    var emailLogin: (String, String) -> Unit
    var googleLogin: (String) -> Unit
}

external interface LoginComponentLocalState : State {
    var errorMessage: String
}


@JsExport
class LoginComponent(props: LoginCardProps) : RComponent<LoginCardProps, LoginComponentLocalState>(props) {
    private val emailInputRef = createRef<HTMLInputElement>()
    private val passwordInputRef = createRef<HTMLInputElement>()

    override fun LoginComponentLocalState.init(props: LoginCardProps){
        errorMessage = props.errorMessage
    }

    override fun componentDidMount() {
        val userToken = props.user.token ?: ""
        if (userToken.isNotEmpty()) {
            document.location?.href = "/profile"
        }
    }

    override fun RBuilder.render() {
        div {
            styledH1 {
                css(loginHeader)
                +"Login"
            }
            styledH3 {
                css(loginSubHeader)
                +"Continue to Classroom Hero"
            }
            if (state.errorMessage.isNotEmpty()) {
                styledP {
                    css(GlobalCss.errorText)
                    css {
                        marginTop = 8.px
                        marginBottom = 8.px
                    }
                    + state.errorMessage
                }
            }
            styledForm {
                css {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    gap = 18.px
                }
                attrs.onSubmitFunction = { event ->
                    event.preventDefault()
                    if (emailInputRef.current != null && passwordInputRef.current != null) {
                        val email = emailInputRef.current!!.value
                        val pw = passwordInputRef.current!!.value

                        if (!UtilsCommon.isValidEmail(email)){
                            setState {
                                errorMessage = "Email format is invalid"
                            }
                        }

                        if (pw.length < 5){
                            setState {
                                errorMessage = "Password must be 6 characters or more"
                            }
                        }


                        if (email.trim().isNotEmpty() && pw.trim().isNotEmpty()) {
                            props.emailLogin(email, pw)
                        }
                    }
                }
                styledInput {
                    css(inputStyle)
                    attrs.id = "LoginPageEmailTextField"
                    attrs.type = InputType.email
                    attrs.placeholder = "Email"
                    ref = emailInputRef
                }
                styledInput {
                    css(inputStyle)
                    attrs.id = "LoginPagePasswordTextField"
                    attrs.type = InputType.password
                    attrs.placeholder = "Password"
                    ref = passwordInputRef
                }
                // on press is handled by the form submit above
                Button(
                    text="Continue with Email",
                    id="LoginPageSubmitButton"
                )
            }
            styledDiv { css(line) }
            Button(
                text = "Continue with Google",
                iconSrc = "images/ic_google.jpg",
                bgColor = "#fff",
                textColor = "#000",
                customCss = {
                    borderStyle = kotlinx.css.BorderStyle.solid
                    borderWidth = 1.px
                    borderColor = Color.black
                }
            ) {

                // calls this method in resources/sso.js
                js("performGoogleAuth()")
            }
            Button(
                text = "Continue with Facebook",
                iconSrc = "images/ic_facebook.png",
                bgColor = "#4267B2",
                textColor = "#fff",
                iconWidth = 12.px,
                iconHeight = 25.px,
                customCss = {
                    marginTop = 12.px
                }
            ) {
                js("performFacebookAuth()")
            }
        }
        styledP {
            + "New to Classroom Hero? "
            styledA {
                attrs.href = "/register"
                + "Get Started"
            }
        }
    }
}

object LoginCardCss : StyleSheet("LoginPageCss", isStatic = true) {
    val inputStyle by css {
        minWidth = 75.px
        verticalAlign = VerticalAlign.baseline
        paddingTop = 5.px
        paddingBottom = 5.px
        paddingRight = 10.px
        paddingLeft = 10.px
        borderRadius = 2.px
        fontSize = 1.rem
        borderStyle = kotlinx.css.BorderStyle.solid
        borderWidth = 2.px
        borderColor = Color("#ccc")
        height = 40.px
    }
    val loginHeader by css {
        fontWeight = FontWeight.w300
        marginBottom = 0.px
    }
    val loginSubHeader by css {
        color = Color("#6d7175")
        fontWeight = FontWeight.w500
        marginTop = 6.px
        marginBottom = 10.px
    }
    val line by css {
        marginTop = 1.5.rem
        marginBottom = 1.5.rem
        width = 100.pct
        textAlign = TextAlign.center
        fontSize = 0.875.rem
        backgroundColor = Color("#8c9196")
        height = 2.px
        borderRadius = 1.rem
    }
}
