package com.jet.classroomhero.data.remote.sources

import com.jet.classroomhero.Logger
import com.jet.classroomhero.data.LocalUserSource
import com.jet.classroomhero.data.RemoteReinforcerSource
import com.jet.classroomhero.entities.Reinforcer
import com.jet.classroomhero.entities.ReinforcerUpload
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.datetime.Clock
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json

val jsonFormat = Json {
    ignoreUnknownKeys = true
    useAlternativeNames = true
}

class NetworkReinforcerSource(localUserSource: LocalUserSource) : ProtectedNetworkSource(localUserSource), RemoteReinforcerSource {

    override suspend fun createReinforcer(newReinforcer: Reinforcer, classId: Int): Reinforcer = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/reinforcer/create/"
        return@withContext client.post(url) {
            contentType(ContentType.Application.Json)
            setBody(newReinforcer)
        }.body()
    }

    override suspend fun editReinforcer(updatedReinforcer: Reinforcer, classId: Int): Reinforcer = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/reinforcer/${updatedReinforcer.id}/edit/"
        return@withContext client.put(url) {
            contentType(ContentType.Application.Json)
            setBody(updatedReinforcer)
        }.body()
    }

    override suspend fun deleteReinforcer(reinforcerId: Int, classId: Int): Boolean = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/reinforcer/${reinforcerId}/delete/"
        val response: HttpResponse = client.delete(url) {
            contentType(ContentType.Application.Json)
        }
        return@withContext response.status.isSuccess()
    }

    override suspend fun fetchReinforcers(classId: Int): List<Reinforcer> = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/reinforcers/"
        return@withContext client.get(url) {
            contentType(ContentType.Application.Json)
        }.body()
    }

    override suspend fun uploadRequirements(requirements: List<ReinforcerUpload>): List<ReinforcerUpload> = withContext(Dispatchers.Default) {
        Logger().logMessage("${requirements[0]}")
        val url = "${baseUrl}api/classes/${requirements[0].groupId}/reinforcer/upload/"
        val response: HttpResponse = client.submitFormWithBinaryData(
            url = url,
            formData = formData {
                append("description", "Achievement Requirements Upload")
                append("achievementId", requirements[0].achievementId)
                append("memberId", requirements[0].memberId)
                append("groupId", requirements[0].groupId)
                requirements.forEachIndexed { index, reinforcerUpload ->
                    reinforcerUpload.bytes?.let { bytes ->
                        append("files", bytes, Headers.build {
                            append(HttpHeaders.ContentType, reinforcerUpload.fileType ?: "")
                            append(HttpHeaders.ContentDisposition, "filename=${reinforcerUpload.fileName}")

                        })
                    }
                }
            }
        ) {
            onUpload { bytesSentTotal, contentLength ->
                // TODO: Hook in a callback so that we can show a progress bar in the UI

//                if (bytesSentTotal == contentLength) {
//
//                }
            }
        }
        try {
            val rawJson: String = response.body()
            return@withContext jsonFormat.decodeFromString<List<ReinforcerUpload>>(rawJson)
        } catch (e: Exception) {
            println("NetworkReinforcerSource exception ${e.message}")
            return@withContext throw Exception("Error parsing response: ${e.message}")
        }
    }

    override suspend fun resubmitRequirement(requirement: ReinforcerUpload): List<ReinforcerUpload> = withContext(Dispatchers.Default) {
        println("NetworkReinforcerSource updating profile photo")
        Logger().logMessage("${requirement}")
        val url = "${baseUrl}api/classes/${requirement.groupId}/reinforcer/upload/${requirement.id}/resubmit/"
        val response: HttpResponse = client.submitFormWithBinaryData(
            url = url,
            formData = formData {
                append("description", "Achievement Requirements Upload")
                requirement.bytes?.let { bytes ->
                    append("file", bytes, Headers.build {
                        append(HttpHeaders.ContentType, requirement.fileType ?: "")
                        append(HttpHeaders.ContentDisposition, "filename=requirement_${Clock.System.now().toEpochMilliseconds()}.png")
                    })
                }
            }
        ) {
            onUpload { bytesSentTotal, contentLength ->
                // TODO: Hook in a callback so that we can show a progress bar in the UI
                println("Sent $bytesSentTotal bytes from $contentLength")
//                if (bytesSentTotal == contentLength) {
//
//                }
            }
        }
        try {
            val rawJson: String = response.body()
            return@withContext jsonFormat.decodeFromString<List<ReinforcerUpload>>(rawJson)
        } catch (e: Exception) {
            println("NetworkReinforcerSource exception ${e.message}")
            throw Exception("Error parsing response: ${e.message}")
        }
    }

    override suspend fun getRequirements(classId: Int): List<ReinforcerUpload> = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/reinforcer/get_uploads/"
        return@withContext client.get(url) {
            contentType(ContentType.Application.Json)
        }.body()
    }

    override suspend fun deleteRequirement(groupId: Int, requirementId: Int): Boolean = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${groupId}/reinforcer/upload/${requirementId}/delete/"
        val response: HttpResponse = client.delete(url) {
            contentType(ContentType.Application.Json)
        }
        return@withContext response.status.isSuccess()
    }

    override suspend fun updateRequirement(groupId: Int, uploadId: Int, status: String, feedback: String?): List<ReinforcerUpload> = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${groupId}/reinforcer/upload/${uploadId}/update/"
// ${if (feedback.isNullOrEmpty()) "" else ", \"feedback\": \"$feedback\" "}
        val requestBody = Json.parseToJsonElement(
            """
            {
                "status": "$status",
                "feedback": "$feedback"
            }
        """
        )
        return@withContext client.put(url) {
            contentType(ContentType.Application.Json)
            setBody(requestBody)
        }.body()
    }
//
//        val response: HttpResponse = client.submitFormWithBinaryData(
//            url = url,
//            formData = formData {
//                append("status", status)
//                append("feedback", if (feedback.isNullOrEmpty()) "" else "\"$feedback\"")
//                requirements!!.forEachIndexed { index, reinforcerUpload ->
//                    reinforcerUpload.bytes?.let { bytes ->
//                        append("files", bytes, Headers.build {
//                            append(HttpHeaders.ContentType, reinforcerUpload.fileType ?: "")
//                            append(
//                                HttpHeaders.ContentDisposition,
//                                "filename=${reinforcerUpload.fileName}"
//                            )
//                        })
//                    }
//                }
//            }
//        ){
//            onUpload { bytesSentTotal, contentLength ->
//                // TODO: Hook in a callback so that we can show a progress bar in the UI
//                println("Sent $bytesSentTotal bytes from $contentLength")
////                if (bytesSentTotal == contentLength) {
////
////                }
//            }
//        }
//        try {
//            val rawJson: String = response.body()
//            return@withContext jsonFormat.decodeFromString<List<ReinforcerUpload>>(rawJson)
//        } catch (e: Exception) {
//            println("NetworkReinforcerSource exception ${e.message}")
//            throw Exception("Error parsing response: ${e.message}")
//        }

}