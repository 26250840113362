package sources.local

import com.jet.classroomhero.data.LocalReinforcerSource
import com.jet.classroomhero.entities.Reinforcer
import com.jet.classroomhero.entities.ReinforcerUpload

class CacheReinforcerSource : LocalReinforcerSource {
    override suspend fun saveReinforcer(reinforcer: Reinforcer, classId: Int) {}
    override suspend fun saveReinforcers(reinforcers: List<Reinforcer>, classId: Int) {}
    override suspend fun deleteReinforcer(reinforcerId: Int): Int { return 0 }
    override suspend fun readReinforcers(classId: Int): List<Reinforcer> { return emptyList() }
    override suspend fun readReinforcer(reinforcerId: Int): Reinforcer {
        return Reinforcer()
    }

    override suspend fun deleteGroupReinforcers(groupId: Int) {}
    override suspend fun saveUploads(uploads: List<ReinforcerUpload>) {
    }

    override suspend fun removeUpload(uploadId: Int) {

    }

    override suspend fun removeAllUploads(classId: Int) {

    }

    override suspend fun readAllGroupUploads(classId: Int): List<ReinforcerUpload> {
        return emptyList()
    }

    override suspend fun readAchievementUploads(achievementId: Int): List<ReinforcerUpload> {
        return emptyList()
    }
}