package utils

import kotlinx.browser.window

class Urls {
  companion object {
    // Ex. Path: /group/123/dashboard
    fun getGroupIdFromUrl(): Int {
      val fullPath = window.location.pathname.split("/")
      val stringId = fullPath[2]
      if (stringId.isNotEmpty()) {
        return stringId.toInt()
      }
      return 0
    }
    // Ex. Path: /group/123/member/123/dashboard
    fun getMemberIdFromUrl(): Int {
      val fullPath = window.location.pathname.split("/")
      val stringId = fullPath[4]
      if (stringId.isNotEmpty()) {
        return stringId.toInt()
      }
      return 0
    }
  }
}