package com.jet.classroomhero.usecases

import com.jet.classroomhero.SuspendWrapper
import com.jet.classroomhero.data.AchievementRepository
import com.jet.classroomhero.entities.Achievement
import com.jet.classroomhero.entities.PaginatedResponse

class AchievementUseCases(private val achievementRepository: AchievementRepository) {
    /**
     * Base repository calls
     */
    suspend fun fetchUserAchievements(forceFetch: Boolean = false, page: Int = 1, pageSize: Int = 10): PaginatedResponse<Achievement> = achievementRepository.fetchUserAchievements()
    suspend fun fetchGroupAchievements(forceFetch: Boolean = false, classId: Int, page: Int = 1, pageSize: Int = 10): PaginatedResponse<Achievement> = achievementRepository.fetchGroupAchievements(forceFetch, classId, page, pageSize)
    suspend fun fetchMemberAchievements(forceFetch: Boolean = false, classId: Int, memberId: Int, page: Int = 1, pageSize: Int = 10): PaginatedResponse<Achievement> = achievementRepository.fetchMemberAchievements(forceFetch, classId, memberId, page, pageSize)

    suspend fun setAchievementSeen(achievementId: Int) = achievementRepository.setAchievementSeen(achievementId)

    /**
     * iOS repository calls
     */
    fun fetchGroupAchievementsIos(forceFetch: Boolean, classId: Int, page: Int = 1, pageSize: Int = 10) = SuspendWrapper { fetchGroupAchievements(forceFetch, classId, page, pageSize) }
    fun fetchMemberAchievementsIos(forceFetch: Boolean, classId: Int, memberId: Int, page: Int = 1, pageSize: Int = 10) = SuspendWrapper { fetchMemberAchievements(forceFetch, classId, memberId, page, pageSize) }
    fun fetchUserAchievementsIos(forceFetch: Boolean = false, page: Int = 1, pageSize: Int = 10) = SuspendWrapper { fetchUserAchievements(forceFetch, page, pageSize) }

}