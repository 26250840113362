package com.jet.classroomhero.usecases

import com.jet.classroomhero.SuspendWrapper
import com.jet.classroomhero.data.ItemRepository
import com.jet.classroomhero.entities.Item

class ItemUseCases(private val itemRepository: ItemRepository) {
    /**
     * Base repository calls
     */
    suspend fun createItem(newItem: Item, classId: Int): Item? = itemRepository.createItem(newItem, classId)
    suspend fun editItem(updatedItem: Item, classId: Int): Item? = itemRepository.editItem(updatedItem, classId)
    suspend fun deleteItem(itemId: Int, classId: Int): Boolean = itemRepository.deleteItem(itemId, classId)
    suspend fun getItems(forceFetch: Boolean = false, classId: Int): List<Item> = itemRepository.getItems(forceFetch, classId)
    suspend fun getItem(itemId: Int): Item = itemRepository.getItem(itemId)

    /**
     * iOS Calls
     */
    fun createItemIos(newItem: Item, classId: Int) = SuspendWrapper { createItem(newItem, classId) }
    fun editItemIos(updatedItem: Item, classId: Int) = SuspendWrapper { editItem(updatedItem, classId) }
    fun deleteItemIos(itemId: Int, classId: Int) = SuspendWrapper { deleteItem(itemId, classId) }
    fun getItemsIos(forceFetch: Boolean = false, classId: Int) = SuspendWrapper { getItems(forceFetch, classId) }

}