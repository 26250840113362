package components.dashboard.achievements

import com.jet.classroomhero.entities.Achievement
import com.jet.classroomhero.entities.Class
import com.jet.classroomhero.entities.Reinforcer
import com.jet.classroomhero.entities.Student
import components.dashboard.DashboardCss
import components.dashboard.getSelectedRouteColor
import components.modals.ModalType
import components.widgets.*
import csstype.HtmlAttributes
import kotlinx.css.*
import kotlinx.html.Entities
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import react.Props
import react.RBuilder
import react.dom.aria.AriaRole
import react.fc
import styled.css
import styled.styledDiv
import styled.styledP
import styled.styledSpan
import styles.GlobalCss.flexColumn
import styles.GlobalCss.flexRow

external interface AchievementListComponentProps : Props {
  // The reinforcers do display
  var achievements: List<Reinforcer>
  // The currently selected achievement
  var selectedAchievement: Reinforcer?
  // The callback after pressing on a reinforcer
  var onPressAchievement: (Reinforcer) -> Unit
  // The reference to the currently selected group
  var currentGroup: Class
  // For displaying modals
  var onShowModalType: (ModalType) -> Unit
  // managers and members have slightly different views
  var isManager: Boolean

  var setSelectedAchievement: (Reinforcer) -> Unit
}

val AchievementListComponent = fc<AchievementListComponentProps> { props ->
  styledDiv {
    css(flexColumn)
    css{
      width = 120.pct
      height = 120.pct
      overflowY = Overflow.auto
    }
    if (props.isManager) {
      styledDiv{
        css(flexRow)
        css {
          maxWidth = 260.px
          padding = "12px"
        }
        Button(
          text = "Create an Achievement",
          bgColor = "#F57C00",
          faIcon = "fa-plus",
          customCss = {
            width = 100.pct
            borderRadius = 0.px
            borderWidth = 0.px
          }
        ) {
          props.onShowModalType(ModalType.CREATE_ACHIEVEMENT)
        }
      }

    }
    styledDiv {
      css(DashboardCss.grid)
      for (achievement in props.achievements) {
        styledDiv {
          attrs.id = "achievement-" + achievement.id.toString() + "-grid-item"
          css(DashboardCss.gridItem)
          attrs.onClickFunction = {
            props.setSelectedAchievement(achievement)
          }
          css {
            display = Display.flex
            alignItems = Align.center
            backgroundColor = getSelectedRouteColor(props.selectedAchievement?.id == achievement.id)
          }
          styledDiv {
            css(DashboardCss.memberPhoto)
            css {
              display = Display.flex
              justifyContent = JustifyContent.spaceBetween
              alignItems = Align.center
            }
            circularFaIcon(
              faIcon = "fa-star",
              bgColor = Color("#F57C00"),
              size = 30.px
            )
          }
          styledDiv {
            css {
              textAlign = TextAlign.center
              gap = 5.px
            }
            styledP {
              css {
                maxWidth = 150.px
                textAlign = TextAlign.center
              }
              if (achievement.name!!.length > 20) {
                + "${achievement.name!!.substring(0, 20) + "..."}"
              } else {
                + "${achievement.name}"
              }
            }
          }
          styledDiv {
            css {
              display = Display.flex
              justifyContent = JustifyContent.spaceBetween
              alignItems = Align.center
              gap = 5.px
            }
            valueAndCurrencyIcon(
              amount = achievement.value ?: 1,
              isLoss = false,
              currencyImgUrl = props.currentGroup.currencyIconUrl!!,
              iconSize = 22.px,
              amountSide = Side.LEFT
            )
          }
        }
      }
    }
  }
}

fun RBuilder.createAchievementButton(props: AchievementListComponentProps) {
  if (props.achievements.isEmpty()) {
    styledDiv {
      css {
        paddingTop = 28.px
        paddingLeft = 14.px
        paddingRight = 14.px
        textAlign = TextAlign.center
      }
      Button(
        text = "Create an Achievement",
        bgColor = "#F57C00",
        faIcon = "fa-plus",
      ) {
        props.onShowModalType(ModalType.CREATE_ACHIEVEMENT)
      }
    }
  } else {
    Button(
      text = "Create an Achievement",
      bgColor = "#F57C00",
      faIcon = "fa-plus",
      customCss = {
        width = 100.pct
        borderRadius = 0.px
        borderWidth = 0.px
      }
    ) {
      props.onShowModalType(ModalType.CREATE_ACHIEVEMENT)
    }
  }
}