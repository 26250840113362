package components.modals.achievements

import components.dashboard.DashboardProps
import components.dashboard.achievements.AchievementDetailComponent
import components.modals.groups.JoinGroupForm
import components.profile.ProfileLocalState
import components.profile.ProfileProps
import components.widgets.Modal
import kotlinx.css.*
import react.RBuilder
import styled.css
import styled.styledDiv
import styled.styledH3

fun RBuilder.renderAchievementDetailModal(
  props: DashboardProps,
  isManager: Boolean
) {
  Modal(onClose = {
    props.showModal(false, null)
    props.setSelectedAchievement(null)
  }) {
    AchievementDetailComponent {
      attrs.achievement = props.selectedAchievement!!
      attrs.group = props.currentGroup
      attrs.member = props.selectedMember
      attrs.parentProps = props
      attrs.onShowModalType = { action ->
        props.showModal(true, action)
      }
      attrs.currModal = props.selectedModalType
      attrs.uploads = props.currentUploads.filter { it.achievementId == props.selectedAchievement!!.id }
      attrs.onBulkCompleteAchievement = { memberIds ->
        props.bulkCompleteAchievement(
          memberIds,
          props.currentGroup!!.id,
          props.selectedAchievement!!.id
        )
      }
      attrs.onUploadRequirements = { requirements ->
        props.uploadRequirements(requirements)
      }
      attrs.onDeleteRequirement = { uploadId ->
        props.deleteRequirement(props.currentGroup!!.id, uploadId)
      }
      attrs.onUpdateStatus = { uploadId, status, feedback, requirements ->
        props.updateRequirement(props.currentGroup!!.id, uploadId, status, feedback)
      }
      attrs.isManager = isManager
      attrs.isLoading = props.isLoading
    }

  }
}