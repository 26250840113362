package components.dashboard.members

import components.dashboard.DashboardCss
import components.dashboard.DashboardProps
import components.dashboard.getSelectedRouteColor
import components.modals.*
import components.modals.achievements.allAchievements
import components.modals.achievements.renderGiveAchievementModal
import components.modals.items.allTransactions
import components.modals.items.renderSellItemModal
import components.modals.students.memberDetailModal
import components.modals.students.renderAdjustCurrencyModal
import components.modals.students.renderCreateOrEditMemberModal
import components.widgets.Button
import components.widgets.circularNamePlaceholder
import components.widgets.imageWidget
import kotlinx.css.*
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import react.RBuilder
import styled.css
import styled.styledDiv
import styled.styledP
import styles.GlobalCss.flexColumn
import styles.GlobalCss.flexRow

fun RBuilder.members(props: DashboardProps, isManager: Boolean) {
  val group = props.currentGroup
  if (group != null) {
    styledDiv {
        attrs.id = "group-" + group.id.toString() + "-student-list"
        css(flexColumn)
        /* Controls */
        if (isManager) {

          styledDiv {
            css(flexRow)
            css {
              maxWidth = 260.px
              padding = "12px"
            }
            Button(
              text = "Add New Student",
              faIcon = "fa-plus",
              customCss = {
                width = 100.pct
                borderRadius = 0.px
                borderWidth = 0.px
              }
            ) {
              props.showModal(true, ModalType.CREATE_MEMBER)
            }
          }
        }
      /* Grid View */
      styledDiv {
        css(DashboardCss.grid)
        for (member in group.students) {
          styledDiv {
            attrs.id = "student-" + member.id.toString() + "-grid-item"
            css(DashboardCss.gridItem)
            attrs.onClickFunction = {
              props.showModal(true, ModalType.MEMBER_DETAIL)
              props.setSelectedMember(member)
            }
            css {
              backgroundColor = getSelectedRouteColor(props.selectedMember?.id == member.id)
            }
            styledDiv {
              if (member.photo.isNullOrEmpty()) {
                circularNamePlaceholder(member.firstName)
              } else {
                imageWidget("${member.photo}")
              }
            }
            styledDiv {
              styledP {
                css {
                  textAlign = TextAlign.center
                }
                + "${member.firstName} ${member.lastName}"
              }
            }
          }
        }
      }
      if (props.modalShowing) {
        when (props.selectedModalType) {
          ModalType.MEMBER_DETAIL -> { memberDetailModal(props) }
          ModalType.ADJUST_CURRENCY -> { renderAdjustCurrencyModal(props) }
          ModalType.GIVE_ACHIEVEMENT -> { renderGiveAchievementModal(props) }
          ModalType.SELL_ITEM -> { renderSellItemModal(props) }
          ModalType.CREATE_MEMBER -> { renderCreateOrEditMemberModal(props, null) }
          ModalType.EDIT_MEMBER -> { renderCreateOrEditMemberModal(props, props.selectedMember) }
          ModalType.ALL_ACHIEVEMENTS -> { allAchievements(props, props.selectedMember!!.achievements) }
          ModalType.ALL_TRANSACTIONS -> { allTransactions(props, props.selectedMember!!.transactions) }
          else -> {}
        }
      }
    }
  }
}