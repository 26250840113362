package store

import actions.*
import redux.RAction

data class AppState(
    var accountState: AccountActions.AccountState = AccountActions.AccountState(),
    var groups: GroupActions.GroupState = GroupActions.GroupState(),
    var achievements: AchievementActions.AchievementState = AchievementActions.AchievementState(),
    //var members: GroupActions.GroupState = GroupActions.GroupState(),
    var appState: AppStateActions.AppState = AppStateActions.AppState(),
)

fun rootReducer(
    state: AppState,
    action: Any
) = AppState(
    AccountActions.reducer(state.accountState, action.unsafeCast<RAction>()),
    GroupActions.reducer(state.groups, action.unsafeCast<RAction>()),
    AchievementActions.reducer(state.achievements, action.unsafeCast<RAction>()),
    //MemberActions.reducer(state.groups, action.unsafeCast<RAction>()),
    AppStateActions.reducer(state.appState, action.unsafeCast<RAction>())
)