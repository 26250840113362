package com.jet.classroomhero.data

import com.jet.classroomhero.Logger
import com.jet.classroomhero.data.remote.sources.NetworkUserSource
import com.jet.classroomhero.data.remote.sources.ProtectedNetworkUserSource
import com.jet.classroomhero.entities.ImageUploadResponse
import com.jet.classroomhero.entities.User
import com.jet.classroomhero.entities.Tokens
import io.ktor.client.plugins.*
import kotlinx.coroutines.*

class UserRepository(
    private val localSource: LocalUserSource,
    private val anonymousUserSource: AnonymousRemoteUserSource = NetworkUserSource(),
    private var protectedUserSource: ProtectedRemoteUserSource? = null


) {
    val logger = Logger()

    // keep public, used by iOS
    val coroutineScope: CoroutineScope = MainScope()

    init {
        logger.logMessage("$TAG init")
        createProtectedUserSource()
    }

    companion object {
        private val TAG = UserRepository::class.simpleName
    }

    suspend fun login(email: String, username: String, password: String): User {
        val loggingIn = coroutineScope.async {
            return@async anonymousUserSource.login(email, username, password)
        }
        val newUser = loggingIn.await()
        // for now it's just user but we'll pass more stuff later
        newUser.let {
            val success = saveUser(it)
            if (!success) {
                logger.logMessage("COMMON: Failed to save user locally!")
            }
            return it
        }
    }

    suspend fun register(user: User): User? {
        val registering = coroutineScope.async {
            return@async anonymousUserSource.register(user)
        }
        val newUser = registering.await() // await for registration to complete on server
        newUser?.let {
            val success = withContext(Dispatchers.Default) {
                return@withContext saveUser(it)
            }
            if (!success) {
                logger.logMessage("COMMON: Failed to save user locally!")
            }
            return it
        }
        return null
    }

    suspend fun loginOrRegisterWithGoogle(googleIdToken: String, role: String): User {
        val fetchedUser = anonymousUserSource.loginOrRegisterWithGoogle(googleIdToken, role)
        fetchedUser.let { user ->
            val success = withContext(Dispatchers.Default) {
                return@withContext saveUser(user)
            }
            if (!success) {
                logger.logMessage("COMMON: Failed to save user locally with google!")
            }
            return user
        }
    }

    suspend fun loginOrRegisterWithFacebook(facebookIdToken: String, userId: String, role: String): User {
        val fetchedUser = anonymousUserSource.loginOrRegisterWithFacebook(facebookIdToken, userId, role)
        fetchedUser.let { user ->
            val success = withContext(Dispatchers.Default) {
                return@withContext saveUser(user)
            }
            if (!success) {
                logger.logMessage("COMMON: Failed to save user locally with facebook!")
            }
            return user
        }
    }

    suspend fun loginOrRegisterWithApple(appleIdToken: String, role: String): User {
        val fetchedUser = anonymousUserSource.loginOrRegisterWithApple(appleIdToken, role)
        fetchedUser.let { user ->
            val success = withContext(Dispatchers.Default) {
                return@withContext saveUser(user)
            }
            if (!success) {
                logger.logMessage("COMMON: Failed to save user locally with apple!")
            }
            return user
        }
    }

    suspend fun userIsAuthenticated(): Boolean {
        val readUser = readUser() ?: return false
        val refreshToken = readUser.refresh
        if (refreshToken.isNullOrBlank()) return false
        return try {
            val tokens = refreshJwt(refreshToken)
            readUser.token = tokens.access
            readUser.refresh = tokens.refresh
            val saved = saveUser(readUser)
            if(saved) {
                logger.logMessage("CHDEBUG: Successfully saved user with new access token")
            }
            true
        } catch (ex: Exception) {
            logger.logMessage("CHDEBUG: Error refreshing token ${ex.message}")
            false
        }
    }

    suspend fun requestPasswordReset(email: String): Boolean {
        return anonymousUserSource.requestPasswordReset(email)
    }

    suspend fun changePassword(password: String): Boolean {
        return protectedUserSource!!.changePassword(password)
    }

    suspend fun changeEmail(password: String): Boolean {
        return protectedUserSource!!.changeEmail(password)
    }

    suspend fun changeUsername(password: String): User? = withContext(Dispatchers.Default) {
        createProtectedUserSource()
        val updatedUser = protectedUserSource!!.changeUsername(password)
        localSource.editUser(updatedUser)
        return@withContext localSource.readUser()
    }

    suspend fun finishAccount(firstName: String, password: String): User? = withContext(Dispatchers.Default) {
        val updatedUser = protectedUserSource!!.finishAccount(firstName, password)
        localSource.editUser(updatedUser)
        return@withContext localSource.readUser()
    }

    suspend fun editAccount(
        firstName: String,
        lastName: String,
        email: String,
        username: String,
        role: String
    ): User? = withContext(Dispatchers.Default) {
        val updatedUser = protectedUserSource!!.editAccount(firstName, lastName, email, username, role)
        localSource.editUser(updatedUser)
        return@withContext localSource.readUser()
    }

    suspend fun deleteAccount(): Boolean = withContext(Dispatchers.Default) {
        // delete account
        protectedUserSource!!.deleteAccount()
        // logout
        logout()
        return@withContext true
    }

    suspend fun readUser(): User? = withContext(Dispatchers.Default) {
        return@withContext localSource.readUser()
    }

    suspend fun logout(): Boolean = withContext(Dispatchers.Default) {
        createProtectedUserSource()
        logger.logMessage("$TAG.logout")
        try {
            protectedUserSource!!.clearJwtToken()
            localSource.deleteUser()
        } catch (e: Exception) {
            logger.logMessage(e.message.toString())
        }
        return@withContext true
    }

    suspend fun updateProfilePhoto(bytes: ByteArray?): String = withContext(Dispatchers.Default) {
        if (bytes == null) {
            return@withContext ""
        }
        val response = protectedUserSource!!.updateProfilePhoto(bytes)
        val url = response.uploadedImageUrl
        localSource.readUser()?.let { user ->
            user.photoUrl = url
            localSource.editUser(user)
        }
        return@withContext url
    }

    suspend fun updateCoverPhoto(bytes: ByteArray?): String = withContext(Dispatchers.Default) {
        if (bytes == null) {
            return@withContext ""
        }
        val response = protectedUserSource!!.updateProfileCover(bytes)
        val url = response.uploadedImageUrl
        localSource.readUser()?.let { user ->
            user.coverUrl = url
            localSource.editUser(user)
        }
        return@withContext url
    }

    suspend fun createFCMDevice(token: String, platform: String): Boolean {
        return protectedUserSource?.createFirebaseMessagingDevice(token, platform) ?: false
    }

    suspend fun userDetail(): User {
        protectedUserSource = ProtectedNetworkUserSource(localSource)
        val user = protectedUserSource!!.userDetail()
        saveUser(user)
        return user
    }

    suspend fun saveUser(user: User): Boolean = withContext(Dispatchers.Default) {
        return@withContext localSource.saveUser(user)
    }

    private suspend fun refreshJwt(refreshToken: String): Tokens = withContext(Dispatchers.Default) {
        return@withContext anonymousUserSource.refreshJwt(refreshToken)
    }

    private fun createProtectedUserSource() {
        if(protectedUserSource == null) {
            protectedUserSource = ProtectedNetworkUserSource(localSource)
        }
    }
}

interface LocalUserSource {
    suspend fun saveUser(user: User): Boolean // full user save
    suspend fun editUser(user: User) // partial updates
    suspend fun readUser(): User?
    suspend fun deleteUser(): Boolean
    suspend fun updateJwt(tokens: Tokens)
}

interface AnonymousRemoteUserSource {
    @Throws(ResponseException::class, kotlin.coroutines.cancellation.CancellationException::class)
    suspend fun register(user: User): User?
    @Throws(ResponseException::class,
        kotlin.coroutines.cancellation.CancellationException::class
    )
    suspend fun login(email: String, username: String, password: String): User
    suspend fun loginOrRegisterWithGoogle(googleIdToken: String, role: String): User
    suspend fun loginOrRegisterWithFacebook(facebookIdToken: String, userId: String, role: String ): User
    suspend fun loginOrRegisterWithApple(appleIdToken: String, role: String): User
    suspend fun refreshJwt(refreshToken: String): Tokens
    suspend fun requestPasswordReset(email: String): Boolean
}

interface ProtectedRemoteUserSource {
    suspend fun changePassword(password: String): Boolean
    suspend fun changeUsername(username: String): User
    suspend fun changeEmail(email: String): Boolean
    suspend fun finishAccount(firstName: String, password: String): User
    suspend fun deleteAccount(): Boolean
    suspend fun editAccount(firstName: String, lastName: String, email: String, username: String, role: String): User
    suspend fun clearJwtToken()
    suspend fun userDetail(): User
    suspend fun updateProfilePhoto(bytes: ByteArray): ImageUploadResponse
    suspend fun updateProfileCover(bytes: ByteArray): ImageUploadResponse
    suspend fun createFirebaseMessagingDevice(token: String, platform: String): Boolean
}
