package com.jet.classroomhero.entities

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

@Serializable
data class User(
    var id: Int = 0,
    @SerialName("first_name")
    var firstName: String? = null,
    @SerialName("last_name")
    var lastName: String? = null,
    @SerialName("group_templates")
    var groupTemplates: List<Template> = emptyList(),
    var username: String? = null,
    var email: String? = null,
    var classes: List<Class> = emptyList(),
    var memberships: List<Class> = emptyList(),
    var password: String? = null,
    var token: String? = null,
    var refresh: String? = null,
    var role: String? = null,
    var firstLogin: Boolean = false,
    @SerialName("photo")
    var photoUrl: String? = "",
    @SerialName("cover")
    var coverUrl: String? = "",
    @SerialName("progress_cap")
    var progressCap: Int = 25,
    @SerialName("progress_earned_today")
    var progressEarnedToday: Int = 0,
    var progress: Int = 0,
    var points: Int = 0,
    var level: Int = 1,
    @SerialName("has_paid")
    var hasPaid: Boolean = false
) {

    constructor(
        firstName: String? = null,
        lastName: String? = null,
        email: String? = null,
        username: String? = null,
        password: String? = null,
        role: String? = null): this(0, firstName, lastName, emptyList(), username, email, emptyList(), emptyList(), password, null, null, role)

    constructor(email: String? = null, username: String? = null, password: String? = null): this(0, null, null, emptyList(), username, email, emptyList(), emptyList(), password, null, null, null)

    constructor(id: Int = 0, refresh: String? = null): this(id, null, null, emptyList(), null, null, emptyList(), emptyList(),null, null, refresh, null)

    constructor(): this(0)
}

fun User.getFloatingProgress(): Float {
    return this.progress.toFloat() / 10
}

fun User.getLevelProgressPercentage(): String {
    return "${((this.getFloatingProgress() / 1.0f) * 100).toInt()}%"
}

fun User.reachedXpCap(): Boolean {
    return this.progressEarnedToday == this.progressCap
}

fun User.remainingXp(): Int {
    return this.progressCap - this.progressEarnedToday
}

fun User.getRole(): String {
    return this.role ?: ""
}

fun User.isTeacherOrParent(): Boolean {
    return this.role == UserRole.Teacher.label || this.role == UserRole.Parent.label
}
