package components.modals.achievements

import com.jet.classroomhero.entities.ReinforcerUploadFeedback
import components.dashboard.DashboardProps
import components.modals.ModalType
import components.widgets.Modal
import kotlinx.css.Align
import kotlinx.css.BorderStyle
import kotlinx.css.Color
import kotlinx.css.JustifyContent
import kotlinx.css.alignItems
import kotlinx.css.borderBottomColor
import kotlinx.css.borderBottomStyle
import kotlinx.css.borderBottomWidth
import kotlinx.css.color
import kotlinx.css.gap
import kotlinx.css.justifyContent
import kotlinx.css.padding
import kotlinx.css.px
import react.RBuilder
import styled.css
import styled.styledDiv
import styled.styledH3
import styled.styledP
import styles.GlobalCss.flexColumn
import styles.GlobalCss.flexRow
import utils.Utils

fun RBuilder.uploadFeedbackModal(
  props: DashboardProps,
  feedbacks: List<ReinforcerUploadFeedback>
) {
  Modal(onClose = {
    props.showModal(true, ModalType.MEMBER_DETAIL)
  }) {
    styledDiv {
      css(flexColumn)
      css {
        gap = 12.px
      }
      styledH3 {
        +"All feedback"
      }
      for (feedback in feedbacks) {
        styledDiv {
          css(flexColumn)
          css {
            alignItems = Align.start
            justifyContent = JustifyContent.start
            gap = 6.px
            borderBottomColor = Color("#eee")
            borderBottomWidth = 1.px
            borderBottomStyle = BorderStyle.solid
            padding = "12px"
          }
          styledP {
            +feedback.feedback
          }
          styledDiv {
            css(flexRow)
            styledP {
              css {
                color = Color("#AAAFB4")
              }
              + "Date: "
            }
            styledP {
              css {
                color = Color("#AAAFB4")
              }
              + Utils.formatDate(feedback.createdAt)
            }
          }
        }
      }
    }
  }
}