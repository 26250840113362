package com.jet.classroomhero.entities

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Extension (
  var id: Int = 0,
  var name: String = "",
  var description: String = "",
  @SerialName("image")
  var imageUrl: String = "",
  var activated: Boolean = false
)