package utils

import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlin.js.Date

open class Utils {
  companion object {
    fun getDayOfWeekIndexMap(): HashMap<Int, Int> {
      return hashMapOf(
        0 to 0,
        1 to 0,
        2 to 0,
        3 to 0,
        4 to 0,
        5 to 0,
        6 to 0
      )
    }
    fun getDayOfWeekNameMap(): HashMap<Int, String> {
      return hashMapOf(
        0 to "Sun",
        1 to "Mon",
        2 to "Tue",
        3 to "Wed",
        4 to "Thu",
        5 to "Fri",
        6 to "Sat"
      )
    }
    fun getMonthNameMap(): HashMap<Int, String> {
      return hashMapOf(
        1 to "Jan",
        2 to "Feb",
        3 to "Mar",
        4 to "Apr",
        5 to "May",
        6 to "Jun",
        7 to "Jul",
        8 to "Aug",
        9 to "Sep",
        10 to "Oct",
        11 to "Nov",
        12 to "Dec"
      )
    }
    fun getDayOfWeekNameForIndex(index: Int): String {
      return getDayOfWeekNameMap()[index]!!
    }
    fun getBarHeightPercentage(value: Int, maxValue: Int): Double {
      return (value.toDouble() / maxValue) * 100
    }
    fun processDateTimesForChart(dates: List<String>): HashMap<Int, Int> {
      val map = getDayOfWeekIndexMap()
      dates.forEach { dateTime ->
        val dayOfWeek = Date(dateTime).getDay()
        map[dayOfWeek] = map[dayOfWeek]!!.plus(1)
      }
      return map
    }
    fun formatDate(originalDate: String): String {
      val instant = Instant.parse(originalDate)
      val date = instant.toLocalDateTime(TimeZone.currentSystemDefault())
      val month = date.monthNumber
      val day = date.dayOfMonth
      return "${getMonthNameMap().get(month)} $day, at ${get12HourTime(date.hour)}:${date.minute} ${getTimeSuffix(date.minute)}"
    }
    private fun get12HourTime(hours: Int): Int {
      return ((hours + 11) % 12 + 1)
    }
    private fun getTimeSuffix(hours: Int): String {
      return if (hours >= 11) "PM" else "AM"
    }
  }
}