package components.widgets.dropdowns

import com.jet.classroomhero.entities.Class
import components.dashboard.DashboardCss
import components.widgets.circularFaIcon
import components.widgets.imageWidget
import components.widgets.faIcon
import kotlinx.css.*
import kotlinx.html.js.onClickFunction
import react.Props
import react.fc
import styled.css
import styled.styledDiv
import styled.styledP

external interface GroupsDropDownProps : Props {
  var groups: List<Class>?
  var currentGroup: Class?
  var showDropdown: (Boolean) -> Unit
  var dropDownShowing: Boolean
  var setSelectedGroup: (Int) -> Unit
  var containerWidth: LinearDimension?
}

val GroupsDropDownMenu = fc<GroupsDropDownProps> { props ->
  styledDiv {
    css(DashboardCss.groupsDropDown)
    css {
      width = props.containerWidth ?: 330.px
    }
    attrs.onClickFunction = {
      props.showDropdown(true)
    }
    if (props.currentGroup != null) {
      val group = props.currentGroup
      if (group!!.groupLogoUrl.isNullOrEmpty()) {
        circularFaIcon(size = 45.px, iconSize = 20.px)
      } else {
        imageWidget("${group.groupLogoUrl}", size = 30.px)
      }
      styledP {
        css {
          color = Color.black
          fontSize = 20.px
          overflow = Overflow.hidden
          textOverflow = TextOverflow.ellipsis
        }
        + group.name
      }
      if (!props.groups.isNullOrEmpty()) {
        faIcon("fa-chevron-down", iconColor = Color("#000"))
      }
    }
    styledDiv {
      css(DashboardCss.groupsDropDownContent)
      css {
        display = if (props.dropDownShowing) Display.flex else Display.none
      }
      for (group in props.groups ?: emptyList()) {
        styledDiv {
          css(DashboardCss.dropDownItem)
          attrs.onClickFunction = {
            props.setSelectedGroup(group.id)
          }
          if (group.groupLogoUrl.isNullOrEmpty()) {
            circularFaIcon(size = 45.px, iconSize = 20.px)
          } else {
            imageWidget("${group.groupLogoUrl}", size = 45.px)
          }
          styledP {
            css {
              color = Color.black
              fontSize = 18.px
              overflow = Overflow.hidden
              textOverflow = TextOverflow.ellipsis
              marginLeft = 8.px
            }
            + group.name
          }
        }
      }
    }
  }
}
