package actions

import com.jet.classroomhero.entities.Jar
import redux.RAction
import store.RThunk
import utils.Request

private val jarUseCases = InjectorUtils.provideJarUseCases()

object JarActions {
  fun createJar(jar: Jar, groupId: Int): RThunk = createJarRequest(jar, groupId)
  fun editJar(jar: Jar, groupId: Int): RThunk = editJarRequest(jar, groupId)
  fun deleteJar(jarId: Int, groupId: Int): RThunk = deleteJarRequest(jarId, groupId)

  data class CreateJar(val jar: Jar) : RAction
  data class EditJar(val jar: Jar) : RAction
  data class DeleteJar(val jarId: Int) : RAction
}

private val createJarRequest: (jar: Jar, groupId: Int) -> RThunk = { jar, groupId ->
  Request(
    doInBackground = { dispatch ->
      jarUseCases.createJar(jar, groupId)?.let {
        dispatch(JarActions.CreateJar(it))
      }
    },
    onError = { message, dispatch -> dispatch(MemberActions.Error(message)) },
    onSuccess = { dispatch ->
      dispatch(AppStateActions.SetModalShowing(false, null))
    }
  )
}

private val editJarRequest: (jar: Jar, groupId: Int) -> RThunk = { jar, groupId ->
  Request(
    doInBackground = { dispatch ->
      dispatch(JarActions.EditJar(jar))
      dispatch(AppStateActions.SetSelectedJar(jar))
      jarUseCases.editJar(jar, groupId)?.let { updatedJar ->
        dispatch(JarActions.EditJar(updatedJar))
        dispatch(AppStateActions.SetSelectedJar(jar))
      }
    },
    onError = { message, dispatch -> dispatch(MemberActions.Error(message)) },
    onSuccess = { dispatch ->
      dispatch(AppStateActions.SetModalShowing(false, null))
    }
  )
}

private val deleteJarRequest: (jarId: Int, groupId: Int) -> RThunk = { jarId, groupId ->
  Request(
    doInBackground = { dispatch ->
      val success = jarUseCases.deleteJar(jarId, groupId)
      if (success) {
        dispatch(JarActions.DeleteJar(jarId))
      } else {
        throw Exception("An error occurred deleting this member")
      }
    },
    onError = { message, dispatch -> dispatch(MemberActions.Error(message)) },
    onSuccess = { dispatch ->
      dispatch(AppStateActions.SetModalShowing(false, null))
    }
  )
}