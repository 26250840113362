package com.jet.classroomhero.data.remote.clients

import com.jet.classroomhero.Logger
import com.jet.classroomhero.data.AnonymousRemoteUserSource
import com.jet.classroomhero.data.LocalUserSource
import com.jet.classroomhero.data.remote.exceptions.CHRequestException
import com.jet.classroomhero.data.remote.exceptions.CHServerResponseError
import com.jet.classroomhero.entities.Tokens
import com.jet.classroomhero.entities.errors.CHErrorDetail
import com.jet.classroomhero.entities.errors.CHErrorMessage
import io.ktor.client.*
import io.ktor.client.engine.js.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.kotlinx.serializer.*
import io.ktor.client.statement.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.jsonObject

actual class AuthorizedClient actual constructor(
    localUserSource: LocalUserSource,
    userSourceAnonymous: AnonymousRemoteUserSource
) : AnonymousClient() {
    var logs = Logger()

    companion object {
        val format = Json { ignoreUnknownKeys = true }
    }

    actual override var httpClient: HttpClient = HttpClient(Js) {
        expectSuccess = true
        install(ContentNegotiation) {
            val json = Json {
                prettyPrint = true
                isLenient = true
                ignoreUnknownKeys = true
            }
            json(json)
        }
        HttpResponseValidator {
            handleResponseExceptionWithRequest { exception, request ->
                logs.logMessage("handleResponseExceptionWithRequest")
                val responseException =
                    exception as? ResponseException ?: return@handleResponseExceptionWithRequest
                val response = responseException.response.bodyAsText()
                logs.logMessage("CHDEBUG: JS AuthorizedClient raw exception: \n ${response}")
                val message = parseError(response)
                logs.logMessage("CHDEBUG: JS AuthorizedClient parsed exception: \n ${message}")
                when (exception) {
                    is ClientRequestException -> throw CHRequestException(message)
                    is ServerResponseException -> throw CHServerResponseError(message)
                    else -> throw exception
                }
            }
        }
        install(Auth) {
            lateinit var tokens: Tokens
            bearer {
                loadTokens {
                    logs.logMessage("CHDEBUG: INSIDE loadTokens, reading")
                    val user = localUserSource.readUser() ?: return@loadTokens null
                    logs.logMessage("CHDEBUG: INSIDE loadTokens, got user $user")
                    BearerTokens(
                        accessToken = user.token!!,
                        refreshToken = user.refresh!!
                    )
                }
                refreshTokens {
                    logs.logMessage("CHDEBUG: INSIDE refreshTokens, reading")
                    val user = localUserSource.readUser()
                    logs.logMessage("CHDEBUG: got user, refreshing $user")
                    tokens = userSourceAnonymous.refreshJwt(user?.refresh!!)
                    BearerTokens(
                        accessToken = tokens.access,
                        refreshToken = tokens.refresh
                    )
                }
            }
        }
    }

    private fun parseError(originalResponse: String?): String {
        if (originalResponse.isNullOrEmpty()) return "Unknown Error"

        var error = ""
        val jsonObject = Json.parseToJsonElement(originalResponse).jsonObject
        if (jsonObject.contains("message")) {
            error = format.decodeFromString<CHErrorMessage>(originalResponse).message
        }
        if (jsonObject.contains("detail")) {
            error = format.decodeFromString<CHErrorDetail>(originalResponse).detail
        }
        return error
    }
}