package components.forms

import com.jet.classroomhero.UtilsCommon
import com.jet.classroomhero.entities.Reinforcer
import components.accounts.LoginCardCss
import components.widgets.Button
import components.widgets.RadioButton
import kotlinx.browser.window
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onSubmitFunction
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLTextAreaElement
import react.Props
import react.dom.value
import react.fc
import react.useState
import styled.*
import styles.GlobalCss

val achievementTypes = mapOf(
  "inperson" to AchievementType.IN_PERSON,
  "remote" to AchievementType.REMOTE
)

enum class AchievementType(val label: String, val description: String) {
  IN_PERSON("In-Person", "This achievement can only be awarded manually by you."),
  REMOTE("Remote", "Members can upload files (documents, videos, images, etc) to complete this achievement.")
}

external interface CreateOrEditAchievementFormProps : BaseFormProps {
  var achievement: Reinforcer?
  var onDeleteAchievement: (Int) -> Unit
  var onEditAchievement: (Reinforcer) -> Unit
  var onCreateAchievement: (Reinforcer) -> Unit
  var hasPaid: Boolean
}

val CreateOrEditAchievementForm = fc<CreateOrEditAchievementFormProps> { props ->
  val (name, setName) = useState(props.achievement?.name ?: "")
  val (value, setValue) = useState(props.achievement?.value ?: 0)
  val (description, setDescription) = useState(props.achievement?.description ?: "")
  val (allowUploads, setAllowUploads) = useState(props.achievement?.type == "remote")
  val (errorMessage, setErrorMessage) = useState("")

  styledForm {
    attrs.id = "achievement-" + props.achievement?.id.toString() + "-form"
    css(GlobalCss.flexCenter)
    css {
      justifyContent = JustifyContent.spaceEvenly
      maxWidth = 500.px
      minHeight = 280.px
      width = 100.pct
    }
    attrs.onSubmitFunction = { event ->
      event.preventDefault()
    }
    /* Achievement Name Field */
    styledDiv {
      css {
        marginBottom = 20.px
        width = 100.pct
      }
      styledInput {
        css(LoginCardCss.inputStyle)
        css {
          marginBottom = 5.px
          width = 100.pct
        }
        attrs.value = name
        attrs.type = InputType.text
        attrs.placeholder = "Achievement Name"
        attrs.required = true
        attrs.onChangeFunction = { event ->
          val target = event.target as HTMLInputElement
          setName(target.value)
        }
      }
      styledP {
        css {
          fontSize = 12.px
          color = Color.dimGray
        }
        + "Ex: Completed Homework"
      }
    }
    /* Achievement Value Field */
    styledDiv {
      css {
        marginBottom = 20.px
      }
      styledInput {
        css(LoginCardCss.inputStyle)
        css {
          marginBottom = 5.px
        }
        attrs.value = if (value <= 0) "" else value.toString()
        attrs.type = InputType.number
        attrs.placeholder = "Achievement Value"
        attrs.onChangeFunction = { event ->
          val target = event.target as HTMLInputElement
          if (target.value.toIntOrNull() == null) {
            setValue(0)
          } else {
            setValue(target.value.toInt())
          }
        }
      }
      styledP {
        css {
          fontSize = 12.px
          color = Color.dimGray
        }
        + "This is the amount of currency a member earns upon completing this achievement"
      }
    }
    /* Achievement Description Field */
    styledDiv {
      css {
        marginBottom = 20.px
        width = 100.pct
      }
      styledTextarea {
        css(LoginCardCss.inputStyle)
        css {
          marginBottom = 5.px
          height = 110.px
          width = 100.pct
        }
        attrs.value = description
        attrs.placeholder = "Description (Optional) - Provide more information about how to complete this achievement."
        attrs.onChangeFunction = { event ->
          val target = event.target as HTMLTextAreaElement
          setDescription(target.value)
        }
      }
    }
    /* Achievement Type Field */
    styledDiv {
      css {
        marginBottom = 16.px
        width = 100.pct
      }

      RadioButton(
        isSelected = allowUploads,
        disabled = !props.hasPaid,
        labelContent = {
          styledDiv {
            css {
              textAlign = kotlinx.css.TextAlign.left
            }
            styledP {
              css {
                fontWeight = FontWeight.bold
                fontSize = 14.px
                if (!props.hasPaid) {
                  color = Color.dimGray
                }
              }
              + "Allow Uploads"
            }
            styledP {
              css {
                fontSize = 14.px
                color = Color.dimGray
              }
              + "Class members can upload files (documents, videos, images, etc) to complete this achievement."
            }
          }

        },
        onPress = { isSelected ->
          setAllowUploads(isSelected)
        }
      )
      if (!props.hasPaid) {
        styledA {
          css {
            marginTop = 8.px
            color = Color("#8c52ff")
            fontSize = 12.px
            fontWeight = FontWeight.bold
          }
          attrs.href = UtilsCommon.getBaseUrlForBuildConfig() + "payments/checkout"
          + "Upgrade to unlock Achievement Uploads"
        }
      }
    }
    // on press is handled by the form submit above
    if (props.isEditing) {
      Button(
        text = "Update Achievement",
        loading = props.isLoading,
        customCss = {
          marginBottom = 10.px
        }
      ) {
        it.preventDefault()
        setErrorMessage("")
        if (name.isEmpty() || value <= 0) {
          setErrorMessage("Name and Value are required.")
        } else {
          val updatedAchievement = props.achievement!!.copy(
            name = name,
            value = value,
            description = description,
            type = if (allowUploads) "remote" else "inperson"
          )
          props.onEditAchievement(updatedAchievement)
        }
      }
      Button(
        text = "Delete Achievement",
        bgColor = "#B00020",
      ) {
        it.preventDefault()
        props.onDeleteAchievement(props.achievement!!.id)
      }
    } else {
      Button("Create Achievement", loading = props.isLoading) {
        it.preventDefault()
        setErrorMessage("")
        if (name.isEmpty() || value <= 0) {
          setErrorMessage("Name and Value are required.")
        } else {
          props.onCreateAchievement(Reinforcer(
            name = name,
            value = value,
            description = description,
            type = if (allowUploads) "remote" else "inperson"
          ))
        }

      }
    }
    if (errorMessage.isNotEmpty()) {
      styledP {
        css {
          color = Color("#B00020")
          fontSize = 16.px
          marginTop = 10.px
        }
        + errorMessage
      }
    }
  }
}