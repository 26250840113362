package utils

import actions.AppStateActions
import kotlinx.coroutines.*
import redux.RAction
import redux.WrapperAction
import store.AppState
import store.RThunk
import store.nullAction

/**
 * @author Eric Horvat
 *
 * A convenience class for performing async (thunk) actions.
 *
 * @param onBeforeAction - The action to dispatch before any work is done, usually a loading action
 * @param onError - The callback when an error occurs. Requires an exception be thrown in doInBackground()
 * @param onSuccess - The callback when no errors occur and all work completed successfully. Could redirect to a new page here, for example
 * @param doInBackground - The actual work to do asynchronously. Usually a call to a Repository method
 */
class Request(
    private val ioDispatcher: CoroutineDispatcher = Dispatchers.Unconfined,
    private val mainDispatcher: CoroutineDispatcher = Dispatchers.Main,
    private val onBeforeAction: RAction = AppStateActions.SetLoading(true),
    private val onError: (message: String, (RAction) -> WrapperAction) -> Unit = {_, _ -> },
    private val onSuccess: ((RAction) -> WrapperAction?) -> Unit = {},
    private val onComplete: RAction = AppStateActions.SetLoading(false),
    private val doInBackground: suspend ((RAction) -> WrapperAction) -> Unit = {}
    ): RThunk {
    override fun invoke(dispatch: (RAction) -> WrapperAction, getState: () -> AppState): WrapperAction {
        dispatch(onBeforeAction)
        CoroutineScope(ioDispatcher).launch {
            try {
                doInBackground(dispatch)
                withContext(mainDispatcher) {
                    onSuccess(dispatch)
                }
            } catch (e: Exception) {
                onError(e.message ?: "Unknown request error", dispatch)
            }
            dispatch(onComplete)
        }
        return nullAction
    }
}