package components.modals.groups

import components.forms.CreateOrEditGroupForm
import components.modals.ModalType
import components.profile.ProfileLocalState
import components.profile.ProfileProps
import components.widgets.Modal
import kotlinx.css.*
import react.RBuilder
import styled.css
import styled.styledDiv
import styled.styledH2

fun RBuilder.createGroupModal(
  props: ProfileProps,
  state: ProfileLocalState
) {
  Modal(onClose = {
    props.showModal(false, null)
    props.setEditing(false)
  }) {
    styledDiv {
      css {
        width = 100.pct
      }
      styledH2 {
        css {
          margin = "12px 0"
        }
        + if (props.isEditing) "Update Class" else "Create a new Class"
      }
      CreateOrEditGroupForm {
        attrs.profileProps = props
        attrs.isEditing = props.isEditing
        attrs.isLoading = props.isLoading
        attrs.selectedGroup = state.selectedGroup
        attrs.onSubmit = { group, groupIconFile, currencyIconFile, coverPhotoFile, templateId ->
          if (props.isEditing) {
            props.editGroup(group, groupIconFile, currencyIconFile, coverPhotoFile)
          } else {
            props.createGroup(group, groupIconFile, currencyIconFile, coverPhotoFile, templateId)
          }
          props.setEditing(false)
          props.showModal(false, ModalType.CREATE_GROUP)
        }
        attrs.onDelete = { id ->
          props.setEditing(false)
          props.showModal(true, ModalType.DELETE_GROUP)
//          props.deleteGroup(id)
//          props.setEditing(false)
//          props.showModal(false, null)
        }
        attrs.stockMedia = props.stockMedia
        attrs.templates = props.templates
      }
    }
  }
}
