package components.forms

import com.jet.classroomhero.entities.Item
import components.accounts.LoginCardCss
import components.widgets.Button
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onSubmitFunction
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLTextAreaElement
import react.Props
import react.dom.value
import react.fc
import react.useState
import styled.*

external interface CreateOrEditItemFormProps : BaseFormProps {
  var item: Item?
  var onDeleteItem: (Int) -> Unit
  var onEditItem: (Item) -> Unit
  var onCreateItem: (Item) -> Unit
}

val CreateOrEditItemForm = fc<CreateOrEditItemFormProps> { props ->
  val (name, setName) = useState(props.item?.name ?: "")
  val (value, setValue) = useState(props.item?.cost ?: 0)
  val (description, setDescription) = useState(props.item?.description ?: "")
  val (errorMessage, setErrorMessage) = useState("")

  styledForm {
    attrs.id = "item-" + props.item?.id.toString() + "-form"
    css {
      display = Display.flex
      flexDirection = FlexDirection.column
      alignItems = Align.center
      justifyContent = JustifyContent.spaceEvenly
      maxWidth = 300.px
      minHeight = 280.px
      width = 100.pct
    }
    attrs.onSubmitFunction = { event ->
      event.preventDefault()
    }
    /* Item Name Field */
    styledDiv {
      css {
        marginBottom = 20.px
      }
      styledInput {
        css(LoginCardCss.inputStyle)
        css {
          marginBottom = 5.px
        }
        attrs.value = name
        attrs.type = InputType.text
        attrs.placeholder = "Item Name"
        attrs.required = true
        attrs.onChangeFunction = { event ->
          val target = event.target as HTMLInputElement
          setName(target.value)
        }
      }
      styledP {
        css {
          fontSize = 12.px
          color = Color.dimGray
        }
        + "Ex: New Pencil"
      }
    }
    /* Item Cost Field */
    styledDiv {
      css {
        marginBottom = 20.px
      }
      styledInput {
        css(LoginCardCss.inputStyle)
        css {
          marginBottom = 5.px
        }
        attrs.value = value.toString()
        attrs.type = InputType.number
        attrs.placeholder = "Item Cost"
        attrs.onChangeFunction = { event ->
          val target = event.target as HTMLInputElement
          setValue(target.value.toInt())
        }
      }
      styledP {
        css {
          fontSize = 12.px
          color = Color.dimGray
        }
        + "This is the amount of currency a member must spend to acquire this item"
      }
    }
    /* Item Description Field */
    styledDiv {
      css {
        marginBottom = 20.px
      }
      styledTextarea {
        css(LoginCardCss.inputStyle)
        css {
          marginBottom = 5.px
          height = 110.px
        }
        attrs.value = description
        attrs.placeholder = "Description (Optional)"
        attrs.onChangeFunction = { event ->
          val target = event.target as HTMLTextAreaElement
          setDescription(target.value)
        }
      }
      styledP {
        css {
          fontSize = 12.px
          color = Color.dimGray
        }
        + "Provide a bit more information about this item."
      }
    }
    // on press is handled by the form submit above
    if (props.isEditing) {
      Button("Update Item", loading = props.isLoading) {
        it.preventDefault()
        val updatedItem = props.item!!.copy(
          name = name,
          cost = value,
          description = description
        )
        props.onEditItem(updatedItem)
      }
      Button(
        text = "Delete Item",
        bgColor = "#B00020",
      ) {
        it.preventDefault()
        props.onDeleteItem(props.item!!.id)
      }
    } else {
      Button("Create Item", loading = props.isLoading) {
        it.preventDefault()
        setErrorMessage("")
        if (name.isEmpty() && value < 1) {
          setErrorMessage("Name and Value are required.")
        } else {
          props.onCreateItem(
            Item(
              name = name,
              cost = value,
              description = description
            )
          )
        }
      }
      if (errorMessage.isNotEmpty()) {
        styledP {
          css {
            color = Color("#B00020")
            fontSize = 16.px
            marginTop = 10.px
          }
          + errorMessage
        }
      }
    }
  }
}