package components.modals

enum class ModalType {
  // Member Modals
  MEMBER_DETAIL,
  CREATE_MEMBER,
  EDIT_MEMBER,
  DELETE_MEMBER,
  ADJUST_CURRENCY,
  GIVE_ACHIEVEMENT,
  SELL_ITEM,
  ALL_ACHIEVEMENTS,
  ALL_TRANSACTIONS,
  // Achievement Modals
  CREATE_ACHIEVEMENT,
  EDIT_ACHIEVEMENT,
  ACHIEVEMENT_DETAIL,
  TRANSACTION_DETAIL,
  // Item Modals
  CREATE_ITEM,
  EDIT_ITEM,
  ITEM_DETAIL,

  // Groups
  JOIN_GROUP,
  CREATE_GROUP,
  EDIT_GROUP,
  DELETE_GROUP,
  // Info Modals
  INFO_MODAL_GROUPS,
  INFO_MODAL_MEMBERSHIPS,
  INFO_MODAL_MANAGER_MEMBERS,
  INFO_MODAL_MANAGER_ACHIEVEMENTS,
  INFO_MODAL_MANAGER_MARKETPLACE,
  INFO_MODAL_MEMBER_MEMBERS,
  INFO_MODAL_MEMBER_ACHIEVEMENTS,
  INFO_MODAL_MEMBER_MARKETPLACE,
  // Extension Modals
  EXTENSION_DETAIL_MODAL,
  // Jar Modals,
  CREATE_JAR,
  JAR_DETAIL,
  // ReinforcerUpload Modals
  UPLOAD_DETAIL_MODAL,
  UPLOAD_FEEDBACK_MODAL
}