package actions

import com.jet.classroomhero.entities.Item
import redux.RAction
import store.RThunk
import utils.Request

private val itemUseCases = InjectorUtils.provideItemUseCases()

object ItemActions {
  fun createItem(item: Item, groupId: Int): RThunk = createItemRequest(item, groupId)
  fun editItem(item: Item, groupId: Int): RThunk = editItemRequest(item, groupId)
  fun deleteItem(itemId: Int, groupId: Int): RThunk = deleteItemRequest(itemId, groupId)

  data class CreateItem(val item: Item) : RAction
  data class EditItem(val item: Item) : RAction
  data class DeleteItem(val itemId: Int) : RAction
}

fun GroupActions.GroupState.handleItemActions(action: RAction): GroupActions.GroupState {
  return when (action) {

    else -> {
      return this
    }
  }
}

private val createItemRequest: (item: Item, groupId: Int) -> RThunk = { item, groupId ->
  Request(
    doInBackground = { dispatch ->
      itemUseCases.createItem(item, groupId)?.let {
        dispatch(ItemActions.CreateItem(it))
//        dispatch(AppStateActions.SetSelectedItem(it))
      }
    },
    onError = { message, dispatch -> dispatch(MemberActions.Error(message)) },
    onSuccess = { dispatch ->
      dispatch(AppStateActions.SetModalShowing(false, null))
    }
  )
}

private val editItemRequest: (item: Item, groupId: Int) -> RThunk = { item, groupId ->
  Request(
    doInBackground = { dispatch ->
      itemUseCases.editItem(item, groupId)?.let { updatedItem ->
        dispatch(ItemActions.EditItem(updatedItem))
        dispatch(AppStateActions.SetSelectedItem(null))
      }
    },
    onError = { message, dispatch -> dispatch(MemberActions.Error(message)) },
    onSuccess = { dispatch ->
      dispatch(AppStateActions.SetModalShowing(false, null))
    }
  )
}

private val deleteItemRequest: (itemId: Int, groupId: Int) -> RThunk = { itemId, groupId ->
  Request(
    doInBackground = { dispatch ->
      val success = itemUseCases.deleteItem(itemId, groupId)
      if (success) {
        dispatch(ItemActions.DeleteItem(itemId))
      } else {
        throw Exception("An error occurred deleting this member")
      }
    },
    onError = { message, dispatch -> dispatch(MemberActions.Error(message)) },
    onSuccess = { dispatch ->
      dispatch(AppStateActions.SetModalShowing(false, null))
    }
  )
}