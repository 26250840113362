package com.jet.classroomhero.entities

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Transaction(
    var id: Int = 0,
    @SerialName("group_id")
    var groupId: Int? = 0,
    @SerialName("member_id")
    var memberId: Int? = 0,
    @SerialName("member_name")
    var memberName: String,
    @SerialName("item_name")
    var itemName: String,
    @SerialName("item_cost")
    var itemCost: Int,
    @SerialName("created_at")
    var createdAt: String
)