package components.forms

import com.jet.classroomhero.combineNames
import com.jet.classroomhero.entities.Class
import com.jet.classroomhero.entities.Reinforcer
import com.jet.classroomhero.entities.Student
import components.profile.GroupCss.item
import components.widgets.Button
import components.widgets.RadioButton
import components.widgets.valueAndCurrencyIcon
import kotlinx.css.*
import kotlinx.html.id
import kotlinx.html.js.onSubmitFunction
import react.Props
import react.fc
import react.useId
import react.useState
import styled.*
import styles.GlobalCss

external interface GiveAchievementFormProps : BaseFormProps {
  var member: Student
  var group: Class
  var achievements: List<Reinforcer>
  var onBulkCompleteAchievement: (List<Int>, Int, Int) -> Unit
}

val GiveAchievementForm = fc<GiveAchievementFormProps> { props ->
  val (selectedAchievement, setSelectedAchievement) = useState<Reinforcer?>(null)

  styledForm {
    attrs.id = "give-achievement-" + props.member.id.toString() + "-form"
    css {
      display = Display.flex
      flexDirection = FlexDirection.column
      marginBottom = 18.px
      backgroundColor = Color.white
      padding = "12px 120px"
      width = 100.pct
    }
    attrs.onSubmitFunction = {
      it.preventDefault()
    }
    props.group.let {
      for (achievement in props.achievements) {
        if (achievement.type =="remote"){
            continue
        }
        RadioButton(
          isSelected = selectedAchievement?.id == achievement.id,
          labelContent = {
            styledDiv {
              attrs.id = "give-achievement-" + props.member.id.toString() + "-form-" + achievement.id.toString()
              css(GlobalCss.flexRow)
              styledSpan {
                css {

                  marginRight = 8.px
                  whiteSpace = WhiteSpace.pre

                }
                + "${achievement.name}"
              }
              valueAndCurrencyIcon(amount = achievement.value ?: 0,  currencyImgUrl = props.group.currencyIconUrl ?: "")
            }
          },
          onPress = { isSelected ->
            if (isSelected) {
              setSelectedAchievement(achievement)
            } else {
              setSelectedAchievement(null)
            }
          }
        )
      }
    }
  }
  Button(
    bgColor = if (selectedAchievement != null) "#1976d2" else "#cccccc",
    loading = props.isLoading,
    customCss = {
      maxWidth = 280.px
      alignSelf = Align.center
      marginTop = 40.px
    },
    text = if (selectedAchievement != null) "Award ${selectedAchievement?.name} to ${props.member.combineNames()}" else "Choose an Achievement"
  ) { event ->
    event.preventDefault()
    props.onBulkCompleteAchievement(
      listOf(props.member.id),
      props.group.id,
      selectedAchievement!!.id
    )
  }
}