package actions

import com.jet.classroomhero.entities.Class
import com.jet.classroomhero.entities.Extension
import com.jet.classroomhero.entities.Item
import com.jet.classroomhero.entities.Jar
import com.jet.classroomhero.entities.Reinforcer
import com.jet.classroomhero.entities.Student
import components.modals.ModalType
import kotlinx.browser.document
import kotlinx.browser.window
import redux.RAction
import store.RThunk
import utils.Request
import com.jet.classroomhero.Logger

object AppStateActions {

    val logger = Logger()

    data class AppState(
        val modalShowing: Boolean = false,
        val snackBarShowing: Boolean = false,
        val snackBarMessage: String = "Action Completed",
        val dropDownShowing: Boolean = false,
        val isEditing: Boolean = false,
        val isLoading: Boolean = false,
        val isGlobalLoading: Boolean = false,
        val currentGroup: Class? = null,
        val selectedModalType: ModalType? = null,
        val selectedMember: Student? = null,
        val selectedAchievement: Reinforcer? = null,
        val selectedItem: Item? = null,
        val selectedExtension: Extension? = null,
        val selectedJar: Jar? = null
    )

    fun setSelectedGroup(groupId: Int): RThunk = setSelectedGroupRequest(groupId)
    fun setSelectedMembership(groupId: Int, memberId: Int) = setSelectedMembershipRequest(groupId, memberId)

    data class SetDropdownShowing(val showing: Boolean): RAction
    data class SetModalShowing(val showing: Boolean, val modalType: ModalType?): RAction
    data class SetIsEditing(val editing: Boolean): RAction
    data class SetSelectedGroup(val group: Class): RAction
    data class SetSelectedMember(val member: Student?): RAction
    data class SetSelectedAchievement(val reinforcer: Reinforcer?): RAction
    data class SetSelectedItem(val item: Item?): RAction
    data class SetSelectedJar(val jar: Jar?): RAction
    data class SetSelectedExtension(val extension: Extension?): RAction
    data class SetShowSnackBar(val show: Boolean, val message: String): RAction
    data class SetLoading(val isLoading: Boolean): RAction
    data class SetGlobalLoading(val isLoading: Boolean): RAction

    fun reducer(appState: AppState = AppState(), action: RAction): AppState {
        logger.logMessage("action: $action")
        return when (action) {
            is SetDropdownShowing -> appState.copy(dropDownShowing = action.showing)
            is SetModalShowing -> appState.copy(modalShowing = action.showing, selectedModalType = action.modalType)
            is SetIsEditing -> appState.copy(isEditing = action.editing)
            is SetSelectedGroup -> appState.copy(currentGroup = action.group)
            is SetSelectedMember -> appState.copy(selectedMember = action.member)
            is SetSelectedAchievement -> appState.copy(selectedAchievement = action.reinforcer)
            is SetSelectedItem -> appState.copy(selectedItem = action.item)
            is SetSelectedJar -> appState.copy(selectedJar = action.jar)
            is SetSelectedExtension -> appState.copy(selectedExtension = action.extension)
            is SetShowSnackBar -> appState.copy(snackBarShowing = action.show, snackBarMessage = action.message)
            is SetLoading -> appState.copy(isLoading = action.isLoading)
            is SetGlobalLoading -> appState.copy(isGlobalLoading = action.isLoading)
            else -> appState.copy()
        }
    }
}

private val setSelectedGroupRequest: (groupId: Int) -> RThunk = { groupId ->
    Request(
        doInBackground = { dispatch ->
            window.localStorage.setItem("CURRENT_GROUP_ID", groupId.toString())
        },
        onSuccess = { document.location?.href = "/group/${groupId}/dashboard" }
    )
}

private val setSelectedMembershipRequest: (groupId: Int, memberId: Int) -> RThunk = { groupId, memberId ->
    Request(
        doInBackground = { dispatch ->
            window.localStorage.setItem("CURRENT_GROUP_ID", groupId.toString())
        },
        onSuccess = { document.location?.href = "/group/${groupId}/membership/${memberId}/dashboard" }
    )
}