package components.widgets

import kotlinx.css.Color
import kotlinx.css.color
import kotlinx.css.marginTop
import kotlinx.css.px
import react.RBuilder
import styled.css
import styled.styledP

// Hard coded to 1xp per achievement
fun RBuilder.xpLabel() {
  styledP {
    css {
      marginTop = 6.px
      color = Color("#6200EE")
    }
    + "+ 1 XP"
  }
}