package sources.local

import com.jet.classroomhero.Utils
import com.jet.classroomhero.data.LocalUserSource
import com.jet.classroomhero.entities.Tokens
import com.jet.classroomhero.entities.User
import kotlinx.browser.document

import utils.getCookieValue

class CacheUserSource : LocalUserSource {
    companion object {
        const val META_TOKEN_JWT_KEY = "ch_jwt"
        const val META_TOKEN_REFRESH_KEY = "ch_refresh"
    }

    override suspend fun saveUser(user: User): Boolean {
        document.cookie = "${META_TOKEN_JWT_KEY}=${user.token};domain=${Utils.getSessionDomain()}"
        document.cookie = "${META_TOKEN_REFRESH_KEY}=${user.refresh};domain=${Utils.getSessionDomain()}"
        return true
    }

    override suspend fun editUser(user: User) {
    }

    override suspend fun readUser(): User? {
        val jwt = document.getCookieValue(META_TOKEN_JWT_KEY)
        val refresh = document.getCookieValue(META_TOKEN_REFRESH_KEY)
        return User(token = jwt, refresh = refresh)
    }

    override suspend fun deleteUser(): Boolean {
        document.cookie = "${META_TOKEN_JWT_KEY}=;domain=${Utils.getSessionDomain()}"
        document.cookie = "${META_TOKEN_REFRESH_KEY}=;domain=${Utils.getSessionDomain()}"
        return true
    }

    override suspend fun updateJwt(tokens: Tokens) {

    }
}