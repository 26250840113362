package components.forms

import com.jet.classroomhero.combineNames
import com.jet.classroomhero.entities.Student
import components.accounts.LoginCardCss
import components.widgets.Button
import kotlinx.css.*
import kotlinx.html.ButtonType
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onSubmitFunction
import org.w3c.dom.HTMLInputElement
import react.Props
import react.fc
import react.useState
import styled.css
import styled.styledForm
import styled.styledInput
import styled.styledP

external interface CreateMemberFormProps : Props {
  var member: Student?
  var onSubmit: (Student) -> Unit
  var onDeleteMember: (Int) -> Unit
  var onEditMember: (Student) -> Unit
  var onCreateMember: (Student) -> Unit
  var isEditing: Boolean
  var isLoading: Boolean
}

val CreateOrEditMemberForm = fc<CreateMemberFormProps> { props ->
  val (name, setName) = useState(props.member?.combineNames()?.ifEmpty { "" } ?: "")
  val (errorMessage, setErrorMessage) = useState("")

  styledForm {
    attrs.id = "member-" + props.member?.id.toString() + "-form"
    css {
      display = Display.flex
      flexDirection = FlexDirection.column
      alignItems = Align.center
      justifyContent = JustifyContent.spaceEvenly
      maxWidth = 280.px
      minHeight = 180.px
      width = 100.pct
    }
    attrs.onSubmitFunction = { event ->
      event.preventDefault()
    }
    styledInput {
      css(LoginCardCss.inputStyle)
      attrs.value = name
      attrs.type = InputType.text
      attrs.placeholder = "Member Name"
      attrs.required = true
      attrs.onChangeFunction = { event ->
        val target = event.target as HTMLInputElement
        setName(target.value)
      }
    }
    // on press is handled by the form submit above
    if (props.isEditing) {
      Button("Update Member", loading = props.isLoading) {
        it.preventDefault()
        val updatedMember = props.member!!.copy(firstName = name)
        props.onEditMember(updatedMember)
      }
      Button(
        text = "Remove Member",
        bgColor = "#B00020",
      ) {
        it.preventDefault()
        props.onDeleteMember(props.member!!.id)
      }
    } else {

        Button("Add Member", loading = props.isLoading) {
          it.preventDefault()
          if (name.isEmpty()) {
            setErrorMessage("Please enter a name")
          }
          else {
            props.onCreateMember(Student(name = name))
          }
        }
      if (errorMessage.isNotEmpty()) {
        styledP {
          css {
            color = Color("#B00020")
            fontSize = 16.px
            marginTop = 10.px
          }
          + errorMessage
        }
      }
    }
  }
}