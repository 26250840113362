package com.jet.classroomhero.data.remote.sources

import com.jet.classroomhero.UtilsCommon
import com.jet.classroomhero.data.LocalUserSource
import com.jet.classroomhero.data.RemoteStudentSource
import com.jet.classroomhero.entities.ImageUploadResponse
import com.jet.classroomhero.entities.Student
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import kotlinx.datetime.*
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json

class NetworkStudentSource(localUserSource: LocalUserSource) : ProtectedNetworkSource(localUserSource), RemoteStudentSource {

    override suspend fun createStudent(newStudent: Student, classId: Int): Student = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/student/create/"
        return@withContext client.post(url) {
            contentType(ContentType.Application.Json)
            setBody(newStudent)
        }.body()
    }

    override suspend fun bulkCreateStudents(newStudents: List<Student>, classId: Int): List<Student> = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/student/create/bulk/"
        return@withContext client.post(url) {
            contentType(ContentType.Application.Json)
            setBody(newStudents)
        }.body()
    }

    override suspend fun editStudent(updatedStudent: Student, classId: Int): Student = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/student/${updatedStudent.id}/edit/"
        return@withContext client.put(url) {
            contentType(ContentType.Application.Json)
            setBody(updatedStudent)
        }.body()
    }

    override suspend fun deleteStudent(studentId: Int, classId: Int): Boolean = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/student/${studentId}/delete/"
        val response: HttpResponse = client.delete(url) {
            contentType(ContentType.Application.Json)
        }
        return@withContext response.status.isSuccess()
    }

    override suspend fun fetchStudents(classId: Int): List<Student> = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/students/"
        return@withContext client.get(url) {
            contentType(ContentType.Application.Json)
        }.body()
    }

    override suspend fun fetchStudent(classId: Int, memberId: Int): Student = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/$classId/student/$memberId/"
        return@withContext client.get(url) {
            contentType(ContentType.Application.Json)
        }.body()
    }

    override suspend fun completeAchievement(classId: Int, memberId: Int, achievementId: Int): Student = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/student/${memberId}/reward/"
        val requestBody = Json.parseToJsonElement("""
            {
                "reinforcer_id": "$achievementId"
            }
        """)
        return@withContext client.put(url) {
            contentType(ContentType.Application.Json)
            setBody(requestBody)
        }.body()
    }

    override suspend fun deleteAchievement(classId: Int, memberId: Int, achievementId: Int): Student = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/student/${memberId}/deleteAchievement/"
        val requestBody = Json.parseToJsonElement("""
            {
                "achievement_id": "$achievementId"
            }
        """)
        return@withContext client.put(url) {
            contentType(ContentType.Application.Json)
            setBody(requestBody)
        }.body()
    }

    override suspend fun deleteTransaction(classId: Int, memberId: Int, transactionId: Int): Student = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/student/${memberId}/deleteTransaction/"
        val requestBody = Json.parseToJsonElement("""
            {
                "transaction_id": "$transactionId"
            }
        """)
        return@withContext client.put(url) {
            contentType(ContentType.Application.Json)
            setBody(requestBody)
        }.body()
    }

    private fun getLocalDateTime(): LocalDateTime {
        val currentMoment: Instant = Clock.System.now()
        return currentMoment.toLocalDateTime(TimeZone.currentSystemDefault())
    }

    override suspend fun bulkCompleteAchievement(
        classId: Int,
        memberIds: List<Int>,
        achievementId: Int
    ): List<Student> = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/student/reward/bulk/"
        val timestamp = UtilsCommon.getLocalDateTimeWithTimeZoneOffset()
        val requestBody = Json.parseToJsonElement("""
            {
                "reinforcer_id": $achievementId,
                "student_ids": $memberIds,
                "timestamp": "$timestamp"
            }
        """)
        println("CHDEBUG: bulkCompleteAchievement REQUEST BODY: $requestBody")
        return@withContext client.put(url) {
            contentType(ContentType.Application.Json)
            setBody(requestBody)
        }.body()
    }

    override suspend fun bulkCreateAchievement(
        classId: Int,
        memberIds: List<Int>,
        achievementName: String,
        achievementValue: Int
    ): List<Student> = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/student/create_achievement/bulk/"
        val requestBody = Json.parseToJsonElement("""
            {
                "achievement_name": "$achievementName",
                "achievement_value": $achievementValue,
                "student_ids": $memberIds
            }
        """)
        println("CHDEBUG: bulkCreateAchievement REQUEST BODY: $requestBody")
        return@withContext client.put(url) {
            contentType(ContentType.Application.Json)
            setBody(requestBody)
        }.body()
    }

    override suspend fun sellItem(classId: Int, memberId: Int, itemId: Int): Student = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/student/${memberId}/sell/"
        val requestBody = Json.parseToJsonElement("""
            {
                "item_id": "$itemId"
            }
        """)
        return@withContext client.put(url) {
            contentType(ContentType.Application.Json)
            setBody(requestBody)
        }.body()
    }

    @Throws(Exception::class)
    override suspend fun bulkSellItem(
        classId: Int,
        memberIds: List<Int>,
        itemId: Int
    ): List<Student> = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/student/sell/bulk/"
        val requestBody = Json.parseToJsonElement("""
            {
                "item_id": $itemId,
                "student_ids": $memberIds
            }
        """)
        return@withContext client.put(url) {
            contentType(ContentType.Application.Json)
            setBody(requestBody)
        }.body()
    }

    override suspend fun addCurrency(classId: Int, memberId: Int, amountToAdd: Int): Student = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/student/${memberId}/add/"
        val requestBody = Json.parseToJsonElement("""
            {
                "points": "$amountToAdd"
            }
        """)
        return@withContext client.put(url) {
            contentType(ContentType.Application.Json)
            setBody(requestBody)
        }.body()
    }

    override suspend fun subtractCurrency(
        classId: Int,
        memberId: Int,
        amountToSubtract: Int
    ): Student = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/student/${memberId}/subtract/"
        val requestBody = Json.parseToJsonElement("""
            {
                "points": "$amountToSubtract"
            }
        """)
        return@withContext client.put(url) {
            contentType(ContentType.Application.Json)
            setBody(requestBody)
        }.body()
    }

    @OptIn(ExperimentalSerializationApi::class)
    override suspend fun updateProfilePhoto(memberId: Int, bytes: ByteArray): ImageUploadResponse = withContext(Dispatchers.Default) {
        println("$TAG updating profile photo")
        val url = "${baseUrl}api/member/${memberId}/update_profile_photo/"
        val response: HttpResponse = client.submitFormWithBinaryData(
            url = url,
            formData = formData {
                append("description", "Classroom hero profile pic upload")
                append("image", bytes, Headers.build {
                    append(HttpHeaders.ContentType, "image/png")
                    append(HttpHeaders.ContentDisposition, "filename=member_${memberId}_profile_pic.png")
                })
            }
        ) {
            onUpload { bytesSentTotal, contentLength ->
                // TODO: Hook in a callback so that we can show a progress bar in the UI
                println("Sent $bytesSentTotal bytes from $contentLength")
            }
        }
        try {
            val rawJson: String = response.body()
            return@withContext Json.decodeFromString<ImageUploadResponse>(rawJson)
        } catch (e: Exception) {
            throw Exception("Error parsing response: ${e.message}")
        }
    }


    override suspend fun unlinkStudent(studentId: Int): Student = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/student/${studentId}/unlink/"
        val requestBody = Json.parseToJsonElement("""
            {
                "student_id": "$studentId"
            }
        """)
        return@withContext client.put(url) {
            contentType(ContentType.Application.Json)
            setBody(requestBody)
        }.body()
    }




    companion object {
        private val TAG = NetworkStudentSource::class.simpleName
    }
}