package com.jet.classroomhero.data

import com.jet.classroomhero.Logger
import com.jet.classroomhero.data.remote.sources.NetworkReinforcerSource
import com.jet.classroomhero.entities.Reinforcer
import com.jet.classroomhero.entities.ReinforcerUpload
import com.jet.classroomhero.entities.UploadStatus
import com.jet.classroomhero.entities.User
import kotlinx.coroutines.*

class ReinforcerRepository(
    val localUserSource: LocalUserSource,
    private val localReinforcerSource: LocalReinforcerSource,
    private val remoteReinforcerSource: RemoteReinforcerSource = NetworkReinforcerSource(localUserSource)
) {

    val logger = Logger()

    val coroutineScope: CoroutineScope = MainScope()

    private suspend fun readUser(): User? {
        return withContext(Dispatchers.Default) {
            return@withContext localUserSource.readUser()
        }
    }

    suspend fun createReinforcer(newReinforcer: Reinforcer, classId: Int): Reinforcer? {
        logger.logMessage("COMMON: ClassRepository.createClass")
        val creatingReinforcer = coroutineScope.async {
            return@async remoteReinforcerSource.createReinforcer(newReinforcer, classId)
        }
        val createdReinforcer = creatingReinforcer.await()
        createdReinforcer?.let {
            saveReinforcer(it, classId)
            return it
        }
        return null
    }

    suspend fun editReinforcer(updatedReinforcer: Reinforcer, classId: Int): Reinforcer? {
        logger.logMessage("COMMON: ClassRepository.editClass")
        val updatingReinforcer = coroutineScope.async {
            return@async remoteReinforcerSource.editReinforcer(updatedReinforcer, classId)
        }
        val updated = updatingReinforcer.await()
        updated?.let {
            saveReinforcer(it, classId)
            return it
        }
        return null
    }

    suspend fun deleteReinforcer(reinforcerId: Int, classId: Int): Boolean {
        logger.logMessage("COMMON: ClassRepository.deleteClass")
        val deletingReinforcer = coroutineScope.async {
            return@async remoteReinforcerSource.deleteReinforcer(reinforcerId, classId)
        }
        val deleted = deletingReinforcer.await()
        if (deleted) {

            val rows = withContext(Dispatchers.Default) {
                localReinforcerSource.deleteReinforcer(reinforcerId)
            }
            return true
        }
        return false
    }

    suspend fun getReinforcers(forceFetch: Boolean = false, classId: Int): List<Reinforcer> {
        logger.logMessage("CHDEBUG: ClassRepository.getReinforcers")
        if (!forceFetch) {
            val localReinforcers = readReinforcers(classId)
            if (!localReinforcers.isNullOrEmpty()) {
                return localReinforcers
            }
        }
        return try {
            val fetchedReinforcers = remoteReinforcerSource.fetchReinforcers(classId)
            coroutineScope.launch(Dispatchers.Default) {
                // Fire and forget
                localReinforcerSource.saveReinforcers(fetchedReinforcers, classId)
            }
            fetchedReinforcers
        } catch (e: Exception) {
            emptyList()
        }
    }

    suspend fun getReinforcer(reinforcerId: Int): Reinforcer {
        return withContext(Dispatchers.Default) {
            localReinforcerSource.readReinforcer(reinforcerId)
        }
    }

    suspend fun uploadRequirements(requirements: List<ReinforcerUpload>): List<ReinforcerUpload> {
        return remoteReinforcerSource.uploadRequirements(requirements)
    }

    suspend fun resubmitRequirement(requirement: ReinforcerUpload): List<ReinforcerUpload> {
        return remoteReinforcerSource.resubmitRequirement(requirement)
    }



    suspend fun getRequirements(classId: Int, forceFetch: Boolean): List<ReinforcerUpload> {
        logger.logMessage("CHDEBUG: ClassRepository.getRequirements")
        if (!forceFetch) {
            val localUploads = withContext(Dispatchers.Default) {
                localReinforcerSource.readAllGroupUploads(classId)
            }
            if (localUploads.isNotEmpty()) {
                return localUploads
            }
        }
        val serverUploads = remoteReinforcerSource.getRequirements(classId)
        logger.logMessage("CHDEBUG: ClassRepository.getRequirements got server uploads")

        withContext(Dispatchers.Default) {
//            localReinforcerSource.removeAllUploads(classId)
            localReinforcerSource.saveUploads(serverUploads)
        }
        return serverUploads
    }

    suspend fun deleteRequirement(groupId: Int, requirementId: Int): Boolean {
        logger.logMessage("COMMON: ClassRepository.deleteRequirement")
        val deletingRequirement = coroutineScope.async {
            return@async remoteReinforcerSource.deleteRequirement(groupId, requirementId)
        }
        val deleted = deletingRequirement.await()
        if (deleted) {
            logger.logMessage("COMMON: ClassRepository.deleteClass Deleting Requirement Locally")
            val rows = withContext(Dispatchers.Default) {
                localReinforcerSource.removeUpload(requirementId)
            }
            return true
        }
        logger.logMessage("COMMON: ClassRepository.deleteClass Returning False")
        return false
    }

    suspend fun updateRequirement(
        groupId: Int,
        requirementId: Int,
        status: String,
        feedback: String?,
    ): List<ReinforcerUpload> {
        val updatedUpload = remoteReinforcerSource.updateRequirement(groupId, requirementId, status, feedback)
        withContext(Dispatchers.Default) {
            localReinforcerSource.saveUploads(updatedUpload)
        }
        return updatedUpload
    }

    private suspend fun saveReinforcer(newReinforcer: Reinforcer, classId: Int) = withContext(Dispatchers.Default) {
        return@withContext localReinforcerSource.saveReinforcer(newReinforcer, classId)
    }

    private suspend fun readReinforcers(classId: Int) = withContext(Dispatchers.Default) {
        return@withContext localReinforcerSource.readReinforcers(classId)
    }
    
}

interface LocalReinforcerSource {
    suspend fun saveReinforcer(reinforcer: Reinforcer, classId: Int)
    suspend fun saveReinforcers(reinforcers: List<Reinforcer>, classId: Int)
    suspend fun deleteReinforcer(reinforcerId: Int): Int
    suspend fun readReinforcers(classId: Int): List<Reinforcer>
    suspend fun readReinforcer(reinforcerId: Int): Reinforcer
    suspend fun deleteGroupReinforcers(groupId: Int)
    suspend fun saveUploads(uploads: List<ReinforcerUpload>)
    suspend fun removeUpload(uploadId: Int)
    suspend fun removeAllUploads(classId: Int)
    suspend fun readAllGroupUploads(classId: Int): List<ReinforcerUpload>
    suspend fun readAchievementUploads(achievementId: Int): List<ReinforcerUpload>
}

interface RemoteReinforcerSource {
    @Throws(Exception::class)
    suspend fun createReinforcer(newReinforcer: Reinforcer, classId: Int): Reinforcer?
    suspend fun editReinforcer(updatedReinforcer: Reinforcer, classId: Int): Reinforcer?
    suspend fun deleteReinforcer(reinforcerId: Int, classId: Int): Boolean
    suspend fun fetchReinforcers(classId: Int): List<Reinforcer>
    suspend fun uploadRequirements(requirements: List<ReinforcerUpload>): List<ReinforcerUpload>
    suspend fun resubmitRequirement(requirement: ReinforcerUpload): List<ReinforcerUpload>
    suspend fun getRequirements(classId: Int): List<ReinforcerUpload>
    suspend fun deleteRequirement(groupId: Int, requirementId: Int): Boolean
    suspend fun updateRequirement(groupId: Int, uploadId: Int, status: String, feedback: String?): List<ReinforcerUpload>
}