package actions

import InjectorUtils
import com.jet.classroomhero.entities.User
import kotlinx.browser.document
import redux.RAction
import store.RThunk
import utils.Request
import com.jet.classroomhero.Logger

val repository = InjectorUtils.provideUserUseCases()

object AccountActions {
    data class AccountState(
        val isLoading: Boolean = false,
        val user: User = User(),
        val error: String = ""
    )
    val logger = Logger()

    fun emailLogin(email: String, password: String): RThunk = emailLoginRequest(email, password)
    fun emailRegister(user: User): RThunk = emailRegisterRequest(user)
    fun updateProfilePhoto(image: ByteArray) = updateProfilePhotoRequest(image)
    fun updateProfileCover(image: ByteArray) = updateProfileCoverRequest(image)

    fun editAccount(firstName: String, lastName: String, email: String, username: String, role: String): RThunk = editAccountRequest(firstName, lastName, email, username, role)
    fun changeUsername(username: String): RThunk = changeUsernameRequest(username)
    fun setRole(role: String): RThunk = editRoleRequest(role)
    fun changeEmail(email: String): RThunk = changeEmailRequest(email)

    data class Success(val user: User): RAction
    data class Error(val message: String): RAction
    data class SetIsLoading(val isLoading: Boolean): RAction
    data class CoverUpdated(val coverUrl: String): RAction
    data class PhotoUpdated(val photoUrl: String): RAction

    fun reducer(accountState: AccountState = AccountState(), action: RAction): AccountState {
        logger.logMessage("Account Action $action")
        return when (action) {
            is SetIsLoading -> accountState.copy(isLoading = action.isLoading)
            is Success -> accountState.copy(user = action.user, isLoading = false)
            is Error -> accountState.copy(error = action.message, isLoading = false)
            is CoverUpdated -> {
                val user = accountState.user.copy(coverUrl = action.coverUrl)
                console.log(user)
                accountState.copy(user = user)
            }
            is PhotoUpdated -> {
                val user = accountState.user.copy(photoUrl = action.photoUrl)
                accountState.copy(user = user)
            }
            else -> accountState.copy()
        }
    }
}

private val emailLoginRequest: (email: String, password: String) -> RThunk = { email, password ->
    Request(
        doInBackground = { dispatch ->
            val authenticatedUser = repository.login(email, "", password)
            dispatch(AccountActions.Success(authenticatedUser))
        },
        onError = { message, dispatch -> dispatch(AccountActions.Error(message)) },
        onSuccess = { document.location?.href = "/profile" }
    )
}


private val emailRegisterRequest: (user: User) -> RThunk = { user ->
    Request(
        doInBackground = { dispatch ->
            repository.registerUser(user)?.let { user ->
                if (user.token.isNullOrEmpty()) {
                    throw Exception("Invalid user token")
                }
                dispatch(AccountActions.Success(user))
            }
        },
        onError = { message, dispatch -> dispatch(AccountActions.Error(message)) },
        onSuccess = { document.location?.href = "/profile" }
    )
}

private val updateProfileCoverRequest: (image: ByteArray) -> RThunk = { image ->
    Request(
        doInBackground = { dispatch ->
            repository.updateCoverPhoto(image).let { url ->
                console.log("updated cover url: $url")
                dispatch(AccountActions.CoverUpdated(url))
            }
        },
        onError = { message, dispatch -> dispatch(AccountActions.Error(message)) },
        onSuccess = { AccountActions.SetIsLoading(false) }
    )
}

private val editRoleRequest: (role: String) -> RThunk = { role ->
    Request(
        onBeforeAction = AccountActions.SetIsLoading(true),
        doInBackground = { dispatch ->
            repository.editAccount("", "", "", "", role)
        },
        onError = { message, dispatch -> dispatch(AccountActions.Error(message)) },
        onSuccess = { document.location?.reload() }
    )
}


private val editAccountRequest: (firstName: String, lastName: String, email: String, username: String, role: String) -> RThunk = { firstName, lastName, email, username, role ->
    Request(
        onBeforeAction = AccountActions.SetIsLoading(true),
        doInBackground = { dispatch ->
            repository.editAccount(firstName, lastName, email, username, role)?.let { user ->
                if (user.token.isNullOrEmpty()) {
                    throw Exception("Invalid user token")
                }
                dispatch(AccountActions.Success(user))
            }
        },
        onError = { message, dispatch -> dispatch(AccountActions.Error(message)) },
        onSuccess = { document.location?.href = "/settings" }
    )
}


private val changeUsernameRequest: (username: String) -> RThunk = { username ->
    Request(
        onBeforeAction = AccountActions.SetIsLoading(true),
        doInBackground = { dispatch ->
            repository.changeUsername(username)?.let { user ->
                if (user.token.isNullOrEmpty()) {
                    throw Exception("Invalid user token")
                }
                dispatch(AccountActions.Success(user))
            }
        },
        onError = { message, dispatch -> dispatch(AccountActions.Error(message)) },
        onSuccess = { document.location?.href = "/settings" }
    )
}

private val changeEmailRequest: (email: String) -> RThunk = { email ->
    Request(
        onBeforeAction = AccountActions.SetIsLoading(true),
        doInBackground = { dispatch ->
            repository.changeEmail(email)?.let { success ->
                if (!success) {
                    throw Exception("Invalid request")
                }
            }
        },
        onError = { message, dispatch -> dispatch(AccountActions.Error(message)) },
        onSuccess = { document.location?.href = "/settings" }
    )
}


private val updateProfilePhotoRequest: (image: ByteArray) -> RThunk = { image ->
    Request(
        doInBackground = { dispatch ->
            repository.updateProfilePhoto(image).let { url ->
                dispatch(AccountActions.PhotoUpdated(url))
            }
        },
        onError = { message, dispatch -> dispatch(AccountActions.Error(message)) },
        onSuccess = { AccountActions.SetIsLoading(false) }
    )
}