package components.widgets

import kotlinx.css.*
import kotlinx.css.properties.*
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import org.khronos.webgl.ArrayBuffer
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.Event
import org.w3c.dom.url.URL
import org.w3c.files.Blob
import org.w3c.files.File
import org.w3c.files.FileReader
import org.w3c.files.get
import react.*
import react.dom.attrs
import react.dom.onDragEnter
import react.dom.onMouseEnter
import react.dom.onMouseLeave
import styled.*
import utils.toByteArray


external interface ImageUploaderProps : Props {
    var elementId: String
    var remoteUrl: String
    var placeholderComponent: ReactNode?
    var placeholderSrc: String?
    var onPress: ((Event) -> Unit)?
    var onUpload: (ByteArray) -> Unit
    var editable: Boolean
}

val ImageUploader = fc<ImageUploaderProps> { props ->
    val (file, setNewFile) = useState<File?>(null)
    val (showLabel, setShowLabel) = useState(false)
    styledDiv {
        css {
            position = Position.relative
            height = 100.pct
            transition += Transition("opacity", 555.ms, Timing.easeInOut, 0.s)
            hover {
                cursor = Cursor.pointer
                opacity = 0.8
            }
        }
        styledLabel {
            css {
                position = Position.relative
                display = Display.block
            }
            attrs.htmlFor = props.elementId
            attrs.onMouseEnter = {
                setShowLabel(true)
            }
            attrs.onMouseLeave = {
                setShowLabel(false)
            }
            if (file != null) {
                // local file
                imageWidget(
                    src = URL.createObjectURL(file),
                    size = 100.px,
                    customCss = {
                        hover {
                            cursor = Cursor.pointer
                        }
                    }
                )
            } else if (props.remoteUrl.isNotEmpty()) {
                imageWidget(
                    src = props.remoteUrl ?: "",
                    size = 100.px,
                    customCss = {
                        hover {
                            cursor = Cursor.pointer
                        }
                    }
                )
            } else {
                props.placeholderComponent?.let {
                    child(it)
                }
            }
            if (showLabel && props.editable) {
                styledDiv {
                    css {
                        padding = "14px 12px"
                        backgroundColor = Color("#000")
                        opacity = 0.5
                        color = Color.white
                        fontSize = 12.px
                        position = Position.absolute
                        bottom = 50.pct
                        left = 50.pct
                        transform = Transforms().apply {
                            translate((-50).pct, 50.pct)
                        }
                        hover  {
                            cursor = Cursor.pointer
                        }
                    }
                    styledP {
                        + "Change"
                    }
                }
            }
        }
        styledInput {
            attrs {
                css {
                    visibility = Visibility.hidden
                    display = Display.none
                }
                disabled = !props.editable
                id = props.elementId
                type = InputType.file
                onClickFunction = { event ->
                    if (props.editable) {
                        props.onPress?.let { onPress ->
                            onPress(event)
                        }
                    }
                }
                onChangeFunction = {
                    val target = it.target as HTMLInputElement
                    val uploadedFile = target.files?.get(0)
                    if (uploadedFile != null) {
                        val reader = FileReader()
                        reader.onload = {
                            val arrayBuffer = reader.result as ArrayBuffer
                            props.onUpload(arrayBuffer.toByteArray())
                        }
                        reader.readAsArrayBuffer(uploadedFile as Blob)
                        setNewFile(uploadedFile)
                    }
                }
            }
        }
    }
}

val ImageUploaderBackground = fc<ImageUploaderProps> { props ->
    val (file, setNewFile) = useState<File?>(null)
    val (showLabel, setShowLabel) = useState(false)
    styledDiv {
        css {
            height = 100.pct
        }
        styledLabel {
            val backgroundSrc = if (file != null) {
                // local file
                URL.createObjectURL(file)
            } else if (!props.remoteUrl.isNullOrEmpty()) {
                props.remoteUrl
            } else if (!props.placeholderSrc.isNullOrEmpty()) {
                props.placeholderSrc
            } else {
                ""
            }
            css {
                position = Position.relative
                background = "url('$backgroundSrc') center no-repeat"
                backgroundSize = "cover"
                height = 100.pct
                display = Display.block
                transition += Transition("opacity", 555.ms, Timing.easeInOut, 0.s)
                hover {
                    cursor = Cursor.pointer
                    opacity = 0.8
                }
            }
            attrs.htmlFor = props.elementId
            attrs.onMouseEnter = {
                setShowLabel(true)
            }
            attrs.onMouseLeave = {
                setShowLabel(false)
            }
            if (showLabel && props.editable) {
                styledDiv {
                    css {
                        padding = "16px 14px"
                        backgroundColor = Color("#000")
                        opacity = 0.5
                        color = Color.white
                        position = Position.absolute
                        top = 50.pct
                        left = 50.pct
                        marginTop = (-24).px
                        transform = Transforms().apply {
                            translate((-50).pct, (-50).pct)
                        }
                    }
                    styledP {
                        + "Change"
                    }
                }
            }
        }
        styledInput {
            attrs {
                css {
                    visibility = Visibility.hidden
                    display = Display.none
                }
                disabled = !props.editable
                id = props.elementId
                type = InputType.file
                onClickFunction = { event ->
                    if (props.editable) {
                        props.onPress?.let { onPress ->
                            onPress(event)
                        }
                    }
                }
                onChangeFunction = {
                    val target = it.target as HTMLInputElement
                    val uploadedFile = target.files?.get(0)
                    if (uploadedFile != null) {
                        val reader = FileReader()
                        reader.onload = {
                            val arrayBuffer = reader.result as ArrayBuffer
                            props.onUpload(arrayBuffer.toByteArray())
                        }
                        reader.readAsArrayBuffer(uploadedFile as Blob)
                        setNewFile(uploadedFile)
                    }
                }
            }
        }
    }
}