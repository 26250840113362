package actions

import com.jet.classroomhero.entities.Class
import com.jet.classroomhero.entities.Student
import kotlinx.coroutines.delay
import redux.RAction
import store.RThunk
import utils.Request
import utils.pluralizeIf

private val memberUseCases = InjectorUtils.provideMemberUseCases()
private val reinforcerUseCases = InjectorUtils.provideReinforcerUseCases()
private val itemUseCases = InjectorUtils.provideItemUseCases()

object MemberActions {
  fun createMember(member: Student, groupId: Int): RThunk = createMemberRequest(member, groupId)
  fun editMember(member: Student, groupId: Int): RThunk = editMemberRequest(member, groupId)
  fun deleteMember(memberId: Int, groupId: Int): RThunk = deleteMemberRequest(memberId, groupId)
  // only performs a local update in the state
  fun updateMember(member: Student): RThunk = updateMemberState(member)

  fun bulkCompleteAchievements(
    memberIds: List<Int>,
    groupId: Int,
    achievementId: Int
  ) = bulkCompleteAchievementRequest(memberIds, groupId, achievementId)

  fun bulkSellItem(
    memberIds: List<Int>,
    groupId: Int,
    achievementId: Int
  ) = bulkSellItemRequest(memberIds, groupId, achievementId)

  data class CreateMember(val member: Student) : RAction
  data class EditMember(val member: Student) : RAction
  data class DeleteMember(val memberId: Int) : RAction
  data class BulkEditMembers(val members:  List<Student>) : RAction
  data class Error(val message: String) : RAction
  data class SetIsLoading(val isLoading: Boolean) : RAction
}

private val createMemberRequest: (member: Student, groupId: Int) -> RThunk = { member, groupId ->
  Request(
    doInBackground = { dispatch ->
      memberUseCases.addStudent(member, groupId)?.let {
        dispatch(MemberActions.CreateMember(it))
      }
    },
    onError = { message, dispatch -> dispatch(MemberActions.Error(message)) },
    onSuccess = { dispatch ->
      dispatch(AppStateActions.SetModalShowing(false, null))
    }
  )
}

private val editMemberRequest: (member: Student, groupId: Int) -> RThunk = { member, groupId ->
  Request(
    doInBackground = { dispatch ->
      memberUseCases.editStudent(member, groupId)?.let { updatedMember ->
        dispatch(MemberActions.EditMember(updatedMember))
      }
    },
    onError = { message, dispatch -> dispatch(MemberActions.Error(message)) },
    onSuccess = { dispatch ->
      dispatch(AppStateActions.SetModalShowing(false, null))
    }
  )
}

private val deleteMemberRequest: (memberId: Int, groupId: Int) -> RThunk = { memberId, groupId ->
  Request(
    doInBackground = { dispatch ->
      val success = memberUseCases.deleteStudent(memberId, groupId)
      if (success) {
        dispatch(MemberActions.DeleteMember(memberId))
      } else {
        throw Exception("An error occurred deleting this member")
      }
    },
    onError = { message, dispatch -> dispatch(MemberActions.Error(message)) },
    onSuccess = { dispatch ->
      dispatch(AppStateActions.SetModalShowing(false, null))
    }
  )
}

private val bulkCompleteAchievementRequest: (memberIds: List<Int>, groupId: Int, achievementId: Int) -> RThunk = { memberIds, groupId, achievementId ->
  Request(
    doInBackground = { dispatch ->
      memberUseCases.bulkCompleteAchievement(groupId, memberIds, achievementId)
    },
    onError = { message, dispatch -> dispatch(MemberActions.Error(message)) },
    onSuccess = { dispatch ->
      dispatch(AppStateActions.SetModalShowing(false, null))
      dispatch(AppStateActions.SetShowSnackBar(
        show = true,
        message = "Successfully awarded ${memberIds.size} ${"Member".pluralizeIf { memberIds.size > 1 }}")
      )
    }
  )
}

private val bulkSellItemRequest: (memberIds: List<Int>, groupId: Int, itemId: Int) -> RThunk = { memberIds, groupId, itemId ->
  Request(
    doInBackground = { dispatch ->
      memberUseCases.bulkSellItem(groupId, memberIds, itemId)
    },
    onError = { message, dispatch -> dispatch(MemberActions.Error(message)) },
    onSuccess = { dispatch ->
      dispatch(AppStateActions.SetModalShowing(false, null))
      dispatch(AppStateActions.SetShowSnackBar(
        show = true,
        message = "Successfully awarded ${memberIds.size} ${"Member".pluralizeIf { memberIds.size > 1 }}")
      )
    }
  )
}

private val updateMemberState: (member: Student) -> RThunk = { updatedMember ->
  console.log("updateMemberRequest")
  Request(
    onSuccess = { dispatch ->
      dispatch(MemberActions.EditMember(updatedMember))
      dispatch(AppStateActions.SetSelectedMember(updatedMember))
    }
  )
}