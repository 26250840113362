package sources.local

import com.jet.classroomhero.data.LocalAchievementSource
import com.jet.classroomhero.entities.Achievement

class CacheAchievementSource : LocalAchievementSource {
  override suspend fun saveAchievements(achievements: List<Achievement>) {}

  override suspend fun saveAchievement(achievement: Achievement): Boolean { return true }

  override suspend fun readAchievement(achievementId: Int): Achievement? { return null }

  override suspend fun readGroupAchievements(groupId: Int): List<Achievement> { return emptyList() }

  override suspend fun readMemberAchievements(memberId: Int): List<Achievement> { return emptyList() }

  override suspend fun deleteAchievement(achievementId: Int) {}
}