package com.jet.classroomhero.data.remote.sources

import com.jet.classroomhero.Logger
import com.jet.classroomhero.data.LocalUserSource
import com.jet.classroomhero.data.RemoteAchievementSource
import com.jet.classroomhero.entities.Achievement
import com.jet.classroomhero.entities.PaginatedResponse
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext

class NetworkAchievementSource(localUserSource: LocalUserSource) : ProtectedNetworkSource(localUserSource), RemoteAchievementSource {
    override suspend fun fetchUserAchievements(page: Int, pageSize: Int): PaginatedResponse<Achievement> = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/achievements/?page=${page}&pageSize=${pageSize}"
        val response = client.get(url) {
            contentType(ContentType.Application.Json)
        }
        return@withContext response.body()
    }

    override suspend fun fetchGroupAchievements(
        classId: Int,
        page: Int,
        pageSize: Int
    ): PaginatedResponse<Achievement> = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/completed_achievements/?page=${page}&pageSize=${pageSize}"
        val response = client.get(url) {
            contentType(ContentType.Application.Json)
        }
        return@withContext response.body()
    }

    override suspend fun fetchMemberAchievements(
        classId: Int,
        memberId: Int,
        page: Int,
        pageSize: Int
    ): PaginatedResponse<Achievement> = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/student/${memberId}/completed_achievements/?page=${page}&pageSize=${pageSize}"
        val response = client.get(url) {
            contentType(ContentType.Application.Json)
        }
        return@withContext response.body()
    }

    override suspend fun setAchievementSeen(achievementId: Int): Boolean = withContext(Dispatchers.Default) {
        Logger().logMessage("setAchievementSeen $achievementId")
        val url = "${baseUrl}api/achievements/seen/${achievementId}"
        val response: HttpResponse = client.patch(url) {
            contentType(ContentType.Application.Json)
        }
        return@withContext response.status.isSuccess()
    }

}