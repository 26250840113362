package com.jet.classroomhero.usecases

import com.jet.classroomhero.SuspendWrapper
import com.jet.classroomhero.data.StudentRepository
import com.jet.classroomhero.entities.Student

class StudentUseCases(private val studentRepository: StudentRepository) {
    /**
     * Base repository calls
     */
    suspend fun addStudent(newStudent: Student, classId: Int): Student? = studentRepository.addStudent(newStudent, classId)
    suspend fun bulkAddStudents(newStudents: List<Student>, classId: Int): List<Student> = studentRepository.bulkAddStudents(newStudents, classId)
    suspend fun editStudent(updatedStudent: Student, classId: Int): Student? = studentRepository.editStudent(updatedStudent, classId)
    suspend fun deleteStudent(studentId: Int, classId: Int): Boolean = studentRepository.deleteStudent(studentId, classId)
    suspend fun getStudents(forceFetch: Boolean = false, classId: Int): List<Student> = studentRepository.getStudents(forceFetch, classId)
    suspend fun getStudent(forceFetch: Boolean = false, classId: Int, memberId: Int) = studentRepository.getStudent(forceFetch, classId, memberId)
    suspend fun completeAchievement(classId: Int, memberId: Int, achievementId: Int): Student = studentRepository.completeAchievement(classId, memberId, achievementId)
    suspend fun deleteAchievement(classId: Int, memberId: Int, achievementId: Int): Student = studentRepository.deleteAchievement(classId, memberId, achievementId)
    suspend fun deleteTransaction(classId: Int, memberId: Int, transactionId: Int): Student = studentRepository.deleteTransaction(classId, memberId, transactionId)
    suspend fun bulkCompleteAchievement(classId: Int, memberIds: List<Int>, achievementId: Int): List<Student> = studentRepository.bulkCompleteAchievement(classId, memberIds, achievementId)
    suspend fun bulkCreateAchievement(classId: Int, achievementName: String, achievementValue: Int, memberIds: List<Int>): List<Student> = studentRepository.bulkCreateAchievement(classId, achievementName, achievementValue, memberIds)
    suspend fun sellItem(classId: Int, memberId: Int, itemId: Int): Student = studentRepository.sellItem(classId, memberId, itemId)
    suspend fun bulkSellItem(classId: Int, memberIds: List<Int>, itemId: Int): List<Student> = studentRepository.bulkSellItem(classId, memberIds, itemId)
    suspend fun addCurrency(classId: Int, memberId: Int, amountToAdd: Int): Student = studentRepository.addCurrency(classId, memberId, amountToAdd)
    suspend fun removeCurrency(classId: Int, memberId: Int, amountToRemove: Int): Student = studentRepository.removeCurrency(classId, memberId, amountToRemove)
    suspend fun updateProfilePicture(memberId: Int, byteArray: ByteArray) = studentRepository.updateProfilePhoto(memberId, byteArray)
    suspend fun unlinkStudent(studentId: Int): Boolean = studentRepository.unlinkStudent(studentId)


    /**
     * iOS Calls
     */
    fun addStudentIos(newStudent: Student, classId: Int) = SuspendWrapper { addStudent(newStudent, classId) }
    fun editStudentIos(updatedStudent: Student, classId: Int) = SuspendWrapper { editStudent(updatedStudent, classId) }
    fun deleteStudentIos(studentId: Int, classId: Int) = SuspendWrapper { deleteStudent(studentId, classId) }
    fun getStudentIos(forceFetch: Boolean = false, classId: Int, memberId: Int) = SuspendWrapper { getStudent(forceFetch, classId, memberId)}
    fun getStudentsIos(forceFetch: Boolean = false, classId: Int) = SuspendWrapper { getStudents(forceFetch, classId) }
    fun bulkCompleteAchievementIos(classId: Int, memberIds: List<Int>, achievementId: Int) = SuspendWrapper { bulkCompleteAchievement(classId, memberIds, achievementId) }
    fun bulkCreateAchievementIos(classId: Int, achievementName: String, achievementValue: Int, memberIds: List<Int>) = SuspendWrapper { bulkCreateAchievement(classId, achievementName, achievementValue, memberIds) }
    fun deleteAchievementIos(classId: Int, memberId: Int, achievementId: Int) = SuspendWrapper { deleteAchievement(classId, memberId, achievementId) }
    fun deleteTransactionIos(classId: Int, memberId: Int, transactionId: Int) = SuspendWrapper { deleteTransaction(classId, memberId, transactionId) }
    fun bulkSellItemIos(classId: Int, memberIds: List<Int>, itemId: Int) = SuspendWrapper { bulkSellItem(classId, memberIds, itemId) }
    fun unlinkStudentIos(studentId: Int) = SuspendWrapper { unlinkStudent(studentId) }
}