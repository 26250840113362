package components.forms

import com.jet.classroomhero.entities.Class
import com.jet.classroomhero.entities.StockGroupMediaResponse
import com.jet.classroomhero.entities.Template
import components.modals.ModalType
import components.accounts.LoginCardCss
import components.dashboard.DashboardProps
import components.profile.ProfileProps
import components.modals.groups.stockIconsModal
import components.widgets.*
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import kotlinx.html.js.onSubmitFunction
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLTextAreaElement
import react.*
import react.dom.value
import styled.*
import styles.GlobalCss




external interface GroupFormProps : BaseFormProps {
  var profileProps: ProfileProps
  var dashboardProps: DashboardProps
  var selectedGroup: Class?
  var onSubmit: (Class, ByteArray?, ByteArray?, ByteArray?, Int?) -> Unit
  var onDelete: (Int) -> Unit
  var stockMedia: StockGroupMediaResponse?
  var templates: List<Template>?
}

val CreateOrEditGroupForm = fc<GroupFormProps> { props ->
  // text input states
  val (name, setName) = useState(props.selectedGroup?.name ?: "")
  val (description, setDescription) = useState(props.selectedGroup?.description ?: "")
  val (selectedTemplate, setSelectedTemplate) = useState<Int?>(null)
  // stock icons
  val (showStockIcons, setShowStockIcons) = useState(false)
  val first = props.stockMedia?.let {
    return@let it.currencyIcons.getOrNull(0)
  }
  val (currencyName, setCurrencyName) = useState(props.selectedGroup?.currencyName ?: first?.name)
  val (selectedStockIcon, setSelectedStockIcon) = useState(first?.icon ?: "")
  // group logo / cover / currency icon ByteArrays
  val (groupCoverFile, setGroupCoverFile) = useState<ByteArray>()
  val (groupIconFile, setGroupIconFile) = useState<ByteArray>()
  val (currencyIconFile, setCurrencyIconFile) = useState<ByteArray>()
  val (changed, setChanged) = useState(false)

  val currencyIconUrl = if (!changed && !props.selectedGroup?.currencyIconUrl.isNullOrEmpty()) {
    props.selectedGroup?.currencyIconUrl
  } else {
    selectedStockIcon
  }

  styledForm {
    attrs.id = "group-" + props.selectedGroup?.id.toString() + "-form"
    css {
      display = Display.flex
      flexDirection = FlexDirection.column
      alignItems = Align.center
      justifyContent = JustifyContent.spaceEvenly
    }
    attrs.autoComplete = false
    attrs.onSubmitFunction = { event ->
      event.preventDefault()
      if (name.trim().isNotEmpty()) {
        val newClass = Class(
          id = props.selectedGroup?.id ?: 0,
          name = name,
          description = description,
          currencyName = currencyName ?: "Currency",
          currencyIconUrl = selectedStockIcon
        )
        props.onSubmit(newClass, groupIconFile, currencyIconFile, groupCoverFile, selectedTemplate)
      }
    }

    styledDiv {
//      ImageUploaderBackground {
//        attrs.elementId = "group-cover-photo"
//        attrs.remoteUrl = props.selectedGroup?.groupCoverUrl ?: ""
//        attrs.placeholderSrc = "/images/user_profile_placeholder.png"
//        attrs.onUpload = { image ->
//          setGroupCoverFile(image)
//        }
//        attrs.editable = true
//      }
      styledH3 {
        css {
          margin = "12px 0"
        }
        + "Class Logo"
      }
      ImageUploader {
        attrs.remoteUrl = props.selectedGroup?.groupLogoUrl ?: ""
        attrs.elementId = "upload-group-logo"
        attrs.placeholderComponent = createElement {
          circularFaIcon(
            size = 100.px,
            iconSize = 45.px,
            showEditIcon = true
          )
        }
        attrs.editable = true
        attrs.onPress = null
        attrs.key = "group-uploader"
        attrs.onUpload = { file ->
          setGroupIconFile(file)
        }
      }
    }

    styledInput {
      css(LoginCardCss.inputStyle)
      css {
        marginTop = 28.px
        marginBottom = 20.px
      }
      attrs.value = name
      attrs.type = InputType.text
      attrs.placeholder = "Class Name"
      attrs.required = true
      attrs.onChangeFunction = {
        val target = it.target as HTMLInputElement
        setName(target.value)
      }
    }
    styledTextarea {
      css(LoginCardCss.inputStyle)
      css {
        height = 135.px
      }
      attrs.value = description
      attrs.placeholder = "Class Description"
      attrs.onChangeFunction = { event ->
        val target = event.target as HTMLTextAreaElement
        setDescription(target.value)
      }
    }
    styledH3 {
      css {
        margin = "12px 0"
      }
      + "Customize Class Currency"
    }
    ImageUploader {
      attrs.remoteUrl = currencyIconUrl ?: ""
      attrs.elementId = "upload-currency-icon"
      attrs.placeholderComponent = createElement {
        circularFaIcon(
          size = 100.px,
          iconSize = 45.px,
          showEditIcon = true
        )
      }
      attrs.onPress = {
        it.preventDefault()
        setShowStockIcons(true)
      }
      attrs.editable = true
      attrs.key = "icon-uploader"
      attrs.onUpload = { file ->
        setCurrencyIconFile(file)
      }
    }
    styledInput {
      css(LoginCardCss.inputStyle)
      css {
        marginBottom = 20.px
        marginTop = 20.px
      }
      attrs.value = currencyName ?: "Currency"
      attrs.type = InputType.text
      attrs.placeholder = "Currency Name"
      attrs.onChangeFunction = {
        val target = it.target as HTMLInputElement
        setCurrencyName(target.value)
      }
    }
    i
    if (props.templates != null && !props.isEditing) {
      styledH4 {
        css {
          margin = "12px 0"
        }
        + "Start From a Template (optional)"
      }
      styledDiv {
        css {
          display = Display.flex
          overflowX = Overflow.auto
          flexDirection = FlexDirection.row
          maxWidth = 400.px
          width = 100.pct
        }
        props.templates?.sortedByDescending { it.name }?.forEach { template ->
          styledDiv {
            attrs.onClickFunction = {
              setSelectedTemplate(template.id)
            }
            css(GlobalCss.flexColumn)
            css {
              marginTop = 20.px
              marginBottom = 20.px
              marginRight = 15.px
              padding = "10px"
              cursor = Cursor.pointer
              borderStyle = kotlinx.css.BorderStyle.solid
              if (selectedTemplate != null && selectedTemplate == template.id) {
                borderWidth = 2.px
                borderColor = Color("#1BB55C")
              } else {
                borderWidth = 2.px
                borderColor = Color.dimGray
              }
            }
            styledP {
              + template.name
            }
            styledImg {
              css {
                height = 163.px
                width = 360.px
                borderRadius = 1.pct
                objectFit = ObjectFit.contain
              }
              attrs.src = template.imageUrl
            }
          }
        }
      }
    }
    // on press is handled by the form submit above
    styledDiv{
      css{
        width = 50.pct
      }
      if (props.isEditing) {
          Button("Update Class", loading = props.isLoading)
          Button(
            text = "Delete Class",
            bgColor = "#B00020",
            loading = props.isLoading
          ) {
            if (props.dashboardProps != null){
              props.dashboardProps.showModal(true, ModalType.DELETE_GROUP)
            }
            else {
              props.profileProps.showModal(true, ModalType.DELETE_GROUP)
            }
          }
      } else {
        Button("Create Class", loading = props.isLoading)
      }
    }
  }

  if (showStockIcons) {
    stockIconsModal(props, onClose = { setShowStockIcons(false) }) {
      setChanged(true)
      setSelectedStockIcon(it.icon)
      setCurrencyName(it.name)
    }
  }
}
