import components.widgets.Footer
import containers.dashboardComponent
import containers.profileComponent
import containers.memberDashboardComponent
import containers.accountSettingsComponent
import react.RBuilder
import react.createElement
import react.router.Route
import react.router.Routes
import react.router.dom.BrowserRouter

fun RBuilder.app() =
    BrowserRouter {
        Routes {
            Route {
                attrs.path = "/profile"
                attrs.element = createElement { profileComponent() }
            }
            Route {
                attrs.path = "/settings"
                attrs.element = createElement { accountSettingsComponent() }
            }
            Route {
                attrs.path = "/group/:id/dashboard"
                attrs.element = createElement { dashboardComponent() }
            }
            Route {
                attrs.path = "/group/:groupId/membership/:memberId/dashboard"
                attrs.element = createElement { memberDashboardComponent() }
            }
        }
        Footer()
    }