package sources.local

import com.jet.classroomhero.data.LocalClassSource
import com.jet.classroomhero.entities.Class
import com.jet.classroomhero.entities.GroupStats

class CacheClassSource : LocalClassSource {
    override suspend fun saveClasses(classes: List<Class>) {}
    override suspend fun saveClass(newClass: Class) {}
    override suspend fun saveClassRelationships(newClass: Class) {
    }

    override suspend fun saveGroupStats(groupStats: GroupStats) {}
    override suspend fun deleteClass(classId: Int) {}

    override suspend fun readClasses(userId: Int): List<Class> {
        return emptyList()
    }

    override suspend fun readMemberships(userId: Int): List<Class> {
        //("Not yet implemented")
        return emptyList()
    }

    override suspend fun readClass(classId: Int): Class {
        return Class()
    }

    override suspend fun saveCurrencyIcon(classId: Int, imgUrl: String) {}

    override suspend fun saveGroupLogo(classId: Int, imgUrl: String) {}
    override suspend fun deleteAllClasses() {

    }

}