import com.jet.classroomhero.data.*
import com.jet.classroomhero.usecases.*
import org.w3c.workers.Cache
import sources.local.*

class InjectorUtils {
    companion object {
        fun provideUserUseCases(): UserUseCases {
            val localSource = CacheUserSource()
            val repository = UserRepository(localSource)
            return UserUseCases(repository)
        }
        fun provideGroupUseCases(): ClassUseCases {
            val repository = ClassRepository(
                CacheUserSource(),
                CacheClassSource(),
                CacheMemberSource(),
                CacheItemSource(),
                CacheReinforcerSource()
            )
            return ClassUseCases(repository)
        }
        fun provideMemberUseCases(): StudentUseCases {
            val repository = StudentRepository(
                CacheUserSource(),
                CacheMemberSource(),
                CacheAchievementSource(),
                CacheTransactionSource()
            )
            return StudentUseCases(repository)
        }
        fun provideReinforcerUseCases(): ReinforcerUseCases {
            val repository = ReinforcerRepository(
                CacheUserSource(),
                CacheReinforcerSource()
            )
            return ReinforcerUseCases(repository)
        }
        fun provideItemUseCases(): ItemUseCases {
            val repository = ItemRepository(
                CacheUserSource(),
                CacheItemSource()
            )
            return ItemUseCases(repository)
        }
        fun provideJarUseCases(): JarUseCases {
            val repository = JarRepository(
                CacheUserSource(),
                CacheJarSource()
            )
            return JarUseCases(repository)
        }
        fun provideTemplatesUseCases(): TemplateUseCases {
            val repository = TemplateRepository(
                CacheUserSource(),
                CacheTemplateSource()
            )
            return TemplateUseCases(repository)
        }
        fun provideAchievementUseCases(): AchievementUseCases {
            val repository = AchievementRepository(
                CacheUserSource(),
                CacheAchievementSource()
            )
            return AchievementUseCases(repository)
        }
        fun provideExtensionUseCases(): ExtensionUseCases {
            val repository = ExtensionRepository(
                CacheUserSource(),
                CacheExtensionSource()
            )
            return ExtensionUseCases(repository)
        }
    }
}