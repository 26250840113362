package components.modals.achievements

import com.jet.classroomhero.entities.Reinforcer
import components.dashboard.DashboardProps
import components.forms.CreateOrEditAchievementForm
import components.widgets.Modal
import kotlinx.css.*
import react.RBuilder
import styled.css
import styled.styledDiv
import styled.styledH3

fun RBuilder.renderCreateOrEditAchievementModal(props: DashboardProps, achievement: Reinforcer?) {
  val isEditing = achievement != null
  Modal(onClose = {
    props.showModal(false, null)
  }) {
    styledDiv {
      css {
        display = Display.flex
        alignItems = Align.center
        flexDirection = FlexDirection.column
        alignSelf = Align.center
        width = 100.pct
        textAlign = kotlinx.css.TextAlign.center
      }
      styledH3 {
        css {
          marginBottom = 20.px
        }
        + if (isEditing) "Edit Achievement" else "Create a New Achievement"
      }
      CreateOrEditAchievementForm {
        attrs.achievement = achievement
        attrs.onCreateAchievement = { newAchievement ->
          props.createAchievement(newAchievement, props.currentGroup!!.id)
//          props.setSelectedAchievement(newAchievement)
        }
        attrs.onEditAchievement = { updatedAchievement ->
          props.editAchievement(updatedAchievement, props.currentGroup!!.id)
//          props.setSelectedAchievement(updatedAchievement)
        }
        attrs.onDeleteAchievement = { achievementId ->
          props.deleteAchievement(achievementId, props.currentGroup!!.id)
          props.setSelectedAchievement(null)
        }
        attrs.isEditing = isEditing
        attrs.isLoading = props.isLoading
        attrs.hasPaid = props.user.hasPaid
      }
    }
  }
}