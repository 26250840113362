package com.jet.classroomhero.data.remote.sources

import com.jet.classroomhero.Logger
import com.jet.classroomhero.data.AnonymousRemoteUserSource
import com.jet.classroomhero.data.remote.post
import com.jet.classroomhero.entities.Tokens
import com.jet.classroomhero.entities.User
import io.ktor.client.call.*
import io.ktor.client.engine.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.coroutines.*
import kotlinx.serialization.json.Json

class NetworkUserSource(engine: HttpClientEngine? = null) : AnonymousNetworkSource(engine), AnonymousRemoteUserSource {

    @Throws(ResponseException::class, kotlin.coroutines.cancellation.CancellationException::class)
    override suspend fun register(user: User): User = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/accounts/register/"
        val newUser = client.post(url) {
            contentType(ContentType.Application.Json)
            setBody(user)
        }.body<User>()
        return@withContext newUser
    }

    @Throws(ResponseException::class, kotlin.coroutines.cancellation.CancellationException::class)
    override suspend fun login(email: String, username: String, password: String): User {
        val url = "${baseUrl}api/accounts/login/"
        val newUser = client.post(url) {
          contentType(ContentType.Application.Json)
          setBody(User(email, username, password))
        }.body<User>()
        return newUser
    }

    override suspend fun loginOrRegisterWithGoogle(googleIdToken: String, role: String): User {
        val url = "${baseUrl}api/accounts/register/google/"
        val requestBody = Json.parseToJsonElement("""
            {
                "token": "$googleIdToken",
                "role": "$role"
            }
        """)
        val newUser = client.post(url) {
            contentType(ContentType.Application.Json)
            setBody(requestBody)
        }.body<User>()
        return newUser
    }

    override suspend fun loginOrRegisterWithFacebook(facebookIdToken: String, userId: String, role: String): User {
        val url = "${baseUrl}api/accounts/register/facebook/"
        val requestBody = Json.parseToJsonElement("""
            {
                "token": "$facebookIdToken",
                "userId": "$userId",
                "role": "$role"
            }
        """)
        val newUser = client.post(url) {
            contentType(ContentType.Application.Json)
            setBody(requestBody)
        }.body<User>()
        return newUser
    }

    override suspend fun loginOrRegisterWithApple(appleIdToken: String, role: String): User {
        val url = "${baseUrl}api/accounts/register/apple/"
        val requestBody = Json.parseToJsonElement("""
            {
                "token": $appleIdToken,
                "role": $role
            }
        """)
        val newUser = client.post(url) {
          contentType(ContentType.Application.Json)
          setBody(requestBody)
        }.body<User>()
        return newUser
    }

    override suspend fun refreshJwt(refreshToken: String): Tokens = withContext(Dispatchers.Default) {
        val url = "${baseUrl}accounts/refresh/"
        val requestBody = Json.parseToJsonElement("""
            {
                "refresh": $refreshToken
            }
        """)
        val tokens = client.post(url) {
            contentType(ContentType.Application.Json)
            setBody(requestBody)
        }.body<Tokens>()
        // return the tokens
        return@withContext tokens
    }

    override suspend fun requestPasswordReset(email: String): Boolean = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/accounts/password_reset/"
        val requestBody = Json.parseToJsonElement("""
            {
                "email": $email
            }
        """)
        val response: HttpResponse = client.post(url) {
            contentType(ContentType.Application.Json)
            setBody(requestBody)
        }
        return@withContext response.status.isSuccess()
    }
}