package components.forms

import com.jet.classroomhero.entities.Jar
import components.accounts.LoginCardCss
import components.widgets.Button
import kotlinx.css.Align
import kotlinx.css.Color
import kotlinx.css.Display
import kotlinx.css.FlexDirection
import kotlinx.css.JustifyContent
import kotlinx.css.alignItems
import kotlinx.css.color
import kotlinx.css.display
import kotlinx.css.flexDirection
import kotlinx.css.fontSize
import kotlinx.css.height
import kotlinx.css.justifyContent
import kotlinx.css.marginBottom
import kotlinx.css.maxWidth
import kotlinx.css.minHeight
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.css.width
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onSubmitFunction
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLTextAreaElement
import react.dom.StringAttr.setValue
import react.dom.value
import react.fc
import react.useState
import styled.css
import styled.styledDiv
import styled.styledForm
import styled.styledInput
import styled.styledP
import styled.styledTextarea

external interface CreateOrEditJarFormProps : BaseFormProps {
  var jar: Jar?
  var onDeleteJar: (Int) -> Unit
  var onEditJar: (Jar) -> Unit
  var onCreateJar: (Jar) -> Unit
}

val CreateOrEditJarForm = fc<CreateOrEditJarFormProps> { props ->
  val (name, setName) = useState(props.jar?.name ?: "")
  val (total, setTotal) = useState(props.jar?.total ?: 100)

  styledForm {
    attrs.id = "jar-" + props.jar?.id.toString() + "-form"
    css {
      display = Display.flex
      flexDirection = FlexDirection.column
      alignItems = Align.center
      justifyContent = JustifyContent.spaceEvenly
      maxWidth = 300.px
      minHeight = 280.px
      width = 100.pct
    }
    attrs.onSubmitFunction = { event ->
      event.preventDefault()
    }
    /* Jar Name Field */
    styledDiv {
      css {
        marginBottom = 20.px
      }
      styledInput {
        css(LoginCardCss.inputStyle)
        css {
          marginBottom = 5.px
        }
        attrs.value = name
        attrs.type = InputType.text
        attrs.placeholder = "Jar Name"
        attrs.required = true
        attrs.onChangeFunction = { event ->
          val target = event.target as HTMLInputElement
          setName(target.value)
        }
      }
      styledP {
        css {
          fontSize = 12.px
          color = Color.dimGray
        }
        + "Ex: Pizza Party"
      }
    }
    /* Jar Total Field */
    styledDiv {
      css {
        marginBottom = 20.px
      }
      styledInput {
        css(LoginCardCss.inputStyle)
        css {
          marginBottom = 5.px
        }
        attrs.value = total.toString()
        attrs.type = InputType.number
        attrs.placeholder = "Jar Cost"
        attrs.onChangeFunction = { event ->
          val target = event.target as HTMLInputElement
          setTotal(target.value.toInt())
        }
      }
      styledP {
        css {
          fontSize = 12.px
          color = Color.dimGray
        }
        + "This is the amount of currency a member must spend to acquire this jar"
      }
    }
    /* Jar Description Field */
//    styledDiv {
//      css {
//        marginBottom = 20.px
//      }
//      styledTextarea {
//        css(LoginCardCss.inputStyle)
//        css {
//          marginBottom = 5.px
//          height = 110.px
//        }
//        attrs.value = description
//        attrs.placeholder = "Description (Optional)"
//        attrs.onChangeFunction = { event ->
//          val target = event.target as HTMLTextAreaElement
//          setDescription(target.value)
//        }
//      }
//      styledP {
//        css {
//          fontSize = 12.px
//          color = Color.dimGray
//        }
//        + "Provide a bit more information about this jar."
//      }
//    }
    // on press is handled by the form submit above
    if (props.isEditing) {
      Button("Update Jar", loading = props.isLoading) {
        it.preventDefault()
        val updatedJar = props.jar!!.copy(
          id = props.jar!!.id,
          name = name,
          total = total,
        )
        props.onEditJar(updatedJar)
      }
      Button(
        text = "Delete Jar",
        bgColor = "#B00020",
      ) {
        it.preventDefault()
        props.onDeleteJar(props.jar!!.id)
      }
    } else {
      Button("Create Jar", loading = props.isLoading) {
        it.preventDefault()
        props.onCreateJar(
          Jar(
          name = name,
          total = total
        )
        )
      }
    }
  }
}