package com.jet.classroomhero.data.remote.sources

import com.jet.classroomhero.data.LocalUserSource
import com.jet.classroomhero.data.RemoteExtensionSource
import com.jet.classroomhero.entities.Extension
import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.client.request.put
import io.ktor.http.ContentType
import io.ktor.http.contentType
import io.ktor.http.isSuccess
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext

class NetworkExtensionSource(localUserSource: LocalUserSource) : ProtectedNetworkSource(localUserSource), RemoteExtensionSource {
  override suspend fun fetchExtensions(): List<Extension> = withContext(Dispatchers.Default) {
    val url = "${baseUrl}api/extensions/"
    return@withContext client.get(url) {
      contentType(ContentType.Application.Json)
    }.body()
  }

  override suspend fun activateExtension(classId: Int, extensionId: Int): Boolean = withContext(Dispatchers.Default) {
    val url = "${baseUrl}api/classes/${classId}/extension/activate/${extensionId}"
    return@withContext client.put(url) {
      contentType(ContentType.Application.Json)
    }.status.isSuccess()
  }

  override suspend fun deactivateExtension(classId: Int, extensionId: Int): Boolean = withContext(Dispatchers.Default) {
    val url = "${baseUrl}api/classes/${classId}/extension/deactivate/${extensionId}"
    return@withContext client.put(url) {
      contentType(ContentType.Application.Json)
    }.status.isSuccess()
  }
}