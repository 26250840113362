package components.modals.user

import com.jet.classroomhero.entities.Class
import com.jet.classroomhero.entities.Item
import com.jet.classroomhero.entities.Student
import components.forms.BaseFormProps
import components.forms.GiveItemFormProps
import components.profile.ProfileProps
import components.widgets.Button
import components.widgets.Modal
import kotlinx.css.Color
import kotlinx.css.TextAlign
import kotlinx.css.alignItems
import kotlinx.css.boxShadow
import kotlinx.css.boxSizing
import kotlinx.css.color
import kotlinx.css.display
import kotlinx.css.flexDirection
import kotlinx.css.fontSize
import kotlinx.css.fontWeight
import kotlinx.css.gap
import kotlinx.css.height
import kotlinx.css.justifyContent
import kotlinx.css.lineHeight
import kotlinx.css.margin
import kotlinx.css.marginBottom
import kotlinx.css.padding
import kotlinx.css.pct
import kotlinx.css.properties.LineHeight
import kotlinx.css.px
import kotlinx.css.textAlign
import kotlinx.css.width
import react.RBuilder
import react.dom.attrs
import styled.css
import styled.styledA
import styled.styledDiv
import styled.styledH2
import styled.styledImg
import styled.styledP
import styles.GlobalCss
import kotlinx.css.*
import kotlinx.css.properties.BoxShadow
import kotlinx.css.properties.BoxShadows
import kotlinx.html.js.onClickFunction
import react.Props
import react.dom.*
import react.fc
import react.useState
import styled.css
import styled.styledA
import styled.styledDiv
import styled.styledImg

external interface RoleSelectProps : Props {
  var parentProps: ProfileProps
}

val RoleSelectComponent = fc<RoleSelectProps> { props ->
  val (selectedRole, setSelectedRole) = useState("teacher")
  Modal(onClose = {
    props.parentProps.showModal(false, null)
  }) {
    styledDiv {
      css {
        display = Display.flex
        flexDirection = FlexDirection.column
        alignItems = Align.center
        gap = 12.px
      }
    }
    styledH2 {
      + "Select a Role"
    }
    styledDiv {
      css {
        display = Display.flex
        flexDirection = FlexDirection.row
        justifyContent = JustifyContent.start
        alignItems = Align.center
        gap = 12.px
        margin = "12px 0px"
      }
      role("teacher", "I'm a teacher", "https://cdn.classroom-hero.com/static/img/role_img_teacher.png", selectedRole) {
        setSelectedRole("teacher")
      }
      role("parent", "I'm a parent", "https://cdn.classroom-hero.com/static/img/role_img_parent.png", selectedRole) {
        setSelectedRole("parent")
      }
      role("student", "I'm a student", "https://cdn.classroom-hero.com/static/img/role_img_student.png", selectedRole) {
        setSelectedRole("student")
      }
    }
    Button("Select Role") {
      it.preventDefault()
      props.parentProps.editRole(selectedRole)
    }
  }
}

fun RBuilder.role(role: String, text: String, imgSrc: String, selectedRole: String = "teacher", onRoleSelect: () -> Unit) {
  styledDiv {
    css {
      height = 130.px
      display = Display.flex
      alignItems = Align.center
      justifyContent = JustifyContent.center
      margin = "5px"
      boxSizing = BoxSizing.borderBox
      flexDirection = FlexDirection.column
      padding = "12px"
      gap = 12.px
      cursor = Cursor.pointer
      boxShadow += BoxShadow(false, 0.px, 7.px, 29.px, 0.px, rgba(100, 100, 111, 0.2))
      borderWidth = 2.px
      borderColor = Color.transparent
      borderStyle = BorderStyle.solid
      if (selectedRole == role) {
        borderWidth = 2.px
        borderColor = Color("#1CB3E4")
        borderStyle = BorderStyle.solid
      }
    }
    attrs.onClickFunction = {
      onRoleSelect()
    }
    styledDiv {
      css {
        display = Display.flex
        justifyContent = JustifyContent.center
        alignItems = Align.center
        flexDirection = FlexDirection.column
        padding = "12px"
        fontWeight = FontWeight.bold
        fontSize = 14.px
      }
      styledImg(src = imgSrc) {
        attrs {
          this["width"] = "75"
          this["height"] = "75"
        }
      }
      p {
        +text
      }
    }
  }
}