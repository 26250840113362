package components.modals.achievements

import com.jet.classroomhero.combineNames
import com.jet.classroomhero.entities.ReinforcerUpload
import com.jet.classroomhero.entities.Student
import com.jet.classroomhero.entities.UploadStatus
import components.accounts.LoginCardCss
import components.dashboard.DashboardProps
import components.modals.ModalType
import components.widgets.Button
import components.widgets.Modal
import kotlinx.browser.window
import kotlinx.css.Align
import kotlinx.css.Color
import kotlinx.css.Display
import kotlinx.css.FlexDirection
import kotlinx.css.alignItems
import kotlinx.css.color
import kotlinx.css.display
import kotlinx.css.flexDirection
import kotlinx.css.fontSize
import kotlinx.css.gap
import kotlinx.css.height
import kotlinx.css.marginBottom
import kotlinx.css.px
import kotlinx.css.width
import kotlinx.html.js.onChangeFunction
import org.w3c.dom.HTMLTextAreaElement
import react.RBuilder
import react.dom.value
import styled.css
import styled.styledDiv
import styled.styledH3
import styled.styledSpan
import styled.styledTextarea
import styles.GlobalCss
import utils.Utils

fun RBuilder.uploadDetailModal(
  props: DashboardProps,
  upload: ReinforcerUpload,
  student: Student,
  feedback: String,
  onFeedbackChange: (String) -> Unit
) {
  Modal(onClose = {
    props.showModal(true, ModalType.MEMBER_DETAIL)
  }) {
    styledDiv {
      css {
        display = Display.flex
        alignItems = Align.center
        flexDirection = FlexDirection.column
        gap = 8.px
      }
      styledH3 {
        css {
          marginBottom = 20.px
        }
        + "Reviewing for ${student.combineNames()}"
      }
      styledDiv {
        css(GlobalCss.flexColumn)
        styledDiv {
          css(GlobalCss.flexRow)
          css {
            gap = 8.px
          }
          styledSpan {
            css {
              color = Color("#AAAFB4")
              fontSize = 18.px
            }
            +"Uploaded on: "
          }
          styledSpan {
            css {
              color = Color("#AAAFB4")
              fontSize = 18.px
            }
            + Utils.formatDate(upload.createdAt)
          }
        }
      }
      styledTextarea {
        css(LoginCardCss.inputStyle)
        css {
          height = 200.px
          width = 315.px
          marginBottom = 20.px
        }
        attrs.value = feedback
        attrs.placeholder = "Provide some feedback for your student."
        attrs.onChangeFunction = { event ->
          val target = event.target as HTMLTextAreaElement
          onFeedbackChange(target.value)
        }
      }
      Button(
        text = "Accept",
        bgColor = "#4BB543",
        faIcon = "fa-check",
        iconWidth = 16.px
      ) {
        props.currentGroup?.id?.let { groupId ->
          props.updateRequirement(groupId, upload.id, UploadStatus.ACCEPTED.status, feedback)
        }
      }
      Button(
        text = "Needs work",
        bgColor = "#B00020",
        faIcon = "fa-times",
        iconWidth = 16.px
      ) {
        props.currentGroup?.id?.let { groupId ->
          props.updateRequirement(groupId, upload.id, UploadStatus.REJECTED.status, feedback)
        }
      }
      Button(
        text = "Download",
        faIcon = "fa-download",
        iconWidth = 16.px
      ) {
        window.open(upload.fileUrl, "_blank");
      }
      Button(
        text = "Cancel",
      ) {
        props.showModal(false, ModalType.UPLOAD_DETAIL_MODAL)
      }
    }
  }
}