package components.dashboard.achievements

import com.jet.classroomhero.combineNames
import com.jet.classroomhero.entities.*
import components.dashboard.DashboardCss
import components.dashboard.DashboardProps
import components.forms.UploadRequirementsForm
import components.modals.ModalType
import components.modals.achievements.uploadDetailModal
import components.modals.achievements.uploadFeedbackModal
import components.widgets.*
import kotlinx.browser.window
import kotlinx.css.*
import kotlinx.css.properties.BoxShadow
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import kotlinx.html.js.onSubmitFunction
import org.khronos.webgl.ArrayBuffer
import org.w3c.files.Blob
import org.w3c.files.FileReader
import react.*
import styled.*
import styles.GlobalCss
import styles.GlobalCss.flexCenter
import styles.GlobalCss.flexColumn
import styles.GlobalCss.flexRow
import utils.Utils
import utils.getFontAwesomeIconForType
import utils.pluralizeIf
import utils.toByteArray

external interface AchievementDetailComponentProps : Props {
  var group: Class?
  var achievement: Reinforcer
  var member: Student?
  var parentProps: DashboardProps
  var uploads: List<ReinforcerUpload>
  var onShowModalType: (ModalType) -> Unit
  var currModal: ModalType?
  var onBulkCompleteAchievement: (List<Int>) -> Unit
  var onUploadRequirements: (List<ReinforcerUpload>) -> Unit
  var onDeleteRequirement: (Int) -> Unit
  var onUpdateStatus: (Int, String, String?, List<ReinforcerUpload>?) -> Unit
  var isManager: Boolean
  var isLoading: Boolean
}

val AchievementDetailComponent = fc<AchievementDetailComponentProps> { props ->
  val (selectedIds, setSelectedIds) = useState<MutableList<Int>>(mutableListOf())
  val (showConfirmationModal, setShowConfirmationModal) = useState(false)
  val (showRejectionModal, setShowRejectionModal) = useState(false)
  val (upload, setUpload) = useState<ReinforcerUpload?>(null)
  val (feedback, setFeedback) = useState("")
  styledDiv {
    attrs.id = "achievement-detail-component"
    css(DashboardCss.memberDetailContainer)
    if (props.isManager) {
      styledDiv {
        css {
          position = Position.absolute
          left = 25.px
          top = 25.px
        }
        Button(
          text = "Edit",
          faIcon = "fa-edit",
          bgColor = "#F57C00"
        ) {
          props.onShowModalType(ModalType.EDIT_ACHIEVEMENT)
        }
      }
    }
    styledDiv {
      css(DashboardCss.memberInfoSection)
      styledDiv {
        css(flexRow)
        css {
          alignItems = Align.center
          marginTop = 60.px
        }
        styledDiv {
          css(flexColumn)
          css(flexCenter)
          css {
            textAlign = TextAlign.center
            flex(1.0, 1.0, FlexBasis.auto)
            gap = 24.px
          }
          circularFaIcon(
            faIcon = "fa-star",
            bgColor = Color("#F57C00"),
            size = 45.px
          )
          styledP {
            css {
              fontSize = 26.px
              marginTop = 10.px
            }
            +"${props.achievement.name}"
          }
          styledDiv {
            css {
              fontSize = 20.px
              textAlign = TextAlign.left
              maxWidth = 450.px
              width = 100.pct
            }

            /* Value and Type Row */
            styledDiv {
              css(flexRow)
              css {
                justifyContent = JustifyContent.spaceBetween
              }
              styledDiv {
                css(flexColumn)
                styledP {
                  css {
                    color = Color("#6d7175")
                    marginBottom = 12.px
                  }
                  + "Value"
                }
                styledDiv {
                  css(flexRow)
                  if (props.group != null) {
                    valueAndCurrencyIcon(
                      amount = props.achievement.value ?: 1,
                      isLoss = false,
                      currencyImgUrl = props.group!!.currencyIconUrl!!,
                      iconSize = 30.px,
                      amountSize = 28.px,
                      amountSide = Side.LEFT
                    )
                  }
                }
              }
              styledDiv {
                css(flexColumn)
                styledP {
                  css {
                    color = Color("#6d7175")
                    marginBottom = 12.px
                  }
                  + "Uploads Allowed"
                }
                achievementTypeWidget(props.achievement.type)
              }
            }

            if (props.achievement.description.isNotEmpty()) {
              styledDiv {
                css {
                  marginTop = 16.px
                  marginBottom = 12.px
                }
                styledP {
                  css {
                    color = Color("#6d7175")
                    marginBottom = 6.px
                  }
                  + "Description"
                }
                styledP {
                  css {
                    fontSize = 18.px
                  }
                  + props.achievement.description
                }
              }
            }
          }
        }
      }
    }
    if (props.isManager) {
      // Managers get the option to award / sell to members
      if (props.achievement.uploadsAllowed()) {
        styledDiv {
        css(DashboardCss.statsRow)
          managerPendingRequirements(
            uploads = props.uploads.filter {
              it.achievementId == props.achievement.id &&
                  (it.status == UploadStatus.PENDING.status || it.status == UploadStatus.REJECTED.status)
            },
            group = props.group,
          ) { upload, modalType ->
            setUpload(upload)
            props.parentProps.showModal(true, modalType)
          }
        }
      }
      if (props.achievement.type == "inperson"){
        styledDiv {
          css(DashboardCss.statsRow)
          awardMembersForm(props, selectedIds, setSelectedIds)
        }
      }
      if (props.parentProps.selectedModalType?.name == ModalType.UPLOAD_DETAIL_MODAL.name) {
        upload?.let {
          props.group?.let {
            val member = it.students.find { member ->
              member.id == upload.memberId
            }
            member?.let {
              uploadDetailModal(props.parentProps, upload, member, feedback) { feedback ->
                setFeedback(feedback)
              }
            }
          }

        }
      }
      if (props.parentProps.selectedModalType == ModalType.UPLOAD_FEEDBACK_MODAL) {
        upload?.let {
          uploadFeedbackModal(props.parentProps, upload.feedback)
        }
      }
//      if (showConfirmationModal || showRejectionModal) {
//        Modal(
//          onClose = {
//            setShowConfirmationModal(false)
//            setShowRejectionModal(false)
//          }
//        ) {
//          styledDiv {
//            css(flexColumn)
//            css(flexCenter)
//            css {
//              maxWidth = 360.px
//              width = 100.pct
//              gap = 12.px
//            }
//            styledH2 {
//              + "Are you sure?"
//            }
//            styledP {
//              + if (showConfirmationModal) "Pressing Continue will complete ${props.achievement.name} for this member."
//              else "Pressing Continue will reject this member's upload for ${props.achievement.name}."
//            }
//            if (showRejectionModal) {
////              styledTextarea {
////                css(LoginCardCss.inputStyle)
////                css {
////                  height = 135.px
////                }
////                css {
////                  marginBottom = 20.px
////                }
////                attrs.value = feedback
////                attrs.placeholder = "Provide some feedback for your student. They will be sent your notes and we will notify you when they re-submit."
////                attrs.onChangeFunction = { event ->
////                  val target = event.target as HTMLTextAreaElement
////                  setFeedback(target.value)
////                }
////              }
//            }
//            Button(
//              text = "Continue",
//              bgColor = "#4BB543"
//            ) {
//              val status = if (showConfirmationModal) UploadStatus.ACCEPTED.status else UploadStatus.REJECTED.status
//              upload?.id?.let { id -> props.onUpdateStatus(id, status, feedback) }
//              setShowConfirmationModal(false)
//              setShowRejectionModal(false)
//            }
//            Button(
//              text = "Cancel",
//              bgColor = "#B00020",
//            ) {
//              setShowConfirmationModal(false)
//              setShowRejectionModal(false)
//            }
//          }
//        }
//      }
    } else if (props.achievement.type == "remote") {
      styledDiv {
        css(DashboardCss.statsRow)
        val pending = props.uploads.filter { it.status == UploadStatus.PENDING.status  }
        val rejected = props.uploads.filter { it.status == UploadStatus.REJECTED.status }
        if (pending.isNotEmpty() || rejected.isNotEmpty()) {
          pending.toMutableList().addAll(rejected)
          memberPendingRequirements(
            uploads = pending,
            onDeleteRequirement = props.onDeleteRequirement
          )
        }
        // only show uploader if non currently pending
        if (pending.isEmpty()) {
          uploader(props)
        }
      }
    }
  }
}

private fun RBuilder.managerPendingRequirements(
  uploads: List<ReinforcerUpload> = emptyList(),
  group: Class?,
  onPress: (ReinforcerUpload, ModalType) -> Unit
) {
  styledDiv {
    css(flexColumn)
    css {
      gap = 24.px
      maxHeight = 400.px
      overflow = Overflow.auto
    }
    css(BarChartCss.statContainer)
    css {
      justifyContent = JustifyContent.start
      flexDirection = FlexDirection.column
      cursor = Cursor.pointer
      hover {
        // deeper box shadow on hover
        boxShadow += BoxShadow(false, 0.px, 8.px, 16.px, 0.px, Color.lightSlateGray)
      }
    }
    attrs.onClickFunction = {
//        if (transactions.isNotEmpty()) {
//          props.onActionSelected(ModalType.ALL_TRANSACTIONS)
//        }
    }
    styledP {
      css {
        fontSize = 18.px
        marginBottom = 20.px
      }
      + "Achievement Review"
    }
    if (uploads.isNotEmpty()) {
      uploads.forEach { upload ->
        val member = group?.let {
          return@let it.students.find { member ->
            member.id == upload.memberId
          }
        }
        styledDiv {
          css(flexRow)
          css {
            alignItems = Align.center
            justifyContent = JustifyContent.spaceBetween
            gap = 54.px
            borderBottomStyle = BorderStyle.solid
            borderBottomColor = Color("#eee")
            borderBottomWidth = 1.px
            paddingBottom = 24.px
          }
          styledDiv {
            css(flexColumn)
            styledP {
              css {
                fontWeight = FontWeight("500")
                marginBottom = 1.px
              }
              + (member?.firstName ?: "")
            }
            styledDiv {
              css(flexRow)
              css {
                gap = 8.px
              }
              styledSpan {
                css {
                  color = Color("#000")
                  fontSize = 14.px
                }
                + "Uploaded on: "
              }
              styledSpan {
                css {
                  color = Color("#AAAFB4")
                  fontSize = 14.px
                }
                + Utils.formatDate(upload.createdAt)
              }
            }
            styledDiv {
              css(flexRow)
              css {
                gap = 8.px
              }
              styledSpan {
                css {
                  color = Color("#000")
                  fontSize = 14.px
                }
                + "File Type: "
              }
              styledSpan {
                css {
                  color = Color("#AAAFB4")
                  fontSize = 14.px
                }
                + upload.fileType.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
              }
            }
          }
          styledDiv {
            css(flexRow)
            css(flexCenter)
            css {
              gap = 12.px
            }
            Button(
              text = "Review",
              faIcon = "fa-comments",
              iconWidth = 16.px
            ) {
              onPress(upload, ModalType.UPLOAD_DETAIL_MODAL)
            }
            Button(
              text = "Download",
              faIcon = "fa-download",
              iconWidth = 16.px
            ) {
              window.open(upload.fileUrl, "_blank");
            }
            if (upload.feedback.isNotEmpty()) {
              Button(
                text = "View Feedback",
              ) {
                onPress(upload, ModalType.UPLOAD_FEEDBACK_MODAL)
              }
            }
//            Button(
//              text = "Accept",
//              bgColor = "#4BB543"
//            ) {
//              showModal(upload)
//            }
//            Button(
//              text = "Give Feedback",
//              bgColor = "#B00020"
//            ) {
//              showModal(upload)
//            }
          }
        }
      }
    } else {
      styledP {
        css {
          color = Color("#AAAFB4")
          fontSize = 16.px
        }
        + "There are no submissions yet for this Achievement."
      }
    }
  }
}

private fun RBuilder.memberPendingRequirements(
  uploads: List<ReinforcerUpload> = emptyList(),
  onDeleteRequirement: (Int) -> Unit,
) {
    styledDiv {
      css(flexColumn)
      css {
        gap = 24.px
      }
      css(BarChartCss.statContainer)
      css {
        justifyContent = JustifyContent.start
        flexDirection = FlexDirection.column
        cursor = Cursor.pointer
        hover {
          // deeper box shadow on hover
          boxShadow += BoxShadow(false, 0.px, 8.px, 16.px, 0.px, Color.lightSlateGray)
        }
      }
      attrs.onClickFunction = {
//        if (transactions.isNotEmpty()) {
//          props.onActionSelected(ModalType.ALL_TRANSACTIONS)
//        }
      }
      styledP {
        css {
          fontSize = 18.px
          marginBottom = 20.px
        }
        + "Pending Requirement Verification"
      }
      if (uploads.isNotEmpty()) {
        uploads.forEach { upload ->
          styledDiv {
            css(flexRow)
            css {
              maxWidth = 250.px
              width = 100.pct
            }
            styledDiv {
              css {
                width = 100.pct
                display = Display.flex
                justifyContent = JustifyContent.spaceBetween
              }
              styledDiv {
                css(flexRow)
                css(flexCenter)
                css {
                  gap = 8.px
                }
                faIcon(
                  iconColor = Color.black,
                  iconSize = 24.px,
                  faIcon = upload.getFontAwesomeIconForType()
                )
                styledP {
                  + upload.fileType.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                }
              }
              styledDiv {
                css(flexRow)
                css(flexCenter)
                css {
                  gap = 12.px
                }
                styledA {
                  attrs.href = upload.fileUrl
                  attrs.target = "_blank"
                  faIcon(
                    iconColor = Color.black,
                    iconSize = 24.px,
                    faIcon = "fa-external-link"
                  )
                }
                styledDiv {
                  attrs.onClickFunction = {
                    onDeleteRequirement(upload.id)
                  }
                  faIcon(
                    iconColor = Color.red,
                    iconSize = 24.px,
                    faIcon = "fa-times"
                  )
                }
              }
            }
          }
        }
      } else {
        styledP {
          css {
            color = Color("#AAAFB4")
            fontSize = 16.px
          }
          + "You don\'t have any pending achievements."
        }
      }
    }
  }

private fun RBuilder.uploader(props: AchievementDetailComponentProps) {
  // Members get the option to upload achievement requirements
  styledDiv {
    css(flexColumn)
    css(BarChartCss.statContainer)
    css {
      justifyContent = JustifyContent.start
      flexDirection = FlexDirection.column
      cursor = Cursor.pointer
      maxHeight = 480.px
    }
    styledP {
      css {
        fontSize = 18.px
        marginBottom = 20.px
      }
      + "Upload Requirements"
    }
    UploadRequirementsForm {
      attrs.props = props
      attrs.onSubmit = { files ->
        val uploads: MutableList<ReinforcerUpload> = mutableListOf()
        // Loop over files, convert to bytearray and submit to server
        files.forEachIndexed  { index, file ->
          val reader = FileReader()
          reader.onload = {
            val arrayBuffer = reader.result as ArrayBuffer
            val upload = ReinforcerUpload(
              achievementId = props.achievement.id,
              memberId = props.member?.id ?: 0,
              groupId = props.group!!.id,
              bytes = arrayBuffer.toByteArray(),
              fileName = file.name
            )
            uploads.add(upload)
            if (index + 1 == files.size) {
              props.onUploadRequirements(uploads)
            }
          }
          reader.readAsArrayBuffer(file as Blob)
        }
      }
    }
  }
}

private fun RBuilder.awardMembersForm(
  props: AchievementDetailComponentProps,
  selectedIds: MutableList<Int>,
  setSelectedIds: StateSetter<MutableList<Int>>
) {
  styledDiv {
    css(BarChartCss.statContainer)
    css {
      display = Display.block
    }
    styledForm {
      attrs.onSubmitFunction = {
        it.preventDefault()
      }
      if (selectedIds.size > 0 || props.isLoading) {
        styledDiv{
          css{
            display = Display.flex
            alignItems = Align.center
            justifyContent = JustifyContent.center
            flexDirection = FlexDirection.row
          }
          Button(
            customCss = {
              maxWidth = 280.px
              alignSelf = Align.center
              marginTop = 24.px
              marginBottom = 24.px
            },
            loading = props.isLoading,
            text = if (!props.isLoading) "Award ${selectedIds.size} ${"Member".pluralizeIf { selectedIds.size > 1 }}" else "Awarding..."
          ) { event ->
            event.preventDefault()
            // reset all of the selected IDs
            setSelectedIds(mutableListOf())
            props.onBulkCompleteAchievement(selectedIds)
          }
        }

      }
      props.group?.let {
        styledH3 {
          css {
            marginBottom = 4.px
            fontSize = 24.px
            fontWeight = FontWeight("300")
          }
          + "Students"
        }
        for (member in it.students) {
          RadioButton(
            isSelected = selectedIds.contains(member.id),
            labelContent = {
              styledDiv {
                attrs.id = "achievement-detail-member-${member.id}"
                css(GlobalCss.flexRow)
                styledSpan {
                  css {
                    marginRight = 8.px
                    whiteSpace = WhiteSpace.pre
                  }
                  + member.combineNames()
                }
              }
            },
            onPress = { isSelected ->
              val currentIds = selectedIds.toMutableList()
              if (isSelected) {
                currentIds.add(member.id)
              } else {
                currentIds.remove(member.id)
              }
              setSelectedIds(currentIds)
            }
          )
        }
      }
    }
  }
}