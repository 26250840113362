package com.jet.classroomhero

import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.offsetAt
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.json.Json

@Suppress("NO_ACTUAL_FOR_EXPECT")
expect class Utils {
    companion object {
        suspend fun fileToByteArray(filePath: String?): ByteArray?
        // build configs
        val isDebug: Boolean
        val baseUrl: String
    }
}

class UtilsCommon {
    companion object {
        private const val BASE_URL_RELEASE = "https://classroomhero.com/"
        private const val BASE_URL_DEBUG = "https://staging.classroomhero.com/"
        private const val BASE_URL_LOCAL = "http://localhost:8000/"
        private const val BASE_URL_LOCAL_ANDROID = "http://10.0.2.2:8000/"
        private const val BASE_URL_TISSERAND = "http://192.168.1.7:8000/"
        private const val BASE_URL_NGROK_IOS = "https://3075-98-35-247-206.ngrok.io"

        fun getBaseUrlForBuildConfig(): String {
            return if (Utils.isDebug) BASE_URL_DEBUG
                else BASE_URL_RELEASE
        }
        
        val jsonConfig =  Json { ignoreUnknownKeys = true }

        fun getLocalDateTimeWithTimeZoneOffset(): String {
            val currentMoment: Instant = Clock.System.now()
            val timeZone = TimeZone.currentSystemDefault()
            val offset = timeZone.offsetAt(currentMoment)
            return "${currentMoment.toLocalDateTime(timeZone)}${offset}"
        }

        const val STAGING = "develop"
        const val LOCAL = "local"

        fun isValidEmail(email: String): Boolean{
            val emailRegex = "^[A-Za-z0-9+_.-]+@[A-Za-z0-9.-]+\$"
            return email.matches(emailRegex.toRegex())
        }
    }
}
