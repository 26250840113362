package components.modals.extensions

import com.jet.classroomhero.entities.getExtensionById
import components.dashboard.DashboardLocalState
import components.dashboard.DashboardProps
import components.dashboard.extensions.extensions
import components.widgets.Button
import components.widgets.Modal
import kotlinx.css.Align
import kotlinx.css.Display
import kotlinx.css.FlexDirection
import kotlinx.css.alignItems
import kotlinx.css.display
import kotlinx.css.flexDirection
import kotlinx.css.gap
import kotlinx.css.height
import kotlinx.css.margin
import kotlinx.css.px
import kotlinx.css.width
import react.RBuilder
import styled.css
import styled.styledDiv
import styled.styledH3
import styled.styledImg
import styled.styledP

fun RBuilder.renderExtensionDetailModal(
  props: DashboardProps
) {
  Modal(onClose = {
    props.showModal(false, null)
  }) {
    styledDiv {
      css {
        display = Display.flex
        alignItems = Align.center
        flexDirection = FlexDirection.column
        gap = 8.px
      }
      styledH3 {
        css {
          margin = "12px 0"
        }
        + props.selectedExtension.name
      }
      styledImg {
        css {
          width = 100.px
        }
        attrs.src = props.selectedExtension.imageUrl
      }
      styledP {
        + props.selectedExtension.description
      }
      if (props.currentGroup?.getExtensionById(props.selectedExtension.id) != null) {
        Button(
          "Deactivate ${props.selectedExtension.name}",
          bgColor = "#B00020",
        ) {
          props.deactivateExtension(props.currentGroup!!.id, props.selectedExtension)
          props.showModal(false, null)
        }
      } else {
        Button(
          "Activate ${props.selectedExtension.name}",
          loading = props.isLoading
        ) {
          props.activateExtension(props.currentGroup!!.id, props.selectedExtension)
          props.showModal(false, null)
        }
      }
    }
  }
}