package sources.local

import com.jet.classroomhero.data.LocalExtensionSource
import com.jet.classroomhero.entities.Extension
import kotlinx.browser.window
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

class CacheExtensionSource : LocalExtensionSource {
  companion object {
    const val KEY_EXTENSION = "key_extensions"
  }

  override suspend fun readExtensions(): List<Extension> {
    window.localStorage.getItem(KEY_EXTENSION)?.let { cache ->
      return Json.decodeFromString(cache)
    }
    return emptyList()
  }

  override suspend fun saveExtensions(extensions: List<Extension>) {
    window.localStorage.setItem(KEY_EXTENSION, Json.encodeToString(extensions))
  }
}