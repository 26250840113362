package com.jet.classroomhero

import com.jet.classroomhero.entities.Student
import com.jet.classroomhero.entities.User
import kotlin.collections.List

fun Student.splitName(): List<String> {
    return this.name.split(" ")
}

fun String.capitalize(): String {
    return this.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
}

fun Student.combineNames(): String {
    return "${this.firstName} ${this.lastName}"
}

fun User.getFullName(): String {
    val builder = StringBuilder()
    if (!firstName.isNullOrEmpty()) {
        builder.append(firstName)
    }
    if (!lastName.isNullOrEmpty()) {
        builder.append(" ")
        builder.append(lastName)
    }
    return builder.toString()
}