package containers

import actions.*
import actions.AchievementActions.updateRequirement
import com.jet.classroomhero.entities.*
import components.dashboard.DashboardComponent
import components.dashboard.DashboardProps
import components.modals.ModalType
import react.ComponentClass
import react.Props
import react.dom.aria.AriaRole
import react.invoke
import react.redux.rConnect
import redux.RAction
import redux.WrapperAction
import store.AppState

external interface DashboardStateProps : Props {
    var user: User
    var currentGroup: Class?
    var currentGroupStats: GroupStats?
    var modalShowing: Boolean
    var dropDownShowing: Boolean
    var isLoading: Boolean
    var isGlobalLoading: Boolean
    var snackBarShowing: Boolean
    var snackBarMessage: String?
    var selectedMember: Student?
    var selectedAchievement: Reinforcer?
    var selectedItem: Item?
    var selectedJar: Jar?
    var selectedExtension: Extension?
    var selectedModalType: ModalType?
    var groups: List<Class>
    var currentUploads: List<ReinforcerUpload>
    var extensions: List<Extension>
}

external interface DashboardDispatcherProps : Props {
    var showModal: (Boolean, ModalType) -> Unit
    var showSnackBar:  (Boolean, String) -> Unit
    var showDropdown: (Boolean) -> Unit
    var populateClassDashboard: () -> Unit
    /* Member Dispatchers */
    var createMember: (Student, Int) -> Unit
    var deleteMember: (Int, Int) -> Unit
    var editMember: (Student, Int) -> Unit
    var setSelectedMember: (Student) -> Unit
    /* Achievement Dispatchers */
    var createAchievement: (Reinforcer, Int) -> Unit
    var deleteAchievement: (Int, Int) -> Unit
    var editAchievement: (Reinforcer, Int) -> Unit
    var uploadRequirements: (List<ReinforcerUpload>) -> Unit
    var deleteRequirement: (Int, Int) -> Unit
    var bulkCompleteAchievement: (List<Int>, Int, Int) -> Unit
    var setSelectedAchievement: (Reinforcer) -> Unit
    var updateRequirement: (Int, Int, String, String?) -> Unit
    /* Item Dispatchers */
    var createItem: (Item, Int) -> Unit
    var deleteItem: (Int, Int) -> Unit
    var editItem: (Item, Int) -> Unit
    var setSelectedJar: (Jar) -> Unit
    /* Jar Dispatchers */
    var createJar: (Jar, Int) -> Unit
    var deleteJar: (Int, Int) -> Unit
    var editJar: (Jar, Int) -> Unit
    var bulkSellItem: (List<Int>, Int, Int) -> Unit
    var setSelectedItem: (Item) -> Unit
    /* Class Dispatchers */
    var fetchGroup: (Int) -> Unit
    var fetchGroups: () -> Unit
    var setSelectedGroup: (Int) -> Unit
    var editGroup: (Class, ByteArray?, ByteArray?, ByteArray?) -> Unit
    var deleteGroup: (Int) -> Unit
    /* Extension Dispatchers */
    var fetchExtensions: () -> Unit
    var activateExtension: (Int, Extension) -> Unit
    var deactivateExtension: (Int, Extension) -> Unit
    var setSelectedExtension: (Extension) -> Unit
}

val dashboardComponent: ComponentClass<Props> = rConnect<AppState, RAction, WrapperAction, Props, DashboardStateProps, DashboardDispatcherProps, DashboardProps>(
    { state, _ ->
        user = state.accountState.user
        currentGroup = state.groups.currentGroup
        currentGroupStats = state.groups.currentGroupStats
        groups = state.groups.groups
        modalShowing = state.appState.modalShowing
        dropDownShowing = state.appState.dropDownShowing
        isLoading = state.appState.isLoading
        isGlobalLoading = state.appState.isGlobalLoading
        snackBarShowing = state.appState.snackBarShowing
        snackBarMessage = state.appState.snackBarMessage
        selectedModalType = state.appState.selectedModalType
        selectedMember = state.appState.selectedMember
        selectedAchievement = state.appState.selectedAchievement
        selectedItem = state.appState.selectedItem
        selectedJar = state.appState.selectedJar
        selectedExtension = state.appState.selectedExtension
        currentUploads = state.groups.uploads
        extensions = state.groups.extensions
    },
    { dispatch, _ ->
        showModal = { show, modalType -> dispatch(AppStateActions.SetModalShowing(show, modalType)) }
        showSnackBar = { show, message -> dispatch(AppStateActions.SetShowSnackBar(show, message)) }
        showDropdown = { show -> dispatch(AppStateActions.SetDropdownShowing(show)) }

        populateClassDashboard = { dispatch(GroupActions.populateClassDashboard()) }

        editGroup = { group, groupLogo, currencyIcon, groupCover -> dispatch(GroupActions.editGroup(group, groupLogo, currencyIcon, groupCover)) }
        deleteGroup = { groupId -> dispatch(GroupActions.deleteGroup(groupId)) }

        createMember = { member, groupId -> dispatch(MemberActions.createMember(member, groupId)) }
        deleteMember = { memberId, groupId -> dispatch(MemberActions.deleteMember(memberId, groupId)) }
        editMember = { member, groupId -> dispatch(MemberActions.editMember(member, groupId)) }
        bulkCompleteAchievement = { memberIds, groupId, achievementId ->
            dispatch(MemberActions.bulkCompleteAchievements(memberIds, groupId, achievementId))
        }
        bulkSellItem = { memberIds, groupId, itemId ->
            dispatch(MemberActions.bulkSellItem(memberIds, groupId, itemId))
        }


        createAchievement = { reinforcer, groupId -> dispatch(AchievementActions.createAchievement(reinforcer, groupId)) }
        editAchievement = { reinforcer, groupId -> dispatch(AchievementActions.editAchievement(reinforcer, groupId)) }
        deleteAchievement = { reinforcerId, groupId -> dispatch(AchievementActions.deleteAchievement(reinforcerId, groupId)) }
        uploadRequirements = { requirements -> dispatch(AchievementActions.uploadAchievementRequirements(requirements)) }
        updateRequirement = { groupId, uploadId, status, feedback -> dispatch(AchievementActions.updateRequirement(groupId, uploadId, status, feedback)) }

        createItem = { item, groupId -> dispatch(ItemActions.createItem(item, groupId)) }
        editItem = { item, groupId -> dispatch(ItemActions.editItem(item, groupId)) }
        deleteItem = { item, groupId -> dispatch(ItemActions.deleteItem(item, groupId)) }

        createJar = { jar, groupId -> dispatch(JarActions.createJar(jar, groupId)) }
        editJar = { jar, groupId -> dispatch(JarActions.editJar(jar, groupId)) }
        deleteJar = { jarId, groupId -> dispatch(JarActions.deleteJar(jarId, groupId)) }

        activateExtension = { classId, extension -> dispatch(GroupActions.activateExtension(classId, extension)) }
        deactivateExtension = { classId, extension -> dispatch(GroupActions.deactivateExtension(classId, extension)) }

        setSelectedGroup = { groupId -> dispatch(AppStateActions.setSelectedGroup(groupId)) }
        setSelectedAchievement = { achievement -> dispatch(AppStateActions.SetSelectedAchievement(achievement)) }
        setSelectedMember = { member -> dispatch(AppStateActions.SetSelectedMember(member)) }
        setSelectedItem = { item -> dispatch(AppStateActions.SetSelectedItem(item)) }
        setSelectedJar = { jar -> dispatch(AppStateActions.SetSelectedJar(jar)) }
        setSelectedExtension = { extension -> dispatch(AppStateActions.SetSelectedExtension(extension)) }
    }
)(DashboardComponent::class.js.unsafeCast<ComponentClass<DashboardProps>>())


