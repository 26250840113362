package components.modals.students

import components.dashboard.DashboardProps
import components.dashboard.members.MemberDetailComponent
import components.widgets.Modal
import kotlinx.css.pct
import react.RBuilder

fun RBuilder.memberDetailModal(
  props: DashboardProps
) {
  Modal(
    width = 75.pct,
    onClose = {
    props.showModal(false, null)
  }) {
      MemberDetailComponent {
          attrs.parentProps = props
          attrs.isManager = true
          attrs.onActionSelected = { action ->
            console.log("onActionSelected index")
            props.showModal(true, action)
          }
      }
  }
}