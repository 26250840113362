package components.accounts

import com.jet.classroomhero.UtilsCommon
import com.jet.classroomhero.entities.User
import components.widgets.Button
import components.accounts.LoginCardCss.inputStyle
import components.accounts.LoginCardCss.line
import components.accounts.LoginCardCss.loginHeader
import components.accounts.LoginCardCss.loginSubHeader
import csstype.BorderBottomStyle
import kotlinx.browser.document
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onSubmitFunction
import org.w3c.dom.HTMLInputElement
import react.*
import react.dom.html.ReactHTML.div
import styled.*
import styles.GlobalCss

external interface RegisterComponentProps : Props {
    var user: User
    var isLoading: Boolean
    var errorMessage: String
    var emailRegister: (User) -> Unit
}

external interface RegisterComponentLocalState : State {
    var errorMessage: String
}


@JsExport
class RegisterComponent(props: RegisterComponentProps) : RComponent<RegisterComponentProps, RegisterComponentLocalState>(props) {
    private val nameInputRef = createRef<HTMLInputElement>()
    private val emailInputRef = createRef<HTMLInputElement>()
    private val passwordInputRef = createRef<HTMLInputElement>()

    override fun RegisterComponentLocalState.init(props: RegisterComponentProps){
        errorMessage = props.errorMessage
    }

    override fun componentDidMount() {
        val userToken = props.user.token ?: ""
        if (userToken.isNotEmpty()) {
            document.location?.href = "/profile"
        }
    }

    override fun RBuilder.render() {
        div {
            styledH1 {
                css(loginHeader)
                +"Register"
            }
            styledH3 {
                css(loginSubHeader)
                +"Create an account with Classroom Hero"
            }
            if (state.errorMessage.isNotEmpty()) {
                styledP {
                    css(GlobalCss.errorText)
                    css {
                        marginTop = 8.px
                        marginBottom = 8.px
                    }
                    + state.errorMessage
                }
            }
            styledForm {
                css {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    gap = 18.px
                }
                attrs.onSubmitFunction = { event ->
                    event.preventDefault()
                    val emailRegex = "^[A-Za-z0-9+_.-]+@[A-Za-z0-9.-]+\$"
                    if (emailInputRef.current != null &&
                        passwordInputRef.current != null &&
                        nameInputRef.current != null) {
                        val email = emailInputRef.current!!.value
                        val pw = passwordInputRef.current!!.value
                        val name = nameInputRef.current!!.value.split(" ")
                        if (!UtilsCommon.isValidEmail(email)){
                            setState {
                                errorMessage = "Email format is invalid"
                            }
                        }

                        if (name[0].length < 2){
                            setState {
                                errorMessage = "First name must be at least 2 characters"
                            }
                        }

                        if (pw.length > 5){
                            setState {
                                errorMessage = "Password must be 6 characters or more"
                            }
                        }

                        val newUser = User(
                            email = email,
                            password = pw,
                            username = "",
                            firstName = name[0],
                            lastName = if(name.size > 2) name[1] else "",
                            role = ""
                        )
                        if (email.trim().isNotEmpty() && pw.trim().isNotEmpty()) {
                            props.emailRegister(newUser)
                        }
                    }
                }
                styledInput {
                    css(inputStyle)
                    attrs.id = "RegisterPageNameTextField"
                    attrs.type = InputType.text
                    attrs.placeholder = "Name"
                    ref = nameInputRef
                }
                styledInput {
                    css(inputStyle)
                    attrs.id = "RegisterPageEmailTextField"
                    attrs.type = InputType.email
                    attrs.placeholder = "Email"
                    ref = emailInputRef
                }
                styledInput {
                    css(inputStyle)
                    attrs.id = "RegisterPagePasswordTextField"
                    attrs.type = InputType.password
                    attrs.placeholder = "Password"
                    ref = passwordInputRef
                }
                // on press is handled by the form submit above
                Button(text = "Continue with Email", id="RegisterPageSubmitButton") {
                }

            }
            styledDiv { css(line) }
            Button(
                text = "Continue with Google",
                iconSrc = "images/ic_google.jpg",
                bgColor = "#fff",
                textColor = "#000",
                customCss = {
                    borderStyle = BorderStyle.solid
                    borderWidth = 1.px
                    borderColor = Color.black
                }
            ) {
                // calls this method in resources/sso.js
                js("performGoogleAuth()")
            }
            Button(
                text = "Continue with Facebook",
                iconSrc = "images/ic_facebook.png",
                bgColor = "#4267B2",
                textColor = "#fff",
                iconWidth = 12.px,
                iconHeight = 25.px,
                customCss = {
                    marginTop = 12.px
                }
            ) {
                js("performGoogleAuth()")
            }
        }
        styledP {
            + "Already have an account? "
            styledA {
                attrs.href = "/login"
                + "Sign In"
            }
        }
    }
}