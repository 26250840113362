package components.dashboard.extensions

import com.jet.classroomhero.entities.Class
import com.jet.classroomhero.entities.Extension
import components.dashboard.DashboardCss
import components.dashboard.items.ItemListComponentProps
import components.modals.ModalType
import components.widgets.Button
import kotlinx.css.BorderStyle
import kotlinx.css.Color
import kotlinx.css.borderBottomColor
import kotlinx.css.borderBottomStyle
import kotlinx.css.borderBottomWidth
import kotlinx.css.borderRadius
import kotlinx.css.borderWidth
import kotlinx.css.fontSize
import kotlinx.css.height
import kotlinx.css.marginBottom
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.css.width
import kotlinx.html.js.onClickFunction
import react.Props
import react.RBuilder
import react.fc
import styled.css
import styled.styledDiv
import styled.styledImg
import styled.styledP

external interface ExtensionListComponentProps : Props {
  // The reinforcers do display
  var extensions: List<Extension>
  // The currently selected item
  var selectedExtension: Extension?
  // The callback after pressing on a reinforcer
  var onPressExtension: (Extension) -> Unit
  // The reference to the currently selected group
  var currentGroup: Class
  // For displaying modals
  var onShowModalType: (ModalType) -> Unit
}

val ExtensionListComponent = fc<ExtensionListComponentProps> { props ->
  styledDiv {
    css(DashboardCss.extensionListContainer)
    styledP {
      css {
        fontSize = 22.px
        marginBottom = 12.px
        borderBottomColor = Color("#eee")
        borderBottomWidth = 1.px
        borderBottomStyle = BorderStyle.solid
        width = 100.pct
      }
      + "Extensions"
    }
    styledP {
      + "Customize your class by selecting from our wide array of extensions."
    }
    styledDiv {
      css(DashboardCss.extensionsGridContainer)
      for (extension in props.extensions) {
        styledDiv {
          attrs.onClickFunction = {
            it.stopPropagation()
            props.onPressExtension(extension)
            props.onShowModalType(ModalType.EXTENSION_DETAIL_MODAL)
          }
          css(DashboardCss.extensionItem)
          styledP {
            + extension.name
          }
          styledImg {
            css {
              width = 100.px
            }
            attrs.src = extension.imageUrl
          }
        }
      }
    }

  }
}