package components.dashboard.achievements

import components.dashboard.DashboardProps
import components.modals.ModalType
import components.modals.achievements.renderAchievementDetailModal
import components.modals.achievements.renderCreateOrEditAchievementModal
import kotlinx.css.*
import kotlinx.html.id
import react.RBuilder
import styled.css
import styled.styledDiv

fun RBuilder.achievements(props: DashboardProps, isManager: Boolean) {
  val group = props.currentGroup
  if (group != null) {
    styledDiv {
      attrs.id = group.id.toString() + "-achievement-list"

      css {
        boxSizing = BoxSizing.borderBox
        display = Display.flex
        alignItems = Align.flexStart
        justifyContent = JustifyContent.flexStart
        height = LinearDimension.auto
        maxWidth = 100.pct
        margin = "0"
        padding = "0"
      }
      /* ACHIEVEMENT LIST COMPONENT */
      AchievementListComponent {
        attrs.achievements = group.reinforcers
        attrs.selectedAchievement = props.selectedAchievement
        attrs.currentGroup = group
        attrs.onPressAchievement = { achievement ->
          props.setSelectedAchievement(achievement)
        }
        attrs.onShowModalType = { modalType ->
          props.showModal(true, modalType)
        }
        attrs.isManager = isManager
        attrs.setSelectedAchievement = { achievement ->
          if (props.selectedAchievement?.id == achievement.id) {
            props.setSelectedAchievement(null)
          } else {
            props.setSelectedAchievement(achievement)
            props.showModal(true, ModalType.ACHIEVEMENT_DETAIL)
          }
        }
      }

      if (props.modalShowing) {
        when (props.selectedModalType) {
          ModalType.CREATE_ACHIEVEMENT -> { renderCreateOrEditAchievementModal(props, null) }
          ModalType.EDIT_ACHIEVEMENT -> { renderCreateOrEditAchievementModal(props, props.selectedAchievement) }
          ModalType.ACHIEVEMENT_DETAIL -> { renderAchievementDetailModal(props, isManager) }
          else -> {}
        }
      }
    }
  }
}