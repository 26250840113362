package sources.local

import com.jet.classroomhero.data.LocalTransactionSource
import com.jet.classroomhero.entities.Transaction

class CacheTransactionSource : LocalTransactionSource {
  override suspend fun saveTransactions(transactions: List<Transaction>) {}
  override suspend fun saveTransaction(transaction: Transaction): Boolean { return false }
  override suspend fun readTransaction(transactionId: Int): Transaction? { return null }
  override suspend fun readTransactions(): List<Transaction> { return emptyList() }
  override suspend fun readMemberTransactions(memberId: Int): List<Transaction> { return emptyList() }
  override suspend fun deleteTransaction(transactionId: Int) {}
}