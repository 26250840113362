package sources.local

import com.jet.classroomhero.data.LocalJarSource
import com.jet.classroomhero.entities.Jar

class CacheJarSource : LocalJarSource {
  override suspend fun saveJar(jar: Jar, classId: Int) {

  }

  override suspend fun saveJars(jars: List<Jar>, classId: Int) {
  }

  override suspend fun deleteJar(jarId: Int): Int {
    return 1
  }

  override suspend fun readJars(classId: Int): List<Jar> {
    return emptyList()
  }

  override suspend fun readJar(jarId: Int): Jar? {
    return null
  }
}