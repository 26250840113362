package components.widgets

import components.widgets.SnackBarCss.snackbar
import csstype.EmptyCells.Companion.show
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.css.properties.Timing
import kotlinx.css.properties.Transition
import kotlinx.css.properties.ms
import kotlinx.css.properties.s
import react.RBuilder
import styled.StyleSheet
import styled.css
import styled.styledDiv
import styled.styledSpan

fun RBuilder.SnackBar(
  show: Boolean = false,
  label: String = "A notification!",
  closeDelayMs: Long = 2500L,
  onClose: () -> Unit
) {
  styledDiv {
    css(snackbar)
    css {
      opacity = if (show) 1 else 0
    }
    styledSpan {
      + label
    }
  }
  if (show) {
    GlobalScope.launch {
      delay(closeDelayMs)
      onClose()
    }
  }
}

object SnackBarCss : StyleSheet("SnackBarCss", isStatic = true) {
  val snackbar by css {
    opacity = 0
    minWidth = 250.px
    marginLeft = (-125).px
    backgroundColor = Color("#4BB543")
    color = Color.white
    textAlign = TextAlign.center
    borderRadius = 2.px
    padding = "16px"
    position = Position.fixed
    zIndex = 1
    left = 50.pct
    bottom = 30.px
    transition += Transition("opacity", 333.ms, Timing.easeInOut, 0.s)
  }
}
