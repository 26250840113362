package components.widgets

import kotlinx.css.*
import react.RBuilder
import styled.StyleSheet
import styled.css
import styled.styledDiv
import components.widgets.ProgressBarCss.progressBar
import components.widgets.ProgressBarCss.progressBarInner
import components.widgets.ProgressBarCss.progressBarFill
import csstype.HtmlAttributes
import kotlinx.css.properties.*
import styled.styledSpan
import kotlin.Float

fun RBuilder.progressBar(
  fillPercentage: Float,
  customMaxWidth: LinearDimension = 500.px
) {
  styledDiv {
    css {
      width = 100.pct
      maxWidth = customMaxWidth
    }
    styledDiv {
      css(progressBar)
      styledSpan {
        css(progressBarFill)
        css {
          width = fillPercentage.pct
        }
      }
    }
  }
}

fun RBuilder.progressBarCenterLabel(
  fillPercentage: Float,
  customMaxWidth: LinearDimension = 500.px,
  customHeight: LinearDimension = 44.px,
  label: String? = null
) {
  styledDiv {
    css {
      width = 100.pct
      maxWidth = customMaxWidth
      height = customHeight
    }
    styledDiv {
      css(progressBar)
      css {
        position = Position.relative
        height = 100.pct
      }
      styledSpan {
        css {
          position = Position.absolute
          top = 0.pct
          left = 50.pct
          transform = Transforms().apply {
            translate((-50).pct, 50.pct)
          }
        }
        + (label ?: "")
      }
      styledSpan {
        css(progressBarFill)
        css {
          width = fillPercentage.pct
          height = 100.pct
        }
      }
    }
  }
}

object ProgressBarCss : StyleSheet("ProgressBarCss", isStatic = true) {
  val wrapper by css {

  }
  val progressBarInner by css {
    progressBar
    display = Display.flex
    justifyContent = JustifyContent.spaceBetween
  }
  val progressBar by css {
    width = 100.pct
    backgroundColor = Color("#e0e0e0")
    padding = "3px"
    borderRadius = 3.px
    boxShadow += kotlinx.css.properties.BoxShadow(
      false,
      0.px,
      4.px,
      16.px,
      0.px,
      Color.lightSlateGray
    )
  }
  val progressBarFill by css {
    display = Display.block
    height = 22.px
    backgroundColor = Color("#5521bd")
    borderRadius = 3.px
    transition += Transition("width", 555.ms, Timing.easeInOut, 0.s)
    opacity = .7
  }
}