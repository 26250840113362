package components.widgets

import kotlinx.css.*
import kotlinx.html.js.onClickFunction
import react.RBuilder
import styled.css
import styled.styledDiv
import styled.styledI
import styles.GlobalCss

fun RBuilder.infoWidget(
  content: RBuilder.() -> Unit,
  onPressInfoIcon: () -> Unit
) {
  styledDiv {
    css(GlobalCss.flexRow)
    css(GlobalCss.flexCenter)
    css {
      justifyContent = JustifyContent.spaceBetween
    }
    content()
    styledI {
      css(GlobalCss.infoIcon)
      css {
        classes.add("fa")
        classes.add("fa-info-circle")
        marginLeft = 18.px
        fontSize = 20.px
      }
      attrs.onClickFunction = {
        onPressInfoIcon()
      }
    }
  }
}