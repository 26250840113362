package com.jet.classroomhero.data

import com.jet.classroomhero.Logger
import com.jet.classroomhero.data.remote.sources.NetworkClassSource
import com.jet.classroomhero.entities.*
import kotlinx.coroutines.*

class  ClassRepository(
    private val localUserSource: LocalUserSource,
    private val localClassSource: LocalClassSource,
    private val localStudentSource: LocalStudentSource,
    private val localItemSource: LocalItemSource,
    private val localReinforcerSource: LocalReinforcerSource,
    private var remoteClassSource: RemoteClassSource = NetworkClassSource(localUserSource)
) {
    val logger = Logger()

    val coroutineScope: CoroutineScope = MainScope()

    init {
        logger.logMessage("$TAG init")
    }

    companion object {
        private val TAG = ClassRepository::class.simpleName
    }

    private suspend fun readUser(): User? {
        return withContext(Dispatchers.Default) {
            return@withContext localUserSource.readUser()
        }
    }

    suspend fun createClass(
        newClass: Class,
        currencyIcon: ByteArray? = null,
        groupLogo: ByteArray? = null,
        groupCover: ByteArray? = null,
        templateId: Int? = null
    ): Class? {
        logger.logMessage("$TAG.createClass")
        val creatingClass = coroutineScope.async {
            return@async remoteClassSource.createClass(newClass, templateId)
        }
        val createdClass = creatingClass.await()
        logger.logMessage("$TAG.createClass About to try to upload stuff")

        createdClass?.let { newGroup ->
            // Upload icons if provided
            if (currencyIcon != null) {
                logger.logMessage("$TAG.createClass uploading currency icon")
                uploadAndDecorateCurrencyIcon(newGroup, currencyIcon)
            }
            if (groupLogo != null) {
                logger.logMessage("$TAG.createClass uploading group logo")
                uploadAndDecorateGroupLogo(newGroup, groupLogo)
            }
            if (groupCover != null) {
                logger.logMessage("$TAG.createClass uploading group cover")
                uploadAndDecorateGroupCover(newGroup, groupCover)
            }
            logger.logMessage("$TAG saving class..")
            // Save the group to local
            saveClass(newGroup)

            return newGroup
        }
        return null
    }

    suspend fun editClass(
        newClass: Class,
        currencyIcon: ByteArray? = null,
        groupLogo: ByteArray? = null,
        groupCover: ByteArray? = null,
        ): Class? {
        logger.logMessage("$TAG.editClass")
        val updatingClass = coroutineScope.async {
            return@async remoteClassSource.editClass(newClass)
        }
        val updatedGroup = updatingClass.await()
        updatedGroup?.let {
            if (currencyIcon != null) {
                // We have an icon, upload that too
                logger.logMessage("$TAG.editClass currency icon")
                uploadAndDecorateCurrencyIcon(updatedGroup, currencyIcon)
            }
            if (groupLogo != null) {
                // We have a logo, upload that too
                logger.logMessage("$TAG.editClass uploading group logo")
                uploadAndDecorateGroupLogo(updatedGroup, groupLogo)
            }
            if (groupCover != null) {
                // We have a cover, upload that too
                logger.logMessage("$TAG.editClass uploading group cover")
                uploadAndDecorateGroupCover(updatedGroup, groupCover)
            }
            saveClass(it)
            return it
        }
        return null
    }

    suspend fun deleteClass(classId: Int): Boolean {
        logger.logMessage("$TAG.deleteClass")
        val deletingClass = coroutineScope.async {
            return@async remoteClassSource.deleteClass(classId)
        }
        val deleted = deletingClass.await()
        if (deleted) {
            withContext(Dispatchers.Default) {
                localClassSource.deleteClass(classId)
            }
            return true
        }
        return false
    }

    suspend fun getGroup(groupId: Int): Class {
        return remoteClassSource.fetchGroup(groupId)
    }

    suspend fun getClasses(forceFetch: Boolean = false): List<Class> {
        logger.logMessage("$TAG.getClasses")
        val user = readUser() ?: return emptyList()
        if (!forceFetch) {
            val localClasses = readClasses(user.id)
            if (localClasses.isNotEmpty()) {
                return localClasses
            }
        }
        val fetchedClasses = remoteClassSource.fetchClasses()
        saveClasses(fetchedClasses)
        return fetchedClasses
    }

    suspend fun getMemberships(forceFetch: Boolean = false): List<Class> {
        logger.logMessage("$TAG.getMemberships")
        val user = readUser() ?: return emptyList()
        logger.logMessage(user.toString())
        if (!forceFetch) {
            val localClasses = readMemberships(user.id)
            if (localClasses.isNotEmpty()) {
                return localClasses
            }
        }
        val fetchedClasses = remoteClassSource.fetchMemberships()
        saveClasses(fetchedClasses)
        return fetchedClasses
    }

    suspend fun getGroupStats(groupId: Int): GroupStats {
        logger.logMessage("$TAG.getGroupStats($groupId)")
        val groupStats = remoteClassSource.fetchGroupStats(groupId)
        localClassSource.saveGroupStats(groupStats)
        return groupStats
    }

    // This rigamarole takes a list of UTC datetimes, localizes them, and converts it into a mapping of: Map<DayOfWeek, Total>, where
    // total is the number of occurrences a transaction / achievements occurs on any day of the week
    // This is a convenient format for populating bar chart data
//    private fun processDateTimesForClients(source: List<String>, target: MutableMap<Int, Int> = mutableMapOf()): Map<Int, Int> {
//        source.forEach {
//            val formattedDate = Instant.parse(it).toLocalDateTime(TimeZone.currentSystemDefault())
//            val dayOfWeek = formattedDate.dayOfWeek.ordinal
//            if (target.containsKey(dayOfWeek)) {
//                val value = target[dayOfWeek]
//                target[dayOfWeek] = value!!.plus(1)
//                return@forEach // acts like "continue" in java
//            }
//            target[dayOfWeek] = 1
//        }
//        return target.toMap()
//    }

    suspend fun resetGroup(groupId: Int): Boolean {
        val success = remoteClassSource.resetGroup(groupId)
        if (success) {
            withContext(Dispatchers.Default) {
                localItemSource.deleteGroupItems(groupId)
                localReinforcerSource.deleteGroupReinforcers(groupId)
                localStudentSource.deleteGroupStudents(groupId)
            }
        }
        return success
    }

    suspend fun updateCurrencyIcon(groupId: Int, byteArray: ByteArray) {
        logger.logMessage("$TAG.updateCurrencyIcon")
        val response: ImageUploadResponse = remoteClassSource.updateCurrencyIcon(groupId, byteArray)
        withContext(Dispatchers.Default) {
            if (response.uploadedImageUrl.isNotEmpty()) {
                localClassSource.saveCurrencyIcon(groupId, response.uploadedImageUrl)
            }
        }
    }

    suspend fun fetchStockGroupMedia(): StockGroupMediaResponse {
        return remoteClassSource.fetchStockGroupMedia()
    }

    suspend fun completeInvite(classHash: String): Class? {
        logger.logMessage("$TAG.completeInvite About to try to complete invite")

        val completingInvite = coroutineScope.async {
            return@async remoteClassSource.completeInvite(classHash)
        }
        val completedInvite = completingInvite.await()
        completedInvite?.let { newGroup ->

            logger.logMessage("$newGroup.name")
            localClassSource.saveClassRelationships(newGroup)
            return newGroup
        }
        return null
    }

    suspend fun completeStudentInvite(studentHash: String): Class? {
        logger.logMessage("Complete Student Invite about to try to complete invite")

        val completingInvite = coroutineScope.async {
            return@async remoteClassSource.completeStudentInvite(studentHash)
        }
        val completedInvite = completingInvite.await()
        completedInvite?.let { newGroup ->
            logger.logMessage("$newGroup.name")
            print("Complete student invite class name: $newGroup.name")
            localClassSource.saveClassRelationships(newGroup)
            return newGroup
        }
        return null

    }


    suspend fun leaveGroup(groupId: Int): Boolean {
        logger.logMessage("$TAG.unlinkGroupMember")
        val unlinking = coroutineScope.async {
            return@async remoteClassSource.leaveGroup(groupId)
        }
        val success = unlinking.await()
        if (success) {
            // we can remove this membership locally
            withContext(Dispatchers.Default) {
                localClassSource.deleteClass(groupId)
            }
        }
        return success
    }

    private suspend fun uploadAndDecorateCurrencyIcon(group: Class, currencyIcon: ByteArray) = withContext(Dispatchers.Default) {
        logger.logMessage("$TAG.uploadAndDecorateCurrencyIcon")
        val uploadResponse = remoteClassSource.updateCurrencyIcon(group.id, currencyIcon)
        logger.logMessage("$TAG icon upload response: $uploadResponse")
        if (uploadResponse.uploadedImageUrl.isNotEmpty()) {
            logger.logMessage("$TAG decorating icon on group")
            // decorate the in-memory group with the imgUrl
            group.currencyIconUrl = uploadResponse.uploadedImageUrl
        }
    }

    private suspend fun uploadAndDecorateGroupLogo(group: Class, currencyIcon: ByteArray) = withContext(Dispatchers.Default) {
        logger.logMessage("$TAG.uploadAndDecorateCurrencyIcon")
        val uploadResponse = remoteClassSource.updateGroupLogo(group.id, currencyIcon)
        logger.logMessage("$TAG icon upload response: $uploadResponse")
        if (uploadResponse.uploadedImageUrl.isNotEmpty()) {
            logger.logMessage("$TAG decorating icon on group")
            // decorate the in-memory group with the imgUrl
            group.groupLogoUrl = uploadResponse.uploadedImageUrl
        }
    }

    suspend fun uploadAndDecorateGroupCover(group: Class, currencyIcon: ByteArray) = withContext(Dispatchers.Default) {
        logger.logMessage("$TAG.uploadGroupCover")
        val uploadResponse = remoteClassSource.updateGroupCover(group.id, currencyIcon)
        logger.logMessage("$TAG icon upload response: $uploadResponse")
        if (uploadResponse.uploadedImageUrl.isNotEmpty()) {
            logger.logMessage("$TAG decorating icon on group")
            // decorate the in-memory group with the imgUrl
            group.groupCoverUrl = uploadResponse.uploadedImageUrl
        }
    }

    private suspend fun saveClasses(groups: List<Class>) {
        groups.forEach { group -> saveClass(group) }
    }

    private suspend fun saveClass(group: Class) = withContext(Dispatchers.Default) {
        localClassSource.saveClass(group)
        localItemSource.saveItems(group.items, group.id)
        localReinforcerSource.saveReinforcers(group.reinforcers, group.id)
        localStudentSource.saveStudents(group.students, group.id)
    }

    private suspend fun readClasses(userId: Int) = withContext(Dispatchers.Default) {
        return@withContext localClassSource.readClasses(userId)
    }

    private suspend fun readMemberships(userId: Int) = withContext(Dispatchers.Default) {
        return@withContext localClassSource.readMemberships(userId)
    }
}

interface LocalClassSource {
    suspend fun saveClasses(classes: List<Class>)
    suspend fun saveClass(newClass: Class)
    suspend fun saveClassRelationships(newClass: Class)
    suspend fun saveGroupStats(groupStats: GroupStats)
    suspend fun deleteClass(classId: Int)
    suspend fun readClasses(userId: Int): List<Class>
    suspend fun readMemberships(userId: Int): List<Class>
    suspend fun readClass(classId: Int): Class
    suspend fun saveCurrencyIcon(classId: Int, imgUrl: String)
    suspend fun saveGroupLogo(classId: Int, imgUrl: String)
    suspend fun deleteAllClasses()
}

interface RemoteClassSource {
    @Throws(Exception::class)
    suspend fun createClass(newClass: Class, templateId: Int?): Class?
    suspend fun editClass(updatedClass: Class): Class?
    suspend fun deleteClass(classId: Int): Boolean
    suspend fun fetchGroup(groupId: Int): Class
    suspend fun fetchClasses(): List<Class>
    suspend fun fetchMemberships(): List<Class>
    suspend fun fetchGroupStats(groupId: Int): GroupStats
    suspend fun updateCurrencyIcon(groupId: Int, bytes: ByteArray): ImageUploadResponse
    suspend fun updateGroupLogo(groupId: Int, bytes: ByteArray): ImageUploadResponse
    suspend fun updateGroupCover(groupId: Int, bytes: ByteArray): ImageUploadResponse
    suspend fun resetGroup(groupId: Int): Boolean
    suspend fun fetchStockGroupMedia(): StockGroupMediaResponse
    suspend fun completeInvite(groupHash: String): Class?
    suspend fun leaveGroup(groupId: Int): Boolean
    suspend fun completeStudentInvite(studentHash: String): Class?

}