package components.dashboard

import com.jet.classroomhero.*
import com.jet.classroomhero.entities.*
import components.dashboard.DashboardCss.allTimeStat
import components.dashboard.DashboardCss.sideBarItem
import components.dashboard.DashboardCss.statsRow
import components.dashboard.achievements.achievements
import components.dashboard.extensions.extensions
import components.dashboard.extensions.jar.JarExtensionComponent
import components.dashboard.items.items
import components.dashboard.members.members
import components.modals.*
import components.modals.groups.deleteGroupModal
import components.modals.groups.editGroupModal
import components.widgets.*
import components.widgets.BarChartCss.statContainer
import components.widgets.dropdowns.GroupsDropDownMenu
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import react.*
import styled.*
import styles.GlobalCss

enum class DashboardRoute(var label: String = "") {
  CLASS_STATS("Class Stats"),
  MEMBERS("Members"),
  MEMBER(""),
  ACHIEVEMENTS("Achievements"),
  MARKETPLACE("Marketplace"),
  EXTENSIONS("Extensions")
}

external interface DashboardProps : Props {
  var populateClassDashboard: () -> Unit
  var user: User
  var editGroup: (group: Class, groupLogo: ByteArray?, currencyIcon: ByteArray?, groupCover: ByteArray?) -> Unit
  var deleteGroup: (Int) -> Unit
  var currentGroup: Class?
  var currentGroupStats: GroupStats?
  var extensions: List<Extension>
  var members: List<Student>
  var groups: List<Class>
  var modalShowing: Boolean
  var dropDownShowing: Boolean
  var snackBarShowing: Boolean
  var snackBarMessage: String
  var selectedModalType: ModalType?
  var showModal: (Boolean, ModalType?) -> Unit
  var showSnackBar: (Boolean, String) -> Unit
  var showDropdown: (Boolean) -> Unit
  var editMember: (Student, Int) -> Unit
  var createMember: (Student, Int) -> Unit
  var deleteMember: (Int, Int) -> Unit
  var createAchievement: (Reinforcer, Int) -> Unit
  var deleteAchievement: (Int, Int) -> Unit
  var editAchievement: (Reinforcer, Int) -> Unit
  var uploadRequirements: (List<ReinforcerUpload>) -> Unit
  var bulkCompleteAchievement: (List<Int>, Int, Int) -> Unit
  var updateRequirement: (Int, Int, String, String?) -> Unit
  var deleteRequirement: (groupId: Int, uploadId: Int) -> Unit
  var currentUploads: List<ReinforcerUpload>
  var createItem: (Item, Int) -> Unit
  var deleteItem: (Int, Int) -> Unit
  var editItem: (Item, Int) -> Unit
  var createJar: (Jar, Int) -> Unit
  var editJar: (Jar, Int) -> Unit
  var deleteJar: (Int, Int) -> Unit
  var bulkSellItem: (List<Int>, Int, Int) -> Unit
  var activateExtension: (Int, Extension) -> Unit
  var deactivateExtension: (Int, Extension) -> Unit
  var setSelectedGroup: (groupId: Int) -> Unit
  var setSelectedMember: (Student?) -> Unit
  var selectedMember: Student?
  var setSelectedAchievement: (Reinforcer?) -> Unit
  var selectedAchievement: Reinforcer?
  var setSelectedItem: (Item?) -> Unit
  var selectedItem: Item?
  var setSelectedExtension: (Extension) -> Unit
  var selectedExtension: Extension
  var setSelectedJar: (Jar?) -> Unit
  var selectedJar: Jar?
  var isLoading: Boolean
  var isGlobalLoading: Boolean
}

external interface DashboardLocalState : State {
  var selectedRoute: String?
  var selectedAchievement: Reinforcer?
  var selectedItem: Item?
  var selectedJar: Jar?
  var selectedGroup: Class?
}

@JsExport
class DashboardComponent(props: DashboardProps) : RComponent<DashboardProps, DashboardLocalState>(props) {

  override fun DashboardLocalState.init(props: DashboardProps) {
    selectedRoute = DashboardRoute.MEMBERS.label
    selectedAchievement = null
    selectedItem = null
    selectedGroup = null
  }

  override fun componentDidMount() {
    val userToken = props.user.token ?: ""
    if (userToken.isEmpty()) {
      CoroutineScope(Dispatchers.Unconfined).launch {
        InjectorUtils.provideUserUseCases().logout()
        document.location?.href = UtilsCommon.getBaseUrlForBuildConfig() + "accounts/logout/"
      }
    }
    props.populateClassDashboard()
  }

  override fun RBuilder.render() {

    styledDiv {
      attrs.onClickFunction = {
        if (props.modalShowing) {
          props.showModal(false, null)

          if (props.selectedItem != null) {
            props.setSelectedItem(null)
          }
          if (props.selectedAchievement != null) {
            props.setSelectedAchievement(null)
          }
          if (props.selectedMember != null) {
            props.setSelectedMember(null)
          }

        }
      }
      styledDiv {
        attrs.id = "dashboard-" + props.currentGroup?.id.toString()
        css(DashboardCss.header)
        css {
          paddingLeft = 24.px
        }
        styledSpan {
          attrs.onClickFunction = {
            window.location.href = "/profile"
          }
          css {
            alignSelf = Align.center
            hover {
              cursor = Cursor.pointer
            }
            marginRight = LinearDimension.auto
          }
          styledSpan {
            css {
              marginRight = 20.px
            }
            faIcon(
              faIcon = "fa-arrow-left",
              iconColor = Color.black
            )
          }
          + "Go back"
        }
        styledDiv {
          css(DashboardCss.headerContent)
          GroupsDropDownMenu {
            attrs.groups = props.groups
            attrs.showDropdown = props.showDropdown
            attrs.dropDownShowing = props.dropDownShowing
            attrs.setSelectedGroup = props.setSelectedGroup
            attrs.currentGroup = props.currentGroup
            attrs.containerWidth = 330.px
          }
        }

        Button(
          text = "Edit Class",
          faIcon = "fa-edit",
          customCss = {
            maxWidth = 180.px
            borderRadius = 0.px
            borderWidth = 0.px
            marginLeft = LinearDimension.auto
            marginRight = 25.px
          }
        ) {
          props.showModal(true, ModalType.EDIT_GROUP)
        }

        val code = props.currentGroup?.classHash
        if (code != null) {
          Button(
            text = "Class Code: ${code}",
            faIcon = "fa-copy",
            iconWidth = 18.px,
            customCss = {
              maxWidth = 180.px
              borderRadius = 0.px
              borderWidth = 0.px
            }
          ) {
            js("copyToClipboard(code)")
            props.showSnackBar(true, "Class Code Copied!")
          }
        }
      }
      if (props.selectedModalType == ModalType.INFO_MODAL_MANAGER_MEMBERS) {
        featureInfoModal(
          FEATURE_INFO_MANAGER_VIEW_MEMBERS_TITLE,
          listOf(
            FEATURE_INFO_MANAGER_VIEW_MEMBERS_DESCRIPTION_PART_1,
            FEATURE_INFO_MANAGER_VIEW_MEMBERS_DESCRIPTION_PART_2,
            FEATURE_INFO_MANAGER_VIEW_MEMBERS_DESCRIPTION_PART_3
          )
        ) {
          props.showModal(false, null)
        }
      }
      if (props.selectedModalType == ModalType.INFO_MODAL_MANAGER_ACHIEVEMENTS) {
        featureInfoModal(
          FEATURE_INFO_MANAGER_VIEW_ACHIEVEMENTS_TITLE,
          listOf(
            FEATURE_INFO_MANAGER_VIEW_ACHIEVEMENTS_DESCRIPTION_PART_1,
            FEATURE_INFO_MANAGER_VIEW_ACHIEVEMENTS_DESCRIPTION_PART_2,
            FEATURE_INFO_MANAGER_VIEW_ACHIEVEMENTS_DESCRIPTION_PART_3,
            FEATURE_INFO_MANAGER_VIEW_ACHIEVEMENTS_DESCRIPTION_PART_4
          )
        ) {
          props.showModal(false, null)
        }
      }
      if (props.selectedModalType == ModalType.INFO_MODAL_MANAGER_MARKETPLACE) {
        featureInfoModal(
          FEATURE_INFO_MANAGER_VIEW_MARKETPLACE_TITLE,
          listOf(
            FEATURE_INFO_MANAGER_VIEW_MARKETPLACE_DESCRIPTION_PART_1,
            FEATURE_INFO_MANAGER_VIEW_MARKETPLACE_DESCRIPTION_PART_2,
            FEATURE_INFO_MANAGER_VIEW_MARKETPLACE_DESCRIPTION_PART_3,
            FEATURE_INFO_MANAGER_VIEW_MARKETPLACE_DESCRIPTION_PART_4
          )
        ) {
          props.showModal(false, null)
        }
      }
      if (props.selectedModalType == ModalType.EDIT_GROUP){
        editGroupModal(props, state)
      }

      if (props.selectedModalType == ModalType.DELETE_GROUP) {
        deleteGroupModal(null, props, null, state)
      }

      if (props.isGlobalLoading) {
        styledDiv {
          css(GlobalCss.flexColumn)
          css {
            gap = 8.px
            height = 1884.px
            padding = "80px"
            alignItems = Align.center
          }
          indeterminateCircularProgress(
            size = 100.px,
            color = Color("#1cb3e4")
          )
          styledP {
            css {
              opacity = 0.8
              fontWeight = FontWeight.bold
              fontSize = 16.px
            }
            + "Loading ${props.currentGroup?.name ?: "Your Class"}"
          }
        }

      } else {
        styledDiv {
          attrs.onClickFunction = {
            props.showDropdown(false)
          }
          css(DashboardCss.page)
          styledDiv {
            css(DashboardCss.sideBar)
            css {
              justifyContent = JustifyContent.spaceBetween
              paddingBottom = 130.px
            }
            styledDiv {
              styledDiv {
                css {
                  backgroundColor =
                    getSelectedRouteColor(state.selectedRoute == DashboardRoute.MEMBERS.label)
                  paddingRight = 15.px
                }
                infoWidget(content = {
                  sideBarItem(
                    title = "Students",
                    faIcon = "fa-users"
                  ) {
                    setState { selectedRoute = DashboardRoute.MEMBERS.label }
                  }
                }) {
                  props.showModal(true, ModalType.INFO_MODAL_MANAGER_MEMBERS)
                }
              }
              styledDiv {
                css {
                  backgroundColor = getSelectedRouteColor(state.selectedRoute == DashboardRoute.ACHIEVEMENTS.label)
                  paddingRight = 15.px
                }
                infoWidget(content = {
                  sideBarItem(
                    title = "Achievements",
                    faIcon = "fa-star",
                    iconColor = "#F57C00"
                  ) {
                    setState { selectedRoute = DashboardRoute.ACHIEVEMENTS.label }
                  }
                }) {
                  props.showModal(true, ModalType.INFO_MODAL_MANAGER_ACHIEVEMENTS)
                }
              }
              styledDiv {
                css {
                  backgroundColor = getSelectedRouteColor(state.selectedRoute == DashboardRoute.MARKETPLACE.label)
                  paddingRight = 15.px
                }
                infoWidget(content = {
                  sideBarItem(
                    title = "Marketplace",
                    faIcon = "fa-shopping-bag",
                    iconColor = "#00796b"
                  ) {
                    setState { selectedRoute = DashboardRoute.MARKETPLACE.label }
                  }
                }) {
                  props.showModal(true, ModalType.INFO_MODAL_MANAGER_MARKETPLACE)
                }
              }
              styledDiv {
                css {
                  backgroundColor =
                    getSelectedRouteColor(state.selectedRoute == DashboardRoute.CLASS_STATS.label)
                }
                sideBarItem(
                  title = DashboardRoute.CLASS_STATS.label,
                  faIcon = "fa-chart-bar",
                  iconColor = "#512DA8"
                ) {
                  setState { selectedRoute = DashboardRoute.CLASS_STATS.label }
                }
              }
              // Populate activated plugins
              if (props.currentGroup != null) {
                for (extension in props.currentGroup!!.extensions) {
                  styledDiv {
                    css {
                      backgroundColor =
                        getSelectedRouteColor(state.selectedRoute == extension.name)
                    }
                    sideBarItem(
                      title = extension.name,
                      imageUrl = extension.imageUrl,
                    ) {
                      setState { selectedRoute = extension.name }
                    }
                  }
                }
              }

              styledDiv {
                css {
                  backgroundColor =
                    getSelectedRouteColor(state.selectedRoute == DashboardRoute.EXTENSIONS.label)
                }
                sideBarItem(
                  title = DashboardRoute.EXTENSIONS.label,
                  faIcon = "fa-puzzle-piece",
                  iconColor = "#000"
                ) {
                  setState { selectedRoute = DashboardRoute.EXTENSIONS.label }
                }
              }

              if (!props.user.hasPaid) {
                Button(
                  text = "Upgrade Plan",
                  bgColor = "#fff",
                  textColor = "#8c52ff",
                  iconSrc = "/images/ic_upgrade.png",
                  customCss = {
                    borderStyle = BorderStyle.solid
                    borderWidth = 1.px
                    borderColor = Color( "#8c52ff")
                    marginTop = 100.px
                  }
                ) {
                  window.location.href = UtilsCommon.getBaseUrlForBuildConfig() + "pricing/"
                }
              }
            }

          }
          styledDiv {
            css(DashboardCss.content)
            css {
              overflow = Overflow.scroll
            }
            // handle selected route
            when (state.selectedRoute) {
              DashboardRoute.CLASS_STATS.label -> {
                //dashboardPhotos(props, true)
                styledDiv {
                  css(statsRow)
                  css {
                    justifyContent = JustifyContent.left
                    marginTop = 12.px
                  }
                  styledP {
                    css {
                      fontSize = 22.px
                      marginBottom = 12.px
                      borderBottomColor = Color("#eee")
                      borderBottomWidth = 1.px
                      borderBottomStyle = BorderStyle.solid
                      width = 100.pct
                    }
                    + "Class Summary"
                  }
                }
                styledDiv {
                  css(statsRow)
                  BarChart {
                    attrs.dataSet = props.currentGroupStats?.formattedWeeklyAchievementArchive ?: emptyMap()
                    attrs.chartTitle = "Achievements completed this week"
                    attrs.barColor = "#FB8C00"
                  }
                  BarChart {
                    attrs.dataSet = props.currentGroupStats?.formattedWeeklyTransactionArchive ?: emptyMap()
                    attrs.chartTitle = "Items sold this week"
                    attrs.barColor = "#00796b"
                  }
                }
                styledDiv {
                  css(statsRow)
                  /* All Time Stats */
                  styledDiv {
                    css(statContainer)
                    css {
                      justifyContent = JustifyContent.spaceBetween
                      flexDirection = FlexDirection.column
                    }
                    styledCaption {
                      css(BarChartCss.barChartTitle)
                      + "All-Time Stats"
                    }
                    styledDiv {
                      css(allTimeStat)
                      styledSpan {
                        + "Total Completed Achievements"
                      }
                      styledSpan {
                        + "${props.currentGroupStats?.completedAchievementCount}"
                      }
                    }
                    styledDiv {
                      css(allTimeStat)
                      styledSpan {
                        + "Total Item Sold"
                      }
                      styledSpan {
                        + "${props.currentGroupStats?.completedTransactionCount}"
                      }
                    }
                    styledDiv {
                      css(allTimeStat)
                      styledSpan {
                        + "Total Currency Awarded"
                      }
                      styledSpan {
                        + "${props.currentGroupStats?.totalCoinsEarnedAllTime}"
                      }
                    }
                  }
                  membersStatContainer(
                    members = props.currentGroup?.students?.sortedByDescending { it.currentCoins }?.take(3) ?: emptyList(),
                    label = "Highest Net Worth"
                  )
                }
                styledDiv {
                  css(statsRow)
                  membersStatContainer(
                    members = props.currentGroup?.students?.sortedByDescending { it.level }?.take(3) ?: emptyList(),
                    label = "Highest Level"
                  )
                }
              }
              DashboardRoute.MEMBERS.label -> { members(props, true) }
              DashboardRoute.ACHIEVEMENTS.label -> { achievements(props, true) }
              DashboardRoute.MARKETPLACE.label -> { items(props, true) }
              DashboardRoute.EXTENSIONS.label -> { extensions(props, true) }
              "Class Jar" -> {
                props.currentGroup?.let {
                  JarExtensionComponent {
                    attrs.currentGroup = it
                    attrs.extension = props.selectedExtension
                    attrs.parentProps = props
                  }
                }
              }
              "Leaderboard" -> {

              }
              else -> {}
            }
          }
        }
      }
      if (props.snackBarShowing) {
        SnackBar(
          show = props.snackBarShowing,
          label = props.snackBarMessage
        ) {
          // occurs after specified delay
          props.showSnackBar(false, "")
        }
      }
    }
  }
}

fun getSelectedRouteColor(isSelected: Boolean): Color {
  return if(isSelected) {
    Color("#eeeeee")
  } else {
    Color("#fff")
  }
}

fun RBuilder.sideBarItem(
  title: String,
  faIcon: String = "fa-dashboard",
  imageUrl: String = "",
  iconColor: String = "#2196f3",
  disabled: Boolean = false,
  onPress: () -> Unit
) {
  styledA {
    css(sideBarItem)
    css {
      if (disabled) {
        cursor = Cursor.notAllowed
      }
    }
    attrs.onClickFunction = {
      if (!disabled) {
        onPress()
      }
    }
    styledSpan {
      css(DashboardCss.sideBarItemIcon)
      css {
        if (disabled) {
          color = Color("#cccccc")
        }
      }
      if (imageUrl.isNotEmpty()) {
        imageWidget(
          src = imageUrl,
          size = 17.px
        )
      } else {
        faIcon(
          faIcon = faIcon,
          iconSize = 17.px,
          iconColor = Color(if (disabled) "#cccccc" else iconColor)
        )
      }
    }
    + title
  }
}

fun RBuilder.membersStatContainer(
  members: List<Student>,
  label: String
) {
  styledDiv {
    css(statContainer)
    css {
      flexDirection = FlexDirection.column
    }
    styledCaption {
      css(BarChartCss.barChartTitle)
      + label
    }
    for (member in members) {
      styledDiv {
        attrs.id = "member-stats-${member.id}"
        attrs.onClickFunction = {
          //props.onPressMember(member)
        }
        css(DashboardCss.memberRow)
        css {
          width = 100.pct
        }
        styledDiv {
          css(DashboardCss.memberPhoto)
          if (member.photo.isNullOrEmpty()) {
            circularNamePlaceholder(member.firstName)
          } else {
            imageWidget("${member.photo}")
          }
        }
        styledDiv {
          styledP {
            + "${member.firstName} ${member.lastName}"
          }
        }
      }
    }
  }
}