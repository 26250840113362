package com.jet.classroomhero.usecases

import com.jet.classroomhero.data.TemplateRepository
import com.jet.classroomhero.entities.Template

class TemplateUseCases(private val templateRepository: TemplateRepository) {
    /**
     * Base repository calls
     */
    suspend fun getTemplates(forceFetch: Boolean): List<Template> = templateRepository.getTemplates(forceFetch)
    suspend fun applyTemplate(groupId: Int, templateId: Int): Boolean = templateRepository.applyTemplate(groupId, templateId)

    /**
     * iOS Calls
     */
    suspend fun getTemplatesIos(forceFetch: Boolean): List<Template> = getTemplates(forceFetch)
    suspend fun applyTemplateIos(groupId: Int, templateId: Int): Boolean = applyTemplate(groupId, templateId)
}