package com.jet.classroomhero.entities

import kotlinx.serialization.SerialName
import kotlinx.serialization.Transient
import kotlinx.serialization.Serializable

@Serializable
data class Class(
    @SerialName("class_id")
    var id: Int = 0,
    // TODO: We should just change this to userId on the server / in responses
    var teacher: Int? = 0,
    var name: String = "",
    var description: String = "",
    @SerialName("member_count")
    var memberCount: Int = 0,
    var level: Int? = 0,
    var progress: Int? = 0,
    @SerialName("class_hash")
    var classHash: String = "",
    var students: List<Student> = emptyList(),
    var items: List<Item> = emptyList(),
    var reinforcers: List<Reinforcer> = emptyList(),
    @SerialName("currency_name")
    var currencyName: String = "Coins",
    @SerialName("currency_icon")
    var currencyIconUrl: String? = "",
    @SerialName("group_logo")
    var groupLogoUrl: String? = null,
    @SerialName("group_cover")
    var groupCoverUrl: String? = null,
    var jars: List<Jar> = emptyList(),
    @Transient var stats: GroupStats? = null,
    val extensions: List<Extension> = emptyList()
){
    constructor(
        name: String,
        description: String,
        currencyName: String,
        currencyIconUrl: String,
        groupLogoUrl: String? = null,
        teacherId: Int,
    ): this(0, teacherId, name, description, 0, 0, 0, "", emptyList(), emptyList(), emptyList(), currencyName, currencyIconUrl, groupLogoUrl, null, emptyList())
}

fun Class.getCurrencyIconUrl(): String {
    return this.currencyIconUrl ?: ""
}

fun Class.getGroupLogoUrl(): String {
    return this.groupLogoUrl ?: ""
}

fun Class.getGroupCoverUrl(): String {
    return this.groupCoverUrl ?: ""
}

fun Class.getExtensionCount(): Int {
    return this.extensions.size
}

fun Class.getExtensionById(id: Int): Extension? {
    return this.extensions.firstOrNull { it.id == id }
}

