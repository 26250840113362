package com.jet.classroomhero.entities

sealed class UserRole(val label: String) {
  object Teacher : UserRole("teacher")
  object Parent : UserRole("parent")
  object Student : UserRole("student")
}

fun UserRole.getUserRoleForLabel(label: String): UserRole {
  return when(label) {
    UserRole.Teacher.label -> UserRole.Teacher
    UserRole.Parent.label -> UserRole.Parent
    UserRole.Student.label -> UserRole.Student
    else -> UserRole.Teacher
  }
}

