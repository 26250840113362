package components.modals.items

import com.jet.classroomhero.combineNames
import com.jet.classroomhero.entities.Class
import com.jet.classroomhero.entities.Item
import components.dashboard.DashboardCss
import components.dashboard.DashboardProps
import components.dashboard.items.ItemDetailComponent
import components.dashboard.items.ItemDetailComponentProps
import components.forms.SellItemForm
import components.modals.ModalType
import components.widgets.*
import kotlinx.css.*
import kotlinx.html.js.onSubmitFunction
import react.Props
import react.RBuilder
import react.StateSetter
import react.useState
import styled.*
import styles.GlobalCss
import utils.pluralizeIf



fun RBuilder.renderItemDetailModal(props: DashboardProps, isManager: Boolean, ) {
    Modal(onClose = {
        props.showModal(false, ModalType.MEMBER_DETAIL)
        props.setSelectedItem(null)
    }) {
        ItemDetailComponent {
            attrs.item = props.selectedItem!!
            attrs.group = props.currentGroup
            attrs.onShowModalType = { action ->
                props.showModal(true, action)
            }
            attrs.onBulkSellItem = { memberIds ->
                props.bulkSellItem(
                  memberIds,
                  props.currentGroup!!.id,
                  props.selectedItem!!.id
                )
            }
            attrs.isLoading = props.isLoading
            attrs.isManager = isManager
        }
    }
}
