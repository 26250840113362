package components.widgets

import com.jet.classroomhero.UtilsCommon
import components.widgets.FooterCss.contentColumn
import components.widgets.FooterCss.footerLink
import kotlinx.css.Align
import kotlinx.css.Color
import kotlinx.css.Display
import kotlinx.css.FlexDirection
import kotlinx.css.JustifyContent
import kotlinx.css.ListStyleType
import kotlinx.css.TextAlign
import kotlinx.css.alignItems
import kotlinx.css.backgroundColor
import kotlinx.css.color
import kotlinx.css.display
import kotlinx.css.flexDirection
import kotlinx.css.gap
import kotlinx.css.height
import kotlinx.css.justifyContent
import kotlinx.css.listStyleType
import kotlinx.css.margin
import kotlinx.css.maxWidth
import kotlinx.css.padding
import kotlinx.css.pct
import kotlinx.css.properties.TextDecoration
import kotlinx.css.px
import kotlinx.css.textAlign
import kotlinx.css.textDecoration
import kotlinx.css.width
import react.dom.attrs
import react.fc
import styled.StyleSheet
import styled.css
import styled.styledA
import styled.styledDiv
import styled.styledH5
import styled.styledImg
import styled.styledLi
import styled.styledSection
import styled.styledSpan
import styled.styledUl
import styles.GlobalCss.flexColumn
import styles.GlobalCss.flexRow

val Footer = fc<NavBarProps> { props ->
  styledSection {
    css(FooterCss.container)
    styledDiv {
      css(FooterCss.content)
      styledDiv {
        css(contentColumn)
        styledImg {
          attrs.src = "/images/ch_logo_alt_white.png"
          css {
            width = 170.px
          }
        }
        styledSpan {
          css {
            display = Display.flex
            gap = 8.px
          }
          faIcon(faIcon = "fa-envelope", iconSize = 16.px)
          styledA(href = "mailto:info@classroomhero.com") {
            css {
              color = Color.white
            }
            + "info@classroomhero.com"
          }
        }
        styledDiv {
          css(flexRow)
          css {
            textAlign = TextAlign.center
            gap = 8.px
          }
          styledA(href = "https://play.google.com/store/apps/details?id=com.jet.classroomhero.android", target = "_blank") {
            styledImg(src = "https://cdn.classroom-hero.com/static/img/google_play.png", alt = "google play store") {
              css {
                height = 50.px
              }
            }
          }
          styledA(href = "https://apps.apple.com/us/app/classroom-hero/id1448817904", target = "_blank") {
            styledImg(src = "https://cdn.classroom-hero.com/static/img/app_store.png", alt = "apple app store") {
              attrs {
                css {
                  height = 50.px
                }
              }
            }
          }
        }
      }
      styledDiv {
        css(contentColumn)
        styledH5 {
          + "General"
        }
        styledUl {
          css(flexColumn)
          css {
            listStyleType = ListStyleType.none
            padding(0.px)
            gap = 12.px
          }
          styledLi {
            styledA(href = "${UtilsCommon.getBaseUrlForBuildConfig()}/pricing/") {
              css(footerLink)
              +"Pricing"
            }
          }
          styledLi {
            styledA(href = "${UtilsCommon.getBaseUrlForBuildConfig()}/privacy-policy/") {
              css(footerLink)
              +"Privacy Policy"
            }
          }
        }
      }
      styledDiv {
        css(contentColumn)
        styledH5 {
          +"Follow Us"
        }
        styledUl {
          css {
            display = Display.flex
            gap = 12.px
            listStyleType = ListStyleType.none
            padding(0.px)
          }
          styledLi {
            styledA(href = "https://www.facebook.com/ClassroomHeroApp") {
              styledImg {
                attrs.src = "/images/ic_facebook.png"
                css {
                  width = 16.px
                }
              }
            }
          }
          styledLi {
            styledA(href = "https://www.instagram.com/classroomheroapp/") {
              styledImg {
                attrs.src = "/images/ic_instagram.png"
                css {
                  width = 32.px
                }
              }
            }
          }
        }
      }
    }
  }
}

private object FooterCss : StyleSheet("FooterCss", isStatic = true) {
  val container by css {
    width = 100.pct
    padding(30.px, 24.px)
    color = Color.white
    gap = 22.px
    backgroundColor = Color("#424242")
  }

  val content by css {
    margin = "0 auto"
    maxWidth = 680.px
    gap = 32.px
    display = Display.flex
    alignItems = Align.start
    justifyContent = JustifyContent.spaceBetween
  }

  val contentColumn by css {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 12.px
  }

  val footerLink by css {
    color = Color.white
    textDecoration = TextDecoration.none
  }
}