
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch

val userUseCases = InjectorUtils.provideUserUseCases()

@JsExport
fun LoginWithGoogle(idToken: String, onSignInComplete: () -> Unit = {}) {
    CoroutineScope(Dispatchers.Default).launch {
        val loggedInUser = userUseCases.loginOrRegisterWithGoogleBase(idToken)
        if (!loggedInUser.token.isNullOrEmpty()) {
            onSignInComplete()
        }
    }
}

@JsExport
fun LoginWithFacebook(userId: String, idToken: String, onSignInComplete: () -> Unit = {}) {
    CoroutineScope(Dispatchers.Default).launch {
        val loggedInUser = userUseCases.loginOrRegisterWithFacebook(idToken, userId)
        if (!loggedInUser.token.isNullOrEmpty()) {
            onSignInComplete()
        }
    }
}