package components.modals.extensions

import com.jet.classroomhero.entities.Jar
import components.dashboard.DashboardProps
import components.forms.CreateOrEditJarForm
import components.widgets.Modal
import kotlinx.css.Align
import kotlinx.css.Display
import kotlinx.css.FlexDirection
import kotlinx.css.alignItems
import kotlinx.css.alignSelf
import kotlinx.css.display
import kotlinx.css.flexDirection
import kotlinx.css.marginBottom
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.css.textAlign
import kotlinx.css.width
import react.RBuilder
import styled.css
import styled.styledDiv
import styled.styledH3

fun RBuilder.renderCreateOrEditJarModal(props: DashboardProps, jar: Jar?) {
  val isEditing = jar != null
  Modal(onClose = {
    props.showModal(false, null)
  }) {
    styledDiv {
      css {
        display = Display.flex
        alignItems = Align.center
        flexDirection = FlexDirection.column
        alignSelf = Align.center
        width = 100.pct
        textAlign = kotlinx.css.TextAlign.center
      }
      styledH3 {
        css {
          marginBottom = 20.px
        }
        + if (isEditing) "Edit Jar" else "Create a New Jar"
      }
      CreateOrEditJarForm {
        attrs.jar = jar
        attrs.onCreateJar = { newJar ->
          props.createJar(newJar, props.currentGroup!!.id)
        }
        attrs.onEditJar = { updatedJar ->
          props.editJar(updatedJar, props.currentGroup!!.id)
        }
        attrs.onDeleteJar = { jarId ->
          props.deleteJar(jarId, props.currentGroup!!.id)
          props.setSelectedJar(null)
        }
        attrs.isEditing = isEditing
        attrs.isLoading = props.isLoading
      }
    }
  }
}