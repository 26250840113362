package components.modals.achievements

import com.jet.classroomhero.entities.Achievement
import components.dashboard.DashboardProps
import components.dashboard.members.recentItem
import components.modals.ModalType
import components.widgets.Modal
import kotlinx.css.*
import react.RBuilder
import styled.css
import styled.styledDiv
import styled.styledH3

fun RBuilder.allAchievements(props: DashboardProps, achievements: List<Achievement>) {
  Modal(onClose = {
    props.showModal(true, ModalType.MEMBER_DETAIL)
  }) {
    styledDiv {
      css {
        display = Display.flex
        alignItems = Align.center
        flexDirection = FlexDirection.column
      }
      styledH3 {
        css {
          marginBottom = 20.px
        }
        +"All Completed Achievements"
      }
    }
    achievements.sortedByDescending { it.createdAt }.forEach {
      recentItem(
        name = it.achievementName,
        date = it.createdAt,
        amount = it.achievementValue,
        currencyImgUrl = props.currentGroup!!.currencyIconUrl ?: "",
        isLoss = false
      )
    }
  }
}