package components.dashboard.extensions

import components.dashboard.DashboardProps
import components.modals.ModalType
import components.modals.extensions.renderExtensionDetailModal
import kotlinx.css.Align
import kotlinx.css.BoxSizing
import kotlinx.css.Display
import kotlinx.css.JustifyContent
import kotlinx.css.LinearDimension
import kotlinx.css.alignItems
import kotlinx.css.boxSizing
import kotlinx.css.display
import kotlinx.css.height
import kotlinx.css.justifyContent
import kotlinx.css.margin
import kotlinx.css.maxWidth
import kotlinx.css.padding
import kotlinx.css.pct
import react.RBuilder
import styled.css
import styled.styledDiv

fun RBuilder.extensions(props: DashboardProps, isManager: Boolean) {

  styledDiv {
    css {
      boxSizing = BoxSizing.borderBox
      display = Display.flex
      alignItems = Align.flexStart
      justifyContent = JustifyContent.flexStart
      height = LinearDimension.auto
      maxWidth = 100.pct
      margin = "0"
      padding = "0"
    }
    /* ACHIEVEMENT LIST COMPONENT */
    ExtensionListComponent {
      attrs.extensions = props.extensions
      attrs.selectedExtension = props.selectedExtension
      attrs.currentGroup = props.currentGroup!!
      attrs.onPressExtension = { extension ->
        props.setSelectedExtension(extension)
      }
      attrs.onShowModalType = { modalType ->
        props.showModal(true, modalType)
      }
    }
//      if (props.selectedItem != null) {
//        /* ACHIEVEMENT DETAIL COMPONENT */
//        ItemDetailComponent {
//          attrs.item = props.selectedItem!!
//          attrs.group = props.currentGroup
//          attrs.onShowModalType = { action ->
//            props.showModal(true, action)
//          }
//          attrs.onBulkSellItem = { memberIds ->
//            props.bulkSellItem(
//              memberIds,
//              group.id,
//              props.selectedItem!!.id
//            )
//          }
//          attrs.isManager = isManager
//        }
//      }
    if (props.modalShowing) {
      when (props.selectedModalType) {
        ModalType.EXTENSION_DETAIL_MODAL -> {
          renderExtensionDetailModal(props)
        }
        else -> {}
      }
    }
  }

}