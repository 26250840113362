package com.jet.classroomhero.usecases

import com.jet.classroomhero.SuspendWrapper
import com.jet.classroomhero.Utils
import com.jet.classroomhero.data.ReinforcerRepository
import com.jet.classroomhero.entities.Reinforcer
import com.jet.classroomhero.entities.ReinforcerUpload
import io.ktor.client.plugins.*

class ReinforcerUseCases(private val reinforcerRepository: ReinforcerRepository) {
    /**
     * Base repository calls
     */

    suspend fun createReinforcer(newReinforcer: Reinforcer, classId: Int): Reinforcer? = reinforcerRepository.createReinforcer(newReinforcer, classId)
    suspend fun editReinforcer(updatedReinforcer: Reinforcer, classId: Int): Reinforcer? = reinforcerRepository.editReinforcer(updatedReinforcer, classId)
    suspend fun deleteReinforcer(reinforcerId: Int, classId: Int): Boolean = reinforcerRepository.deleteReinforcer(reinforcerId, classId)
    suspend fun getReinforcers(forceFetch: Boolean = false, classId: Int): List<Reinforcer> = reinforcerRepository.getReinforcers(forceFetch, classId)
    suspend fun getReinforcer(reinforcerId: Int): Reinforcer = reinforcerRepository.getReinforcer(reinforcerId)
    suspend fun uploadRequirements(requirements: List<ReinforcerUpload>): List<ReinforcerUpload> = reinforcerRepository.uploadRequirements(requirements)
    suspend fun resubmitRequirement(requirement: ReinforcerUpload): List<ReinforcerUpload> = reinforcerRepository.resubmitRequirement(requirement)
    suspend fun getRequirements(classId: Int, forceFetch: Boolean = false): List<ReinforcerUpload> = reinforcerRepository.getRequirements(classId, forceFetch)
    suspend fun deleteRequirement(groupId: Int, requirementId: Int) = reinforcerRepository.deleteRequirement(groupId, requirementId)
    suspend fun updateRequirement(groupId: Int, requirementId: Int, status: String, feedback: String?): List<ReinforcerUpload> = reinforcerRepository.updateRequirement(groupId, requirementId, status, feedback)

    /**
     * iOS Calls
     */

    fun addReinforcerIos(newReinforcer: Reinforcer, classId: Int) = SuspendWrapper { createReinforcer(newReinforcer, classId) }
    fun editReinforcerIos(updatedReinforcer: Reinforcer, classId: Int) = SuspendWrapper { editReinforcer(updatedReinforcer, classId) }
    fun deleteReinforcerIos(reinforcerId: Int, classId: Int) = SuspendWrapper { deleteReinforcer(reinforcerId, classId) }
    fun getReinforcersIos(forceFetch: Boolean = false, classId: Int) = SuspendWrapper { getReinforcers(forceFetch, classId) }
    fun getRequirementsIos(classId: Int, forceFetch: Boolean = false) = SuspendWrapper { getRequirements(classId, forceFetch) }
    fun uploadRequirementsIos(requirements: List<ReinforcerUpload>) = SuspendWrapper {
        for (requirement in requirements) {
            // update requirement with actual file data
            requirement.bytes = try {
                // convert the file on iOS to a ByteArray
                Utils.fileToByteArray(requirement.filePath)
            } catch(t: Throwable) {
                null
            }
        }
        uploadRequirements(requirements)
    }

    fun resubmitUploadRequirementIos(requirement: ReinforcerUpload) = SuspendWrapper {
        // update requirement with actual file data
        requirement.bytes = try {
            // convert the file on iOS to a ByteArray
            Utils.fileToByteArray(requirement.filePath)
        } catch(t: Throwable) {
            null
        }
        resubmitRequirement(requirement)
    }

    fun updateRequirementIos(groupId: Int, requirementId: Int, status: String, feedback: String = "") = SuspendWrapper { updateRequirement(groupId, requirementId, status, feedback)}
    fun deleteRequirementIos(groupId: Int, requirementId: Int) = SuspendWrapper { deleteRequirement(groupId, requirementId) }
}