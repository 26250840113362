package components.modals.groups

import components.dashboard.DashboardLocalState
import components.forms.CreateOrEditGroupForm
import components.dashboard.DashboardProps
import components.modals.ModalType
import components.widgets.Modal
import kotlinx.browser.document
import kotlinx.css.*
import react.RBuilder
import styled.css
import styled.styledDiv
import styled.styledH2

fun RBuilder.editGroupModal(
  props: DashboardProps,
  state: DashboardLocalState
) {
  Modal(onClose = {
    props.showModal(false, null)
  }) {
    styledDiv {
      css {
        width = 100.pct
      }
      styledH2 {
        css {
          margin = "12px 0"
        }
        + "Update Class"
      }
      CreateOrEditGroupForm {
        attrs.dashboardProps = props
        attrs.isEditing = true
        attrs.selectedGroup = props.currentGroup
        attrs.onSubmit = { group, groupIconFile, currencyIconFile, coverPhotoFile, templateId ->
          props.editGroup(group, groupIconFile, currencyIconFile, coverPhotoFile)
          props.showModal(false, ModalType.CREATE_GROUP)
          val groupId = group.id
//          props.setSelectedGroup(groupId)
          document.location?.href = "/group/${groupId}/dashboard"
        }
        attrs.onDelete = { id ->
          props.showModal(true, ModalType.DELETE_GROUP)
//          props.deleteGroup(id)
//          props.showModal(false, null)
//          document.location?.href = "/profile"
        }
      }
    }
  }
}
