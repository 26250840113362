package com.jet.classroomhero.data

import com.jet.classroomhero.Logger
import com.jet.classroomhero.data.remote.sources.NetworkTemplateSource
import com.jet.classroomhero.entities.Template
import com.jet.classroomhero.entities.Tokens
import com.jet.classroomhero.entities.User
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.withContext

class TemplateRepository(
    private val localUserSource: LocalUserSource,
    private val localTemplateSource: LocalTemplateSource,
    private val remoteTemplateSource: RemoteTemplateSource = NetworkTemplateSource(localUserSource)
) {
    private val logger = Logger()

    // keep public, used by iOS
    val coroutineScope: CoroutineScope = MainScope()

    suspend fun getTemplates(forceFetch: Boolean): List<Template> = withContext(Dispatchers.Default) {
        logger.logMessage("CHDEBUG: TemplateRepository.getTemplates")
        if (!forceFetch) {
            val localTemplates = localTemplateSource.readTemplates()
            if (!localTemplates.isNullOrEmpty()) {
                return@withContext localTemplates
            }
        }
        val fetchedTemplates = remoteTemplateSource.fetchTemplates()
        withContext(Dispatchers.Default) {
            // Fire and forget
            localTemplateSource.saveTemplates(fetchedTemplates)
        }
        return@withContext fetchedTemplates
    }

    suspend fun applyTemplate(groupId: Int, templateId: Int): Boolean = withContext(Dispatchers.Default) {
        logger.logMessage("CHDEBUG: TemplateRepository.applyTemplate")
        return@withContext remoteTemplateSource.applyTemplate(groupId, templateId)
    }

}

interface LocalTemplateSource {
    suspend fun saveTemplates(templates: List<Template>)
    suspend fun saveTemplate(template: Template)
    suspend fun readTemplates(): List<Template>
}

interface RemoteTemplateSource {
    suspend fun fetchTemplates(): List<Template>
    suspend fun applyTemplate(groupId: Int, templateId: Int): Boolean
}