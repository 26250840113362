package components.widgets

import components.widgets.ModalCss.modal
import components.widgets.ModalCss.modalClose
import components.widgets.ModalCss.modelContent
import csstype.Border
import csstype.BorderStyle
import kotlinx.css.*
import kotlinx.css.properties.BoxShadow
import kotlinx.html.js.onClickFunction
import kotlinx.browser.window
import react.RBuilder
import styled.*

fun RBuilder.Modal(width:  LinearDimension = 75.pct, onClose: () -> Unit, content: RBuilder.() -> Unit) {
    styledDiv {
        css(modal)
        styledDiv {
            attrs.onClickFunction = {
                console.log("Stop prop")
                it.stopPropagation()
            }
            css(modelContent)
            css {
                this.width = width
            }
            styledI {
                css(modalClose)
                css {
                    classes.add("fa")
                    classes.add("fa-close")
                }
                attrs.onClickFunction = {
                    onClose()
                }
            }
            styledDiv {
                css {
                    display = Display.flex
                    alignItems = Align.center
                    flexDirection = FlexDirection.column
                    alignSelf = Align.center
                    this.width = 100.pct
                    textAlign = TextAlign.center
                }
                content()
            }
        }
    }
}

object ModalCss : StyleSheet("ModalCss", isStatic = true) {
    val modal by css {
        position = Position.fixed
        zIndex = 2
        paddingTop = 50.px
        left = 0.px
        top = 0.px
        width = 100.pct
        height = 100.pct
        overflow = Overflow.auto
        backgroundColor = rgba(0,0,0,0.25)
    }
    val modelContent by css {
        backgroundColor = Color("#fefefe")
        margin = "auto"
        padding = "20px"
        border = "1px solid #888;"
        maxHeight = 750.px
        overflow = Overflow.auto
        borderColor = Color.transparent
        borderRadius = 5.px
        boxShadow += BoxShadow(false, 0.px, 7.px, 29.px, 0.px, rgba(100, 100, 111, 0.2))
    }
    val modalClose by css {
        fontSize = 28.px
        color = Color("#aaaaaa")
        float = Float.right
        cursor = Cursor.pointer
    }
}