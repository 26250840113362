package com.jet.classroomhero.entities

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Item (
    @SerialName("item_id")
    var id: Int = 0,
    @SerialName("name")
    var name: String = "",
    @SerialName("cost")
    var cost: Int = 0,
    @SerialName("description")
    var description: String = ""
){constructor(
    name: String = "",
    cost: Int = 0
): this(0, name, cost)

}