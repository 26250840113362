package components.widgets

import com.jet.classroomhero.entities.UploadStatus
import kotlinx.css.Color
import react.RBuilder

fun RBuilder.StatusIcon(
  status: String
) {
  when(status) {
    UploadStatus.PENDING.status -> {
      return faIcon(
        faIcon = "fa-circle-exclamation",
        iconColor = Color("#F7CB73")
      )
    }
    UploadStatus.ACCEPTED.status -> {
      return faIcon(
        faIcon = "fa-check-circle",
        iconColor = Color("#4BB543")
      )
    }
    UploadStatus.REJECTED.status -> {
      return faIcon(
        faIcon = "fa-exclamation-triangle",
        iconColor = Color("#B00020")
      )
    }
  }
}