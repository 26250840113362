package components.member_dashboard

import com.jet.classroomhero.*
import com.jet.classroomhero.entities.*
import components.dashboard.*
import components.dashboard.DashboardRoute
import components.dashboard.achievements.achievements
import components.dashboard.items.items
import components.dashboard.members.MemberDetailComponent
import components.modals.ModalType
import components.modals.achievements.allAchievements
import components.modals.items.allTransactions
import components.modals.featureInfoModal
import components.widgets.*
import components.widgets.dropdowns.GroupsDropDownMenu
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.html.js.onClickFunction
import react.*
import styled.*
import utils.Urls

external interface MemberDashboardProps : DashboardProps {
  var fetchMembership: (groupId: Int, memberId: Int) -> Unit
}

external interface MemberDashboardLocalState : State {
  var selectedRoute: DashboardRoute?
  var selectedAchievement: Reinforcer?
  var selectedItem: Item?
}

@JsExport
class MemberDashboardComponent(props: MemberDashboardProps) : RComponent<MemberDashboardProps, MemberDashboardLocalState>(props) {

  override fun MemberDashboardLocalState.init(props: MemberDashboardProps) {
    selectedRoute = DashboardRoute.MEMBER
    selectedAchievement = null
    selectedItem = null
  }

  override fun componentDidMount() {
    val userToken = props.user.token ?: ""
    if (userToken.isEmpty()) {
      CoroutineScope(Dispatchers.Unconfined).launch {
        InjectorUtils.provideUserUseCases().logout()
        document.location?.href = UtilsCommon.getBaseUrlForBuildConfig() + "accounts/logout/"
      }
    }
    props.fetchMembership(Urls.getGroupIdFromUrl(), Urls.getMemberIdFromUrl())
  }

  override fun RBuilder.render() {
    styledDiv {
      attrs.onClickFunction = {
        if (props.modalShowing) {
          props.showModal(false, null)
        }
      }
      styledDiv {
        css(DashboardCss.header)
        css {
          paddingLeft = 24.px
          height = 72.px
        }
        styledSpan {
          attrs.onClickFunction = {
            window.location.href = "/profile"
          }
          css {
            alignSelf = Align.center
            hover {
              cursor = Cursor.pointer
            }
          }
          styledSpan {
            css {
              marginRight = 20.px
            }
            faIcon(
              faIcon = "fa-arrow-left",
              iconColor = Color.black
            )
          }

          + "Go back"
        }
        styledSpan {
          css {
            fontSize = 22.px
            alignSelf = Align.center
          }
          + "${state.selectedRoute?.label}"
        }
        styledDiv {
          css(DashboardCss.headerContent)
          GroupsDropDownMenu {
            attrs.currentGroup = props.currentGroup
            attrs.containerWidth = 220.px
          }
        }
      }
      if (props.selectedModalType == ModalType.INFO_MODAL_MEMBER_ACHIEVEMENTS) {
        featureInfoModal(
          FEATURE_INFO_MEMBER_VIEW_ACHIEVEMENTS_TITLE,
          listOf(
            FEATURE_INFO_MEMBER_VIEW_ACHIEVEMENTS_DESCRIPTION_PART_1,
            FEATURE_INFO_MEMBER_VIEW_ACHIEVEMENTS_DESCRIPTION_PART_2
          )
        ) {
          props.showModal(false, null)
        }
      }
      if (props.selectedModalType == ModalType.INFO_MODAL_MEMBER_MARKETPLACE) {
        featureInfoModal(
          FEATURE_INFO_MEMBER_VIEW_MARKETPLACE_TITLE,
          listOf(
            FEATURE_INFO_MEMBER_VIEW_MARKETPLACE_DESCRIPTION_PART_1,
            FEATURE_INFO_MEMBER_VIEW_MARKETPLACE_DESCRIPTION_PART_2
          )
        ) {
          props.showModal(false, null)
        }
      }
      styledDiv {
        attrs.onClickFunction = {
          //props.showDropdown(false)
        }
        css(DashboardCss.page)
        styledDiv {
          css(DashboardCss.sideBar)
          styledDiv {
            css {
              backgroundColor =
                getSelectedRouteColor(state.selectedRoute == DashboardRoute.MEMBER)
            }
            sideBarItem(
              title = "You",
              faIcon = "fa-user",
              iconColor = "#512DA8"
            ) {
              setState { selectedRoute = DashboardRoute.MEMBER }
            }
          }
          styledDiv {
            css {
              backgroundColor =
                getSelectedRouteColor(state.selectedRoute == DashboardRoute.ACHIEVEMENTS)
              paddingRight = 15.px
            }
            infoWidget(content = {
              sideBarItem(
                title = "Achievements",
                faIcon = "fa-star",
                iconColor = "#F57C00"
              ) {
                setState { selectedRoute = DashboardRoute.ACHIEVEMENTS }
              }
            }) {
              props.showModal(true, ModalType.INFO_MODAL_MEMBER_ACHIEVEMENTS)
            }
          }
          styledDiv {
            css {
              backgroundColor =
                getSelectedRouteColor(state.selectedRoute == DashboardRoute.MARKETPLACE)
              paddingRight = 15.px
            }
            infoWidget(content = {
              sideBarItem(
                title = "Marketplace",
                faIcon = "fa-shopping-bag",
                iconColor = "#00796b"
              ) {
                setState { selectedRoute = DashboardRoute.MARKETPLACE }
              }
            }) {
              props.showModal(true, ModalType.INFO_MODAL_MEMBER_MARKETPLACE)
            }
          }
        }
        styledDiv {
          css(DashboardCss.content)
          css {
            overflow = Overflow.scroll
          }
          // handle selected route
          when (state.selectedRoute) {
            DashboardRoute.MEMBER -> {
              //dashboardPhotos(props, false)
              styledDiv {
                css(DashboardCss.statsRow)
                css {
                  justifyContent = JustifyContent.left
                  marginTop = 48.px
                }
                styledP {
                  css {
                    fontSize = 22.px
                    marginBottom = 12.px
                    borderBottomColor = Color("#eee")
                    borderBottomWidth = 1.px
                    borderBottomStyle = BorderStyle.solid
                    width = 100.pct
                  }
                  + "Your Activity"
                }
              }
              if (props.selectedMember != null) {
                MemberDetailComponent {
                  attrs.parentProps = props
                  attrs.isManager = false
                  attrs.currentUploads = props.currentUploads
                  attrs.onActionSelected = { modalType ->
                    props.showModal(true, modalType)
                  }
                  attrs.linkedUser = if (props.user.id == props.selectedMember!!.linkedUser) props.user else null
                }
              }
            }
            DashboardRoute.ACHIEVEMENTS -> { achievements(props, false) }
            DashboardRoute.MARKETPLACE -> { items(props, false) }
            else -> {}
          }
        }
      }
      if (props.snackBarShowing) {
        SnackBar(
          show = props.snackBarShowing,
          label = props.snackBarMessage
        ) {
          // occurs after specified delay
          props.showSnackBar(false, "")
        }
      }
      if (props.modalShowing) {
        when (props.selectedModalType) {
          ModalType.ALL_ACHIEVEMENTS -> {
            allAchievements(props, props.selectedMember!!.achievements)
          }
          ModalType.ALL_TRANSACTIONS -> {
            allTransactions(props, props.selectedMember!!.transactions)
          }
          else -> {}
        }
      }
    }
//    SnackBar(
//      show = props.snackBarShowing,
//      label = props.snackBarMessage
//    ) {
//      // occurs after specified delay
//      props.showSnackBar(false, "")
//    }
  }
}
