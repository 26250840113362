package com.jet.classroomhero.entities

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class StockGroupMediaResponse(
    @SerialName("currency_icons")
    val currencyIcons: List<StockCurrencyIcon> = emptyList(),
    @SerialName("group_logos")
    val groupLogos: List<StockGroupLogo> = emptyList()
)

@Serializable
data class StockGroupLogo(
    val name: String = "",
    val logo: String
)

@Serializable
data class StockCurrencyIcon(
    val name: String = "",
    val icon: String
)