package components.modals.items

import com.jet.classroomhero.entities.Transaction
import components.dashboard.DashboardProps
import components.dashboard.members.recentItem
import components.modals.ModalType
import components.widgets.Modal
import kotlinx.css.*
import react.RBuilder
import styled.css
import styled.styledDiv
import styled.styledH3

fun RBuilder.allTransactions(props: DashboardProps, transactions: List<Transaction>) {
  Modal(onClose = {
    props.showModal(true, ModalType.MEMBER_DETAIL)
  }) {
    styledDiv {
      css {
        display = Display.flex
        alignItems = Align.center
        flexDirection = FlexDirection.column
      }
      styledH3 {
        css {
          marginBottom = 20.px
        }
        +"All Completed Achievements"
      }
    }
    transactions.sortedByDescending { it.createdAt }.forEach {
      recentItem(
        name = it.itemName,
        date = it.createdAt,
        amount = it.itemCost,
        currencyImgUrl = props.currentGroup!!.currencyIconUrl ?: "",
        isLoss = false
      )
    }
  }
}