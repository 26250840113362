package components.modals.achievements

import com.jet.classroomhero.combineNames
import components.dashboard.DashboardProps
import components.forms.GiveAchievementForm
import components.modals.ModalType
import components.widgets.Modal
import kotlinx.css.*
import react.RBuilder
import styled.css
import styled.styledDiv
import styled.styledH3

fun RBuilder.renderGiveAchievementModal(props: DashboardProps) {
  Modal(onClose = {
    props.showModal(true, ModalType.MEMBER_DETAIL)
  }) {
    styledDiv {
      css {
        display = Display.flex
        alignItems = Align.center
        flexDirection = FlexDirection.column
      }
      styledH3 {
        css {
          marginBottom = 20.px
        }
        + "Award ${props.selectedMember?.combineNames()}"
      }
      GiveAchievementForm {
        attrs.member = props.selectedMember!!
        attrs.achievements = props.currentGroup?.reinforcers ?: emptyList()
        attrs.group = props.currentGroup!!
        attrs.onBulkCompleteAchievement = props.bulkCompleteAchievement
        attrs.isLoading = props.isLoading
      }
    }
  }
}