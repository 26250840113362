package com.jet.classroomhero.data.remote.sources

import com.jet.classroomhero.Utils
import com.jet.classroomhero.data.LocalUserSource
import com.jet.classroomhero.data.remote.clients.AuthorizedClient

open class ProtectedNetworkSource(private var localUserSource: LocalUserSource) {
    private val _authorizedClient: AuthorizedClient = AuthorizedClient(localUserSource, NetworkUserSource(null))
    var client = _authorizedClient.httpClient
    private var _baseUrl: String = Utils.baseUrl
    var baseUrl = _baseUrl
}