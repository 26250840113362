package components.dashboard.items

import com.jet.classroomhero.combineNames
import com.jet.classroomhero.entities.Class
import com.jet.classroomhero.entities.Item
import components.dashboard.DashboardCss
import components.modals.ModalType
import components.profile.GroupCss.item
import components.widgets.*
import csstype.FontSynthesis.Companion.weight
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onSubmitFunction
import org.w3c.dom.HTMLInputElement
import react.*
import styled.*
import styles.GlobalCss
import utils.pluralizeIf

external interface ItemDetailComponentProps : Props {
  var group: Class?
  var item: Item
  var onShowModalType: (ModalType) -> Unit
  var onBulkSellItem: (List<Int>) -> Unit
  var isManager: Boolean
  var isLoading: Boolean
}

val ItemDetailComponent = fc<ItemDetailComponentProps> { props ->
  val (selectedIds, setSelectedIds) = useState<MutableList<Int>>(mutableListOf())

  styledDiv {
    attrs.id = "item-" + props.item.id.toString() + "-detail-container"
    css(DashboardCss.memberDetailContainer)
    if (props.isManager) {
      styledDiv {
        css {
          position = Position.absolute
          left = 25.px
          top = 25.px
        }
        Button(
          text = "Edit",
          faIcon = "fa-edit",
          bgColor = "#00796b"
        ) {
          props.onShowModalType(ModalType.EDIT_ITEM)
        }
      }
    }
    styledDiv {
      css(DashboardCss.memberInfoSection)
      styledDiv {
        css(GlobalCss.flexRow)
        css {
          alignItems = Align.center
          marginTop = 60.px
        }
        styledDiv {
          css(GlobalCss.flexColumn)
          css(GlobalCss.flexCenter)
          css {
            textAlign = TextAlign.center
            gap = 10.px
            flex(1.0, 1.0, FlexBasis.auto)
          }
          circularFaIcon(
            faIcon = "fa-shopping-bag",
            bgColor = Color("#00796b"),
            size = 45.px
          )
          styledDiv{
            css(GlobalCss.flexRow)
            css{
              alignItems = Align.center
              gap = 10.px
            }

            styledP {
              css {
                fontSize = 26.px
              }
              +"${props.item.name}"
            }

            valueAndCurrencyIcon(
              amount = props.item.cost,
              isLoss = true,
              currencyImgUrl = props.group!!.currencyIconUrl!!,
              iconSize = 30.px,
              amountSize = 28.px,
              amountSide = Side.LEFT
            )
          }


          if (props.item.description.isNotEmpty()) {
            styledDiv {
              css {
                marginTop = 12.px
                marginBottom = 12.px
              }
              styledP {
                css {
                  color = Color("#6d7175")
                  marginBottom = 6.px
                }
                + "Description"
              }
              styledP {
                css {
                  fontSize = 18.px
                }
                + props.item.description
              }
            }
          }
        }
      }
    }
    if (props.isManager) {
      sellItemsView(props, selectedIds, setSelectedIds)
    }
  }
}

private fun RBuilder.sellItemsView(
  props: ItemDetailComponentProps,
  selectedIds: MutableList<Int>,
  setSelectedIds: StateSetter<MutableList<Int>>
) {
  styledForm {
    css(GlobalCss.flexCenter)
    css {
      marginTop = 18.px
      marginBottom = 18.px
      backgroundColor = Color.white
      padding = "12px 120px"
    }
    attrs.onSubmitFunction = {
      it.preventDefault()
    }
    if (selectedIds.size > 0 || props.isLoading) {
      styledDiv{
        css{
          display = Display.flex
          alignItems = Align.center
          justifyContent = JustifyContent.center
          flexDirection = FlexDirection.row
        }
        Button(
          customCss = {
            maxWidth = 280.px
            alignSelf = Align.center
            marginTop = 24.px
            marginBottom = 24.px
          },
          loading = props.isLoading,
          text = if (!props.isLoading) "Sell to ${selectedIds.size} ${"Member".pluralizeIf { selectedIds.size > 1 }}" else "Selling..."
        ) { event ->
          event.preventDefault()
          props.onBulkSellItem(selectedIds)
          setSelectedIds(mutableListOf())
        }
      }

    }
    props.group?.let { group ->
      for (member in group.students.sortedByDescending { it.currentCoins }) {
        RadioButton(
          isSelected = selectedIds.contains(member.id),
          disabled = member.currentCoins < props.item.cost,
          disabledMessage = "Not enough currency.",
          labelContent = {
            styledDiv {
              attrs.id = "sell-item-${props.item.id}-member-${member.id}-label"
              css(GlobalCss.flexRow)
              styledSpan {
                css {
                  marginRight = 8.px
                  whiteSpace = WhiteSpace.pre
                  color = if (member.currentCoins < props.item.cost) Color("#ccc") else Color("#000")
                }
                + member.combineNames()
              }
            }
          },
          onPress = { isSelected ->
            val currentIds = selectedIds.toMutableList()
            if (isSelected) {
              currentIds.add(member.id)
            } else {
              currentIds.remove(member.id)
            }
            setSelectedIds(currentIds)
          }
        )
      }
    }
  }
}