package com.jet.classroomhero.data

import com.jet.classroomhero.Logger
import com.jet.classroomhero.data.remote.sources.NetworkStudentSource
import com.jet.classroomhero.entities.*
import kotlinx.coroutines.*

class StudentRepository(
    private val localUserSource: LocalUserSource,
    private val localStudentSource: LocalStudentSource,
    private val localAchievementSource: LocalAchievementSource,
    private val localTransactionSource: LocalTransactionSource,
    private val remoteStudentSource: RemoteStudentSource = NetworkStudentSource(localUserSource)
) {
    val logger = Logger()
    val coroutineScope: CoroutineScope = MainScope()

    init {
        logger.logMessage("CHDEBUG: StudentRepository.init")
    }

    companion object {
        private val TAG = StudentRepository::class.simpleName
    }

    suspend fun addStudent(newStudent: Student, classId: Int): Student? {
        logger.logMessage("$TAG createStudent")
        val creatingStudent = coroutineScope.async {
            //val nameParts = newStudent.splitName()
            val student = Student(name = newStudent.name)
            return@async remoteStudentSource.createStudent(student, classId)
        }
        val createdStudent = creatingStudent.await()
        createdStudent?.let {
            saveStudent(it, classId)
            return it
        }
        return createdStudent
    }

    suspend fun bulkAddStudents(newStudents: List<Student>, classId: Int): List<Student> {
        val creatingStudents = coroutineScope.async {
            //val nameParts = newStudent.splitName()
            return@async remoteStudentSource.bulkCreateStudents(newStudents, classId)
        }
        val createdStudents = creatingStudents.await()
        createdStudents.let {
            saveStudents(it, classId)
            return it
        }
    }

    suspend fun editStudent(updatedMemberBody: Student, classId: Int): Student? {
        logger.logMessage("$TAG editStudent")
        val updatingStudent = coroutineScope.async {
            return@async remoteStudentSource.editStudent(updatedMemberBody, classId)
        }
        val updated = updatingStudent.await()
        updated.let {
            // update student instead
            updateStudent(it, classId)
            return it
        }
        return null
    }

    suspend fun deleteStudent(studentId: Int, classId: Int): Boolean {
        logger.logMessage("$TAG deleteStudent")
        val deletingStudent = coroutineScope.async {
            return@async remoteStudentSource.deleteStudent(studentId, classId)
        }
        val deleted = deletingStudent.await()
        logger.logMessage("$TAG was deleted $deleted")
        if (deleted) {
            withContext(Dispatchers.Default) {
                localStudentSource.deleteStudent(studentId)
            }
            return true
        }
        return false
    }

    suspend fun getStudents(forceFetch: Boolean = false, classId: Int): List<Student> {
        logger.logMessage("$TAG getStudents($forceFetch)")
        if (!forceFetch) {
            logger.logMessage("$TAG reading members locally")
            val localStudents = readStudents(classId)
            if (!localStudents.isNullOrEmpty()) {
                return localStudents
            }
        }
        logger.logMessage("$TAG fetching members remotely")
        return try {
            val students = remoteStudentSource.fetchStudents(classId)
            saveStudents(students, classId)
            students
        } catch (e: Exception) {
            logger.logMessage("$TAG Error fetching students")
            emptyList()
        }
    }

    suspend fun getStudent(forceFetch: Boolean = false, classId: Int, memberId: Int): Student? {
        logger.logMessage("$TAG getStudent($memberId)")
        if (!forceFetch) {
            logger.logMessage("$TAG reading member locally")
            val member = readStudent(memberId)
            // if we don't have achievements or transactions locally, just trying fetching
            // from the server
            if (member == null || member.achievements.isNotEmpty() || member.transactions.isNotEmpty()) {
                return member
            }
        }
        logger.logMessage("$TAG fetching member remotely")
        val fetchedStudent = remoteStudentSource.fetchStudent(classId, memberId)
        saveStudent(fetchedStudent, classId)
        return fetchedStudent
    }

    suspend fun completeAchievement(classId: Int, memberId: Int, achievementId: Int): Student {
        logger.logMessage("CHDEBUG: StudentRepository.completeAchievement")
        val updatedStudent = remoteStudentSource.completeAchievement(classId, memberId, achievementId)
        val achievements = updatedStudent.achievements.sortedByDescending { it.createdAt }
        val lastAchievement = achievements.first()
        localAchievementSource.saveAchievement(lastAchievement)
        updateStudent(updatedStudent, classId)
        // we should also update the class progress level

        return readStudent(updatedStudent.id)
    }

    suspend fun bulkCreateAchievement(classId: Int, achievementName: String, achievementValue: Int, memberIds: List<Int>): List<Student> {
        logger.logMessage("CHDEBUG: StudentRepository.completeAchievement")
        val updatedStudents = remoteStudentSource.bulkCreateAchievement(classId, memberIds, achievementName, achievementValue)
        for (updatedStudent in updatedStudents){
            val achievements = updatedStudent.achievements.sortedByDescending { it.createdAt }
            val lastAchievement = achievements.first()
            withContext(Dispatchers.Default) {
                localAchievementSource.saveAchievement(lastAchievement)
            }
            updateStudent(updatedStudent, classId)
        }
        val ids = updatedStudents.map { it.id }
        return readStudents(ids.toList())
    }

    suspend fun bulkCompleteAchievement(classId: Int, memberIds: List<Int>, achievementId: Int): List<Student> {
        logger.logMessage("CHDEBUG: StudentRepository.bulkCompleteAchievement")
        val updatedStudents =
            remoteStudentSource.bulkCompleteAchievement(classId, memberIds, achievementId)

        for (updatedStudent in updatedStudents){
            val achievements = updatedStudent.achievements.sortedByDescending { it.createdAt }
            val lastAchievement = achievements.first()
            withContext(Dispatchers.Default) {
                localAchievementSource.saveAchievement(lastAchievement)
            }
            updateStudent(updatedStudent, classId)

        }
        val ids = updatedStudents.map { it.id }
        return readStudents(ids.toList())

    }

    suspend fun sellItem(classId: Int, memberId: Int, itemId: Int): Student {
        logger.logMessage("CHDEBUG: StudentRepository.sellItem")
        val updatedStudent = remoteStudentSource.sellItem(classId, memberId, itemId)
        val transactions = updatedStudent.transactions.sortedByDescending { it.createdAt }
        val lastTransaction = transactions.first()
        withContext(Dispatchers.Default) {
            localTransactionSource.saveTransaction(lastTransaction)
        }
        updateStudent(updatedStudent, classId)

        return readStudent(updatedStudent.id)
    }

    @Throws(Exception::class)
    suspend fun bulkSellItem(classId: Int, memberIds: List<Int>, itemId: Int): List<Student> {
        logger.logMessage("CHDEBUG: StudentRepository.bulkSellItem")
        val updatedStudents = remoteStudentSource.bulkSellItem(classId, memberIds, itemId)
        for (updatedStudent in updatedStudents){
            val transactions = updatedStudent.transactions.sortedByDescending { it.createdAt }
            val lastTransaction = transactions.first()
            withContext(Dispatchers.Default) {
                localTransactionSource.saveTransaction(lastTransaction)
            }
            updateStudent(updatedStudent, classId)
        }
        val ids = updatedStudents.map { it.id }
        return readStudents(ids.toList())
    }

    suspend fun deleteAchievement(classId: Int, memberId: Int, achievementId: Int): Student {
        logger.logMessage("CHDEBUG: StudentRepository.deleteAchievement")
        val updatedStudent = remoteStudentSource.deleteAchievement(classId, memberId, achievementId)
        deleteAchievement(achievementId)
        updateStudent(updatedStudent, classId)
        return readStudent(updatedStudent.id)
    }

    suspend fun deleteTransaction(classId: Int, memberId: Int, transactionId: Int): Student {
        logger.logMessage("CHDEBUG: StudentRepository.deleteTransaction")
        val updatedStudent = remoteStudentSource.deleteTransaction(classId, memberId, transactionId)
        deleteTransaction(transactionId)
        updateStudent(updatedStudent, classId)
        return readStudent(updatedStudent.id)
    }

    suspend fun addCurrency(classId: Int, memberId: Int, amountToAdd: Int): Student {
        logger.logMessage("CHDEBUG: StudentRepository.addCurrency")
        val updatedStudent = remoteStudentSource.addCurrency(classId, memberId, amountToAdd)
        updateStudent(updatedStudent, classId)
        return readStudent(updatedStudent.id)
    }

    suspend fun removeCurrency(classId: Int, memberId: Int, amountToRemove: Int): Student {
        logger.logMessage("CHDEBUG: StudentRepository.removeCurrency")
        val updatedStudent = remoteStudentSource.subtractCurrency(classId, memberId, amountToRemove)
        updateStudent(updatedStudent, classId)
        return readStudent(updatedStudent.id)
    }

    suspend fun updateProfilePhoto(memberId: Int, byteArray: ByteArray) {
        val response: ImageUploadResponse = remoteStudentSource.updateProfilePhoto(memberId, byteArray)
        logger.logMessage("$TAG final url: ${response.uploadedImageUrl}")
        withContext(Dispatchers.Default) {
            if (response.uploadedImageUrl.isNotEmpty()) {
                localStudentSource.savePhotoUrl(memberId, response.uploadedImageUrl)
            }
        }
    }

    suspend fun unlinkStudent(studentId: Int): Boolean{
        logger.logMessage("$TAG unlinkStudent")
        val updatedStudent = remoteStudentSource.unlinkStudent(studentId)
        withContext(Dispatchers.Default) {
            localStudentSource.unlinkStudent(studentId)
        }
        return updatedStudent.linkedUser == 0
    }

    private suspend fun saveStudent(member: Student, classId: Int) = withContext(Dispatchers.Default) {
        // save achievements and transactions
        localStudentSource.saveStudent(member, classId)

        logger.logMessage("CHDEBUG: SAVING ACHIEVEMENTS ${member.achievements.size}")
        member.achievements.forEach { localAchievementSource.saveAchievement(it) }
        logger.logMessage("CHDEBUG: SAVING TRANSACTIONS ${member.transactions.size}")
        member.transactions.forEach { localTransactionSource.saveTransaction(it) }

        return@withContext
    }

    private suspend fun updateStudent(member: Student, classId: Int) = withContext(Dispatchers.Default) {
        localStudentSource.saveStudent(member, classId)
        return@withContext
    }

    private suspend fun saveStudents(members: List<Student>, classId: Int) = withContext(Dispatchers.Default) {
        members.forEach { member -> saveStudent(member, classId) }
    }

    private suspend fun deleteAchievement(achievementId: Int) = withContext(Dispatchers.Default){
        localAchievementSource.deleteAchievement(achievementId)
    }

    private suspend fun deleteTransaction(transactionId: Int) = withContext(Dispatchers.Default){
        localTransactionSource.deleteTransaction(transactionId)
    }

    private suspend fun readStudent(memberId: Int) = withContext(Dispatchers.Default) {
        val member = localStudentSource.readStudent(memberId)
        decorateMember(member)
        return@withContext member
    }
    
    private suspend fun readStudents(classId: Int) = withContext(Dispatchers.Default) {
        val readMembers = localStudentSource.readStudents(classId)
        decorateMembers(readMembers)
        return@withContext readMembers
    }

    private suspend fun readStudents(memberIds: List<Int>) = withContext(Dispatchers.Default) {
        val readMembers = localStudentSource.readStudents(memberIds)
        decorateMembers(readMembers)
        return@withContext readMembers
    }

    private suspend fun decorateMembers(members: List<Student>) {
        members.forEach { decorateMember(it) }
    }

    private suspend fun decorateMember(member: Student) {
        logger.logMessage("CHDEBUG: StudentRepository.decorateMember")
        member.apply {
            logger.logMessage("CHDEBUG: Decorating achievements")
            achievements = localAchievementSource.readMemberAchievements(id)
            logger.logMessage("CHDEBUG: Decorating transactions")
            transactions = localTransactionSource.readMemberTransactions(id)
        }
    }

}

interface LocalStudentSource {
    suspend fun saveStudent(newStudent: Student, classId: Int)
    suspend fun saveStudents(students: List<Student>, classId: Int)
    suspend fun readStudent(memberId: Int): Student
    suspend fun readStudents(classId: Int): List<Student>
    suspend fun readStudents(memberIds: List<Int>): List<Student>
    suspend fun deleteStudent(studentId: Int): Int
    suspend fun savePhotoUrl(memberId: Int, photoUrl: String)
    suspend fun deleteGroupStudents(groupId: Int)
    suspend fun unlinkStudent(studentId: Int)
}

interface RemoteStudentSource {
    @Throws(Exception::class)
    suspend fun createStudent(newStudent: Student, classId: Int): Student
    suspend fun bulkCreateStudents(newStudents: List<Student>, classId: Int): List<Student>
    suspend fun editStudent(updatedStudent: Student, classId: Int): Student
    suspend fun deleteStudent(studentId: Int, classId: Int): Boolean
    suspend fun fetchStudents(classId: Int): List<Student>
    suspend fun fetchStudent(classId: Int, memberId: Int): Student
    suspend fun completeAchievement(classId: Int, memberId: Int, achievementId: Int): Student
    suspend fun deleteAchievement(classId: Int, memberId: Int, achievementId: Int): Student
    suspend fun deleteTransaction(classId: Int, memberId: Int, transactionId: Int): Student
    suspend fun bulkCreateAchievement(classId: Int, memberIds: List<Int>, achievementName: String, achievementValue: Int): List<Student>
    suspend fun bulkCompleteAchievement(classId: Int, memberIds: List<Int>, achievementId: Int): List<Student>
    suspend fun sellItem(classId: Int, memberId: Int, itemId: Int): Student
    @Throws(Exception::class)
    suspend fun bulkSellItem(classId: Int, memberIds: List<Int>, itemId: Int): List<Student>
    suspend fun addCurrency(classId: Int, memberId: Int, amountToAdd: Int): Student
    suspend fun subtractCurrency(classId: Int, memberId: Int, amountToSubtract: Int): Student
    suspend fun updateProfilePhoto(memberId: Int, bytes: ByteArray): ImageUploadResponse
    suspend fun unlinkStudent(studentId: Int): Student
}