package containers

import actions.AchievementActions
import actions.AppStateActions
import actions.GroupActions
import components.member_dashboard.MemberDashboardComponent
import components.member_dashboard.MemberDashboardProps
import react.ComponentClass
import react.Props
import react.invoke
import react.redux.rConnect
import redux.RAction
import redux.WrapperAction
import store.AppState

external interface MemberDashboardStateProps : DashboardStateProps {

}

external interface MemberDashboardDispatcherProps : DashboardDispatcherProps {
  var fetchMembership: (groupId: Int, memberId: Int) -> Unit

}

val memberDashboardComponent: ComponentClass<Props> = rConnect<AppState, RAction, WrapperAction, Props, MemberDashboardStateProps, MemberDashboardDispatcherProps, MemberDashboardProps>(
  { state, _ ->
    user = state.accountState.user
    currentGroup = state.appState.currentGroup
    selectedMember = state.appState.selectedMember
    modalShowing = state.appState.modalShowing
    snackBarShowing = state.appState.snackBarShowing
    snackBarMessage = state.appState.snackBarMessage
    selectedModalType = state.appState.selectedModalType
    selectedAchievement = state.appState.selectedAchievement
    selectedItem = state.appState.selectedItem
    currentUploads = state.groups.uploads
  },
  { dispatch, _ ->
    fetchMembership = { groupId, memberId -> dispatch(GroupActions.fetchMembership(groupId, memberId)) }
    showSnackBar = { show, message -> dispatch(AppStateActions.SetShowSnackBar(show, message)) }
    showModal = { show, modalType -> dispatch(AppStateActions.SetModalShowing(show, modalType)) }

    setSelectedGroup = { groupId -> dispatch(AppStateActions.setSelectedGroup(groupId)) }
    setSelectedAchievement = { achievement -> dispatch(AppStateActions.SetSelectedAchievement(achievement)) }
    setSelectedMember = { member -> dispatch(AppStateActions.SetSelectedMember(member)) }
    setSelectedItem = { item -> dispatch(AppStateActions.SetSelectedItem(item)) }

    uploadRequirements = { requirements -> dispatch(AchievementActions.uploadAchievementRequirements(requirements)) }
    deleteRequirement = { groupId, uploadId -> dispatch(AchievementActions.deleteRequirement(groupId, uploadId)) }
  }
)(MemberDashboardComponent::class.js.unsafeCast<ComponentClass<MemberDashboardProps>>())