package components.dashboard.items

import components.dashboard.DashboardProps
import components.modals.ModalType
import components.modals.items.renderCreateOrEditItemModal
import components.modals.items.renderItemDetailModal
import kotlinx.css.*
import kotlinx.html.id
import react.RBuilder
import styled.css
import styled.styledDiv

fun RBuilder.items(props: DashboardProps, isManager: Boolean) {
  val group = props.currentGroup
  if (group != null) {
    styledDiv {
      attrs.id = group.id.toString() + "-item-list"

      css {
        boxSizing = BoxSizing.borderBox
        display = Display.flex
        alignItems = Align.flexStart
        justifyContent = JustifyContent.flexStart
        height = LinearDimension.auto
        maxWidth = 100.pct
        margin = "0"
        padding = "0"
      }
      /* ACHIEVEMENT LIST COMPONENT */
      ItemListComponent {
        attrs.items = group.items
        attrs.selectedItem = props.selectedItem
        attrs.currentGroup = group
        attrs.onPressItem = { item ->
          if (props.selectedItem?.id == item.id) {
            props.setSelectedItem(null)
          } else {
            props.showModal(true, ModalType.ITEM_DETAIL)
            props.setSelectedItem(item)
          }
        }
        attrs.onShowModalType = { modalType ->
          props.showModal(true, modalType)
        }
        attrs.isManager = isManager
      }
//      if (props.selectedItem != null) {
//        /* ACHIEVEMENT DETAIL COMPONdENT */
//        ItemDetailComponent {
//          attrs.item = props.selectedItem!!
//          attrs.group = props.currentGroup
//          attrs.onShowModalType = { action ->
//            props.showModal(true, action)
//          }
//          attrs.onBulkSellItem = { memberIds ->
//            props.bulkSellItem(
//              memberIds,
//              group.id,
//              props.selectedItem!!.id
//            )
//          }
//          attrs.isLoading = props.isLoading
//          attrs.isManager = isManager
//        }
//      }
      if (props.modalShowing) {
        when (props.selectedModalType) {
          ModalType.CREATE_ITEM -> { renderCreateOrEditItemModal(props, null) }
          ModalType.EDIT_ITEM -> { renderCreateOrEditItemModal(props, props.selectedItem) }
          ModalType.ITEM_DETAIL -> { renderItemDetailModal(props, isManager, ) }
          else -> {}
        }
      }
    }
  }
}