package components.widgets

import components.forms.achievementTypes
import kotlinx.css.*
import react.RBuilder
import styled.css
import styled.styledDiv
import styled.styledSpan

fun RBuilder.achievementTypeWidget(type: String) {
  styledDiv {
    css {
      display = Display.flex
      flexDirection = FlexDirection.row
      alignItems = Align.center
    }
    faIcon(
      faIcon = if (type == "inperson") "fa-times" else "fa-check",
      iconColor = Color(if (type == "inperson") "#B00020" else "#4BB543")
    )
    styledSpan {
      css {
        marginLeft = 6.px
      }
      + (if (type == "inperson") "No" else "Yes")
    }
  }
}