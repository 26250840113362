package sources.local

import com.jet.classroomhero.data.LocalItemSource
import com.jet.classroomhero.entities.Item

class CacheItemSource : LocalItemSource {
    override suspend fun saveItem(item: Item, classId: Int) {}
    override suspend fun saveItems(items: List<Item>, classId: Int) {}
    override suspend fun deleteItem(itemId: Int): Int { return 0 }
    override suspend fun readItems(classId: Int): List<Item> { return emptyList() }
    override suspend fun readItem(itemId: Int): Item {
        return Item()
    }
    override suspend fun deleteGroupItems(groupId: Int) {}
}