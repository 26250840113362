package actions

import InjectorUtils
import com.jet.classroomhero.entities.Achievement
import com.jet.classroomhero.entities.Reinforcer
import com.jet.classroomhero.entities.ReinforcerUpload
import csstype.HtmlAttributes
import io.ktor.http.ContentType.Text.Html
import react.dom.aria.AriaRole
import redux.RAction
import store.RThunk
import utils.Request

private val reinforcerUseCases = InjectorUtils.provideReinforcerUseCases()
private val achievementUseCases = InjectorUtils.provideAchievementUseCases()

object AchievementActions {

  data class AchievementState(
    val completedAchievements: List<Achievement> = emptyList()
  )

  fun createAchievement(reinforcer: Reinforcer, groupId: Int): RThunk = createAchievementRequest(reinforcer, groupId)
  fun editAchievement(reinforcer: Reinforcer, groupId: Int): RThunk = editAchievementRequest(reinforcer, groupId)
  fun deleteAchievement(reinforcerId: Int, groupId: Int): RThunk = deleteAchievementRequest(reinforcerId, groupId)
  fun uploadAchievementRequirements(requirements: List<ReinforcerUpload>) = uploadRequirementsRequest(requirements)
  fun getAchievementRequirements(groupId: Int) = getAchievementRequirementsRequest(groupId)
  fun deleteRequirement(groupId: Int, uploadId: Int) = deleteRequirementRequest(groupId, uploadId)
  fun updateRequirement(groupId: Int, uploadId: Int, status: String, feedback: String?) =
    updateRequirementRequest(groupId, uploadId, status, feedback)
  fun getCompletedAchievementsForUser() = getCompletedAchievementsForUserRequest()

  fun setAchievementSeen(achievementId: Int) = setAchievementSeenRequest(achievementId)

  data class CreateAchievement(val reinforcer: Reinforcer) : RAction
  data class EditAchievement(val reinforcer: Reinforcer) : RAction
  data class DeleteAchievement(val reinforcerId: Int) : RAction
  data class UpdateCurrentUploads(val uploads: List<ReinforcerUpload>, val reset: Boolean): RAction
  data class RemoveUpload(val uploadId: Int): RAction
  data class UpdateCompletedAchievements(val achievements: List<Achievement>): RAction

  data class SetAchievementSeen(val achievementId: Int): RAction

  fun reducer(achievementState: AchievementState = AchievementState(), action: RAction): AchievementState {
    return when (action) {
      is UpdateCompletedAchievements -> achievementState.copy(completedAchievements = action.achievements)
      is SetAchievementSeen -> {
        val updatedAchievements = achievementState.completedAchievements.map { achievement ->
          if (achievement.id == action.achievementId) {
            achievement.copy(
              seen = true
            )
          } else {
            achievement
          }
        }
        achievementState.copy(completedAchievements = updatedAchievements)
      }
      else -> achievementState
    }
  }
}

private val createAchievementRequest: (reinforcer: Reinforcer, groupId: Int) -> RThunk = { reinforcer, groupId ->
  Request(
    doInBackground = { dispatch ->
      reinforcerUseCases.createReinforcer(reinforcer, groupId)?.let {
        dispatch(AchievementActions.CreateAchievement(it))
//        dispatch(AppStateActions.SetSelectedAchievement(null))
      }
    },
    onError = { message, dispatch -> dispatch(MemberActions.Error(message)) },
    onSuccess = { dispatch ->
      AppStateActions.SetLoading(false)
      dispatch(AppStateActions.SetModalShowing(false, null))
    }
  )
}

private val editAchievementRequest: (reinforcer: Reinforcer, groupId: Int) -> RThunk = { reinforcer, groupId ->
  Request(
    doInBackground = { dispatch ->
      reinforcerUseCases.editReinforcer(reinforcer, groupId)?.let { updatedReinforcer ->
        dispatch(AchievementActions.EditAchievement(updatedReinforcer))
        dispatch(AppStateActions.SetSelectedAchievement(null))
      }
    },
    onError = { message, dispatch -> dispatch(MemberActions.Error(message)) },
    onSuccess = { dispatch ->
      dispatch(AppStateActions.SetModalShowing(false, null))
    }
  )
}

private val deleteAchievementRequest: (reinforcerId: Int, groupId: Int) -> RThunk = { reinforcerId, groupId ->
  Request(
    doInBackground = { dispatch ->
      val success = reinforcerUseCases.deleteReinforcer(reinforcerId, groupId)
      if (success) {
        dispatch(AchievementActions.DeleteAchievement(reinforcerId))
      } else {
        throw Exception("An error occurred deleting this member")
      }
    },
    onError = { message, dispatch -> dispatch(MemberActions.Error(message)) },
    onSuccess = { dispatch ->
      dispatch(AppStateActions.SetModalShowing(false, null))
    }
  )
}

private val getCompletedAchievementsForUserRequest: () -> RThunk = {
  Request(
    doInBackground = { dispatch ->
      val response = achievementUseCases.fetchUserAchievements(
        forceFetch = true,
        pageSize = 100
      )
      dispatch(AchievementActions.UpdateCompletedAchievements(response.results))
    },
    onError = { message, dispatch ->

    },
    onSuccess = { dispatch ->

    }
  )
}

private val uploadRequirementsRequest: (requirements: List<ReinforcerUpload>) -> RThunk = { requirements ->
  Request(
    doInBackground = { dispatch ->
      val uploads = reinforcerUseCases.uploadRequirements(requirements)
      dispatch(AchievementActions.UpdateCurrentUploads(uploads, false))
    },
    onError = { message, dispatch -> dispatch(MemberActions.Error(message)) },
    onSuccess = { dispatch ->
      dispatch(MemberActions.SetIsLoading(false))
      dispatch(AppStateActions.SetModalShowing(false, null))
      dispatch(AppStateActions.SetShowSnackBar(
        show = true,
        message = "Successfully uploaded ${requirements.size} files")
      )
    }
  )
}

private val getAchievementRequirementsRequest: (groupId: Int) -> RThunk = { groupId ->
  Request(
    doInBackground = { dispatch ->
      val uploads = reinforcerUseCases.getRequirements(groupId)
      dispatch(AchievementActions.UpdateCurrentUploads(uploads, true))
    },
    onError = { message, dispatch -> dispatch(MemberActions.Error(message)) },
    onSuccess = { dispatch ->
      dispatch(MemberActions.SetIsLoading(false))
      dispatch(AppStateActions.SetModalShowing(false, null))
    }
  )
}

private val deleteRequirementRequest: (groupId: Int, uploadId: Int) -> RThunk = { groupId, uploadId ->
  Request(
    doInBackground = { dispatch ->
      val success = reinforcerUseCases.deleteRequirement(groupId, uploadId)
      if (success) {
        dispatch(AchievementActions.RemoveUpload(uploadId))
      }
    },
    onError = { message, dispatch -> dispatch(MemberActions.Error(message)) },
    onSuccess = { dispatch ->
      dispatch(MemberActions.SetIsLoading(false))
      dispatch(AppStateActions.SetModalShowing(false, null))
    }
  )
}

private val updateRequirementRequest: (groupId: Int, uploadId: Int, status: String, feedback: String?) -> RThunk = { groupId, uploadId, status, feedback ->
  Request(
    doInBackground = { dispatch ->
      val upload = reinforcerUseCases.updateRequirement(groupId, uploadId, status, feedback)
      dispatch(AchievementActions.RemoveUpload(uploadId))
    },
    onError = { message, dispatch -> dispatch(MemberActions.Error(message)) },
    onSuccess = { dispatch ->
      dispatch(MemberActions.SetIsLoading(false))
      dispatch(AppStateActions.SetModalShowing(false, null))
    }
  )
}

private val setAchievementSeenRequest: (achievementId: Int) -> RThunk = { achievementId ->
  Request(
    doInBackground = { dispatch ->
      console.log("setAchievementSeenRequest")
      achievementUseCases.setAchievementSeen(achievementId)
    },
    onError = { message, dispatch ->
      dispatch(AchievementActions.SetAchievementSeen(achievementId))
    },
    onSuccess = { dispatch ->
      dispatch(AchievementActions.SetAchievementSeen(achievementId))
    }
  )
}