package components.modals.items

import com.jet.classroomhero.combineNames
import components.dashboard.DashboardProps
import components.forms.SellItemForm
import components.modals.ModalType
import components.widgets.Modal
import kotlinx.css.*
import react.RBuilder
import styled.css
import styled.styledDiv
import styled.styledH3

fun RBuilder.renderSellItemModal(props: DashboardProps) {
  Modal(onClose = {
    props.showModal(true, ModalType.MEMBER_DETAIL)
  }) {
    styledDiv {
      css {
        display = Display.flex
        alignItems = Align.center
        flexDirection = FlexDirection.column
      }
      styledH3 {
        css {
          marginBottom = 20.px
        }
        + "Sell an item to ${props.selectedMember?.combineNames()}"
      }
      SellItemForm {
        attrs.member = props.selectedMember!!
        attrs.items = props.currentGroup?.items ?: emptyList()
        attrs.group = props.currentGroup!!
        attrs.onBulkSellItem = props.bulkSellItem
        attrs.isLoading = props.isLoading
      }
    }
  }
}