package components.profile

import com.jet.classroomhero.*
import com.jet.classroomhero.entities.*
import components.dashboard.*
import components.modals.ModalType
import components.modals.groups.createGroupModal
import components.modals.featureInfoModal
import components.modals.groups.joinGroupModal
import components.modals.groups.deleteGroupModal
import components.modals.user.RoleSelectComponent
import components.profile.GroupCss.card
import components.profile.GroupCss.cardBottom
import components.profile.GroupCss.cardTop
import components.widgets.*
import kotlinx.css.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.css.properties.TextDecoration
import kotlinx.html.js.onClickFunction
import react.*
import styled.*
import styles.GlobalCss
import styles.GlobalCss.flexCenter
import styles.GlobalCss.flexColumn
import styles.GlobalCss.flexRow

external interface ProfileProps : Props {
    // state variables
    var user: User
    var groups: List<Class>
    var memberships: List<Class>
    var isEditing: Boolean
    var isLoading: Boolean
    var modalShowing: Boolean
    var selectedGroup: Class?
    var stockMedia: StockGroupMediaResponse?
    var templates: List<Template>
    // dispatch methods
    var showModal: (Boolean, ModalType?) -> Unit
    var selectedModalType: ModalType?
    var setEditing: (Boolean) -> Unit
    var setSelectedGroup: (groupId: Int) -> Unit
    var setSelectedMembership: (groupId: Int, memberId: Int) -> Unit
    var createGroup: (Class, ByteArray?, ByteArray?, ByteArray?, Int?) -> Unit
    var joinGroup: (String) -> Unit
    var editGroup: (Class, ByteArray?, ByteArray?, ByteArray?) -> Unit
    var deleteGroup: (Int) -> Unit
    var setAchievementSeen: (Int) -> Unit
    var populateProfile: () -> Unit
    var isGlobalLoading: Boolean
    var editRole: (String) -> Unit
}

external interface ProfileLocalState : State {
    var selectedGroup: Class?
    var selectedRoute: ProfileRoute?
    var selectedProfileTab: ProfileTab?
    var pagePressed: Boolean
}

enum class ProfileRoute(var label: String = "") {
    YOUR_PROFILE("Your Profile")
}

enum class ProfileTab(
    var label: String,
    var icon: String
) {
    YOUR_GROUPS("Memberships", "fa-users"),
    ACHIEVEMENTS("Completed Achievements", "fa-star"),
    MANAGE_GROUPS("Manage Classes", "fa-edit");

    fun labelToUrlHash(): String = label.replace(" ", "")
}

fun getSelectedTabFromHash(): ProfileTab {
    if (window.location.hash.isNullOrEmpty()) {
        return ProfileTab.MANAGE_GROUPS
    }
    return when(window.location.hash.replace("#", "")) {
        ProfileTab.YOUR_GROUPS.labelToUrlHash() -> {
            ProfileTab.YOUR_GROUPS
        }
        ProfileTab.ACHIEVEMENTS.labelToUrlHash() -> {
            ProfileTab.ACHIEVEMENTS
        }
        ProfileTab.MANAGE_GROUPS.labelToUrlHash() -> {
            ProfileTab.MANAGE_GROUPS
        }
        else -> {
            ProfileTab.MANAGE_GROUPS
        }
    }
}

@JsExport
class ProfileComponent(props: ProfileProps) : RComponent<ProfileProps, ProfileLocalState>(props) {

    override fun ProfileLocalState.init(props: ProfileProps) {
        selectedGroup = null
        selectedRoute = ProfileRoute.YOUR_PROFILE
        selectedProfileTab = getSelectedTabFromHash()
        pagePressed = false
    }

    override fun componentDidMount() {
        val userToken = props.user.token ?: ""
        if (userToken.isEmpty()) {
            CoroutineScope(Dispatchers.Unconfined).launch {
                InjectorUtils.provideUserUseCases().logout()
                document.location?.href = UtilsCommon.getBaseUrlForBuildConfig() + "accounts/logout/"
            }
            return
        }
        props.populateProfile()
    }

    override fun RBuilder.render() {
        if (props.user.role.isNullOrEmpty()) {
            RoleSelectComponent {
                attrs.parentProps =  props
            }
        }
        styledDiv {
            css(DashboardCss.header)
            css {
                paddingTop = 12.px
                paddingBottom = 14.px
                paddingLeft = 24.px
                paddingRight = 50.px
            }

            styledImg {
                attrs.onClickFunction = {
                    document.location?.href = UtilsCommon.getBaseUrlForBuildConfig()
                }
                css {
                    height = 60.px
                }
                attrs.src = "/images/ch_logo_alt.png"
            }

            styledDiv {
                css(flexRow)
                css {
                    justifyContent = JustifyContent.spaceBetween
                    gap = 16.px
                }
//                    NotificationsDropDown {
//                        attrs.completedAchievements = props.completedAchievements
//                        attrs.groups = props.memberships
//                        attrs.setAchievementSeen = props.setAchievementSeen
//                    }
                userDropDown(props.user.firstName)
                if (!props.user.hasPaid) {
                    Button(
                        text = "Upgrade Plan",
                        bgColor = "#fff",
                        textColor = "#8c52ff",
                        iconSrc = "/images/ic_upgrade.png",
                        customCss = {
                            borderStyle = BorderStyle.solid
                            borderWidth = 1.px
                            borderColor = Color( "#8c52ff")
                        }
                    ) {
                        window.location.href = UtilsCommon.getBaseUrlForBuildConfig() + "pricing/"
                    }
                }
            }

        }
        styledDiv {
            attrs.onClickFunction = {
                if (props.modalShowing) {
                    props.showModal(false, null)
                } else {
                    it.stopPropagation()
                }
            }
            if (props.modalShowing) {
                if (props.selectedModalType == ModalType.CREATE_GROUP) {
                    createGroupModal(props, state)
                }
                if (props.selectedModalType == ModalType.JOIN_GROUP) {
                    joinGroupModal(props, state)
                }

                if (props.selectedModalType == ModalType.DELETE_GROUP) {
                    deleteGroupModal(props, null, state, null)
                }

                if (props.selectedModalType == ModalType.INFO_MODAL_GROUPS) {
                    featureInfoModal(
                        FEATURE_INFO_GROUP_TITLE,
                        listOf(
                            FEATURE_INFO_GROUP_DESCRIPTION_PART_1,
                            FEATURE_INFO_GROUP_DESCRIPTION_PART_4,
                            FEATURE_INFO_GROUP_DESCRIPTION_PART_5
                        )
                    ) {
                        props.showModal(false, null)
                    }
                }
                if (props.selectedModalType == ModalType.INFO_MODAL_MEMBERSHIPS) {
                    featureInfoModal(
                        FEATURE_INFO_MEMBERSHIPS_TITLE,
                        listOf(
                            FEATURE_INFO_MEMBERSHIPS_DESCRIPTION_PART_1,
                            FEATURE_INFO_MEMBERSHIPS_DESCRIPTION_PART_2,
                            FEATURE_INFO_MEMBERSHIPS_DESCRIPTION_PART_3
                        )
                    ) {
                        props.showModal(false, null)
                    }
                }
            }
            css {
                height = 100.pct
                display = Display.grid
            }

            styledDiv {
                css(DashboardCss.content)
                css {
                    overflow = Overflow.scroll
                }
                if (props.isGlobalLoading) {
                    styledDiv {
                        css(flexColumn)
                        css {
                            gap = 8.px
                            height = 950.px
                            padding = "80px"
                            alignItems = Align.center
                        }
                        indeterminateCircularProgress(
                            size = 100.px,
                            color = Color("#1cb3e4")
                        )
                        styledP {
                            css {
                                opacity = 0.8
                                fontWeight = FontWeight.bold
                                fontSize = 16.px
                            }
                            + "Loading your classes"
                        }
                    }

                } else {
                    userProfile(
                        props = props,
                        state = state,
                        onProfileTabPressed = {
                            setState {
                                selectedProfileTab = it
                            }
                        }) {
                        setState {
                            selectedGroup = it
                        }
                    }
                }
            }
//            styledDiv {
//                attrs.onClickFunction = {
//                    //props.showDropdown(false)
//                }
//                css(DashboardCss.page)
//                css {
//                    height = 95.vh
//                }
//                styledDiv {
//                    css(DashboardCss.sideBar)
//                    css {
//                        justifyContent = JustifyContent.spaceBetween
//                        paddingLeft = 8.px
//                        paddingRight = 8.px
//                        paddingBottom = 32.px
//                        paddingTop = 0.px
//                    }
//                    styledDiv {
//                        sideBarItem(
//                            title = "My Profile",
//                            faIcon = "fa-dashboard",
//                            iconColor = "#512DA8"
//                        ) {
//                        }
////                        styledDiv {
////                            css { position = Position.relative }
////                            sideBarItem(
////                                title = "Discover Classes",
////                                faIcon = "fa-globe",
////                                iconColor = "#512DA8",
////                                disabled = true
////                            ) {
////                            }
////                            styledP {
////                                css {
////                                    fontSize = 10.px
////                                    color = rgb(28, 179, 228)
////                                    fontWeight = FontWeight.bold
////                                    position = Position.absolute
////                                    bottom = 3.px
////                                    left = 110.px
////                                }
////                                + "Coming soon!"
////                            }
////                        }
//
//
//                        spacer()
//                    }
//                    styledDiv {
//                        css(flexColumn)
//                        css {
//                            marginBottom = 60.px
//                        }
//                        if (!props.user.hasPaid) {
//                            Button(
//                                text = "Upgrade Plan",
//                                bgColor = "#fff",
//                                textColor = "#8c52ff",
//                                iconSrc = "/images/ic_upgrade.png",
//                                customCss = {
//                                    borderStyle = BorderStyle.solid
//                                    borderWidth = 1.px
//                                    borderColor = Color( "#8c52ff")
//                                }
//                            ) {
//                                window.location.href = UtilsCommon.getBaseUrlForBuildConfig() + "pricing/"
//                            }
//                        }
//                    }
//                }
//            }
        }
    }
}

fun RBuilder.userProfile(
    props: ProfileProps,
    state: ProfileLocalState,
    onProfileTabPressed: (ProfileTab) -> Unit,
    onEditGroup: (Class) -> Unit
) {
//    styledDiv {
//        css {
//            height = 320.px
//            position = Position.relative
//        }
//        ImageUploaderBackground {
//            attrs.elementId = "user-cover-photo"
//            attrs.remoteUrl = props.user.coverUrl ?: ""
//            attrs.placeholderSrc = "images/user_profile_placeholder.png"
//            attrs.onUpload = { image ->
//                props.updateCoverPhoto(image)
//            }
//        }
//        styledDiv {
//            css {
//                position = Position.absolute
//                bottom = (-40).px
//                left = 80.px
//                zIndex = 2
//                backgroundColor = Color("#cccccc")
//                borderRadius = 50.pct
//                boxShadow += BoxShadow(false, 0.px, 0.px, 10.px, 0.px, rgba(0, 0, 0, 0.35))
//            }
//            ImageUploader {
//                attrs.elementId = "user-profile-photo"
//                attrs.remoteUrl = props.user.photoUrl ?: ""
//                attrs.placeholderComponent = createElement {
//                    circularNamePlaceholder(
//                        firstName = props.user.firstName ?: "A",
//                        bgColor = "#fff",
//                        size = 100.px,
//                        textSize = 24.px,
//                        letterColor = "#000"
//                    )
//                }
//                attrs.onUpload = { image ->
//                    props.updateProfilePhoto(image)
//                }
//            }
//        }
//    }
    styledDiv {
        css {
            padding = "80px 80px"
        }
        styledP {
            css {
                fontSize = 40.px
                marginBottom = 32.px
            }
            + "${props.user.firstName}"
        }
        /* User PROGRESS */
        if (!props.user.isTeacherOrParent()) {
            styledDiv {
                css(flexColumn)
                css {
                    gap = 8.px
                    marginBottom = 24.px
                }
//            styledP {
//                css {
//                    fontSize = 22.px
//                    marginBottom = 12.px
//                    borderBottomColor = Color("#eee")
//                    borderBottomWidth = 1.px
//                    borderBottomStyle = BorderStyle.solid
//                }
//                + "Summary"
//            }
                styledDiv {
                    css(flexRow)
                    css {
                        fontSize = 18.px
                        alignItems = Align.center
                    }
                    styledSpan {
                        css {
                            color = Color("#6d7175")
                        }
                        + "Level"
                        styledSpan {
                            css {
                                color = Color("#6200EE")
                                marginLeft = 5.px
                            }
                            + "${props.user.level}"
                        }
                    }
//                    styledDiv {
//                        css(flexRow)
//                        css {
//                            marginLeft = 16.px
//                            alignItems = Align.center
//                        }
//                        styledImg {
//                            css {
//                                width = 28.px
//                            }
//                            attrs.src = "https://cdn.classroom-hero.com/media/currency_icons/currency_icon_4.png"
//                        }
//                        styledSpan {
//                            css {
//                                color = Color("#000")
//                                marginLeft = 5.px
//                                fontSize = 18.px
//                            }
//                            + "${props.user.points}"
//                        }
//                    }

                }
                styledDiv {
                    css {
                        display = Display.flex
                        justifyContent = JustifyContent.spaceBetween
                        fontSize = 12.px
                        maxWidth = 500.px
                        width = 100.pct
                    }
                    styledSpan {
                        + "${props.user.getFloatingProgress() * 100}% to next level"
                    }
                    styledSpan {
                        + "XP ${props.user.progress}/${props.user.level * 10}"
                    }
                }
                progressBar(props.user.getFloatingProgress() * 100)
                if (props.user.reachedXpCap()) {
                    styledP {
                        css {
                            opacity = .5
                        }
                        + "Congratulations! You've reached the daily experience cap. You can continue to earn experience points tomorrow."
                    }
                } else {

                    styledP {
                        css {
                            opacity = .5
                        }
                        styledSpan {
                            + "You can still earn"
                        }
                        styledSpan {
                            css {
                                color = rgb(98, 0, 238)
                                fontWeight = FontWeight.bold
                            }
                            + " ${props.user.remainingXp()} "
                        }
                        styledSpan {
                            + "more experience points today!"
                        }
                    }
                }

            }
        }

        if (!props.user.role.isNullOrEmpty()) {
            styledP {
                css {
                    fontSize = 26.px
                    marginBottom = 12.px
                    borderBottomColor = Color("#eee")
                    borderBottomWidth = 1.px
                    borderBottomStyle = BorderStyle.solid
                }
                + "Your Classes"
            }
        }

        if (props.user.isTeacherOrParent()) {
            styledDiv {
                css {
                    flexDirection = FlexDirection.column
                    width = 100.pct
                    maxWidth = 1080.px
                    color = Color.dimGray
                }
                styledDiv {
                    css {
                        marginBottom = 8.px
                    }
                    if (props.groups.isEmpty()) {
                        styledP {
                            css{
                                fontSize = 16.px
                            }
                            + "You haven\'t started any classes yet. Start a class to begin building your reward system."
                        }
                    } else {
                        styledP {
                            css{
                                fontSize = 16.px
                            }
                            +"These are the classes that you manage."
                        }
                    }
                }
                styledDiv {
                    css(flexRow)
                    css {
                        gap = 12.px
                        flexWrap = FlexWrap.wrap
                    }
                    for (group in props.groups) {
                        groupCard(group, props) {
                            // on edit pressed
                            props.showModal(true, ModalType.CREATE_GROUP)
                            props.setEditing(true)
                            onEditGroup(group)
                        }
                    }
                }

                styledDiv {
                    css {
                        maxWidth = 330.px
                        justifyContent = JustifyContent.center
                    }
                    Button(
                        text = "Start a Class",
                        bgColor = "#fcdf3d",
                        textColor = "#000",
                        textSize = 18.px
                    ) {
                        props.setEditing(false)
                        props.showModal(true, ModalType.CREATE_GROUP)
                    }
                }
            }
        }

//        styledP {
//            css {
//                fontSize = 26.px
//                marginBottom = 12.px
//                marginTop = 44.px
//                borderBottomColor = Color("#eee")
//                borderBottomWidth = 1.px
//                borderBottomStyle = BorderStyle.solid
//            }
//            + "Your Memberships"
//        }

        if (props.user.role == UserRole.Student.label) {
            styledDiv {
                css {
                    flexDirection = FlexDirection.column
                    width = 100.pct
                    maxWidth = 1080.px
                    color = Color.dimGray
                }
                styledDiv {
                    css {
                        marginBottom = 8.px
                    }
                    if (props.memberships.isEmpty()) {
                        styledP {
                            + "You haven\'t joined any classes yet! Ask your instructor for the class code."
                        }
                    } else {
                        styledP {
                            + "These are the classes that you have joined so far. Tap into one to explore available achievements!"
                        }
                    }
                }
                styledDiv {
                    css(flexRow)
                    css {
                        gap = 12.px
                        flexWrap = FlexWrap.wrap
                    }
                    for (group in props.memberships) {
                        group.students.find { it.linkedUser == props.user.id }?.let { member ->
                            membershipCard(group, member, props)
                        }
                    }
                }
                styledDiv {
                    css(flexColumn)
                    css {
                        maxWidth = 330.px
                        justifyContent = JustifyContent.center
                    }
                    Button(
                        text = "Join a Class",
                        textSize = 18.px
                    ) {
                        props.showModal(true, ModalType.JOIN_GROUP)
                    }
                }
            }
        }
    }
}

fun RBuilder.selectableTab(
    tab: ProfileTab,
    label: String? = null,
    isActive: Boolean = false,
    onPress: () -> Unit = {}
) {
    val tabColor = if (isActive) "#0A5AE4" else "#72777a"
    styledDiv {
        css(flexColumn)
        css {
            gap = 4.px
        }
        styledA {
            attrs.onClickFunction = {
                onPress()
                //it.preventDefault()
            }
            attrs.href = "#${tab.labelToUrlHash()}"
            css(GlobalCss.cursorPointer)
            css {
                color = Color(tabColor)
                gap = 6.px
                textDecoration = TextDecoration.none
                hover {
                    opacity = 0.8
                }
            }
            css(GlobalCss.flexRow)
            faIcon(
                faIcon = tab.icon,
                iconColor = Color(tabColor),
                iconSize = 18.px
            )
            styledSpan {
                + (label ?: tab.label)
            }
        }
        if (isActive) {
            styledDiv {
                css {
                    height = 4.px
                    width = 100.pct
                    borderRadius = 4.px
                    backgroundColor = Color(tabColor)
                }
            }
        }
    }

}

fun RBuilder.membershipCard(
    group: Class,
    member: Student,
    props: ProfileProps,
) {
    styledDiv {
        attrs.onClickFunction = {
            props.setSelectedMembership(group.id, member.id)
        }
        css(card)
        css(flexColumn)
        css {
            maxWidth = 350.px
            width = 100.pct
            justifyContent = JustifyContent.spaceBetween
            position = Position.relative
        }
        styledDiv {
            css(flexRow)
            css {
                position = Position.absolute
                right = 16.px
                top = 16.px
                justifyContent = JustifyContent.spaceBetween
                alignItems = Align.center
                gap = 6.px
                color = rgba(61, 59, 73, 0.7)
            }
            faIcon("fa-users", iconColor = rgba(61, 59, 73, 0.7), iconSize = 16.px)
            styledP {
                css {
                    fontSize = 16.px
                }
                + group.memberCount.toString()
            }
        }
        styledDiv {
            css(cardTop)
            styledDiv {
                css {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                }
                if (group.groupLogoUrl.isNullOrEmpty()) {
                    circularFaIcon(radius = 5.pct)
                } else {
                    imageWidget("${group.groupLogoUrl}", radius = 5.pct)
                }
                styledDiv {
                    css {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        justifyContent = JustifyContent.center
                        marginLeft = 16.px
                    }
                    styledH4 {
                        css {
                            color = Color.black
                        }
                        + group.name
                    }
                    styledDiv {
                        css {
                            display = Display.flex
                            flexDirection = FlexDirection.row
                            marginTop = 6.px
                        }
                        styledSpan {
                            css {
                                color = Color("#6d7175")
                            }
                            + "Level"
                            styledSpan {
                                css {
                                    color = Color("#6200EE")
                                    marginLeft = 5.px
                                    marginRight = 15.px
                                }
                                + "${member.level}"
                            }
                        }
                        styledDiv {
                            css {
                                display = Display.flex
                                flexDirection = FlexDirection.row
                                alignItems = Align.center
                            }
                            imageWidget(
                                src = group.currencyIconUrl!!,
                                size = 15.px
                            )
                            styledSpan {
                                css {
                                    color = Color("#6200EE")
                                    marginLeft = 5.px
                                }
                                + "${member.currentCoins}"
                            }
                        }
                    }
                }
            }
        }
//        var progress = member.floatingProgress * 100
//        if (progress <= 0) {
//            progress = 1f
//        }
//        progressBarCenterLabel(
//            fillPercentage =  progress,
//            label = "${member.floatingProgress * 100}% - XP: ${member.progress}/${member.level * 10}"
//        )
    }
}

fun RBuilder.groupCard(group: Class, props: ProfileProps, onEdit: (Class) -> Unit = {}) {
    styledDiv {
        attrs.onClickFunction = {
            val groupId = group.id
            props.setSelectedGroup(groupId)
        }
        css(card)
        css(flexColumn)
        css {
            maxWidth = 350.px
            width = 100.pct
            height = 180.px
            justifyContent = JustifyContent.spaceBetween
        }
        styledDiv {
            css(cardTop)
            styledDiv {
                css {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                }
                if (group.groupLogoUrl.isNullOrEmpty()) {
                    circularFaIcon()
                } else {
                    imageWidget("${group.groupLogoUrl}")
                }
                styledDiv {
                    css {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        justifyContent = JustifyContent.center
                        marginLeft = 16.px
                    }
                    styledH4 {
                        css {
                            color = Color.black
                        }
                        + group.name
                    }
                    styledP {
                        css {
                            color = Color("#6d7175")
                        }
                        + group.description
                    }
                }
            }
            styledI {
                attrs.onClickFunction = {
                    it.stopPropagation()
                    onEdit(group)
                }
                css {
                    fontSize = 28.px
                    color = Color("#aaaaaa")
                    float = Float.right
                    cursor = Cursor.pointer
                    classes.add("fa")
                    classes.add("fa-edit")
                }
            }
        }
        styledDiv {
            css(cardBottom)
            titleAndCount("Members", Color("#1976d2"), group.students.size)
            titleAndCount("Achievements", Color("#F57C00"), group.reinforcers.size)
            titleAndCount("Items", Color("#00796b"), group.items.size)
        }
    }
}

fun RBuilder.titleAndCount(
    title: String,
    titleColor: Color = Color.black,
    count: Int
) {
    styledDiv {
        css(GroupCss.titleAndCount)
        styledP {
            css {
                color = titleColor
                marginBottom = 8.px
            }
            + title
        }
        styledP {
            + count.toString()
        }
    }
}