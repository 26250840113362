package com.jet.classroomhero.entities

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

@Serializable
data class Student (
    @SerialName("student_id")
    var id: Int = 0,
    @Transient
    var name: String = "",
    @SerialName("first_name")
    var firstName: String = "",
    @SerialName("last_name")
    var lastName: String = "",
    @SerialName("total_coins")
    var totalCoins: Int = 0,
    @SerialName("current_coins")
    var currentCoins: Int = 0,
    var level: Int = 1,
    var progress: Int = 0,
    @SerialName("floating_progress")
    var floatingProgress: Float = 0.0f,
    @SerialName("checked_in")
    var checkedIn: Boolean = false,
    @SerialName("student_hash")
    var studentHash: String = "",
    @SerialName("linked_user")
    var linkedUser: Int? = 0,
    var achievements: List<Achievement> = emptyList(),
    var transactions: List<Transaction> = emptyList(),
    @SerialName("member_photo")
    var photo: String? = ""
) {
    constructor(
        name: String = "",
    ): this(0, name, name, "", 0, 0, 0, 0, 0.0f,false, "", 0)
}