package components.modals

import components.widgets.Modal
import kotlinx.css.*
import kotlinx.css.properties.LineHeight
import react.RBuilder
import styled.*
import styles.GlobalCss

fun RBuilder.featureInfoModal(
  title: String = "Feature Title",
  paragraphs: List<String> = emptyList(),
  closeModal: () -> Unit
) {
  Modal(onClose = {
    closeModal()
  }) {
    styledDiv {
      css(GlobalCss.flexColumn)
      css(GlobalCss.flexCenter)
      css {
        padding = "20px"
      }
      styledH2 {
        css {
          marginBottom = 12.px
        }
        + title
      }
      paragraphs.forEach { paragraph ->
        styledP {
          css {
            color = Color("#6d7175")
            textAlign = TextAlign.left
            marginBottom = 18.px
            lineHeight = LineHeight("1.5")
            width = 100.pct
          }
          +  paragraph
        }
      }
    }
  }
}