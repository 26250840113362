package components.dashboard

import csstype.AlignItems
import kotlinx.css.*
import kotlinx.css.properties.*
import styled.StyleSheet

object DashboardCss : StyleSheet("DashboardCss", isStatic = true) {
  val page by css {
    display = Display.flex
    height = 100.vh
  }
  val content by css {
    flex(1.0, 0.0, FlexBasis.zero)
    backgroundColor = Color("#F5f5f5")
  }
  /* Header CSS */
  val header by css {
    display = Display.flex
    alignItems = Align.stretch
    justifyContent = JustifyContent.spaceBetween
    backgroundColor = Color.white
    color = Color.black
    borderBottomWidth = 1.px
    borderBottomStyle = kotlinx.css.BorderStyle.solid
    borderBottomColor = Color("#eee")
    height = 80.px
  }
  val headerContent by css {
  }
  val groupsDropDown by css {
    position = Position.relative
    display = Display.flex
    width = 330.px
    height = 100.pct
    alignItems = Align.center
    justifyContent = JustifyContent.spaceEvenly
    backgroundColor = Color("#eee")
    hover {
      cursor = Cursor.pointer
    }
  }
  val groupsDropDownContent by css {
    display = Display.none
    flexDirection = FlexDirection.column
    position = Position.absolute
    backgroundColor = Color("#f9f9f9")
    width = 320.px
    boxShadow += BoxShadow(false, 0.px, 4.px, 8.px, 0.px, Color.lightSlateGray)
    paddingTop = 18.px
    paddingLeft = 18.px
    paddingRight = 18.px
    zIndex = 1
    top = 50.px
  }
  val dropDownItem by css {
    display = Display.flex
    width = 100.pct
    justifyContent = JustifyContent.flexStart
    alignItems = Align.center
    flexDirection = FlexDirection.row
    marginBottom = 18.px
    transition += Transition("background-color", 200.ms, Timing.linear, 0.s)
    hover {
      cursor = Cursor.pointer
      backgroundColor = Color("#eee")
    }
  }
  /* SideBar CSS */
  val sideBar by css {
    display = Display.flex
    width = 250.px
    backgroundColor = Color.white
    flexDirection = FlexDirection.column
    borderRightColor = Color("#eee")
    borderRightWidth = 1.px
    borderRightStyle = kotlinx.css.BorderStyle.solid
    paddingTop = 30.px
  }
  val sideBarItem by css {
    padding = "5px 15px"
    textDecoration = TextDecoration.none
    fontSize = 15.px
    color = Color("#72777a")
    display = Display.flex
    alignItems = Align.center
    fontWeight = FontWeight("500")
    position = Position.relative
    whiteSpace = WhiteSpace.nowrap
    transition += Transition("all", .3.s, Timing.ease, 0.s)
    fontSize = 18.px
    hover {
      color = Color("#313435")
      cursor = Cursor.pointer
    }
  }
  val sideBarItemIcon by css {
    borderRadius = 6.px
    display = Display.flex
    alignItems = Align.center
    fontSize = 17.px
    height = 35.px
    left = 0.px
    position = Position.relative
    width = 35.px
  }
  /* MembersComponent CSS */
  val memberListContainer by css {
    width = 280.px
    height = 100.vh
    overflow = Overflow.auto
    backgroundColor = Color.white
  }
  val memberRow by css {
    padding = "20px"
    display = Display.flex
    alignItems = Align.center
    flexWrap = FlexWrap.nowrap
    justifyContent = JustifyContent.flexStart
    borderBottomWidth = 1.px
    borderBottomColor = Color("#eee")
    borderBottomStyle = BorderStyle.solid
    hover {
      cursor = Cursor.pointer
    }
  }
  val memberPhoto by css {
    display = Display.flex
    alignItems = Align.center
    justifyContent = JustifyContent.center
  }
  /* Member Detail CSS */
  val memberDetailContainer by css {
    display = Display.flex
    flexDirection = FlexDirection.column
    position = Position.relative
    width = 100.pct
    flex(1.0, 1.0, FlexBasis.auto)
  }
  val memberInfoSection by css {
    display = Display.flex
    flexDirection = FlexDirection.column
    backgroundColor = Color.white
  }
  val memberValueLabel by css {
    fontSize = 22.px
    fontWeight = FontWeight("300")
  }
  val memberValue by css {
    color = Color("#7B1FA2")
  }
  val statsRow by css {
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.spaceAround
    alignItems = Align.stretch
    paddingLeft = 24.px
    paddingRight = 24.px
    gap = 24.px
  }
  val allTimeStat by css {
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.spaceBetween
    width = 100.pct
    alignItems = Align.center
  }
  /* Extension CSS */
  val extensionListContainer by css {
    display = Display.flex
    flexDirection = FlexDirection.column
    padding = "24px"
  }
  val extensionsGridContainer by css {
    display = Display.flex
    flexWrap = kotlinx.css.FlexWrap.wrap
    gap = 8.px
    marginTop = 12.px
    children {
      flex(1.0, 1.0, 80.px)
    }
  }
  val extensionItem by css {
    display = Display.flex
    flexDirection = FlexDirection.column
    position = Position.relative
    justifyContent = JustifyContent.center
    alignItems = Align.center
    gap = 6.px
    height = 150.px
    width = 150.px
    backgroundColor = Color.lightGray
    boxShadow += BoxShadow(false, 0.px, 1.px, 2.px, 0.24.px, Color.black)
    hover {
      cursor = Cursor.pointer
    }
  }
  val grid by css {
    display = Display.flex
    flexWrap = FlexWrap.wrap
    maxWidth = 100.pct
    minWidth = 50.pct
  }
  val gridItem by css {
    flex(0.0, 1.0, 150.px)
    height = 130.px
    display = Display.flex
    alignItems = Align.center
    justifyContent = JustifyContent.center
    boxSizing = BoxSizing.borderBox
    flexDirection = FlexDirection.column
    padding = "12px"
    gap = 12.px
    margin = "12px"
    hover {
      cursor = Cursor.pointer
    }
  }
}
