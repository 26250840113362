package com.jet.classroomhero.data

import com.jet.classroomhero.data.remote.sources.NetworkExtensionSource
import com.jet.classroomhero.entities.Extension
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext

class ExtensionRepository(
  val localUserSource: LocalUserSource,
  private val localExtensionSource: LocalExtensionSource,
  private val remoteExtensionSource: RemoteExtensionSource = NetworkExtensionSource(localUserSource)
) {

  suspend fun getExtensions(forceFetch: Boolean = false): List<Extension> {
    if (!forceFetch) {
      val localExtensions = withContext(Dispatchers.Default) {
        localExtensionSource.readExtensions()
      }
      if (localExtensions.isNotEmpty()) {
        return localExtensions
      }
    }

    val remoteExtensions = remoteExtensionSource.fetchExtensions()
    withContext(Dispatchers.Default) {
      localExtensionSource.saveExtensions(remoteExtensions)
    }
    return remoteExtensions
  }

  suspend fun activateExtension(classId: Int, extensionId: Int): Boolean {
    return remoteExtensionSource.activateExtension(classId, extensionId)
  }

  suspend fun deactivateExtension(classId: Int, extensionId: Int): Boolean {
    return remoteExtensionSource.deactivateExtension(classId, extensionId)
  }

}

interface LocalExtensionSource {
  suspend fun readExtensions(): List<Extension>
  suspend fun saveExtensions(extensions: List<Extension>)
}

interface RemoteExtensionSource {
  suspend fun fetchExtensions(): List<Extension>
  suspend fun activateExtension(classId: Int, extensionId: Int): Boolean
  suspend fun deactivateExtension(classId: Int, extensionId: Int): Boolean
}