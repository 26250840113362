package components.widgets

import kotlinx.css.*
import kotlinx.html.ButtonType
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import org.w3c.dom.events.Event

import react.RBuilder
import react.child
import styled.*



private object ButtonCss : StyleSheet("ButtonCss", isStatic = true) {
    val button by css {
        position = Position.relative
        textAlign = TextAlign.center
        verticalAlign = VerticalAlign.middle
        display = Display.flex
        flexDirection = FlexDirection.row
        alignItems = Align.center
        justifyContent = JustifyContent.center
        margin = "0"
        border = "0"
        fontWeight = FontWeight.w400
        cursor = Cursor.pointer
        verticalAlign = VerticalAlign.middle
        appearance = Appearance.none
        padding = "14px 16px"
        fontSize = 0.9375.rem
        minHeight = 2.25.rem
        color = Color.white
        width = 100.pct
        backgroundColor = Color("#1cb3e4")
        borderRadius = 3.px
        fontWeight = FontWeight.bold
    }
}

fun RBuilder.Button(
    text: String,
    iconSrc: String? = null,
    faIcon: String = "",
    iconHeight: LinearDimension = 25.px,
    iconWidth: LinearDimension = 25.px,
    bgColor: String = "#1cb3e4",
    textColor: String = "#fff",
    type: ButtonType = ButtonType.submit,
    customCss: RuleSet = { },
    disabled: Boolean = false,
    id: String? = null,
    loading: Boolean = false,
    textSize: LinearDimension = 16.px,
    onPress: (Event) -> Unit = { console.warn("OnPress not implemented") },
) {
    styledButton {
        attrs.onClickFunction = {
            onPress(it)
        }
        attrs.disabled = disabled
        attrs.asDynamic().type = type
        if (!id.isNullOrEmpty()){
            attrs.id = id
        }
        // base button css
        css(ButtonCss.button)
        css(customCss)
        // for dynamic values
        css {
            if (disabled) {
                opacity = 0.5
            }
            backgroundColor = Color(bgColor)
            color = Color(textColor)
        }

        if (loading) {
            styledDiv {
                css {
                    marginRight = 8.px
                }
                indeterminateCircularProgress(
                    size = 15.px,
                    color = Color("#fff")
                )
            }

        } else if (!iconSrc.isNullOrEmpty()) {
            styledImg {
                css {
                    height = iconHeight
                    width = iconWidth
                    marginRight = 6.px
                }
                attrs.src = iconSrc
            }
        } else if (iconSrc.isNullOrEmpty() && faIcon.isNotEmpty()) {
            styledSpan {
                css {
                    marginRight = 6.px
                }
                faIcon(faIcon, iconWidth)
            }

        }
        styledSpan {
            css {
                fontSize = textSize
            }
            + text
        }
    }
}