package components.forms

import com.jet.classroomhero.combineNames
import com.jet.classroomhero.entities.Class
import com.jet.classroomhero.entities.Item
import com.jet.classroomhero.entities.Student
import components.widgets.Button
import components.widgets.RadioButton
import components.widgets.valueAndCurrencyIcon
import kotlinx.css.*
import kotlinx.html.id
import kotlinx.html.js.onSubmitFunction
import react.Props
import react.fc
import react.useState
import styled.*
import styles.GlobalCss

external interface GiveItemFormProps : BaseFormProps {
  var member: Student
  var group: Class
  var items: List<Item>
  var onBulkSellItem: (List<Int>, Int, Int) -> Unit
}

val SellItemForm = fc<GiveItemFormProps> { props ->
  val (selectedItem, setSelectedItem) = useState<Item?>(null)
  styledForm {
    attrs.id = "give-item-" + props.member.id.toString() + "-form"
    css {
      display = Display.flex
      flexDirection = FlexDirection.column
      alignItems = Align.flexStart
      width = 100.pct
      marginTop = 18.px
      backgroundColor = Color.white
      padding = "12px 120px"
    }
    attrs.onSubmitFunction = {
      it.preventDefault()
    }
    props.group.let {
      for (item in props.items) {
        RadioButton(
          isSelected = selectedItem?.id == item.id,
          labelContent = {
            styledDiv {
              attrs.id = "give-item-" + props.member.id.toString() + "-form-" + item.id.toString()
              css(GlobalCss.flexRow)
              styledSpan {
                css {
                  marginRight = 8.px
                  whiteSpace = WhiteSpace.pre
                  color = if (props.member.currentCoins < item.cost) Color.dimGray else Color.black
                }
                + item.name
              }
              valueAndCurrencyIcon(amount = item.cost,  currencyImgUrl = props.group.currencyIconUrl ?: "")
            }
          },
          disabled = props.member.currentCoins < item.cost,
          onPress = { isSelected ->
            if (isSelected) {
              setSelectedItem(item)
            } else {
              setSelectedItem(null)
            }
            + "Not enough currency"
          }
        )
        if (props.member.currentCoins < item.cost) {
          styledSpan {
            css {
              color = Color("#B00020")
              fontSize = 12.px
              marginTop = 2.px
              marginBottom = 8.px
            }
            + "Not enough currency"
          }
        }
      }
      Button(
        bgColor = if (selectedItem != null) "#00796b" else "#cccccc",
        customCss = {
          maxWidth = 280.px
          alignSelf = Align.center
          marginTop = 40.px
        },
        loading = props.isLoading,
        text = "Award ${selectedItem?.name} to ${props.member.combineNames()}"
      ) { event ->
        event.preventDefault()
        props.onBulkSellItem(
          listOf(props.member.id),
          props.group.id,
          selectedItem?.id ?: 0
        )
      }
    }
  }
}