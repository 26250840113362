package styles

import kotlinx.css.*

import styled.StyleSheet

object GlobalCss : StyleSheet("GlobalCss", isStatic = true) {
  val flexRow by css {
    display = Display.flex
    flexDirection = FlexDirection.row
  }
  val flexColumn by css {
    display = Display.flex
    flexDirection = FlexDirection.column
  }
  val flexCenter by css {
    alignItems = Align.center
    justifyContent = JustifyContent.center
  }
  val errorText by css {
    color = Color("#B00020")
  }
  val infoIcon by css {
    color = Color("#1976d2")
    cursor = Cursor.pointer
  }
  val cursorPointer by css {
    cursor = Cursor.pointer
  }
  fun CssBuilder.selectedBackground(isSelected: Boolean) {
    css {
      backgroundColor = if(isSelected) {
        Color("#eeeeee")
      } else {
        Color("#fff")
      }
    }
  }
}