package com.jet.classroomhero.data.remote.clients

import com.jet.classroomhero.Logger
import com.jet.classroomhero.data.remote.exceptions.CHRequestException
import com.jet.classroomhero.data.remote.exceptions.CHServerResponseError
import com.jet.classroomhero.entities.errors.CHErrorDetail
import com.jet.classroomhero.entities.errors.CHErrorMessage
import io.ktor.client.*
import io.ktor.client.engine.*
import io.ktor.client.engine.js.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.statement.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.jsonObject
import kotlin.js.json

actual open class AnonymousClient actual constructor(engine: HttpClientEngine?) {

    private val format = Json {
        ignoreUnknownKeys = true
        useAlternativeNames = true
    }
    actual var baseUrl = "http://localhost:8000/"
    actual open var httpClient: HttpClient = HttpClient(Js) {
        expectSuccess = true
        install(ContentNegotiation) {
            val json = Json {
                prettyPrint = true
                isLenient = true
                ignoreUnknownKeys = true
            }
            json(json)
        }
        HttpResponseValidator {
            handleResponseExceptionWithRequest { exception, request ->
                val responseException =
                    exception as? ResponseException ?: return@handleResponseExceptionWithRequest
                val response = responseException.response.bodyAsText()
                val message = parseError(response)
                when (exception) {
                    is ClientRequestException -> throw CHRequestException(message)
                    is ServerResponseException -> throw CHServerResponseError(message)
                    else -> throw exception
                }
            }
        }
    }
    private fun parseError(originalResponse: String?): String {
        if (originalResponse.isNullOrEmpty()) return "Unknown Error"

        var error = ""
        val jsonObject = Json.parseToJsonElement(originalResponse).jsonObject
        if (jsonObject.contains("message")) {
            error = format.decodeFromString<CHErrorMessage>(originalResponse).message
        }
        if (jsonObject.contains("detail")) {
            error = format.decodeFromString<CHErrorDetail>(originalResponse).detail
        }
        return error
    }
}