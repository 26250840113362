package com.jet.classroomhero.entities

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Jar (
  @SerialName("jar_id")
  var id: Int = 0,
  var name: String = "",
  var total: Int = 1,
  var progress: Int = 0,
  @SerialName("created_at")
  var createdAt: String = "",
  @SerialName("updated_at")
  var updatedAt: String = ""
)

fun Jar.percentComplete(): String {
  return "${((this.progress  / this.total.toFloat()) * 100)}%"
}

fun Jar.isFilled(): Boolean {
  return this.progress >= this.total
}