package components.widgets

import components.widgets.BarChartCss.bar
import kotlinx.css.*
import react.Props
import react.fc
import styled.*
import components.widgets.BarChartCss.barChart
import components.widgets.BarChartCss.barChartTitle
import components.widgets.BarChartCss.barContainer
import components.widgets.BarChartCss.statContainer
import kotlinx.css.properties.*
import utils.Utils

external interface BarChartProps: Props {
  var dataSet: Map<Int, Int>
  var chartTitle: String
  var barColor: String
}

val BarChart = fc<BarChartProps> { props ->
  val maxValue = props.dataSet.maxByOrNull { it.value }?.value ?: 0
  styledDiv {
    css(statContainer)
    styledUl {
      styledCaption {
        css(barChartTitle)
        + props.chartTitle
      }
      css(barChart)
      props.dataSet.forEach { entry ->
        styledLi {
          css(barContainer)
          styledSpan {
            css(bar)
            css.height = Utils.getBarHeightPercentage(entry.value, maxValue).pct
            css.backgroundColor = Color(props.barColor)
            css {
              before {
                content = QuotedString(Utils.getDayOfWeekNameForIndex(entry.key))
              }
              after {
                if (entry.value > 0) {
                  content = QuotedString(entry.value.toString())
                }
              }
            }
          }
        }
      }
    }
  }
}

object BarChartCss : StyleSheet("BarChartCss", isStatic = true) {
  val statContainer by css {
    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = Align.center
    justifyContent = JustifyContent.center
    width = 100.pct
    boxShadow += BoxShadow(
      inset = false,
      offsetX = 1.95.px,
      offsetY = 1.95.px,
      blurRadius = 2.6.px,
      spreadRadius = 0.px,
      color = rgba(0, 0, 0, .15)
    )
    backgroundColor = Color.white
    paddingTop = 30.px
    paddingBottom = 50.px
    paddingLeft = 30.px
    paddingRight = 30.px
  }
  val barChart by css {
    display = Display.table
    tableLayout = TableLayout.fixed
    maxWidth = 500.px
    width = 100.pct
    height = 200.px
    backgroundSize = "100% 50px;"
    backgroundPosition = "left top"
    borderBottomWidth = 1.px
    borderBottomColor =  Color.black
    borderBottomStyle = kotlinx.css.BorderStyle.solid
    borderLeftWidth = 1.px
    borderLeftColor = Color.black
    borderLeftStyle = kotlinx.css.BorderStyle.solid
  ///linearGradient(to top, rgba(0, 0, 0, 0.1) 2%, rgba(0, 0, 0, 0) 2%);
  }
  val barContainer by css {
    position = Position.relative
    display = Display.tableCell
    verticalAlign = VerticalAlign.bottom
    height = 200.px
  }
  val bar by css {
    margin = "0 1em"
    display = Display.block
    backgroundColor = Color.red
    transition += Transition("all", 555.ms, Timing.easeInOut, 0.s)
    borderRadius = 3.px
    // Bar label
    before {
      position = Position.absolute
      left = 0.px
      right = 0.px
      top = 100.pct
      padding = "5px 1em 0"
      display = Display.block
      textAlign = TextAlign.center
      wordWrap = WordWrap.breakWord
    }
    after {
      position = Position.relative
      paddingTop = 5.px
      color = Color.white
      fontSize = 14.px
      fontWeight = FontWeight.bold
      textAlign = TextAlign.center
      display = Display.block
      textAlign = TextAlign.center
      wordWrap = WordWrap.breakWord
    }
  }
  val barChartTitle by css {
    fontSize = 22.px
    marginBottom = 30.px
  }
}