package com.jet.classroomhero.usecases

import com.jet.classroomhero.SuspendWrapper
import com.jet.classroomhero.Utils
import com.jet.classroomhero.data.ClassRepository
import com.jet.classroomhero.entities.*

class ClassUseCases(private val classRepository: ClassRepository) {
    /**
     * Base repository calls
     */
    suspend fun addClass(
        group: Class,
        currenyIcon: ByteArray? = null,
        groupIcon: ByteArray? = null,
        groupCover: ByteArray? = null,
        templateId: Int? = null
    ): Class? = classRepository.createClass(group, currenyIcon, groupIcon, groupCover, templateId)

    suspend fun editClass(
        group: Class,
        currenyIcon: ByteArray? = null,
        groupIcon: ByteArray? = null,
        groupCover: ByteArray? = null
    ): Class? = classRepository.editClass(group, currenyIcon, groupIcon, groupCover)

    suspend fun deleteClass(classId: Int): Boolean = classRepository.deleteClass(classId)
    suspend fun getGroup(groupId: Int): Class = classRepository.getGroup(groupId)
    suspend fun getClasses(forceFetch: Boolean = false): List<Class> = classRepository.getClasses(forceFetch)
    suspend fun getMemberships(forceFetch: Boolean = false): List<Class> = classRepository.getMemberships(forceFetch)
    suspend fun getGroupStats(groupId: Int): GroupStats = classRepository.getGroupStats(groupId)
    suspend fun resetGroup(groupId: Int): Boolean = classRepository.resetGroup(groupId)
    suspend fun uploadCurrencyIcon(groupId: Int, bytes: ByteArray) = classRepository.updateCurrencyIcon(groupId, bytes)
    suspend fun fetchStockMedia(): StockGroupMediaResponse = classRepository.fetchStockGroupMedia()
    suspend fun completeInvite(classHash: String): Class? = classRepository.completeInvite(classHash)
    suspend fun leaveGroup(groupId: Int) = classRepository.leaveGroup(groupId)
    suspend fun completeStudentInvite(studentHash: String) = classRepository.completeStudentInvite(studentHash)

    /**
     * iOS Calls
     */
    // keeping around for backwards compatibility
    fun addClassIos(name: String, grade: String, teacher: User? = null) = SuspendWrapper { addClassIos(Class(name = name, description = grade)) }
    // new iOS call that takes a class object
    fun addClassIos(
        group: Class,
        currencyIconFilePath: String? = null,
        groupIconFilePath: String? = null,
        groupCoverFilePath: String? = null,
        templateId: Int? = null
    ) = SuspendWrapper {
        // Use withContext to make this happen synchronously
        var iconByteArray: ByteArray? = null
        try{
            iconByteArray = Utils.fileToByteArray(currencyIconFilePath)
        } catch(t: Throwable) {
            iconByteArray = null
        }

        var logoByteArray: ByteArray? = null
        if (groupIconFilePath != null) {
            logoByteArray = Utils.fileToByteArray(groupIconFilePath)
        }

        addClass(group, iconByteArray, logoByteArray, null, templateId)
    }

    fun editClassIos(
        updatedClass: Class,
        currencyIconFilePath: String? = null,
    ) = SuspendWrapper {
        var iconByteArray: ByteArray? = null
        try{
            iconByteArray = Utils.fileToByteArray(currencyIconFilePath)
        }catch(t: Throwable) {
            iconByteArray = null
        }
        editClass(updatedClass, iconByteArray)
    }

    fun deleteClassIos(classId: Int) = SuspendWrapper { deleteClass(classId) }
    fun getClassesIos(forceFetch: Boolean = false) = SuspendWrapper { getClasses(forceFetch) }
    fun getGroupStatsIos(groupId: Int) = SuspendWrapper { getGroupStats(groupId) }

    fun completeInviteIos(classHash: String) = SuspendWrapper { completeInvite(classHash)}
    fun completeStudentInviteIos(studentHash: String) = SuspendWrapper { completeStudentInvite(studentHash)}
    fun getMembershipsIos(forceFetch: Boolean) = SuspendWrapper {getMemberships(forceFetch)}
    // NOTE: Haven't needed to use the one-off end point yet, uploads occur via create / edit class
    fun uploadCurrencyIconIos(groupId: Int, filePath: String) = SuspendWrapper {
        //uploadCurrencyIcon(groupId, bytes)
    }

    fun fetchStockMediaIos() = SuspendWrapper{fetchStockMedia()}
}