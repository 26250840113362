package com.jet.classroomhero.usecases

import com.jet.classroomhero.SuspendWrapper
import com.jet.classroomhero.Utils
import com.jet.classroomhero.data.UserRepository
import com.jet.classroomhero.entities.PlatformTypes
import com.jet.classroomhero.entities.User

class UserUseCases(private val userRepository: UserRepository) {
    // Base repository calls
    suspend fun login(email: String, username: String, password: String): User = userRepository.login(email, username, password)
    suspend fun registerUser(user: User): User? = userRepository.register(user)
    suspend fun loginOrRegisterWithGoogleBase(googleIdToken: String, role: String = ""): User = userRepository.loginOrRegisterWithGoogle(googleIdToken, role)
    suspend fun loginOrRegisterWithFacebook(facebookIdToken: String, userId: String, role: String = ""): User = userRepository.loginOrRegisterWithFacebook(facebookIdToken, userId, role)
    suspend fun loginOrRegisterWithAppleBase(appleIdToken: String, role: String = ""): User = userRepository.loginOrRegisterWithApple(appleIdToken, role)
    suspend fun userIsAuthenticatedBase(): Boolean = userRepository.userIsAuthenticated()
    suspend fun readUser(): User? = userRepository.readUser()
    suspend fun saveUser(user: User) = userRepository.saveUser(user)
    suspend fun changePassword(newPassword: String): Boolean = userRepository.changePassword(newPassword)
    suspend fun changeUsername(username: String): User? = userRepository.changeUsername(username)
    suspend fun changeEmail(email: String): Boolean = userRepository.changeEmail(email)
    suspend fun requestPasswordReset(email: String): Boolean = userRepository.requestPasswordReset(email)
    suspend fun deleteAccount(): Boolean = userRepository.deleteAccount()
    suspend fun editAccount(firstName: String, lastName: String, email: String, username: String, role: String = "teacher"): User? = userRepository.editAccount(firstName, lastName, email, username, role)
    suspend fun logout(): Boolean = userRepository.logout()
    suspend fun userDetail(): User = userRepository.userDetail()
    suspend fun finishAccount(firstName: String, password: String): User? = userRepository.finishAccount(firstName, password)
    suspend fun updateProfilePhoto(byteArray: ByteArray): String = userRepository.updateProfilePhoto(byteArray)
    suspend fun updateCoverPhoto(byteArray: ByteArray): String = userRepository.updateCoverPhoto(byteArray)
    suspend fun createFCMDevice(token: String, platform: String): Boolean = userRepository.createFCMDevice(token, platform)

    // Optional calls for iOS that uses SuspendWrapper that make success / response callbacks cleaner
    fun registerUserIos(user: User) = SuspendWrapper { registerUser(user) }
    fun logoutIos() = SuspendWrapper{ logout() }
    fun loginIos(email: String, username: String, password: String) = SuspendWrapper { login(email, username, password) }
    fun loginOrRegisterWithGoogleIos(googleIdToken: String, role: String) = SuspendWrapper { loginOrRegisterWithGoogleBase(googleIdToken, role) }
    fun loginOrRegisterWithFacebookIos(facebookIdToken: String, userId: String, role: String) = SuspendWrapper { loginOrRegisterWithFacebook(facebookIdToken, userId, role) }
    fun loginOrRegisterWithAppleIos(appleIdToken: String, role: String) = SuspendWrapper { loginOrRegisterWithAppleBase(appleIdToken, role) }
    fun requestPasswordResetIos(email: String) = SuspendWrapper { requestPasswordReset(email) }
    fun userIsAuthenticatedIos() = SuspendWrapper { userIsAuthenticatedBase() }
    fun deleteAccountIos() = SuspendWrapper { deleteAccount() }
    fun editAccountIos(firstName: String, lastName: String) = SuspendWrapper {editAccount(firstName, lastName, "", "")}
    fun changePasswordIos(password: String) = SuspendWrapper {changePassword(password)}
    fun changeUsernameIos(username: String) = SuspendWrapper {changeUsername(username)}
    fun changeEmailIos(email: String) = SuspendWrapper {changeEmail(email)}
    fun finishAccountIos(name: String, password: String) = SuspendWrapper {finishAccount(name, password)}
    fun updateProfilePhotoIos(filePath: String) = SuspendWrapper {
        val fileByteArray = try{
            Utils.fileToByteArray(filePath)
        } catch(t: Throwable) {
            null
        }
        userRepository.updateProfilePhoto(fileByteArray)
    }
    fun updateCoverPhotoIos(filePath: String) = SuspendWrapper {
        val fileByteArray = try{
            Utils.fileToByteArray(filePath)
        } catch(t: Throwable) {
            null
        }
        userRepository.updateCoverPhoto(fileByteArray)
    }
    fun createFCMDeviceIos(token: String) = SuspendWrapper { createFCMDevice(token, PlatformTypes.Ios.label) }
}
