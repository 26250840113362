package com.jet.classroomhero.data.remote.sources

import com.jet.classroomhero.data.RemoteItemSource
import com.jet.classroomhero.data.LocalUserSource
import com.jet.classroomhero.entities.Item
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext

class NetworkItemSource(localUserSource: LocalUserSource) : ProtectedNetworkSource(localUserSource), RemoteItemSource {

    override suspend fun createItem(newItem: Item, classId: Int): Item? = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/item/create/"
        return@withContext client.post(url) {
            contentType(ContentType.Application.Json)
            setBody(newItem)
        }.body()
    }

    override suspend fun editItem(updatedItem: Item, classId: Int): Item? = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/item/${updatedItem.id}/edit/"
        return@withContext client.put(url) {
            contentType(ContentType.Application.Json)
            setBody(updatedItem)
        }.body()
    }

    override suspend fun deleteItem(itemId: Int, classId: Int): Boolean = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/item/${itemId}/delete/"
        val response: HttpResponse = client.delete(url)
        return@withContext response.status.isSuccess()
    }

    override suspend fun fetchItems(classId: Int): List<Item> = withContext(Dispatchers.Default) {
        val url = "${baseUrl}api/classes/${classId}/items/"
        return@withContext client.get(url) {
            contentType(ContentType.Application.Json)
        }.body()
    }
}