package components.widgets

import components.widgets.CircularLoaderStyle.loaderStyle
import components.widgets.CircularLoaderStyle.loaderWrapperStyle
import styled.*
import styled.styledDiv
import kotlinx.css.*
import kotlinx.css.properties.IterationCount
import kotlinx.css.properties.Timing
import kotlinx.css.properties.deg
import kotlinx.css.properties.rotate
import kotlinx.css.properties.s
import kotlinx.css.properties.transform
import react.RBuilder

fun RBuilder.indeterminateCircularProgress(
  size: LinearDimension = 24.px,
  color: Color = Color.white
) {
  styledDiv {
    css(loaderWrapperStyle)
    css {
      height = size
      width = size
    }
    styledDiv {
      css {
        height = size
        width = size
        borderBottomColor = color
        borderLeftColor = color
      }
      css(loaderStyle)

    }
  }
}

object CircularLoaderStyle : StyleSheet("CircularLoaderStyle", isStatic = true) {
  val loaderWrapperStyle by css {
    display = Display.flex
    justifyContent = JustifyContent.center
    alignItems = Align.center
  }

  val loaderStyle by css {
    borderRadius = 50.pct
    borderStyle = BorderStyle.solid
    borderWidth = 3.px
    backgroundColor = Color.transparent
    borderRightColor = Color.transparent
    borderTopColor = Color.transparent
    zIndex = 9
    animation(
      1.s, // Duration
      iterationCount = IterationCount.infinite,
      timing = Timing.linear,
      builder = {
        from { transform { rotate(0.deg) } }
        to { transform { rotate(360.deg) } }
      }
    )
  }
}


