package components.widgets

import kotlinx.css.*
import kotlinx.css.properties.BoxShadow
import react.RBuilder
import styled.*

fun RBuilder.imageWidget(
    src: String,
    size: LinearDimension = 50.px,
    radius: LinearDimension = 50.pct,
    customCss: RuleSet = { }
) {
    styledImg {
        css {
            height = size
            width = size
            borderRadius = radius
            objectFit = ObjectFit.cover
        }
        css(customCss)
        attrs.src = src
    }
}

fun RBuilder.circularNamePlaceholder(
    firstName: String,
    bgColor: String = "#1CB3E4",
    size: LinearDimension = 50.px,
    textSize: LinearDimension = 18.px,
    letterColor: String = "#fff"
) {
    styledDiv {
        css {
            height = size
            width = size
            borderRadius = 50.pct
            backgroundColor = Color(bgColor)
            display = Display.flex
            alignItems = Align.center
            justifyContent = JustifyContent.center
            boxShadow += BoxShadow(false, 0.px, 0.px, 5.px, 1.px, rgba(0, 0, 0, 0.35))
//            boxShadow = rgba(0, 0, 0, 0.35) 0px 5px 15px;
        }
        styledSpan {
            css {
                textAlign = TextAlign.center
                color = Color(letterColor)
                fontWeight = FontWeight.bold
                fontSize = textSize
            }
            + firstName.first().uppercase()
        }
    }
}

fun RBuilder.faIcon(
    faIcon: String = "fa-users",
    iconSize: LinearDimension = 22.px,
    iconColor: Color = Color("#fff"),
) {
    styledI {
        css {
            fontSize = iconSize
            color = iconColor
            classes.add("fa")
            classes.add(faIcon)
        }
    }
}

fun RBuilder.circularFaIcon(
    faIcon: String = "fa-users",
    size: LinearDimension = 50.px,
    iconSize: LinearDimension = 22.px,
    iconColor: Color = Color("#fff"),
    bgColor: Color = Color("#1976d2"),
    radius: LinearDimension = 50.pct,
    showEditIcon: Boolean = false
) {
    styledDiv {
        css {
            display = Display.flex
            position = Position.relative
            alignItems = Align.center
            justifyContent = JustifyContent.center
            padding = "6px"
            borderRadius = radius
            objectFit = ObjectFit.cover
            backgroundColor = bgColor
            height = size
            width = size
        }
        styledI {
            css {
                fontSize = iconSize
                color = iconColor
                classes.add("fa")
                classes.add(faIcon)
            }
        }
        if (showEditIcon) {
            circularEditIcon()
        }
    }
}

fun RBuilder.circularEditIcon(
    size: LinearDimension = 22.px,
    iconSize: LinearDimension = 16.px,
    iconColor: Color = Color("#fff"),
    bgColor: Color = Color("#aaaaaa")
) {
    styledDiv {
        css {
            display = Display.flex
            justifyContent = JustifyContent.center
            alignItems = Align.center
            position = Position.absolute
            right = 0.px
            bottom = 0.px
            backgroundColor = bgColor
            height = size
            width = size
            padding = "2px"
            borderRadius = 50.pct
        }
        styledI {
            css {
                fontSize = iconSize
                color = iconColor
                classes.add("fa")
                classes.add("fa-edit")
            }
        }
    }
}