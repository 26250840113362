package components.settings

import com.jet.classroomhero.UtilsCommon
import com.jet.classroomhero.entities.*
import components.dashboard.DashboardCss
import components.modals.ModalType
import components.widgets.*
import kotlinx.browser.document
import kotlinx.css.*
import kotlinx.css.properties.BoxShadow
import kotlinx.css.properties.Timing
import kotlinx.css.properties.Transition
import kotlinx.css.properties.s
import kotlinx.html.InputType
import kotlinx.html.js.onClickFunction
import kotlinx.html.js.onChangeFunction
import org.w3c.dom.HTMLInputElement
import react.*
import styled.*
import styles.GlobalCss


external interface AccountSettingsProps : Props {
    // state variables
    var user: User
    var isEditing: Boolean
    var errorMessage: String

    // dispatch methods
    var editAccount: (firstName: String, lastName: String) -> Unit
    var changeUsername: (userName: String) -> Unit
    var changeEmail: (email: String) -> Unit
}

external interface AccountSettingsLocalState: State {
    var firstName: String
    var username: String
    var errorMessage: String
    var email: String
}

@JsExport
class AccountSettingsComponent(props: AccountSettingsProps) : RComponent<AccountSettingsProps, AccountSettingsLocalState>(props) {

    override fun AccountSettingsLocalState.init(props: AccountSettingsProps) {
        firstName = ""
        username = ""
        errorMessage = props.errorMessage
        email = ""
    }

    override fun componentDidMount() {
        val userToken = props.user.token ?: ""
        if (userToken.isEmpty()) {
            console.log("USER TOKEN EMPTY")
            document.location?.href = "/login"
            return
        }
    }

    override fun RBuilder.render() {
        styledDiv{
            styledDiv {
                css(DashboardCss.header)
                css {
                    paddingTop = 14.px
                    paddingBottom = 14.px
                    paddingLeft = 18.px
                    paddingRight = 224.px
                }

                styledImg {
                    attrs.onClickFunction = {
                        document.location?.href = "/"
                    }
                    css {
                        height = 50.px
                    }
                    attrs.src = "/images/ch_logo_alt.png"
                }

                styledDiv {
                    css(GlobalCss.flexRow)
                    css {
                        justifyContent = JustifyContent.spaceBetween
                        gap = 16.px
                    }
//                    NotificationsDropDown {
//                        attrs.completedAchievements = props.completedAchievements
//                        attrs.groups = props.memberships
//                        attrs.setAchievementSeen = props.setAchievementSeen
//                    }
                    userDropDown(props.user.firstName)
                }
            }





            styledDiv{
                css(GlobalCss.flexCenter)
                css(GlobalCss.flexRow)
                css {
                    paddingTop = 50.px
                }

                styledDiv{
                    css(GlobalCss.flexColumn)
                    css{
                        maxWidth = 600.px
                        gap = 12.px
                        justifyContent = JustifyContent.left
                        width = 100.pct
                    }
                    styledH1{
                        + "Settings"
                    }
                    if (state.errorMessage.isNotEmpty() || props.errorMessage.isNotEmpty()) {
                        styledP {
                            css(GlobalCss.errorText)
                            css {
                                marginTop = 8.px
                                marginBottom = 8.px
                            }
                            + state.errorMessage.ifEmpty { props.errorMessage }
                        }
                    }


                    styledInput{
                        attrs.type = InputType.text
                        attrs.placeholder = props.user.email ?: ""
                        attrs.value = state.email
                        attrs.onChangeFunction = { event ->
                            setState{
                                val target = event.target as HTMLInputElement
                                email = target.value
                            }
                        }
                    }

                    Button(
                        text = "Edit email"
                    ) {
                        if (!UtilsCommon.isValidEmail(state.email)){
                            setState {
                                errorMessage = "Email must be at least 2 characters"
                            }
                        }
                        else {
                            props.changeEmail(state.email)
                        }

                    }

                    styledInput{
                        attrs.type = InputType.text
                        attrs.placeholder = props.user.firstName ?: ""
                        attrs.value = state.firstName
                        attrs.onChangeFunction = { event ->
                            setState{
                                val target = event.target as HTMLInputElement
                                firstName = target.value
                            }
                        }
                    }

                    Button(
                        text = "Edit name"
                    ) {
                        if (state.firstName.length < 2){
                            setState {
                                errorMessage = "Name must be at least 2 characters"
                            }
                        }
                        else {
                            props.editAccount(state.firstName, "")
                        }

                    }

                    styledInput{
                        attrs.type = InputType.text
                        attrs.placeholder = props.user.username ?: "Username"
                        attrs.value = state.username
                        attrs.onChangeFunction = { event ->
                            setState{
                                val target = event.target as HTMLInputElement
                                username = target.value
                            }
                        }
                    }


                    Button(
                        text = "Edit username"
                    ) {
                        if (state.username.count() > 2 && state.username != props.user.username){
                            props.changeUsername(state.username)
                        }
                        else{
                            setState {
                                errorMessage = "Username must be at least 2 characters"
                            }
                        }

                    }
                }
            }
        }
    }
}
