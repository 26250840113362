package components.modals.groups

import components.accounts.LoginCardCss
import components.forms.BaseFormProps
import components.profile.ProfileLocalState
import components.profile.ProfileProps
import components.widgets.Button
import components.widgets.Modal
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.html.js.onChangeFunction
import org.w3c.dom.HTMLInputElement
import react.RBuilder
import react.fc
import react.useState
import styled.*

fun RBuilder.joinGroupModal(
  props: ProfileProps,
  state: ProfileLocalState
) {
  Modal(onClose = {
    props.showModal(false, null)
    props.setEditing(false)
  }) {
    styledDiv {
      css {
        display = Display.flex
        alignItems = Align.center
        flexDirection = FlexDirection.column
      }
      styledH3 {
        css {
          margin = "12px 0"
        }
        + "Join a Class"
      }
      JoinGroupForm {
        attrs.onSubmit = { code ->
          props.joinGroup(code)
          props.showModal(false, null)
        }
        attrs.isLoading = props.isLoading
      }
    }
  }
}

external interface JoinGroupFormProps : BaseFormProps {
  var onSubmit: (String) -> Unit
}

val JoinGroupForm = fc<JoinGroupFormProps> { props ->
  val (groupCode, setGroupCode) = useState("")
  styledForm {
    css {
      display = Display.flex
      flexDirection = FlexDirection.column
      alignItems = Align.center
      justifyContent = JustifyContent.spaceEvenly
    }
    styledInput {
      css(LoginCardCss.inputStyle)
      css {
        marginBottom = 20.px
      }
      attrs.value = groupCode
      attrs.type = InputType.text
      attrs.placeholder = "Class Code"
      attrs.onChangeFunction = { event ->
        val target = event.target as HTMLInputElement
        setGroupCode(target.value)
      }
    }
    Button("Join Class", loading = props.isLoading) {
      it.preventDefault()
      if (groupCode.isNotEmpty()) {
        props.onSubmit(groupCode)
      }
    }
  }
}