package components.dashboard.members

import com.jet.classroomhero.combineNames
import com.jet.classroomhero.entities.*
import components.dashboard.DashboardCss
import components.dashboard.DashboardProps
import components.modals.ModalType
import components.modals.achievements.uploadFeedbackModal
import components.widgets.*
import kotlinx.browser.window
import kotlinx.css.*
import kotlinx.css.properties.BoxShadow
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import react.Props
import react.RBuilder
import react.fc
import styled.*
import styles.GlobalCss
import utils.Utils

external interface MemberDetailComponentProps : Props {
  var parentProps: DashboardProps
  var currentUploads: List<ReinforcerUpload>?
  var isManager: Boolean?
  var onActionSelected: (ModalType) -> Unit
  var linkedUser: User?
  var customCss: (RuleSet) -> Unit

}

val MemberDetailComponent = fc<MemberDetailComponentProps> { props ->
  val member = props.parentProps.selectedMember ?: Student()
  val group = props.parentProps.currentGroup!!
  styledDiv {
    attrs.id = "student-" + member.id.toString() + "-detail"
    css(DashboardCss.memberDetailContainer)
    styledDiv {
      css(GlobalCss.flexRow)
      // show edit button if not manager and no linked user
      if (props.isManager == true && props.linkedUser == null) {
        Button(
          text = "Student Code: ${member.studentHash}",
          faIcon = "fa-copy",
          iconWidth = 18.px,
          customCss = {
            position = Position.absolute
            right = 25.px
            top = 25.px
            maxWidth = 200.px
            borderRadius = 0.px
            borderWidth = 0.px
          }
        ) {
          js("copyToClipboard(member.studentHash)")
          props.parentProps.showSnackBar(true, "Student code copied to clipboard")
        }

        Button(
          text = "Edit",
          faIcon = "fa-edit",
          customCss = {
            position = Position.absolute
            left = 25.px
            top = 25.px
            maxWidth = 200.px
            borderRadius = 0.px
            borderWidth = 0.px
          }
        ) {

          props.onActionSelected(ModalType.EDIT_MEMBER)
        }
      }
    }
//    styledDiv {
//      css(DashboardCss.memberInfoSection)
//      styledDiv {
//        css(GlobalCss.flexRow)
//        css {
//          justifyContent = JustifyContent.spaceEvenly
//          alignItems = Align.center
//          marginTop = 60.px
//        }
//        /* LEVEL SECTION */
//        labelAndValue(
//          "Level",
//          member.level.toString()
//        )
//        /* MEMBER PHOTO SECTION */
//        styledDiv {
//          css(DashboardCss.memberPhoto)
//          css {
//            flexDirection = FlexDirection.column
//          }
//          if (!props.linkedUser?.photoUrl.isNullOrEmpty()) { // first try photo from user
//            imageWidget("${props.linkedUser?.photoUrl}", 75.px)
//          } else if (!member.photo.isNullOrEmpty()) { // else try member photo
//            imageWidget("${member.photo}", 75.px)
//          } else { // else use placeholder
//            circularNamePlaceholder(member.firstName, size = 75.px)
//          }
//          /* MEMBER NAME */
//          styledDiv {
//            styledP {
//              css {
//                textAlign = TextAlign.center
//                fontSize = 26.px
//                marginTop = 18.px
//                marginBottom = 18.px
//              }
//              + member.combineNames()
//            }
//          }
//        }
//        /* POINTS SECTION */
//        labelAndValue(
//          group?.currencyName ?: "Currency",
//          member.currentCoins.toString()
//        )
//      }
//      /* MEMBER PROGRESS */
//      styledDiv {
//        css(GlobalCss.flexColumn)
//        css(GlobalCss.flexCenter)
//        styledDiv {
//          css {
//            display = Display.flex
//            justifyContent = JustifyContent.spaceBetween
//            fontSize = 12.px
//            maxWidth = 500.px
//            width = 100.pct
//          }
//          styledSpan {
//            + "${member.floatingProgress * 100}% to next level"
//          }
//          styledSpan {
//            + "XP ${member.progress}/${member.level * 10}"
//          }
//        }
//        progressBar(member.floatingProgress * 100)
//      }
//    }
    /* MEMBER ACTIONS */
    if (props.isManager == true) {
      managerActions(props)
    }
    styledDiv {
      css(DashboardCss.statsRow)
      props.currentUploads?.filter {
        it.status == UploadStatus.PENDING.status ||
        it.status == UploadStatus.REJECTED.status
      }?.let { uploads ->
        uploadList(
          props = props.parentProps,
          label = "Pending Achievements",
          emptyLabel = "You haven\'t started any achievements yet.",
          uploads = uploads,
          group = group
        )
      }
    }
    styledDiv {
      css(DashboardCss.statsRow)
      recentAchievements(props, member.achievements.sortedByDescending { it.createdAt }.take(5))
      recentTransactions(props, member.transactions.sortedByDescending { it.createdAt }.take(5))
    }
  }
}

fun RBuilder.uploadList(
  props: DashboardProps,
  label: String = "Uploads",
  emptyLabel: String = "You have no uploads",
  uploads: List<ReinforcerUpload>,
  group: Class
) {
  styledDiv {
    css(GlobalCss.flexColumn)
    css(BarChartCss.statContainer)
    css {
      maxWidth = 950.px
    }
    css {
      justifyContent = JustifyContent.start
      flexDirection = FlexDirection.column
      cursor = Cursor.pointer
      hover {
        // deeper box shadow on hover
        boxShadow += BoxShadow(false, 0.px, 8.px, 16.px, 0.px, Color.lightSlateGray)
      }
    }
    attrs.onClickFunction = {
//      if (achievements.isNotEmpty()) {
//        props.onActionSelected(ModalType.ALL_ACHIEVEMENTS)
//      }
    }
    styledP {
      css {
        fontSize = 18.px
        marginBottom = 4.px
      }
      + label
    }
    if (uploads.isNotEmpty()) {
      styledP {
        css {
          color = Color("#AAAFB4")
          fontSize = 14.px
          marginBottom = 20.px
        }
        + "These requirements are still under review by the teacher"
      }
    }
    if (uploads.isNotEmpty()) {
      uploads.forEach { upload ->
        val achievement = group.reinforcers.find { it.id == upload.achievementId }
        if (achievement != null) {
          pendingRequirement(
            props = props,
            achievementName = achievement.name ?: "",
            upload = upload
          )
        }
      }
    } else {
      styledP {
        css {
          color = Color("#AAAFB4")
          fontSize = 16.px
        }
        + emptyLabel
      }
    }
  }
}

fun RBuilder.recentAchievements(props: MemberDetailComponentProps, achievements: List<Achievement>) {
  styledDiv {
    css(GlobalCss.flexColumn)
    css(BarChartCss.statContainer)
    css {
      justifyContent = JustifyContent.start
      flexDirection = FlexDirection.column
      cursor = Cursor.pointer
      hover {
        // deeper box shadow on hover
        boxShadow += BoxShadow(false, 0.px, 8.px, 16.px, 0.px, Color.lightSlateGray)
      }
    }
    attrs.onClickFunction = {
      if (achievements.isNotEmpty()) {
        props.onActionSelected(ModalType.ALL_ACHIEVEMENTS)
      }
    }
    styledP {
      css {
        fontSize = 18.px
        marginBottom = 20.px
      }
      + "Completed Achievements"
    }
    if (achievements.isNotEmpty()) {
      achievements.sortedByDescending { it.createdAt }.forEach {
        recentItem(
          name = it.achievementName,
          date = it.createdAt,
          amount = it.achievementValue,
          currencyImgUrl = props.parentProps.currentGroup?.currencyIconUrl!!,
          isLoss = false
        )
      }
    } else {
      styledP {
        css {
          color = Color("#AAAFB4")
          fontSize = 16.px
        }
        + "You haven\'t completed any achievements yet!"
      }
    }
  }
}

fun RBuilder.recentTransactions(props: MemberDetailComponentProps, transactions: List<Transaction>) {
  styledDiv {
    css(GlobalCss.flexColumn)
    css(BarChartCss.statContainer)
    css {
      justifyContent = JustifyContent.start
      flexDirection = FlexDirection.column
      cursor = Cursor.pointer
      hover {
        // deeper box shadow on hover
        boxShadow += BoxShadow(false, 0.px, 8.px, 16.px, 0.px, Color.lightSlateGray)
      }
    }
    attrs.onClickFunction = {
      if (transactions.isNotEmpty()) {
        props.onActionSelected(ModalType.ALL_TRANSACTIONS)
      }
    }
    styledP {
      css {
        fontSize = 18.px
        marginBottom = 20.px
      }
      + "Recent Purchases"
    }
    if (transactions.isNotEmpty()) {
      transactions.forEach {
        recentItem(
          name = it.itemName,
          date = it.createdAt,
          amount = it.itemCost,
          currencyImgUrl = props.parentProps.currentGroup?.currencyIconUrl!!,
          isLoss = true
        )
      }
    } else {
      styledP {
        css {
          color = Color("#AAAFB4")
          fontSize = 16.px
        }
        + "You haven\'t purchased any items yet!"
      }
    }
  }
}

fun RBuilder.pendingRequirement(
  props: DashboardProps,
  achievementName: String,
  upload: ReinforcerUpload
) {
  if (props.selectedModalType == ModalType.UPLOAD_FEEDBACK_MODAL) {
    upload.let {
      uploadFeedbackModal(props, upload.feedback)
    }
  }
  styledDiv {
    css(GlobalCss.flexRow)
    css {
      alignItems = Align.center
      justifyContent = JustifyContent.spaceEvenly
      padding = "15px"
      width = 100.pct
      borderBottomColor = Color("#eee")
      borderBottomWidth = 1.px
      borderBottomStyle = kotlinx.css.BorderStyle.solid
    }
    styledDiv {
      css(GlobalCss.flexRow)
      css {
        gap = 14.px
        alignItems = Align.center
      }
      StatusIcon(upload.status)
      styledDiv {
        css(GlobalCss.flexColumn)
        styledP {
          css {
            fontWeight = FontWeight("500")
            marginBottom = 1.px
          }
          + achievementName
        }
        styledDiv {
          css(GlobalCss.flexRow)
          css {
            gap = 8.px
          }
          styledSpan {
            css {
              color = Color("#000")
              fontSize = 14.px
            }
            + "Uploaded on: "
          }
          styledSpan {
            css {
              color = Color("#AAAFB4")
              fontSize = 14.px
            }
            + Utils.formatDate(upload.createdAt)
          }
        }
        styledDiv {
          css(GlobalCss.flexRow)
          css {
            gap = 8.px
          }
          styledSpan {
            css {
              color = Color("#000")
              fontSize = 14.px
            }
            + "File Type: "
          }
          styledSpan {
            css {
              color = Color("#AAAFB4")
              fontSize = 14.px
            }
            + upload.fileType.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
          }
        }


      }

      styledDiv {
        css(GlobalCss.flexRow)
        css(GlobalCss.flexCenter)
        css {
          gap = 12.px
        }
        Button(
          text = "View Feedback",
          faIcon = "fa-comments",
          bgColor = "#F57C00",
          iconWidth = 16.px
        ) {
          props.showModal(true, ModalType.UPLOAD_FEEDBACK_MODAL)
        }
        Button(
          text = "Download",
          faIcon = "fa-download",
          iconWidth = 16.px
        ) {
          window.open(upload.fileUrl, "_blank");
        }
        Button(
          text = "Remove",
          bgColor = "#B00020"
        ) {
          props.deleteRequirement(props.currentGroup!!.id, upload.id)
        }
      }
    }
  }
}

fun RBuilder.recentItem(
  name: String,
  date: String,
  amount: Int? = 0,
  currencyImgUrl: String? = "",
  isLoss: Boolean = false
) {
  styledDiv {
    css(GlobalCss.flexRow)
    css {
      alignItems = Align.center
      justifyContent = JustifyContent.spaceBetween
      padding = "15px"
      width = 100.pct
      borderBottomColor = Color("#eee")
      borderBottomWidth = 1.px
      borderBottomStyle = kotlinx.css.BorderStyle.solid
    }
    styledDiv {
      css(GlobalCss.flexColumn)
      styledP {
        + name
      }
      styledP {
        css {
          color = Color("#AAAFB4")
          fontSize = 14.px
        }
        + "Date: ${Utils.formatDate(date)}"
      }
    }
    if (amount != null) {
      styledDiv {
        css(GlobalCss.flexColumn)
        valueAndCurrencyIcon(
          amount = amount,
          isLoss = isLoss,
          currencyImgUrl = currencyImgUrl ?: "",
          iconSize = 16.px
        )
        if (!isLoss) {
          xpLabel()
        }
      }
    }
  }
}

fun RBuilder.managerActions(props: MemberDetailComponentProps) {
  styledDiv {
    css {
      marginTop = 18.px
      marginBottom = 18.px
      backgroundColor = Color.white
      paddingLeft = 120.px
      paddingRight = 120.px
      paddingTop = 12.px
      paddingBottom = 12.px
    }
    styledSpan {
      css {
        fontSize = 26.px
        marginBottom = 30.px
        display = Display.block
      }
      + props.parentProps.selectedMember!!.combineNames()
    }
    styledDiv {
      css {
        display = Display.flex
        justifyContent = JustifyContent.spaceAround
      }
      memberAction(
        "Adjust Currency",
        "fa-star",
        "#888888"
      ) {
        props.onActionSelected(ModalType.ADJUST_CURRENCY)
      }
      memberAction(
        "Complete Achievement",
        "fa-star",
        "#F57C00"
      ) {
        props.onActionSelected(ModalType.GIVE_ACHIEVEMENT)
      }
      memberAction(
        "Sell Item",
        "fa-shopping-bag",
        "#00796B"
      ) {
        props.onActionSelected(ModalType.SELL_ITEM)
      }
    }
  }
}

fun RBuilder.labelAndValue(
  label: String,
  value: String
) {
  styledDiv {
    css {
      flex(1.0, 0.0, FlexBasis.auto)
      justifyContent = JustifyContent.center
      alignItems = Align.center
      textAlign = TextAlign.center
    }
    styledP {
      css(DashboardCss.memberValueLabel)
      + label
    }
    styledP {
      css(DashboardCss.memberValue)
      + value
    }
  }
}

fun RBuilder.memberAction(
  label: String,
  faIcon: String,
  backgroundColor: String,
  onPress: () -> Unit = {}
) {
  styledDiv {
    attrs.onClickFunction = {
      onPress()
    }
    css {
      display = Display.flex
      flexDirection = FlexDirection.column
      alignItems = Align.center
      justifyContent = JustifyContent.center
      textAlign = TextAlign.center
      hover {
        cursor = Cursor.pointer
      }
    }
    styledDiv {
      css {
        marginBottom = 10.px
      }
      circularFaIcon(faIcon, bgColor = Color(backgroundColor))
    }
    styledSpan {
      + label
    }
  }
}
