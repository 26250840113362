package containers

import actions.AccountActions
import actions.AchievementActions
import actions.AppStateActions
import actions.GroupActions
import com.jet.classroomhero.entities.*
import components.modals.ModalType
import components.profile.ProfileComponent
import components.profile.ProfileProps
import react.ComponentClass
import react.Props
import react.invoke
import react.redux.rConnect
import redux.RAction
import redux.WrapperAction
import store.AppState

external interface GroupsStateProps : Props {
    var user: User
    var groups: List<Class>
    var memberships: List<Class>
    var templates: List<Template>
    var stockMedia: StockGroupMediaResponse?
    var modalShowing: Boolean
    var selectedModalType: ModalType?
    var isEditing: Boolean
    var isLoading: Boolean
    var isGlobalLoading: Boolean
    var completedAchievements: List<Achievement>
}

external interface GroupsDispatcherProps : Props {
    var showModal: (Boolean, ModalType?) -> Unit
    var setEditing: (Boolean) -> Unit
    var createGroup: (Class, ByteArray?, ByteArray?, ByteArray?, Int?) -> Unit
    var joinGroup: (String) -> Unit
    var deleteGroup: (Int) -> Unit
    var editGroup: (Class, ByteArray?, ByteArray?, ByteArray?) -> Unit
    var setSelectedGroup: (Int) -> Unit
    var setSelectedMembership: (groupId: Int, memberId: Int) -> Unit
    var fetchCompletedAchievements: () -> Unit
    var setAchievementSeen: (Int) -> Unit
    var populateProfile: () -> Unit
    var editRole: (role: String) -> Unit
}

val profileComponent: ComponentClass<Props> = rConnect<AppState, RAction, WrapperAction, Props, GroupsStateProps, GroupsDispatcherProps, ProfileProps>(
    { state, _ ->
        user = state.accountState.user
        groups = state.groups.groups
        memberships = state.groups.memberships
        templates = state.groups.templates
        stockMedia = state.groups.stockMedia
        modalShowing = state.appState.modalShowing
        isEditing = state.appState.isEditing
        selectedModalType = state.appState.selectedModalType
        isLoading = state.appState.isLoading
        isGlobalLoading = state.appState.isGlobalLoading
        completedAchievements = state.achievements.completedAchievements
    },
    { dispatch, _ ->
        showModal = { show, modalType -> dispatch(AppStateActions.SetModalShowing(show, modalType)) }
        createGroup = { group, groupIcon, currencyIcon, groupCover, templateId -> dispatch(GroupActions.createGroup(group, groupIcon, currencyIcon, groupCover, templateId)) }
        joinGroup = { groupCode -> dispatch(GroupActions.joinGroup(groupCode)) }
        deleteGroup = { groupId -> dispatch(GroupActions.deleteGroup(groupId)) }
        editGroup = { group, groupIcon, currencyIcon, groupCover -> dispatch(GroupActions.editGroup(group, groupIcon, currencyIcon, groupCover)) }
        setEditing = { editing -> dispatch(AppStateActions.SetIsEditing(editing)) }
        setSelectedGroup = { groupId -> dispatch(AppStateActions.setSelectedGroup(groupId)) }
        setSelectedMembership = { groupId, memberId -> dispatch(AppStateActions.setSelectedMembership(groupId, memberId)) }
        fetchCompletedAchievements = { dispatch(AchievementActions.getCompletedAchievementsForUser()) }
        setAchievementSeen = { achievementId -> dispatch(AchievementActions.setAchievementSeen(achievementId)) }
        populateProfile = { dispatch(GroupActions.populateProfile()) }
        editRole = { role ->  dispatch(AccountActions.setRole(role)) }
    }
)(ProfileComponent::class.js.unsafeCast<ComponentClass<ProfileProps>>())