package components.widgets

import components.widgets.RadioButtonCss.container
import components.widgets.RadioButtonCss.itemContainer
import kotlinx.css.*
import kotlinx.html.js.onClickFunction
import react.RBuilder
import styled.*

private object RadioButtonCss : StyleSheet("RadioButtonCss", isStatic = true) {
  val container by css {
    display = Display.flex
    flexDirection = FlexDirection.column
    borderBottomColor = Color("#ccc")
    borderBottomWidth = 1.px
    borderBottomStyle = BorderStyle.solid
    paddingBottom = 8.px
    paddingTop = 8.px
    hover {
      backgroundColor = Color("#eee")
      cursor = Cursor.pointer
    }
  }
  val itemContainer by css {
    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = Align.center
    maxHeight = 400.px
    overflow = Overflow.visible
    position = Position.relative
    paddingBottom = 2.px
    paddingTop = 2.px
    backgroundColor = Color.transparent
  }
}

fun RBuilder.RadioButton(
  isSelected: Boolean = false,
  labelContent: RBuilder.() -> Unit,
  disabled: Boolean = false,
  disabledMessage: String? = null,
  onPress: (Boolean) -> Unit = { console.warn("OnPress not implemented") }
) {
  styledDiv {
    css(container)
    attrs.onClickFunction = {
      if (!disabled) {
        if (isSelected) {
          onPress(false)
        } else {
          onPress(true)
        }
      }
    }
    styledDiv {
      css(itemContainer)
      styledDiv {
        css {
          height = 22.px
          width = 22.px
          marginRight = 8.px
          backgroundColor = if (isSelected) Color("#2196F3") else Color("#eeeeee")
          hover {
            if (!isSelected) {
              backgroundColor = Color("#36454F")
            } else {
              backgroundColor = Color("808080")
            }
            cursor = Cursor.pointer
          }
        }
        faIcon("fa-check", iconColor = Color.white)
      }
      styledLabel {
        css {
          hover {
            cursor = Cursor.pointer
          }
        }
        attrs.onClickFunction = {
          if (!disabled) {
            if (isSelected) {
              onPress(false)
            } else {
              onPress(true)
            }
          }
        }
        labelContent()
      }
    }
    if (disabled) {
      disabledMessage?.let {
        styledP {
          css {
            color = Color("#B00020")
            fontSize = 12.px
          }
          + disabledMessage
        }
      }
    }
  }
}