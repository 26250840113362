package components.modals.items

import com.jet.classroomhero.entities.Item
import components.dashboard.DashboardProps
import components.forms.CreateOrEditItemForm
import components.widgets.Modal
import kotlinx.css.*
import kotlinx.html.js.onClickFunction
import react.RBuilder
import styled.css
import styled.styledDiv
import styled.styledH3

fun RBuilder.renderCreateOrEditItemModal(props: DashboardProps, item: Item?) {
  val isEditing = item != null
  Modal(onClose = {
    props.showModal(false, null)
  }) {
    styledDiv {
      css {
        display = Display.flex
        alignItems = Align.center
        flexDirection = FlexDirection.column
        alignSelf = Align.center
        width = 100.pct
        textAlign = kotlinx.css.TextAlign.center
      }
      styledH3 {
        css {
          marginBottom = 20.px
        }
        + if (isEditing) "Edit Item" else "Create a New Item"
      }
      CreateOrEditItemForm {
        attrs.item = item
        attrs.onCreateItem = { newItem ->
          props.createItem(newItem, props.currentGroup!!.id)
          props.setSelectedItem(newItem)
        }
        attrs.onEditItem = { updatedItem ->
          props.editItem(updatedItem, props.currentGroup!!.id)
          props.setSelectedItem(updatedItem)
        }
        attrs.onDeleteItem = { itemId ->
          props.deleteItem(itemId, props.currentGroup!!.id)
          props.setSelectedItem(null)
        }
        attrs.isEditing = isEditing
        attrs.isLoading = props.isLoading
      }
    }
  }
}