package com.jet.classroomhero

/* Classes */
const val FEATURE_INFO_GROUP_TITLE = "Classes"
const val FEATURE_INFO_GROUP_DESCRIPTION_PART_1 = """
  Classes are at the core of Classroom Hero. They provide a way to define who will participate in your reward system.
"""
//const val FEATURE_INFO_GROUP_DESCRIPTION_PART_2= """
//  For example, c could be a class (such as an elementary classroom or gym class), a family guild
//        at home or used for your business. Gmfy is adaptable enough to handle just about any scenario.
//"""
//const val FEATURE_INFO_GROUP_DESCRIPTION_PART_3 = """
//  The people that participate in your cka are called "Guild Members" and you are the "Guild Master".
//"""
const val FEATURE_INFO_GROUP_DESCRIPTION_PART_4 = """
  In Classroom Hero, every class has its own currency. You can think of this as a virtual
  token or coin which the students of your class can earn.
"""
const val FEATURE_INFO_GROUP_DESCRIPTION_PART_5 = """
  Teachers will have the option of assigning a name and icon to the class's virtual currency.
  Don’t worry, we have several stock icons to choose from, or you can even upload your own!
  The system will default to “Coins” as the name, but an example might be "Sue Bucks" or "McCoins".
"""

/* MEMBERSHIPS */
const val FEATURE_INFO_MEMBERSHIPS_TITLE = "Memberships"
const val FEATURE_INFO_MEMBERSHIPS_DESCRIPTION_PART_1 = """
  Memberships are Classes that you belong to as Student. The only way to become a member of a class is to enter a Class Code,
  which can be obtained from your instructor.
"""
const val FEATURE_INFO_MEMBERSHIPS_DESCRIPTION_PART_2= """
  Once you have joined a class, you can begin to complete that class's achievements, earn the class's virtual currency and purchase items.
"""
const val FEATURE_INFO_MEMBERSHIPS_DESCRIPTION_PART_3 = """
  You can track your progress in the class by looking at your overall level and view a history of completed achievements and purchased items.
"""

/* MANAGER-VIEW MEMBERS */
const val FEATURE_INFO_MANAGER_VIEW_MEMBERS_TITLE = "Students"
const val FEATURE_INFO_MANAGER_VIEW_MEMBERS_DESCRIPTION_PART_1 = """
  Students are people that you add to your class, and can earn the currency, level up, and purchase items using the class's virtual currency.
"""
const val FEATURE_INFO_MANAGER_VIEW_MEMBERS_DESCRIPTION_PART_2= """
  Members can be added individually or in bulk, with each member name on a new line.
  Every new member that you add is going to start at level one and will have zero currency to their name.
  You may adjust how much currency an individual has, but changing a member’s current level is not permitted.
  Members can only level up by completing the class achievements which you create, and members cannot lose levels.
"""
const val FEATURE_INFO_MANAGER_VIEW_MEMBERS_DESCRIPTION_PART_3 = """
  To reward a member, you can do so from their member screen or you can award them with the bulk
  reward function in your achievements list. Likewise, for selling items this can be done from the member screen
  or with the bulk sell function in the marketplace.
"""

/* MANAGER-VIEW ACHIEVEMENTS */
const val FEATURE_INFO_MANAGER_VIEW_ACHIEVEMENTS_TITLE = "Achievements"
const val FEATURE_INFO_MANAGER_VIEW_ACHIEVEMENTS_DESCRIPTION_PART_1 = """
  Achievements are "tasks" that students complete to earn your virtual currency.
  Achievements award 1 point of experience, and this cannot be changed.
  However, you can change the value of the achievement to whatever you’d like.
"""
const val FEATURE_INFO_MANAGER_VIEW_ACHIEVEMENTS_DESCRIPTION_PART_2= """
  Achievements require the following:
"""
const val FEATURE_INFO_MANAGER_VIEW_ACHIEVEMENTS_DESCRIPTION_PART_3 = """
  Name: The name of the achievement. (Ex: Completed Extra Credit)
"""
const val FEATURE_INFO_MANAGER_VIEW_ACHIEVEMENTS_DESCRIPTION_PART_4 = """
  Value: The amount of currency a member earns upon completing this achievement.
"""

/* MANAGER-VIEW MARKETPLACE */
const val FEATURE_INFO_MANAGER_VIEW_MARKETPLACE_TITLE = "Marketplace"
const val FEATURE_INFO_MANAGER_VIEW_MARKETPLACE_DESCRIPTION_PART_1 = """
  The marketplace consists of "items". Items are virtual or physical goods which the teacher is responsible for inputting into the system and handing out to their members.

  Items only deduct member currency, not their level. Use the marketplace as a way to
  incentivize members to complete achievements!
"""
const val FEATURE_INFO_MANAGER_VIEW_MARKETPLACE_DESCRIPTION_PART_2= """
  Creating an item requires the following:
"""
const val FEATURE_INFO_MANAGER_VIEW_MARKETPLACE_DESCRIPTION_PART_3 = """
  Name: The name of the item. (Ex: New Pencil)
"""
const val FEATURE_INFO_MANAGER_VIEW_MARKETPLACE_DESCRIPTION_PART_4 = """
  Cost: The amount of currency a member must spend to have this item.
"""

/* MEMBER-VIEW ACHIEVEMENTS */
const val FEATURE_INFO_MEMBER_VIEW_ACHIEVEMENTS_TITLE = "Achievements"
const val FEATURE_INFO_MEMBER_VIEW_ACHIEVEMENTS_DESCRIPTION_PART_1 = """
  Achievements are "tasks" that you as the student complete to earn virtual currency and levels.
"""
const val FEATURE_INFO_MEMBER_VIEW_ACHIEVEMENTS_DESCRIPTION_PART_2= """
  Your teacher is responsible for adding achievements to the class. Check with your instructor on the requirements of completing
  each achievement.
"""

/* MEMBER-VIEW MARKETPLACE */
const val FEATURE_INFO_MEMBER_VIEW_MARKETPLACE_TITLE = "Marketplace"
const val FEATURE_INFO_MEMBER_VIEW_MARKETPLACE_DESCRIPTION_PART_1 = """
  The marketplace consists of "items". Items are virtual or physical goods which the teacher adds.
"""
const val FEATURE_INFO_MEMBER_VIEW_MARKETPLACE_DESCRIPTION_PART_2= """
  As the member, you can purchase items with the currency that you earn from completing achievements.
"""