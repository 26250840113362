package components.forms

import components.dashboard.DashboardCss
import components.dashboard.achievements.AchievementDetailComponentProps
import components.widgets.Button
import components.widgets.faIcon
import csstype.HtmlAttributes
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.html.fileInput
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import kotlinx.html.js.onDragOverFunction
import org.khronos.webgl.ArrayBuffer
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.EventTarget
import org.w3c.files.Blob
import org.w3c.files.File
import org.w3c.files.get
import react.*
import react.dom.onDrop
import styled.*
import styles.GlobalCss
import utils.toByteArray

external interface UploadRequirementsFormProps : BaseFormProps {
  var props: AchievementDetailComponentProps
  var onSubmit: (List<File>) -> Unit
}

val UploadRequirementsForm = fc<UploadRequirementsFormProps> { props ->
  val fileUploadRef = createRef<HTMLInputElement>()
  val (uploads, setUploads) = useState<List<File>>(emptyList())
  val (isDragging, setDragging) = useState(false)
  styledDiv {
    attrs.id  = "upload-requirements-form"
    css(DashboardCss.memberDetailContainer)
    // Drop container
    styledDiv {
      css {
        borderWidth = if (isDragging) 3.px else 2.px
        borderColor = if (isDragging) Color.green else Color.black
        borderStyle = kotlinx.css.BorderStyle.solid
        display = Display.flex
        minHeight = 240.px
        minWidth = 290.px
        justifyContent = JustifyContent.spaceEvenly
        alignItems = Align.center
        flexDirection = FlexDirection.column
        padding = "24px"
      }
      attrs.onClickFunction = {
        fileUploadRef.current?.click()
      }
      if (uploads.isEmpty()) {
        styledDiv {
          css {
            maxWidth = 240.px
            width = 100.pct
            display = Display.flex
            justifyContent = JustifyContent.center
            alignItems = Align.center
            flexDirection = FlexDirection.column
            gap = 16.px
          }
          styledInput {
            css {
                display = Display.none
            }
            ref = fileUploadRef
            attrs.type = InputType.file
            attrs.multiple = false
            attrs.onChangeFunction = { e ->
              e.preventDefault()
              val files = (e.target as HTMLInputElement).files
              if (files != null && files.length > 0 && files.item(0) != null) {
                val currentUploads = uploads.toMutableList()
                currentUploads.add(files.item(0)!!)
                setUploads(currentUploads)
              }
            }
          }
          styledDiv {

          }
          faIcon(
            faIcon = "fa-upload",
            iconSize = 24.px,
            iconColor = Color.gray
          )
          styledSpan {
            + "Drag and Drop Requirements"
          }
        }
      } else {
        uploads.forEach { file -> uploadedItem(file) }
      }
    }

    Button(
      text = "Upload",
      disabled = uploads.isEmpty(),
      customCss = {
        marginTop = 8.px
      },
      loading = props.isLoading
    ) {
      if (uploads.isNotEmpty()) {
        props.onSubmit(uploads)
        setUploads(emptyList())
      }
    }

    if (uploads.isNotEmpty()) {
      Button(
        text = "Cancel",
        bgColor = "#B00020",
        disabled = uploads.isEmpty(),
        customCss = {
          marginTop = 16.px
        },
      ) {
        setUploads(emptyList())
      }
    }

    attrs.onDrop = { e ->
      if (uploads.isEmpty()) {
        e.preventDefault()
        setDragging(false)
        val files = e.dataTransfer.files
        if (files.length > 0 && files.item(0) != null) {
          val currentUploads = uploads.toMutableList()
          currentUploads.add(files.item(0)!!)
          setUploads(currentUploads)
        }
      }
    }

    attrs.onDragOverFunction = { e ->
      if (uploads.isEmpty()) {
        e.preventDefault()
        setDragging(true)
      }
    }
  }
}

fun RBuilder.uploadedItem(file: File) {
  styledDiv {
    css(GlobalCss.flexColumn)
    css(GlobalCss.flexCenter)
    faIcon(
      iconColor = Color.black,
      iconSize = 24.px,
      faIcon = getIconForFileType(file.type)
    )
    styledP {
      + "${file.name.take(10)}..."
    }
  }
}

private fun getIconForFileType(fileType: String): String {
  return if (fileType.contains("image")) {
    "fa-image"
  } else if (fileType.contains("video")) {
    "fa-video"
  } else if (fileType.contains("text")) {
    "fa-file-text"
  } else {
    "fa-file"
  }
}