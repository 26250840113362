package components.widgets

import kotlinx.css.*
import react.RBuilder
import styled.css
import styled.styledDiv
import styled.styledSpan

enum class Side {
  LEFT,
  RIGHT
}

fun RBuilder.valueAndCurrencyIcon(
  amount: Int,
  amountSide: Side = Side.LEFT,
  currencyImgUrl: String,
  iconSize: LinearDimension =  15.px,
  amountSize: LinearDimension = 15.px,
  isLoss: Boolean = false,
) {
  styledDiv {
    css {
      display = Display.flex
      alignItems = Align.center
      justifyContent = JustifyContent.center
      flexDirection = FlexDirection.row
    }
    if (amountSide == Side.LEFT) {
      amount(amount, amountSize, isLoss)
    }
    imageWidget(
      src = currencyImgUrl,
      size = iconSize,
      customCss = {
        if (amountSide == Side.LEFT) {
          marginLeft = 6.px
        } else {
          marginRight = 6.px
        }
      }
    )
    if (amountSide == Side.RIGHT) {
      amount(amount, amountSize, isLoss)
    }
  }
}

fun RBuilder.amount(
  amount: Int,
  amountSize: LinearDimension = 15.px,
  isLoss: Boolean
) {
  val sign = if (isLoss) "-" else "+"
  val textColor = Color(if (isLoss) "#B00020" else "#018787")
 styledSpan {
    css {
      flexShrink = 0.0
      fontSize = amountSize
      color = textColor
    }
    + "$sign $amount"
  }
}