package com.jet.classroomhero.data.remote.sources

import com.jet.classroomhero.Utils
import com.jet.classroomhero.data.remote.clients.AnonymousClient
import io.ktor.client.*
import io.ktor.client.engine.*

open class AnonymousNetworkSource(engine: HttpClientEngine? = null) {
    private val anonClient = AnonymousClient(engine)
    val baseUrl = Utils.baseUrl
    val client: HttpClient = anonClient.httpClient
}