package components.dashboard.extensions.jar

import com.jet.classroomhero.entities.Class
import com.jet.classroomhero.entities.Extension
import com.jet.classroomhero.entities.percentComplete
import components.accounts.LoginCardCss
import components.dashboard.DashboardCss
import components.dashboard.DashboardProps
import components.dashboard.getSelectedRouteColor
import components.forms.BaseFormProps
import components.modals.ModalType
import components.modals.extensions.renderCreateOrEditJarModal
import components.widgets.Button
import components.widgets.faIcon
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import org.w3c.dom.HTMLInputElement
import react.Props
import react.fc
import react.useEffect
import react.useEffectOnce
import react.useState
import styled.css
import styled.styledDiv
import styled.styledImg
import styled.styledInput
import styled.styledP
import styled.styledSpan
import styles.GlobalCss
import styles.GlobalCss.flexCenter
import styles.GlobalCss.flexColumn

external interface ExtensionComponentProps : BaseFormProps {
  var currentGroup: Class?
  var extension: Extension?
  var parentProps: DashboardProps
}

val JarExtensionComponent = fc<ExtensionComponentProps> { props ->
  val group = props.currentGroup
  if (group != null) {
    if (props.parentProps.selectedJar != null) {
      JarDetailComponent {
        attrs.parentProps = props.parentProps
      }
    } else {
      styledDiv {
        css(GlobalCss.flexColumn)
        /* Controls */
        styledDiv {
          css(GlobalCss.flexRow)
          css {
            maxWidth = 260.px
            padding = "12px"
          }
          Button(
            text = "Add Class Jar",
            faIcon = "fa-plus",
            customCss = {
              width = 100.pct
              borderRadius = 0.px
              borderWidth = 0.px
            }
          ) {
            props.parentProps.showModal(true, ModalType.CREATE_JAR)
          }
        }
        /* Grid View */
        styledDiv {
          css(DashboardCss.grid)
          for (jar in group.jars) {
            styledDiv {
              css(DashboardCss.gridItem)
              attrs.onClickFunction = {
                props.parentProps.showModal(true, ModalType.JAR_DETAIL)
                props.parentProps.setSelectedJar(jar)
              }
              css {
                backgroundColor = getSelectedRouteColor(props.parentProps.selectedJar?.id == jar.id)
              }
              styledDiv {
                css(flexColumn)
                css(flexCenter)
                css {
                  gap = 6.px
                  padding = "8px"
                }
                styledP {
                  + jar.name
                }
                styledImg {
                  attrs.src = "/images/empty_jar.png"
                  css.width = 80.px
                }
                styledP {
                  + jar.percentComplete()
                }
              }
            }
          }
        }
      }
    }
  }

  if (props.parentProps.modalShowing) {
    when (props.parentProps.selectedModalType) {
      ModalType.CREATE_JAR -> {
        renderCreateOrEditJarModal(props.parentProps, props.parentProps.selectedJar)
      }
      else -> {}
    }
  }
}

val JarDetailComponent = fc<JarDetailProps> { props ->
  val (amount, setAmount) = useState(1)
  val (amountToAdd, setAmountToAdd) = useState(0)
  val (jarProgress, setJarProgress) = useState(props.parentProps.selectedJar?.progress ?: 0)
  val (disableButton, setDisableButton) = useState(false)

  useEffectOnce {
    initJar(props.parentProps.selectedJar?.progress.toString())
  }

  useEffect(amountToAdd) {
    if (amountToAdd >= 1) {
      setDisableButton(true)
      props.parentProps.selectedJar?.let {
        var nextProgress = jarProgress + amountToAdd
        // Ensures the progress never exceeds the total
        if (nextProgress >= it.total) {
          nextProgress = it.total
          // Jar filled, show confetti
          CoroutineScope(Dispatchers.Main).launch {
            startConfetti()
            delay(10000)
            stopConfetti()
          }
        }
        if (nextProgress <= it.total) {
          setJarProgress(nextProgress)
          // debounce actually saving to the server
          editDebounced(
            props = props.parentProps,
            nextProgress = nextProgress,
            onEdit = {
              setAmountToAdd(0)
              setDisableButton(false)
            }
          )
        }
      }
    }
  }

  val group = props.parentProps.currentGroup
  val jar = props.parentProps.selectedJar
  if (group != null && jar != null) {
    styledDiv {
      css {
        padding = "24px"
      }
//      styledCanvas {
//        attrs.id = "confetti-canvas"
//        css {
//          position = Position.absolute
//          width = 100.pct
//        }
//      }
      styledDiv {
        css.float = Float.left
        css.cursor = Cursor.pointer
        attrs.onClickFunction = {
          props.parentProps.setSelectedJar(null)
        }
        faIcon("fa-arrow-left", iconColor = Color("#000"))
        styledSpan {
          css.marginLeft = 6.px
          + "Go Back"
        }
      }
      styledDiv {
        css(GlobalCss.flexColumn)
        css {
          gap = 6.px
          borderBottomColor = Color("#eee")
          borderBottomWidth = 1.px
          borderBottomStyle = BorderStyle.solid
          width = 100.pct
          marginBottom = 12.px
        }
        styledP {
          css {
            fontSize = 22.px
            textAlign = TextAlign.center
          }
          + jar.name
        }
        styledP {
          css {
            fontSize = 16.px
            textAlign = TextAlign.center
          }
          + "Progress $jarProgress / ${jar.total} (${((jarProgress / jar.total.toFloat()) * 100).toInt()}%)"
        }
      }
      styledP {
        css {
          marginTop = 8.px
          marginBottom = 8.px
        }
        + "Amount"
      }

      styledInput {
        css(LoginCardCss.inputStyle)
        css {
          width = 280.px
          marginBottom = 4.px
        }
        attrs.min = "1"
        attrs.max = "100"
        attrs.step = "1"
        attrs.value = amount.toString()
        attrs.type = InputType.number
        attrs.placeholder = "Amount"
        attrs.onChangeFunction = { event ->
          val target = event.target as HTMLInputElement
          setAmount(target.value.toInt())
        }
      }
      Button(
        text = "Add to Jar",
        customCss = { width = 280.px },
        disabled = (disableButton || jar.progress >= jar.total)
      ) {
        addToJar(amount.toString())
        setAmountToAdd(amountToAdd + amount)
      }
      styledDiv {
        css {
          display = Display.flex
          justifyContent = JustifyContent.center
          alignItems = Align.center
          width = 100.pct
          marginTop = (-100).px
        }
        attrs.id = "jar-extension-container"
      }
    }
  }
}

private var editJob: Job? = null

fun editDebounced(
  props: DashboardProps,
  nextProgress: Int,
  onEdit: () -> Unit
) {
  editJob?.cancel()
  editJob = CoroutineScope(Dispatchers.Main).launch {
    delay(2000)
    val jar = props.selectedJar ?: return@launch
    val updatedJar = jar.copy(progress = nextProgress)
    props.editJar(updatedJar, props.currentGroup!!.id)
    onEdit()
  }
}

external interface JarDetailProps : Props {
  var parentProps: DashboardProps
}

private fun addToJar(amount: String) {
  js("addToJar(amount)")
}

private fun initJar(
  progress: String,
) {
  js("initJar(progress)")
}

private fun startConfetti() {
  js("startConfetti()")
}

private fun stopConfetti() {
  js("stopConfetti()")
}


