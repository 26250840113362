package components.forms
import com.jet.classroomhero.entities.Class
import components.widgets.Button
import kotlinx.css.*
import kotlinx.html.id
import kotlinx.html.js.onSubmitFunction
import react.fc
import react.useState
import styled.*

external interface DeleteClassFormProps : BaseFormProps {
    var group: Class?
    var onDeleteClass: (Class) -> Unit }

val DeleteClassForm = fc<DeleteClassFormProps> { props ->
    val (name, setName) = useState(props.group?.name ?: "")
    val (description, setDescription) = useState(props.group?.description ?: "")
    val (errorMessage, setErrorMessage) = useState("")

    styledForm {
        attrs.id = "delete-class-form"
        css {
            display = Display.flex
            flexDirection = FlexDirection.column
            alignItems = Align.center
            justifyContent = JustifyContent.spaceEvenly
        }
        attrs.onSubmitFunction = { event ->
            event.preventDefault()
        }
        styledDiv{
            Button("Delete", loading = props.isLoading) {
                it.preventDefault()
                props.onDeleteClass(props.group!!)
            }
        }


        if (errorMessage.isNotEmpty()) {
            styledP {
                css {
                    color = Color("#B00020")
                    fontSize = 16.px
                    marginTop = 10.px
                }
                +errorMessage
            }
        }
    }
}