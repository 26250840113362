package com.jet.classroomhero.entities

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GroupStats (
    @SerialName("group_id")
    var groupId: Int = 0,
    @SerialName("completed_achievements")
    var completedAchievements: List<Achievement> = emptyList(),
    @SerialName("completed_transactions")
    var completedTransactions: List<Transaction> = emptyList(),
    @SerialName("completed_achievement_count")
    var completedAchievementCount: Int = 0,
    @SerialName("completed_transaction_count")
    var completedTransactionCount: Int = 0,
    @SerialName("achievements_archive")
    var monthlyAchievementsArchive: List<MonthYearTotal> = emptyList(),
    @SerialName("transactions_archive")
    var monthlyTransactionArchive: List<MonthYearTotal> = emptyList(),
    @SerialName("achievements_archive_weekly")
    var weeklyAchievementArchive: List<String> = emptyList(),
    @SerialName("transactions_archive_weekly")
    var weeklyTransactionArchive: List<String> = emptyList(),
    @SerialName("total_coins_earned_all_time")
    var totalCoinsEarnedAllTime: Int = 0,

    var formattedWeeklyAchievementArchive: Map<Int, Int> = emptyMap(),
    var formattedWeeklyTransactionArchive: Map<Int, Int> = emptyMap()
)

/*
    This object holds the total transactions / achievements for any month in any year
 */
@Serializable
data class MonthYearTotal(
    var month: Int = 0,
    var year: Int = 0,
    var total: Int = 0
)

/*
    This object holds the total transactions / achievements for any day in a week
 */
@Serializable
data class WeekDayTotal(
    var weekday: Int = 0,
    var total: Int = 0
)