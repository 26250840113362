package com.jet.classroomhero.data

import com.jet.classroomhero.data.remote.sources.NetworkItemSource
import com.jet.classroomhero.data.remote.sources.NetworkJarSource
import com.jet.classroomhero.entities.Item
import com.jet.classroomhero.entities.Jar
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext

class JarRepository(
  val localUserSource: LocalUserSource,
  private val localJarSource: LocalJarSource,
  private val remoteJarSource: RemoteJarSource = NetworkJarSource(localUserSource)
) {

  val coroutineScope = MainScope()

  suspend fun createJar(jar: Jar, classId: Int): Jar? {
    val creatingItem = coroutineScope.async {
      return@async remoteJarSource.createJar(jar, classId)
    }
    val createdItem = creatingItem.await()
    createdItem?.let {
      localJarSource.saveJar(it, classId)
      return it
    }
    return null
  }

  suspend fun editJar(jar: Jar, classId: Int): Jar? {
    val updatingItem = coroutineScope.async {
      return@async remoteJarSource.editJar(jar, classId)
    }
    val updated = updatingItem.await()
    updated?.let {
      localJarSource.saveJar(it, classId)
      return it
    }
    return null
  }

  suspend fun deleteJar(jarId: Int, classId: Int): Boolean {
    val deletingItem = coroutineScope.async {
      return@async remoteJarSource.deleteJar(jarId, classId)
    }
    val deleted = deletingItem.await()
    if (deleted) {
      withContext(Dispatchers.Default) {
        localJarSource.deleteJar(jarId)
      }
      return true
    }
    return false
  }

  suspend fun getJars(classId: Int, forceFetch: Boolean): List<Jar> {
    if (!forceFetch) {
      val localItems = localJarSource.readJars(classId)
      if (!localItems.isNullOrEmpty()) {
        return localItems
      }
    }
    return try {
      val fetchedJars = remoteJarSource.fetchJars(classId)
      coroutineScope.launch(Dispatchers.Default) {
        // Fire and forget
        localJarSource.saveJars(fetchedJars, classId)
      }
      fetchedJars
    } catch (e: Exception) {
      emptyList()
    }
  }



}

interface LocalJarSource {
  suspend fun saveJar(jar: Jar, classId: Int)
  suspend fun saveJars(jars: List<Jar>, classId: Int)
  suspend fun deleteJar(jarId: Int): Int
  suspend fun readJars(classId: Int): List<Jar>
  suspend fun readJar(jarId: Int): Jar?
}

interface RemoteJarSource {
  @Throws(Exception::class)
  suspend fun createJar(jar: Jar, classId: Int): Jar?
  suspend fun editJar(updatedJar: Jar, classId: Int): Jar?
  suspend fun deleteJar(jarId: Int, classId: Int): Boolean
  suspend fun fetchJars(classId: Int): List<Jar>
}