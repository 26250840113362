package components.profile

import kotlinx.css.*
import kotlinx.css.properties.BoxShadow
import kotlinx.css.properties.Timing
import kotlinx.css.properties.Transition
import kotlinx.css.properties.s
import styled.StyleSheet

object GroupCss : StyleSheet("GroupCss", isStatic = true) {
  val contentContainer by css {
    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = Align.start
    justifyContent = JustifyContent.spaceEvenly
    paddingTop = 2.rem
  }
  val groupsContainer by css {
    maxWidth = 500.px
    width = 100.pct
  }
  val card by css {
    boxShadow += BoxShadow(false, 0.px, 7.px, 29.px, 0.px, rgba(100, 100, 111, 0.2))
    transition += Transition("width", .3.s, Timing.ease, 0.s)
    cursor = Cursor.pointer
    marginBottom = 30.px
    hover {
      // deeper box shadow on hover
      boxShadow += BoxShadow(false, 0.px, 14.px, 38.px, 0.px, rgba(100, 100, 111, 0.2))
    }
    borderRadius = 5.px
    borderColor = Color.transparent
  }
  val cardTop by css {
    display = Display.flex
    flexDirection = FlexDirection.row
    padding = "16px"
    justifyContent = JustifyContent.spaceBetween
  }
  val cardBottom by css {
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.spaceBetween
    width = 100.pct
    paddingLeft = 16.px
    paddingRight = 16.px
    paddingBottom = 16.px
  }
  val titleAndCount by css {
    display = Display.flex
    alignItems = Align.center
    flexDirection = FlexDirection.column
  }
  val grid by css {
    display = Display.flex
    flexWrap = kotlinx.css.FlexWrap.wrap
    children {
      flex(1.0, 1.0, 120.px)
    }
  }
  val item by css {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = Align.center
  }
}