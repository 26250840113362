package com.jet.classroomhero.entities

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Reinforcer (
    @SerialName("reinforcer_id")
    var id: Int = 0,
    var name: String? = null,
    var value: Int? = 0,
    var description: String = "",
    var type: String = "inperson"
) {
    constructor(
        name: String = "",
        value: Int = 0
    ): this(0, name, value)
}

fun Reinforcer.uploadsAllowed() : Boolean {
    return this.type == "remote"
}

