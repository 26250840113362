package com.jet.classroomhero

import com.jet.classroomhero.UtilsCommon.Companion.LOCAL
import com.jet.classroomhero.UtilsCommon.Companion.STAGING

actual class Utils {
    actual companion object {
        actual val isDebug: Boolean = js("BUILD_TYPE") == STAGING || js("BUILD_TYPE") == LOCAL
        actual val baseUrl: String = UtilsCommon.getBaseUrlForBuildConfig()
        // no op. ios only
        actual suspend fun fileToByteArray(filePath: String?): ByteArray? = null

        fun isDebug(): Boolean {
            return js("BUILD_TYPE") == STAGING || js("BUILD_TYPE") == LOCAL
        }

        fun getSessionDomain(): String {
            return if (js("BUILD_TYPE") == LOCAL) ".localhost" else ".classroomhero.com"
        }
    }
}