package com.jet.classroomhero.data

import com.jet.classroomhero.Logger
import com.jet.classroomhero.data.remote.sources.NetworkAchievementSource
import com.jet.classroomhero.entities.Achievement
import com.jet.classroomhero.entities.PaginatedResponse
import com.jet.classroomhero.entities.Reinforcer
import com.jet.classroomhero.entities.Transaction
import kotlinx.coroutines.*

class AchievementRepository(
    private val localUserSource: LocalUserSource,
    private val localAchievementSource: LocalAchievementSource,
    private val remoteAchievementSource: RemoteAchievementSource = NetworkAchievementSource(localUserSource)
) {
    companion object {
        val TAG = AchievementRepository::class.simpleName
        val logger = Logger()
    }

    val coroutineScope: CoroutineScope = MainScope()

    suspend fun readAchievements(groupId: Int): List<Achievement> {
        logger.logMessage("${TAG}: readAchievements()")
        val localAchievements = localAchievementSource.readGroupAchievements(groupId)
        if (!localAchievements.isNullOrEmpty()) {
            return localAchievements
        }
        return emptyList()
    }

    suspend fun fetchUserAchievements(forceFetch: Boolean = false, page: Int = 1, pageSize: Int = 10): PaginatedResponse<Achievement> {
        val paginatedResponse = remoteAchievementSource.fetchUserAchievements(page, pageSize)
        withContext(Dispatchers.Default) {
            saveCompletedAchievements(paginatedResponse.results)
        }
        return paginatedResponse
    }

    suspend fun fetchGroupAchievements(forceFetch: Boolean = false, classId: Int, page: Int = 1, pageSize: Int = 10): PaginatedResponse<Achievement> {
        val paginatedResponse = remoteAchievementSource.fetchGroupAchievements(classId, page, pageSize)
        withContext(Dispatchers.Default) {
            saveCompletedAchievements(paginatedResponse.results)
        }
        return paginatedResponse
    }

    suspend fun fetchMemberAchievements(forceFetch: Boolean = false, classId: Int, memberId: Int, page: Int = 1, pageSize: Int = 10): PaginatedResponse<Achievement> {
        if (!forceFetch) {
            val localAchievements = readMemberAchievements(memberId)
            if (!localAchievements.isNullOrEmpty()) {
                val paginated = PaginatedResponse(results = localAchievements)
                return paginated
            }
        }

        logger.logMessage("CHDEBUG: AchievementRepository.fetchMemberAchievements")
        val paginatedResponse = remoteAchievementSource.fetchMemberAchievements(classId, memberId, page, pageSize)
        withContext(Dispatchers.Default) {
            saveCompletedAchievements(paginatedResponse.results)
        }
        return paginatedResponse
    }

    suspend fun setAchievementSeen(achievementId: Int) {
        remoteAchievementSource.setAchievementSeen(achievementId)
    }

    private suspend fun saveCompletedAchievements(achievements: List<Achievement>) = withContext(Dispatchers.Default) {
        localAchievementSource.saveAchievements(achievements)
    }

    private suspend fun readMemberAchievements(memberId: Int) = withContext(Dispatchers.Default) {
        return@withContext localAchievementSource.readMemberAchievements(memberId)
    }

    private suspend fun readGroupAchievements(groupId: Int) = withContext(Dispatchers.Default) {
        return@withContext localAchievementSource.readGroupAchievements(groupId)
    }

    private suspend fun deleteAchievement(achievementId: Int) = withContext(Dispatchers.Default){
        localAchievementSource.deleteAchievement(achievementId)
    }

}

interface RemoteAchievementSource {
    suspend fun fetchUserAchievements(page: Int = 1, pageSize: Int = 10): PaginatedResponse<Achievement>
    suspend fun fetchGroupAchievements(classId: Int, page: Int = 1, pageSize: Int = 10): PaginatedResponse<Achievement>
    suspend fun fetchMemberAchievements(classId: Int, memberId: Int, page: Int, pageSize: Int): PaginatedResponse<Achievement>

    suspend fun setAchievementSeen(achievementId: Int): Boolean
}

interface LocalAchievementSource {
    suspend fun saveAchievements(achievements: List<Achievement>)
    suspend fun saveAchievement(achievement: Achievement): Boolean
    suspend fun readAchievement(achievementId: Int): Achievement?
    suspend fun readGroupAchievements(groupId: Int): List<Achievement>
    suspend fun readMemberAchievements(memberId: Int): List<Achievement>
    suspend fun deleteAchievement(achievementId: Int)
}