package com.jet.classroomhero.entities

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Achievement(
    var id: Int = 0,
    @SerialName("group_id")
    var groupId: Int? = 0,
    @SerialName("member_id")
    var memberId: Int? = 0,
    @SerialName("member_name")
    var memberName: String,
    @SerialName("achievement_id")
    var achievementId: Int = 0,
    @SerialName("achievement_name")
    var achievementName: String,
    @SerialName("achievement_value")
    var achievementValue: Int,
    @SerialName("seen")
    var seen: Boolean,
    @SerialName("updated_at")
    var updatedAt: String,
    @SerialName("created_at")
    var createdAt: String
)