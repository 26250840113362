package components.modals.students

import com.jet.classroomhero.entities.Student
import components.dashboard.DashboardLocalState
import components.dashboard.DashboardProps
import components.forms.CreateOrEditMemberForm
import components.widgets.Modal
import kotlinx.css.*
import react.RBuilder
import styled.css
import styled.styledDiv
import styled.styledH3

fun RBuilder.renderCreateOrEditMemberModal(
  props: DashboardProps,
  member: Student?
) {
  val isEditing = member != null
  Modal(onClose = {
    props.showModal(true, null)
  }) {
    styledH3 {
      css {
        marginBottom = 20.px
      }
      + if (isEditing) "Edit Member" else "Create a New Member"
    }
    CreateOrEditMemberForm {
      attrs.member = member
      attrs.onCreateMember = { newMember ->
        props.createMember(newMember, props.currentGroup!!.id)
        props.setSelectedMember(newMember)
      }
      attrs.onEditMember = { updatedMember ->
        props.editMember(updatedMember, props.currentGroup!!.id)
        props.setSelectedMember(updatedMember)
      }
      attrs.onDeleteMember = { memberId ->
        props.deleteMember(memberId, props.currentGroup!!.id)
        props.setSelectedMember(null)
      }
      attrs.isEditing = isEditing
      attrs.isLoading = props.isLoading
    }
  }
}