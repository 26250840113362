package actions


import InjectorUtils
import com.jet.classroomhero.entities.*
import components.profile.GroupCss.item
import csstype.HtmlAttributes
import kotlinx.coroutines.delay
import react.dom.aria.AriaRole
import redux.RAction
import store.RThunk
import utils.Request
import utils.Urls
import utils.Utils
import kotlin.js.Date

private val groupUseCases = InjectorUtils.provideGroupUseCases()
private val memberUseCases = InjectorUtils.provideMemberUseCases()
private val reinforcerUseCases = InjectorUtils.provideReinforcerUseCases()
private val templateUseCases = InjectorUtils.provideTemplatesUseCases()
private val achievementUseCases = InjectorUtils.provideAchievementUseCases()
private val extensionUseCases = InjectorUtils.provideExtensionUseCases()

object GroupActions {
    data class GroupState(
        val groups: List<Class> = emptyList(),
        val memberships: List<Class> = emptyList(),
        val currentGroupStats: GroupStats? = null,
        val currentGroup: Class? = null,
        val currentMembership: Class? = null,
        val uploads: List<ReinforcerUpload> = emptyList(),
        val extensions: List<Extension> = emptyList(),
        val stockMedia: StockGroupMediaResponse? = null,
        val templates: List<Template> = emptyList(),
        val isLoading: Boolean = false,
        val error: String = ""
    )

    /* Class Dispatchers */
    fun createGroup(
        group: Class,
        groupIcon: ByteArray?,
        currencyIcon: ByteArray?,
        groupCover: ByteArray?,
        templateId: Int?
    ): RThunk = createGroupRequest(group, groupIcon, currencyIcon, groupCover, templateId)
    fun joinGroup(groupCode: String): RThunk = joinGroupRequest(groupCode)
    fun editGroup(
        group: Class,
        groupIcon: ByteArray?,
        currencyIcon: ByteArray?,
        groupCover: ByteArray?
    ): RThunk = editGroupRequest(group, groupIcon, currencyIcon, groupCover)
    fun deleteGroup(groupId: Int): RThunk = deleteGroupRequest(groupId)
    fun fetchGroups(): RThunk = fetchGroupsRequest()
    fun fetchGroup(groupId: Int): RThunk = fetchGroupRequest(groupId)
    fun fetchMembership(groupId: Int, memberId: Int) = fetchMembershipRequest(groupId, memberId)
    fun fetchMemberships(): RThunk = fetchMembershipsRequest()
    fun fetchStockMedia(): RThunk = fetchStockMediaRequest()
    fun fetchTemplates(): RThunk = fetchTemplatesRequest()
    fun fetchExtensions(): RThunk = fetchExtensionsRequest()
    fun activateExtension(classId: Int, extension: Extension): RThunk = activateExtensionRequest(classId, extension)
    fun deactivateExtension(classId: Int, extension: Extension): RThunk = deactivateExtensionRequest(classId, extension)
    fun populateProfile(): RThunk = populateProfileRequest()
    fun populateClassDashboard(): RThunk = populateClassDashboardRequest()

    data class CreateGroup(val group: Class): RAction
    data class EditGroup(val group: Class): RAction
    data class DeleteGroup(val groupId: Int): RAction
    data class FetchGroups(val groups: List<Class>): RAction
    data class FetchGroup(val group: Class): RAction
    data class FetchMemberships(val memberships: List<Class>): RAction
    data class FetchStockMedia(val stockMedia: StockGroupMediaResponse): RAction
    data class FetchTemplates(val templates: List<Template>): RAction
    data class FetchExtensions(val extensions: List<Extension>): RAction
    data class ActivateExtension(val activate: Boolean, val classId: Int, val extension: Extension): RAction
    data class Error(val message: String): RAction
    data class SetIsLoading(val isLoading: Boolean): RAction
    data class FetchGroupStats(val groupStats: GroupStats): RAction
    data class JoinGroup(val joinedGroup: Class): RAction

    fun reducer(groupState: GroupState = GroupState(), action: RAction): GroupState {
        // Does the actual state updating
        return when (action) {
            is SetIsLoading -> groupState.copy(isLoading = action.isLoading)
            is CreateGroup -> {
                val currentGroups = groupState.groups.toMutableList()
                if (currentGroups.add(action.group)) {
                    groupState.copy(groups = currentGroups, isLoading = false)
                } else {
                    groupState.copy(groups = groupState.groups, isLoading = false)
                }
            }
            is JoinGroup -> {
                val currentMemberships = groupState.memberships.toMutableList()
                if (currentMemberships.add(action.joinedGroup)) {
                    groupState.copy(memberships = currentMemberships, isLoading = false)
                } else {
                    groupState.copy(isLoading = false)
                }
            }
            is FetchGroup -> {
                groupState.copy(currentGroup = action.group)
            }
            is FetchGroups -> {
                groupState.copy(groups = action.groups, isLoading = false)
            }
            is FetchMemberships -> {
                groupState.copy(memberships = action.memberships, isLoading = false)
            }
            is FetchStockMedia -> {
                groupState.copy(stockMedia = action.stockMedia)
            }
            is FetchTemplates -> {
                groupState.copy(templates = action.templates)
            }
            is FetchExtensions -> {
                groupState.copy(extensions = action.extensions)
            }
            is FetchGroupStats -> {
                groupState.copy(currentGroupStats = action.groupStats)
            }
            is EditGroup -> {
                val updatedGroups = groupState.groups.map { group ->
                    if (group.id == action.group.id) {
                        group.copy(
                            id = action.group.id,
                            name = action.group.name,
                            description = action.group.description,
                            groupLogoUrl = action.group.groupLogoUrl,
                            currencyIconUrl = action.group.currencyIconUrl,
                            groupCoverUrl = action.group.groupCoverUrl,
                            currencyName = action.group.currencyName
                        )
                    } else {
                        group
                    }
                }
                groupState.copy(groups = updatedGroups)
            }
            is DeleteGroup -> {
                val updatedGroups = groupState.groups.filterNot { it.id == action.groupId }
                groupState.copy(groups = updatedGroups)
            }
            is ActivateExtension -> {
                val currentGroup = groupState.currentGroup?.copy() ?: return groupState
                val extensions = currentGroup.extensions.toMutableList()
                if (action.activate) {
                    extensions?.add(action.extension)
                } else {
                    extensions.removeAll { it.id == action.extension.id }
                }
                console.log("adding extensions")
                console.log(extensions)
                val group = currentGroup.copy(extensions = extensions)
                return groupState.copy(
                    currentGroup = group
                )
            }
            is MemberActions.CreateMember -> {
                val currentMembers = groupState.currentGroup!!.students.toMutableList()
                if (currentMembers.add(action.member)) {
                    val updatedGroup = groupState.currentGroup!!.copy(students = currentMembers)
                    groupState.copy(currentGroup = updatedGroup.copy())
                } else {
                    groupState.copy()
                }
            }
            is MemberActions.EditMember -> {
                console.log("MemberActions.EditMember")
                val updatedMembers = groupState.currentGroup!!.students.map { member ->
                    if (member.id == action.member.id) {
                        console.log("UPDATING MEMBER")
                        with(action.member) {
                            copy(
                                id,
                                name,
                                firstName,
                                lastName,
                                totalCoins,
                                currentCoins,
                                level,
                                progress,
                                floatingProgress,
                                checkedIn,
                                studentHash
                            )
                        }
                    } else {
                        member
                    }
                }
                val updatedGroup = groupState.currentGroup!!.copy(students = updatedMembers)
                console.log("updateing")
                console.log(updatedGroup)
                return groupState.copy(currentGroup = updatedGroup.copy())
            }
            is MemberActions.DeleteMember -> {
                console.log("DELETING MEMBER")
                val updatedMembers = groupState.currentGroup!!.students.filterNot { it.id == action.memberId }
                val updatedGroup = groupState.currentGroup.copy(students = updatedMembers)
                groupState.copy(currentGroup = updatedGroup.copy())
            }
            is MemberActions.BulkEditMembers -> {
                val updatedList = groupState.currentGroup!!.students.map { member ->
                    val updatedMember = action.members.firstOrNull { it.id == member.id }
                    if (updatedMember != null) {
                        with(updatedMember) {
                            return@map member.copy(
                                id,
                                name,
                                firstName,
                                lastName,
                                totalCoins,
                                currentCoins,
                                level,
                                progress,
                                floatingProgress,
                                checkedIn,
                                studentHash
                            )
                        }
                    }
                    return@map member
                }
                val updatedGroup = groupState.currentGroup.copy(students = updatedList)
                groupState.copy(currentGroup = updatedGroup)
            }
            is AchievementActions.CreateAchievement -> {
                val currentAchievements = groupState.currentGroup!!.reinforcers.toMutableList()
                if (currentAchievements.add(action.reinforcer)) {
                    val updatedAchievements = groupState.currentGroup.copy(reinforcers = currentAchievements)
                    groupState.copy(currentGroup = updatedAchievements.copy())
                } else {
                    groupState.copy()
                }
            }
            is AchievementActions.EditAchievement -> {
                val updatedAchievements = groupState.currentGroup!!.reinforcers.map { reinforcer ->
                    if (reinforcer.id == action.reinforcer.id) {
                        with(action.reinforcer) {
                            copy(id, name, value, type)
                        }
                    } else {
                        reinforcer
                    }
                }
                groupState.copy(currentGroup = groupState.currentGroup.copy(reinforcers = updatedAchievements))
            }
            is AchievementActions.DeleteAchievement -> {
                val updatedAchievements = groupState.currentGroup!!.reinforcers.filterNot { it.id == action.reinforcerId }
                val updatedGroup = groupState.currentGroup.copy(reinforcers = updatedAchievements)
                groupState.copy(currentGroup = updatedGroup.copy())
            }
            is AchievementActions.UpdateCurrentUploads -> {
                if (action.reset) {
                    groupState.copy(uploads = action.uploads)
                } else {
                    val currentUploads = groupState.uploads.toMutableList()
                    currentUploads.addAll(action.uploads)
                    groupState.copy(uploads = currentUploads)
                }
            }
            is AchievementActions.RemoveUpload -> {
                val updatedUploads = groupState.uploads.filterNot { it.id == action.uploadId }
                groupState.copy(uploads = updatedUploads)
            }
            is ItemActions.CreateItem -> {
                val currentItems = groupState.currentGroup!!.items.toMutableList()
                if (currentItems.add(action.item)) {
                    val updatedItems = groupState.currentGroup.copy(items = currentItems)
                    groupState.copy(currentGroup = updatedItems.copy())
                } else {
                    groupState.copy()
                }
            }
            is ItemActions.EditItem -> {
                val updatedItems = groupState.currentGroup!!.items.map { item ->
                    if (item.id == action.item.id) {
                        with(action.item) {
                            copy(id, name, cost)
                        }
                    } else {
                        item
                    }
                }
                groupState.copy(currentGroup = groupState.currentGroup.copy(items = updatedItems))
            }
            is ItemActions.DeleteItem -> {
                val updatedItems = groupState.currentGroup!!.items.filterNot { it.id == action.itemId }
                val updatedGroup = groupState.currentGroup.copy(items = updatedItems)
                groupState.copy(currentGroup = updatedGroup.copy())
            }
            is JarActions.CreateJar -> {
                val currentJars = groupState.currentGroup!!.jars.toMutableList()
                if (currentJars.add(action.jar)) {
                    val updatedJars = groupState.currentGroup.copy(jars = currentJars)
                    groupState.copy(currentGroup = updatedJars.copy())
                } else {
                    groupState.copy()
                }
            }
            is JarActions.EditJar -> {
                val updatedJars = groupState.currentGroup!!.jars.map { jar ->
                    if (jar.id == action.jar.id) {
                        with(action.jar) {
                            copy(id, name, total, progress, createdAt, updatedAt)
                        }
                    } else {
                        jar
                    }
                }
                groupState.copy(currentGroup = groupState.currentGroup.copy(jars = updatedJars))
            }
            is JarActions.DeleteJar -> {
                val updatedJars = groupState.currentGroup!!.jars.filterNot { it.id == action.jarId }
                val updatedGroup = groupState.currentGroup.copy(jars = updatedJars)
                groupState.copy(currentGroup = updatedGroup.copy())
            }
            is Error -> groupState.copy(error = action.message, isLoading = false)
            else -> groupState.copy()
        }
    }
}

private val populateClassDashboardRequest: () -> RThunk = {
    Request(
        onBeforeAction = AppStateActions.SetGlobalLoading(true),
        doInBackground = { dispatch ->
            delay(800)
            val groupId = Urls.getGroupIdFromUrl()
            // Fetch all groups and memberships
            dispatch(GroupActions.FetchGroup(groupUseCases.getGroup(Urls.getGroupIdFromUrl())))
            dispatch(GroupActions.FetchGroups(groupUseCases.getClasses(true)))
            groupUseCases.getGroupStats(groupId).let { groupStats ->
                groupStats.formattedWeeklyAchievementArchive = Utils.processDateTimesForChart(groupStats.weeklyAchievementArchive)
                groupStats.formattedWeeklyTransactionArchive = Utils.processDateTimesForChart(groupStats.weeklyTransactionArchive)
                dispatch(GroupActions.FetchGroupStats(groupStats))
            }
            reinforcerUseCases.getRequirements(groupId).let { uploads ->
                dispatch(AchievementActions.UpdateCurrentUploads(uploads, true))
            }
            dispatch(GroupActions.FetchExtensions(extensionUseCases.getExtensions()))
        },
        onError = { message, dispatch -> dispatch(GroupActions.Error(message)) },
        onSuccess = { dispatch ->
            //dispatch(AppStateActions.SetModalShowing(false, null))
        },
        onComplete = AppStateActions.SetGlobalLoading(false)
    )
}

private val populateProfileRequest: () -> RThunk = {
    Request(
        onBeforeAction = AppStateActions.SetGlobalLoading(true),
        doInBackground = { dispatch ->
            delay(800)
            // Fetch all groups and memberships
            with(groupUseCases) {
                dispatch(GroupActions.FetchGroups(getClasses(true)))
                dispatch(GroupActions.FetchMemberships(getMemberships(true)))
                dispatch(GroupActions.FetchStockMedia(fetchStockMedia()))
            }
            // Fetch Templates
            dispatch(GroupActions.FetchTemplates(templateUseCases.getTemplates(false)))
            // Fetch User Achievements
            dispatch(AchievementActions.UpdateCompletedAchievements(achievementUseCases.fetchUserAchievements(
                forceFetch = true,
                pageSize = 100
            ).results))
        },
        onError = { message, dispatch -> dispatch(GroupActions.Error(message)) },
        onSuccess = { dispatch ->
            //dispatch(AppStateActions.SetModalShowing(false, null))
        },
        onComplete = AppStateActions.SetGlobalLoading(false)
    )
}

private val createGroupRequest: (
    group: Class,
    groupIcon: ByteArray?,
    currencyIcon: ByteArray?,
    groupCover: ByteArray?,
    templateId: Int?
) -> RThunk = { group, groupIcon, currencyIcon, groupCover, templateId ->
    Request(
        doInBackground = { dispatch ->
            groupUseCases.addClass(group, currencyIcon, groupIcon, groupCover, templateId)?.let { newGroup ->
                dispatch(GroupActions.CreateGroup(newGroup))
            }
        },
        onError = { message, dispatch -> dispatch(GroupActions.Error(message)) },
        onSuccess = { dispatch ->
            dispatch(AppStateActions.SetModalShowing(false, null))
        }
    )
}

private val joinGroupRequest: (groupCode: String) -> RThunk = { groupCode ->
    Request(
        doInBackground = { dispatch ->
            groupUseCases.completeInvite(groupCode)?.let { joinedGroup ->
                dispatch(GroupActions.JoinGroup(joinedGroup))
            }
        },
        onError = { message, dispatch -> dispatch(GroupActions.Error(message)) },
        onSuccess = { dispatch ->
            dispatch(AppStateActions.SetModalShowing(false, null))
        }
    )
}

private val editGroupRequest: (group: Class, groupIcon: ByteArray?, currencyIcon: ByteArray?, groupCover: ByteArray?) -> RThunk = { group, groupIcon, currencyIcon, groupCover ->
    Request(
        doInBackground = { dispatch ->
            groupUseCases.editClass(group, currencyIcon, groupIcon, groupCover)?.let { updatedGroup ->
                dispatch(GroupActions.EditGroup(updatedGroup))
            }
        },
        onError = { message, dispatch -> dispatch(GroupActions.Error(message)) },
        onSuccess = { dispatch ->
            dispatch(AppStateActions.SetModalShowing(false, null))
        }
    )
}

private val deleteGroupRequest: (groupId: Int) -> RThunk = { id ->
    Request(
        doInBackground = { dispatch ->
            val success = groupUseCases.deleteClass(id)
            if (success) {
                dispatch(GroupActions.DeleteGroup(id))
            } else {
                throw Exception("An error occurred deleting this class")
            }
        },
        onError = { message, dispatch -> dispatch(GroupActions.Error(message)) },
        onSuccess = { dispatch ->
            dispatch(AppStateActions.SetModalShowing(false, null))
        }
    )
}

private val fetchGroupRequest: (groupId: Int) -> RThunk = { groupId ->
    Request(
        doInBackground = { dispatch ->
            groupUseCases.getGroup(groupId).let { group ->
                dispatch(GroupActions.FetchGroup(group))
            }
            groupUseCases.getGroupStats(groupId).let { groupStats ->
                groupStats.formattedWeeklyAchievementArchive = Utils.processDateTimesForChart(groupStats.weeklyAchievementArchive)
                groupStats.formattedWeeklyTransactionArchive = Utils.processDateTimesForChart(groupStats.weeklyTransactionArchive)
                dispatch(GroupActions.FetchGroupStats(groupStats))
            }
            reinforcerUseCases.getRequirements(groupId).let { uploads ->
                dispatch(AchievementActions.UpdateCurrentUploads(uploads, true))
            }
        },
        onError = { message, dispatch -> dispatch(GroupActions.Error(message)) },
        onSuccess = { dispatch ->
            GroupActions.SetIsLoading(false)
        }
    )
}

private val fetchGroupsRequest: () -> RThunk = {
    Request(
        doInBackground = { dispatch ->
            groupUseCases.getClasses(true).let { groups ->
                dispatch(GroupActions.FetchGroups(groups))
            }
        },
        onError = { message, dispatch -> dispatch(GroupActions.Error(message)) },
        onSuccess = { dispatch ->
            //dispatch(AppStateActions.SetModalShowing(false))
        }
    )
}

private val fetchMembershipsRequest: () -> RThunk = {
    Request(
        doInBackground = { dispatch ->
            groupUseCases.getMemberships(true).let { memberships ->
                dispatch(GroupActions.FetchMemberships(memberships))
            }
        },
        onError = { message, dispatch -> dispatch(GroupActions.Error(message)) },
        onSuccess = { dispatch ->
            //dispatch(AppStateActions.SetModalShowing(false))
        }
    )
}

private val fetchMembershipRequest: (groupId: Int, memberId: Int) -> RThunk = { groupId, memberId ->
    Request(
        doInBackground = { dispatch ->
            val group = groupUseCases.getGroup(groupId)
            val member = memberUseCases.getStudent(true, groupId, memberId)
            val uploads = reinforcerUseCases.getRequirements(groupId)
            dispatch(AppStateActions.SetSelectedGroup(group))
            dispatch(AppStateActions.SetSelectedMember(member))
            dispatch(AchievementActions.UpdateCurrentUploads(uploads, true))
        },
        onError = { message, dispatch -> dispatch(GroupActions.Error(message)) },
        onSuccess = { dispatch ->
            GroupActions.SetIsLoading(false)
        },
    )
}

private val fetchStockMediaRequest: () -> RThunk = {
    Request(
        doInBackground = { dispatch ->
            groupUseCases.fetchStockMedia().let { stockMedia ->
                dispatch(GroupActions.FetchStockMedia(stockMedia))
            }
        },
        onError = { message, dispatch -> dispatch(GroupActions.Error(message)) },
        onSuccess = { dispatch ->
            console.log("Successfully fetched stock media")
        },
    )
}

private val fetchTemplatesRequest: () -> RThunk = {
    Request(
        doInBackground = { dispatch ->
           val templates = templateUseCases.getTemplates(false)
           dispatch(GroupActions.FetchTemplates(templates))
        },
        onError = { message, dispatch -> dispatch(GroupActions.Error(message)) },
        onSuccess = { dispatch ->
            console.log("Successfully fetched templates")
        }
    )
}

private val fetchExtensionsRequest: () -> RThunk = {
    Request(
        doInBackground = { dispatch ->
            val extensions = extensionUseCases.getExtensions()
            dispatch(GroupActions.FetchExtensions(extensions))
        },
        onError = { message, dispatch -> dispatch(GroupActions.Error(message)) },
        onSuccess = { dispatch ->
            console.log("Successfully fetched templates")
        }
    )
}

private val activateExtensionRequest: (classId: Int, extension: Extension) -> RThunk = { classId, extension ->
    Request(
        doInBackground = { dispatch ->
            val success = extensionUseCases.activateExtension(classId, extension.id)
            if (success) {
                dispatch(GroupActions.ActivateExtension(true, classId, extension))
            }
        },
        onError = { message, dispatch -> dispatch(GroupActions.Error(message)) },
        onSuccess = { dispatch ->
            console.log("Successfully fetched templates")
        }
    )
}

private val deactivateExtensionRequest: (classId: Int, extension: Extension) -> RThunk = { classId, extension ->
    Request(
        doInBackground = { dispatch ->
            val success = extensionUseCases.deactivateExtension(classId, extension.id)
            if (success) {
                dispatch(GroupActions.ActivateExtension(false, classId, extension))
            }
        },
        onError = { message, dispatch -> dispatch(GroupActions.Error(message)) },
        onSuccess = { dispatch ->
            console.log("Successfully fetched templates")
        }
    )
}