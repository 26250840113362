package containers

import actions.AccountActions
import components.settings.AccountSettingsComponent

import com.jet.classroomhero.entities.User
import components.settings.AccountSettingsProps
import kotlinx.coroutines.Dispatchers
import react.ComponentClass
import react.Props
import react.invoke
import react.redux.rConnect
import redux.RAction
import redux.WrapperAction
import store.AppState

private external interface AccountSettingsComponentStateProps : Props {
    var user: User
    var isEditing: Boolean
    var errorMessage: String
}

external interface AccountSettingsComponentDispatcherProps : Props {
    var setEditing: (Boolean) -> Unit
    var editAccount: (firstName: String, lastName: String) -> Unit
    var changeUsername: (username: String) -> Unit
    var changeEmail: (email: String) -> Unit
}

val accountSettingsComponent: ComponentClass<Props> = rConnect<AppState, RAction, WrapperAction, Props, AccountSettingsComponentStateProps, AccountSettingsComponentDispatcherProps, AccountSettingsProps>(
    { state, _ ->
        user = state.accountState.user
        isEditing = state.appState.isEditing
        errorMessage = state.accountState.error
    },
    { dispatch, _ ->
        editAccount = {firstName, lastName -> dispatch(AccountActions.editAccount(firstName, lastName, "", "", ""))}
        changeUsername = {username -> dispatch(AccountActions.changeUsername(username))}
        changeEmail = {email -> dispatch(AccountActions.changeEmail(email))}

    }
)(AccountSettingsComponent::class.js.unsafeCast<ComponentClass<AccountSettingsProps>>())