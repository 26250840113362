package com.jet.classroomhero.entities

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ReinforcerUploadFeedback(
  val id: Int,
  val feedback: String,
  @SerialName("updated_at")
  var updatedAt: String,
  @SerialName("created_at")
  var createdAt: String
)