package com.jet.classroomhero.data

import com.jet.classroomhero.Logger
import com.jet.classroomhero.data.remote.sources.NetworkItemSource
import com.jet.classroomhero.entities.Item
import com.jet.classroomhero.entities.User
import kotlinx.coroutines.*

class ItemRepository(
    val localUserSource: LocalUserSource,
    private val localItemSource: LocalItemSource,
    private val remoteItemSource: RemoteItemSource = NetworkItemSource(localUserSource)
) {

    val logger = Logger()

    val coroutineScope: CoroutineScope = MainScope()

    private suspend fun readUser(): User? {
        return withContext(Dispatchers.Default) {
            return@withContext localUserSource.readUser()
        }
    }

    suspend fun createItem(newItem: Item, classId: Int): Item? {
        logger.logMessage("COMMON: ClassRepository.createClass")
        val creatingItem = coroutineScope.async {
            return@async remoteItemSource.createItem(newItem, classId)
        }
        val createdItem = creatingItem.await()
        createdItem?.let {
            saveItem(it, classId)
            return it
        }
        return null
    }

    suspend fun editItem(updatedItem: Item, classId: Int): Item? {
        logger.logMessage("COMMON: ClassRepository.editClass")
        val updatingItem = coroutineScope.async {
            return@async remoteItemSource.editItem(updatedItem, classId)
        }
        val updated = updatingItem.await()
        updated?.let {
            saveItem(it, classId)
            return it
        }
        return null
    }

    suspend fun deleteItem(itemId: Int, classId: Int): Boolean {
        logger.logMessage("COMMON: ClassRepository.deleteClass")
        val deletingItem = coroutineScope.async {
            return@async remoteItemSource.deleteItem(itemId, classId)
        }
        val deleted = deletingItem.await()
        if (deleted) {
            val rows = withContext(Dispatchers.Default) {
                localItemSource.deleteItem(itemId)
            }
            return true // deleted exactly 1 row
        }
        return false
    }

    suspend fun getItems(forceFetch: Boolean = false, classId: Int): List<Item> {
        logger.logMessage("CHDEBUG: ClassRepository.getClasses")
        if (!forceFetch) {
            val localItems = readItems(classId)
            if (!localItems.isNullOrEmpty()) {
                return localItems
            }
        }
        return try {
            val fetchedItems = remoteItemSource.fetchItems(classId)
            coroutineScope.launch(Dispatchers.Default) {
                // Fire and forget
                localItemSource.saveItems(fetchedItems, classId)
            }
            fetchedItems
        } catch (e: Exception) {
            emptyList()
        }
    }

    suspend fun getItem(itemId: Int): Item {
        logger.logMessage("CHDEBUG: ClassRepository.getItem")
        return withContext(Dispatchers.Default) {
            localItemSource.readItem(itemId)
        }
    }

    private suspend fun saveItem(newItem: Item, classId: Int) = withContext(Dispatchers.Default) {
        return@withContext localItemSource.saveItem(newItem, classId)
    }

    private suspend fun readItems(classId: Int) = withContext(Dispatchers.Default) {
        return@withContext localItemSource.readItems(classId)
    }
}

interface LocalItemSource {
    suspend fun saveItem(item: Item, classId: Int)
    suspend fun saveItems(items: List<Item>, classId: Int)
    suspend fun deleteItem(itemId: Int): Int
    suspend fun readItems(classId: Int): List<Item>
    suspend fun readItem(itemId: Int): Item
    suspend fun deleteGroupItems(groupId: Int)
}

interface RemoteItemSource {
    @Throws(Exception::class)
    suspend fun createItem(newItem: Item, classId: Int): Item?
    suspend fun editItem(updatedItem: Item, classId: Int): Item?
    suspend fun deleteItem(itemId: Int, classId: Int): Boolean
    suspend fun fetchItems(classId: Int): List<Item>
}