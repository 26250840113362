package sources.local

import actions.MemberActions
import appStore
import com.jet.classroomhero.data.LocalStudentSource
import com.jet.classroomhero.entities.Student

class CacheMemberSource : LocalStudentSource {
    override suspend fun saveStudent(newStudent: Student, classId: Int) {
        appStore?.dispatch(MemberActions.updateMember(newStudent))
    }
    override suspend fun saveStudents(students: List<Student>, classId: Int) {}
    override suspend fun readStudent(memberId: Int): Student { return Student() }
    override suspend fun readStudents(classId: Int): List<Student> { return emptyList() }
    override suspend fun readStudents(memberIds: List<Int>): List<Student> { return emptyList() }
    override suspend fun deleteStudent(studentId: Int): Int { return 1 }
    override suspend fun savePhotoUrl(memberId: Int, photoUrl: String) {}
    override suspend fun deleteGroupStudents(groupId: Int) {}
    override suspend fun unlinkStudent(studentId: Int) {

    }
}