package com.jet.classroomhero.entities

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PaginatedResponse<T> (
    var count: Int = 0,
    var pages: Int = 0,
    @SerialName("current_page")
    var currentPage: Int? = 1,
    var results: List<T> = emptyList()
)