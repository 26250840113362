package sources.local

import com.jet.classroomhero.data.LocalTemplateSource
import com.jet.classroomhero.entities.Template
import kotlinx.browser.window
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

class CacheTemplateSource : LocalTemplateSource {
  override suspend fun saveTemplates(templates: List<Template>) {
    val templateJson = Json.encodeToString(templates)
    window.localStorage.setItem("TEMPLATES", templateJson)
  }

  override suspend fun saveTemplate(template: Template) {

  }

  override suspend fun readTemplates(): List<Template> {
    val localTemplates = window.localStorage.getItem("TEMPLATES") ?: "[]"
    return Json.decodeFromString(localTemplates)
  }
}