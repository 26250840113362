package com.jet.classroomhero.data.remote.sources

import com.jet.classroomhero.data.LocalUserSource
import com.jet.classroomhero.data.RemoteJarSource
import com.jet.classroomhero.entities.Item
import com.jet.classroomhero.entities.Jar
import io.ktor.client.call.body
import io.ktor.client.request.delete
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.put
import io.ktor.client.request.setBody
import io.ktor.client.statement.HttpResponse
import io.ktor.http.ContentType
import io.ktor.http.contentType
import io.ktor.http.isSuccess
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext

class NetworkJarSource(localUserSource: LocalUserSource) : ProtectedNetworkSource(localUserSource), RemoteJarSource {
  override suspend fun createJar(jar: Jar, classId: Int): Jar? = withContext(Dispatchers.Default) {
    val url = "${baseUrl}api/classes/${classId}/jar/create/"
    return@withContext client.post(url) {
      contentType(ContentType.Application.Json)
      setBody(jar)
    }.body()
  }

  override suspend fun editJar(updatedJar: Jar, classId: Int): Jar? = withContext(Dispatchers.Default) {
    val url = "${baseUrl}api/classes/${classId}/jar/${updatedJar.id}/edit/"
    return@withContext client.put(url) {
      contentType(ContentType.Application.Json)
      setBody(updatedJar)
    }.body()
  }

  override suspend fun deleteJar(jarId: Int, classId: Int): Boolean = withContext(Dispatchers.Default) {
    val url = "${baseUrl}api/classes/${classId}/jar/${jarId}/delete/"
    val response: HttpResponse = client.delete(url)
    return@withContext response.status.isSuccess()
  }

  override suspend fun fetchJars(classId: Int): List<Jar> = withContext(Dispatchers.Default) {
    val url = "${baseUrl}api/classes/${classId}/jars/"
    return@withContext client.get(url) {
      contentType(ContentType.Application.Json)
    }.body()
  }
}