package com.jet.classroomhero.entities

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Template (
    val id: Int = 0,
    val name: String = "",
    val description: String = "",
    @SerialName("photo")
    val imageUrl: String = ""
)