package components.forms

import com.jet.classroomhero.entities.Student
import components.accounts.LoginCardCss
import components.widgets.Button
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onSubmitFunction
import org.w3c.dom.HTMLInputElement
import react.Props
import react.fc
import react.useState
import styled.css
import styled.styledForm
import styled.styledInput

external interface AdjustCurrencyFormProps : BaseFormProps {
  var member: Student
  var onSubmit: (Student) -> Unit
}

val AdjustCurrencyForm = fc<AdjustCurrencyFormProps> { props ->
  val (currentCoins, setCurrentCoins) = useState(props.member.currentCoins)
  styledForm {
    attrs.id = "member-" + props.member.id.toString() + "adjust-currency-form"
    css {
      display = Display.flex
      flexDirection = FlexDirection.column
      alignItems = Align.center
      justifyContent = JustifyContent.spaceEvenly
    }
    styledInput {
      css(LoginCardCss.inputStyle)
      attrs.value = currentCoins.toString()
      attrs.type = InputType.number
      attrs.placeholder = "Current Currency"
      attrs.onChangeFunction = { event ->
        val target = event.target as HTMLInputElement
        setCurrentCoins(target.value.toInt())
      }
    }
    Button("Update", loading = props.isLoading) {
      it.preventDefault()
      props.onSubmit(props.member.copy(currentCoins = currentCoins))
    }
  }
}