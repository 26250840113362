package components.modals.students

import components.dashboard.DashboardProps
import components.forms.AdjustCurrencyForm
import components.modals.ModalType
import components.widgets.Modal
import kotlinx.css.*
import react.RBuilder
import styled.css
import styled.styledDiv
import styled.styledH3

fun RBuilder.renderAdjustCurrencyModal(props: DashboardProps) {
  Modal(onClose = {
    props.showModal(true, ModalType.MEMBER_DETAIL)
  }) {
    styledDiv {
      css {
        display = Display.flex
        alignItems = Align.center
        flexDirection = FlexDirection.column
      }
      styledH3 {
        css {
          marginBottom = 20.px
        }
        +"Adjust Currency"
      }
      AdjustCurrencyForm {
        attrs.member = props.selectedMember!!
        attrs.onSubmit = { member ->
          props.editMember(member, props.currentGroup!!.id)
          props.setSelectedMember(member)
        }
        attrs.isLoading = props.isLoading
      }
    }
  }
}