package utils

import com.jet.classroomhero.entities.ReinforcerUpload
import kotlinx.browser.document
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Int8Array
import org.khronos.webgl.get
import org.w3c.dom.Document

fun Document.getCookie(name: String): String? {
    val cookies = document.cookie
       val parts = cookies.split("; ${name}=").toMutableList()
    if (parts.size == 2) return parts.removeLast().split(';').toMutableList().removeLast()
    return null
}

fun Document.getCookieValue(name: String): String? {
    val currentCookies = this.cookie.split(";")
    for (cookiePair in currentCookies) {
        val cookieKeyValue = cookiePair.split("=")
        if (cookieKeyValue.size < 2) {
          continue
        }
        if (cookieKeyValue[1].isEmpty()) {
          continue
        }
        if (cookieKeyValue.first().trim() == name.trim()) {
          return cookieKeyValue[1].trim()
        }
    }
    return null
}

fun ArrayBuffer.toByteArray(): ByteArray {
    val intArray = Int8Array(this)
    val byteArray = ByteArray(intArray.length) { pos ->
        intArray.get(pos)
    }
    return byteArray
}

fun ReinforcerUpload.getFontAwesomeIconForType(): String {
    return when (this.fileType) {
      "image" -> {
          "fa-image"
      }
      "video" -> {
          "fa-video"
      }
      "text" -> {
          "fa-file-text"
      }
      else -> {
          "fa-file"
      }
    }
}

sealed class Single<out Any> {
    object Success : Single<Nothing>()
}

fun String.pluralizeIf(condition: () -> Boolean): String {
    if (condition()) {
        return this + "s"
    }
    return this
}