import com.jet.classroomhero.Utils
import com.jet.classroomhero.UtilsCommon
import com.jet.classroomhero.entities.User
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import org.w3c.dom.url.URLSearchParams
import react.dom.render
import react.redux.provider
import redux.RAction
import redux.Store
import redux.compose
import redux.rEnhancer
import store.*

@JsModule("@js-joda/timezone")
@JsNonModule
external object JsJodaTimeZoneModule

private val jsJodaTz = JsJodaTimeZoneModule

var appStore: Store<AppState, RAction, dynamic>? = null

fun main() {
    window.onload = {
        val rootDiv = document.getElementById("root")!!
        val initialState = AppState()

        loadInitial() { user ->
            if (!Utils.isDebug()) {
                js("startFirebaseTracking()")
            } else {
                console.log("Skipping firebase initialization")
            }
            if (user != null) {
                initialState.accountState = initialState.accountState.copy(user = user)
            }
            appStore = createStore<AppState, RAction, dynamic>(
                ::rootReducer, initialState, compose(
                    rThunk(),
                    rEnhancer(),
                    //js("window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__ ()")
                )
            )
            appStore?.let { store ->
                render(rootDiv) {
                    provider(store) {
                        app()


                    }
                }
            }
        }
    }
}

private fun loadInitial(onComplete: (user: User?) -> Unit) {
    val userUseCases = InjectorUtils.provideUserUseCases()
    CoroutineScope(Dispatchers.Unconfined).launch {
        userUseCases.readUser()?.let {
            console.log(it)
            userUseCases.saveUser(it)
        } ?: run { onComplete(null) }
        try {
            onComplete(userUseCases.userDetail())
        } catch (e: Exception) {
            onComplete(null)
        }
    }
}

private fun setUserTokens(): User? {
    val url = window.location.search
    val params = URLSearchParams(url)
    val token = params.get("token")
    val refresh = params.get("refresh")
    if (!token.isNullOrEmpty() && !refresh.isNullOrEmpty()) {
        return User(token = token, refresh = refresh)
    }
    return null
}